import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registerInfo',
  templateUrl: './registerInfo.component.html',
  styleUrls: ['./registerInfo.component.css']
})

export class UserRegisterInfoComponent implements OnInit {

  title: string = '給与前払いサービス－会員登録完了';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }

  // 利用を開始するボタンを押す
  onRegisterLogin() {
    sessionStorage.setItem('userLoggedIn','true');
    this.router.navigate(['/userPrePayStart']); // 前払申請画面へ
  }
}