import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transferFeePatternModifyInfo',
  templateUrl: './transferFeePatternModifyInfo.component.html',
  styleUrls: ['./transferFeePatternModifyInfo.component.css']
})
export class AdminTransferFeePatternModifyInfoComponent implements OnInit {

  title: string = '振込手数料マスタ変更完了';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '振込手数料マスタ変更完了'); // タイトル
  }


  // 閉じるボタンを押す
  onCloseClick() {
    this.router.navigate(['/adminBankDataSearch']); // 銀行情報照会画面
  }
}
