import { Component, Inject, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from './../../../environments/environment.dev';


export interface DialogData {  
  errorMsg: string;
  id: string;
  messageCode: string;
}

// エラーのダイアログ
@Component({
  templateUrl: 'errorDialog.html',
  styleUrls: ['errorDialog.css'],
})
export class AdminErrorDialog {

  errorMsg: string; // エラーメッセージ
  errorId: string; // エラーID
  messageCode: string; // エラーコード

  constructor(
    private dialogRef: MatDialogRef<AdminErrorDialog>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      this.dialogRef.disableClose = true;
      this.errorMsg = data.errorMsg;
      this.errorId = data.id;
      this.messageCode = data.messageCode;
    }
    closeDialog(): void{
      this.dialogRef.close();
      if(this.errorId === 'sysError'){
        this.router.navigate(['/adminLogin']); // ログイン画面へ
      }
    }
}
@Injectable({
  providedIn: 'root'
})
export class AdminAuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  // 検証
  public confirmSignUp(userId, code): boolean {
    return true;
  }

  // トーケンの取得
  public getToken(pageId: string, uuId: string, sessionId: string, scope: string) {
    const apiUrl = environment.adminUrl.authServiceApiUrl;
    // header
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer app_version=1.0.0'
    });
    // body
    const body = JSON.stringify({
      commonHeader: {
        pageId: pageId,
        eventId: 'access_token_get',
        uuId: uuId,
        sessionId: sessionId
      },
      parameters: {
        scope: scope
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}access_token_get`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // header
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];

          sessionStorage.setItem('tokenParameters', JSON.stringify(parametersData));
          if (commonHeaderData['messageCode'] !== '02000') {
            this.resDialog(commonHeaderData['message']);
          }
        },
        error => {
          this.errDialog(error);
        }
      );
  }

  // ログアウト
  public signOut() {
    const apiUrl = environment.adminUrl.authServiceApiUrl;
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // header
    const headers = new HttpHeaders();
    // headers.append('x-forwarded-for', '10.197.216.93');
    headers.append('Content-Type', 'application/json');

    // body
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0020',
        eventId: 'admin_logout',
        uuId: adminSession['uuId'],
        sessionId: adminSession['sessionId']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}admin_logout`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.removeItem('adminSession');
          sessionStorage.removeItem('adminParameters');
          sessionStorage.removeItem('adminLoggedIn');
          if (commonHeaderData['messageCode'] !== '02000') {
            this.resDialog(commonHeaderData['message']);
          }
        },
        error => {
          this.errDialog(error);
        }
      );
  }

  // ログイン状態の取得
  public isAuthenticated(): boolean {
    // if(!this.adminLoggedIn.value){
    if (sessionStorage.getItem('adminLoggedIn') === 'true') {
      return true;
    } else {
      return false;
    }
  }

  public warnLog(data, callback) {
    const dialogConfigWarn = new MatDialogConfig();
    dialogConfigWarn.data = {
      id: '',
      title: data['title'],
      message: data['message'],
    };
    dialogConfigWarn.height = '200px';
    const dialogRef = this.dialog.open(AdminWarnLog, dialogConfigWarn);
    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });
  } 

  public messageLog(data) {
    const dialogConfigMessage = new MatDialogConfig();
    dialogConfigMessage.data = {
      id: '',
      title: data['title'],
      message: data['message'],
    };
    dialogConfigMessage.maxWidth = '500px';
    this.dialog.open(AdminMessageLog, dialogConfigMessage);
  }

  // エラーダイアログ
  public errDialog(e){
    const dialogConfig = new MatDialogConfig();
    if (e['error']['commonHeader']){
      let id = 'sysError';
      let messageCode = e['error']['commonHeader']['messageCode'];
      let errorMsg = e['error']['commonHeader']['message'];
      let errCode = messageCode.substr(2,1);
      if (errCode !== '9' && messageCode !== '02103'){
        id = 'dataError';
      }
      dialogConfig.data = {
        id: id,
        messageCode: messageCode,
        errorMsg: errorMsg.replace('<br>', '\n')
      };
      if(id === 'sysError'){
        sessionStorage.setItem('adminLoggedIn','false');
      }
    } else {
      dialogConfig.data = {
        id: 'sysError',
        messageCode: null,
        errorMsg: e['message']
      };
      sessionStorage.setItem('adminLoggedIn','false');
    }
    dialogConfig.height = '200px'; 
    this.dialog.open(AdminErrorDialog, dialogConfig);
  }


  // リスポンスダイアログ
  public resDialog(commonHeaderData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id : 'dataError',
      errorMsg: commonHeaderData['message'],
      messageCode: commonHeaderData['messageCode']
    };
    dialogConfig.height = '200px';
    this.dialog.open(AdminErrorDialog, dialogConfig);
  }

  // 性別編集
  genderStr(str) {
    // 性別種別 '１:男性'、'２:女性'、'３:非公開'
    if (str === '1') {
      return '男性';
    } else if (str === '2') {
      return '女性';
    } else if (str === '3') {
      return '非公開';
    } else {
      return '';
    }
  }

  toFeeBurdenTypeStr(feeBurdenType){
    // ０：企業負担；１：個人負担（内）；２：個人負担（外）
    return feeBurdenType === '0' ? '企業負担' : feeBurdenType === '1' ? '個人負担（内）' : feeBurdenType === '2' ? '個人負担（外）' : '';
  }

  // 口座種別編集
  accountTypeStr(str) {
    // 口座種別 1：普通、2：当座、4：貯蓄
    if (str === '1') {
      return '普通';
    } else if (str === '2') {
      return '当座';
    } else if (str === '4') {
      return '貯蓄';
    } else {
      return '';
    }
  }
  
  // ステータス文字列編集
  statusStr(status){
    // ステータス 
    return status === '1' ? '未送信' : status === '2' ? '送信済' : status === '3' ? 'エラー' : status === '4' ? '取消' :'';
  }

  // 数値型文字列 → 日付型文字列(xxxx年xx月xx日)
  stringToDate(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length !== 8) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '年'
        + strDate.substring(4, 6) + '月'
        + strDate.substring(6, 8) + '日';
    }
    return dateFormaStr;
  }

  // 数値型文字列 → 日付型文字列(xxxx/xx/xx)
  stringToDateFormat(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length !== 8) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '/'
        + strDate.substring(4, 6) + '/'
        + strDate.substring(6, 8);
    }
    return dateFormaStr;
  }
  
  // 数値型文字列 → 日付型文字列(YYYYMM => YYYY年MM月)
  yearMonthDataFormat(time: string){
    if(time && time.length === 6){
      return time.substr(0,4) + '年' + time.substr(4,2) + '月';
    }else{
      return '';
    }
  }
  
  // 数値型文字列 → 時刻型文字列
  stringToHMFormat(strHM){
    let dateFormaStr = '';
    if(strHM && strHM.length >= 5){
      dateFormaStr = strHM.substring(0,5) 
    } else {
      dateFormaStr = '';
    }  
    return dateFormaStr;
  }
  // 名称取得
  public getValueFromListByKey(list, key) {
    for (let i of list) {
      if (i.key === key) {
        return i.value;
      }
    }
    return '';
  }
  
  // // 郵便番号編集を行う
  // public toPostFmt(postCode) {
  //   if (postCode && postCode.trim().length == 7) {
  //     var str = postCode.trim();
  //     var h = str.substr(0, 3);
  //     var m = str.substr(3);
  //     postCode = h + '-' + m;
  //   }
  //   return postCode
  // }

  // // 郵便番号はフォーカスオン処理（郵便番号編集を解除するFunction）
  // public offPostFmt(postCode) {
  //   var reg = new RegExp('-', 'g');
  //   return postCode.replace(reg, '');
  // }

  // // 電話番号編集を行うFunction(XXX-XXXX-XXXX)
  // public toTelephoneNumberFmt(telephoneNumber) {
  //   if (telephoneNumber && telephoneNumber.trim().length >= 10) {
  //     var str = telephoneNumber.trim();
  //     var s = str.substr(0, str.length - 8);
  //     var h = str.substr(str.length - 8, 4);
  //     var m = str.substr(-4);
  //     telephoneNumber = s + '-' + h + '-' + m;
  //   }
  //   return telephoneNumber;
  // }

  // // 電話番号はフォーカスオン処理（電話番号編集を解除するFunction）
  // public offTelephoneNumberFmt(telephoneNumber) {
  //   var reg = new RegExp('-', 'g');
  //   return telephoneNumber.replace(reg, '');
  // }
}

export interface MessageLogData {
  title: string;
  message: string;
}

@Component({
  templateUrl: 'messageLog.html',
  styleUrls: ['messageLog.css'],
})
export class AdminMessageLog {
  message: string;
  title:string;
  constructor(
    private dialogRef: MatDialogRef<AdminMessageLog>,
    @Inject(MAT_DIALOG_DATA) public data: MessageLogData
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  onOk(): void {
    this.dialogRef.close();
  }
}

export interface WarnLogData {
  message: string;
  title: string;
}

@Component({
  templateUrl: 'warnLog.html',
  styleUrls: ['warnLog.css'],
})

export class AdminWarnLog {

  message: string;
  title: string;

  constructor(
    private dialogRef: MatDialogRef<AdminWarnLog>,
    @Inject(MAT_DIALOG_DATA) public data: WarnLogData
  ) {
    this.message = data.message;
    this.title = data.title;
  }
  back(): void {
    this.dialogRef.close();
  }
  closeChange(): void{
    this.dialogRef.close('change');
  }
  closeDelete(): void{
    this.dialogRef.close('delete');
  }
}