import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment  from 'moment';

@Component({
  selector: 'app-prepaymentDetailSearch',
  templateUrl: './prepaymentDetailSearch.component.html',
  styleUrls: ['./prepaymentDetailSearch.component.css']
})

export class CompanyPrepaymentDetailSearchComponent implements OnInit {

  dataSource = []; // テーブル
  displayedColumns: string[] = [
    'receiptNumber',
    'applicationTimeJST',
    'applicationAmount', 
    'transferFeeBurdenType',
    'transferFee', 
    'prepayFeeBurdenType',
    'prepayFee', 
    'status'
  ]; // テーブルのコラム
  affiliatedCompany: string; // 所属企業
  companyCode: string; // 企業コード
  userName: string; // 氏名
  prepayLimit: number; // 前払申請可能額
  usageCheckPeriod: string; // 利用状況確認期間
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', "前払利用状況明細"); // タイトル
    // セッション取得
    const compParameters = JSON.parse(sessionStorage.getItem("compParameters"));
    this.companyCode = compParameters['companyCode']; // 企業コード
    this.affiliatedCompany = this.companyCode +' '+ compParameters['companyName']; // 所属企業
    this.userName = compParameters['employeeName']; // 姓名
    this.prepayLimit = compParameters['prepayLimit']; // 前払申請可能額
    this.usageCheckPeriod = compParameters['selectTimeStart'] + '～本日'; // 利用状況確認期間
    let prepayDetailList =  compParameters['prepayDetailList'];
    for(let i of prepayDetailList){
      i.applicationTimeJST = moment(i['applicationTime']).format('YYYY年MM月DD日');
      i.applicationTimeJSTHm = moment(i['applicationTime']).format('HH時mm分');
      i.transferFeeBurdenType = this.auth.toFeeBurdenTypeStr(i['transferFeeBurdenType'] + '');
      i.prepayFeeBurdenType = this.auth.toFeeBurdenTypeStr(i['prepayFeeBurdenType'] + '');
      i.status = this.auth.statusStr(i['status']);
    }
    this.dataSource = prepayDetailList;
    this.onInit();
  }

  // 前払利用状況明細情報初期化処理API接続
  onInit(){
    const apiUrl = environment.companyUrl.employeeInfo2Url;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPC0120', // 画面ID
        eventId: 'prepayment_detail_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}prepayment_detail_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            // セッション設定
            // ヘッダー部 セッション対象
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            this.loading = false;
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/companyPrepaymentEmployee']); // 前払利用従業員一覧画面へ遷移
  }
}
