import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userCompanyAddInfo',
  templateUrl: './userCompanyAddInfo.component.html',
  styleUrls: ['./userCompanyAddInfo.component.css']
})

export class UserCompanyAddInfoComponent implements OnInit {

  title: string = '給与前払いサービス－所属企業追加完了';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}