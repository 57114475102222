import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-accountLinkageGanbTokenCallback',
  templateUrl: './accountLinkageGanbTokenCallback.component.html',
  styleUrls: ['./accountLinkageGanbTokenCallback.component.css']
})
export class CompanyAccountLinkageGanbTokenCallbackComponent implements OnInit {

  timestampJST: string; // リダイレクトURI

  // コンストラクタ
  constructor( ) { }
  
  // 画面初期化処理
  ngOnInit() {
    setTimeout(function() { 
      window.close() 
    }, 3000);
    this.timestampJST = moment().format("YYYY-MM-DDT HH:mm:ss.SSS Z"); // 日本時間タイムスタンプ
    sessionStorage.setItem('title', 'GMOあおぞらネット銀行提携サービス認証完了'); // タイトル
  };
  
  // 閉じるボタンを押す
  windowClose() {
    window.close();
  }
}