import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-bankDataDeleteInfo',
  templateUrl: './bankDataDeleteInfo.component.html',
  styleUrls: ['./bankDataDeleteInfo.component.css']
})
export class AdminBankDataDeleteInfoComponent implements OnInit {
 
  title: string = '銀行情報削除が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '銀行情報削除完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick() {
    // 銀行マスタ照会画面項目変更時に画面項目保存セッション
    sessionStorage.removeItem('bankDataSearchSession');
    this.router.navigate(['/adminBankDataSearch']); // 銀行情報照会画面へ
  }

}
