import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserAuthService, UserOnetimePasswordDialog } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-registerInput',
  templateUrl: './registerInputConfirm.component.html',
  styleUrls: ['./registerInputConfirm.component.css']
})

export class UserRegisterInputConfirmComponent implements OnInit {

  title: string = '給与前払いサービス－個人情報登録確認';
  confirmDisabled = true;
  companyCode: string; // 企業コード
  employeeNumber: string; // 従業員番号
  lastNameInKanji: string; // 姓
  firstNameInKanji: string; // 名
  lastNameFurigana: string; // セイ
  firstNameFurigana: string; // メイ
  gender: string; // 性別
  genderStr: string; // 性別（表示用）
  birthDateStr: string; // 生年月日（表示用）
  birthDate: string; // 生年月日
  postCode: string; // 郵便番号
  address: string; // 住所
  mobileNumber: string; // 携帯電話番号
  telphoneNumber: string; // 固定電話番号
  mailAddress1: string; // メールアドレス1
  mailAddress2: string; // メールアドレス2
  secretQuestion: string; // 秘密の質問
  secretQuestionNo: string; // 秘密の質問番号
  secretQuestionAnswer: string; // 秘密の質問の答え
  uuId; // uuId
  sessionId; // sessionId
  oneTimePassword = new FormControl({value: '', disabled: this.confirmDisabled});
  loading: boolean = false; // ローディング状態

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    // 認証トークン取得
    this.auth.getToken('SPU0050', null, null, 'user_signup_commit');
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    this.companyCode = userSession['companyCode']; // 企業コード
    this.employeeNumber = userSession['employeeNumber']; // 従業員番号
    this.lastNameInKanji = userParameters['lastNameInKanji']; // 姓
    this.firstNameInKanji = userParameters['firstNameInKanji']; // 名
    this.lastNameFurigana = userParameters['lastNameFurigana']; // セイ
    this.firstNameFurigana = userParameters['firstNameFurigana']; // メイ
    this.gender =userParameters['gender']; // 性別
    this.genderStr = this.auth.genderStr(this.gender); // 性別（表示用）
    this.birthDateStr = this.auth.stringToDate(userParameters['birthDate']); // 生年月日（表示用）
    this.birthDate = userParameters['birthDate']; // 生年月日
    // this.postCode = this.auth.toPostFmt(userParameters['postCode']); // 郵便番号 
    this.postCode = userParameters['postCode']; // 郵便番号 
    this.address = userParameters['address']; // 住所
    this.mobileNumber = userParameters['mobileNumber']; // 携帯電話番号
    this.telphoneNumber = userParameters['telphoneNumber']; // 固定電話番号
    this.mailAddress1 = userParameters['mailAddress1']; // メールアドレス1
    this.mailAddress2 = userParameters['mailAddress2']; // メールアドレス2
    this.secretQuestion = userParameters['secretQuestion']; // 秘密の質問
    this.secretQuestionNo = userParameters['secretQuestionNo']; // 秘密の質問
    this.secretQuestionAnswer = userParameters['secretQuestionAnswer']; // 秘密の質問の答え
    this.uuId = userSession['uuId'];
    this.sessionId = userSession['sessionId'];
    this.loading = false;
  }
  
  openDialog() {
    this.loading = true;
    const apiUrl = environment.userUrl.authServiceApiUrl;
    this.oneTimePassword = new FormControl({value: '', disabled: false}, [Validators.required]);
    this.dialog.open(UserOnetimePasswordDialog, { });
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0050',
        eventId: 'one_time_password_send',
        uuId: this.uuId,
        appVersion: null,
        sessionId: this.sessionId
      },
      parameters:{
        companyCode: this.companyCode, 
        employeeNumber: this.employeeNumber, 
        mailAddress1: this.mailAddress1
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}one_time_password_send`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            this.confirmDisabled = false;
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
  
  // 登録ボタンを押す
  onConfirm() {
    const apiUrl = environment.userUrl.signupServiceApiUrl;
    if(this.oneTimePassword.valid){
      this.loading = true;
      const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters')); // tokenParameters
      const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
      const authToken = tokenParameters['authToken']['access_token'];
      // header
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + authToken,
        'Content-Type': 'application/json'
      });
      // body
      const body = JSON.stringify({
        commonHeader:{
          pageId: 'SPU0050',
          eventId: 'user_signup_commit',
          uuId: this.uuId,
          appVersion: null,
          sessionId: this.sessionId
        },
        parameters:{
          companyCode: this.companyCode,
          employeeNumber: this.employeeNumber,
          lastNameInKanji: this.lastNameInKanji,
          firstNameInKanji: this.firstNameInKanji,
          lastNameFurigana: this.lastNameFurigana,
          firstNameFurigana: this.firstNameFurigana,
          gender: this.gender,
          postCode: userParameters['postCode'],
          address: this.address,
          mobileNumber: this.mobileNumber,
          telphoneNumber: this.telphoneNumber,
          mailAddress1: this.mailAddress1,
          mailAddress2: this.mailAddress2,
          password: '********',
          secretQuestionNo: this.secretQuestionNo,
          secretQuestion: this.secretQuestion,
          secretQuestionAnswer: this.secretQuestionAnswer,
          oneTimePassword: this.oneTimePassword.value
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}user_signup_commit`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // commonHeader
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              this.loading = false;
              this.router.navigate(['/userRegisterInfo']); // 個人情報登録確認へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  };

  // 戻るボタンを押す
  onClickBack(){
    this.router.navigate(['/userRegisterInput']); // ログイン画面へ
  }
  
  // ワンタイムパスワードのエラーメッセージ
  getOneTimePasswordErrorMessage(): any {
    return this.oneTimePassword.hasError('required') ? 'ワンタイムパスワードを入力してください' :
           '';
  };
}