import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-code404',
  templateUrl: './code404.component.html',
  styleUrls: ['./code404.component.css']
})
export class Code404Component implements OnInit {

  title: string = 'ページが見つかりません'; // タイトル

  constructor( ) { }

  ngOnInit() {
  }
}
