import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { environment } from 'src/environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';

// 日付型フォーマット
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
  },
  display: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-employeeModify',
  templateUrl: './employeeModify.component.html',
  styleUrls: ['./employeeModify.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CompanyEmployeeModifyComponent implements OnInit {
  
  company: string; // 所属企業
  employeeNumber: string; // 従業員番号
  lastNameInKanji: string; // 姓
  firstNameInKanji: string; // 名
  lastNameFurigana: string; // 姓(フリガナ)
  firstNameFurigana: string; // 名(フリガナ)
  middleName: string; // ミドルネーム
  birthDate: string; // 生年月日
  // postCodeStr: string; // 郵便番号（表示用）
  postCode: string; // 郵便番号
  genderStr: string; // 性別（表示用）
  address: string; // 住所
  emailAtCompany: string; // 会社メールアドレス
  // telephoneNumberStr: string; // 電話番号（表示用）
  telephoneNumber: string; // 電話番号
  retirementScheduledDate: string; // 退職予定日
  isRetirement: string; //退職フラグ
  bankAccount; // 振込先口座情報リスト
  accountTypeList; // 口座種別情報リスト
  bank1: string; // 金融機関①(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode1: string; // 金融機関コード①
  bankName1: string; // 金融機関名①
  branch1: string; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber1: string; // 支店番号①
  branchName1: string; // 支店名①
  accountType1: string; // 口座種別①
  accountType1Str: string; // 口座種別①（表示用）
  accountNumber1: string; // 口座番号①
  accountHolder1: string; // 口座名義人①
  bank2: string; // 金融機関②(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode2: string; // 金融機関コード②
  bankName2: string; // 金融機関名②
  branch2: string; // 支店②(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber2: string; // 支店番号②
  branchName2: string; // 支店名②
  accountType2: string; // 口座種別②
  accountType2Str: string; // 口座種別②（表示用）
  accountNumber2: string; // 口座番号②
  accountHolder2: string; // 口座名義人②
  bank3: string; // 金融機関③(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode3: string; // 金融機関コード③
  bankName3: string; // 金融機関名③
  branch3: string; // 支店③(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber3: string; // 支店番号③
  branchName3: string; // 支店名③
  accountType3: string; // 口座種別③
  accountType3Str: string; // 口座種別③（表示用）
  accountNumber3: string; // 口座番号③
  accountHolder3: string; // 口座名義人③
  loading: boolean; // ローディング状態
  employeeModifyForm: FormGroup; // フォーム
  isSubmitFlg: boolean = false; // 確認ボタン押下FLG
  chkIsDisabled: boolean = false; // 強制使用停止フラグ
  contractType: string; // 契約形態
  contractTypeStr: string; // 契約形態（表示用）
  unitPriceClass: string; // 基礎単価区分
  unitPriceClassStr: string; // 基礎単価区分（表示用）
  unitPrice: string; // 基礎単価
  // hourlyWage: string; // 時給
  // basicSalary: string; // 基本給

  // コンストラクタ
  constructor(
    dateAdapter: DateAdapter<NativeDateAdapter>,
    private auth: CompanyAuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    dateAdapter.setLocale('ja');
  }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '従業員情報変更'); // タイトル
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // パラメータ
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    this.company = compSession['companyCode'] + ' ' + compSession['companyName']; // 所属企業
    this.employeeNumber = compParameters['employeeNumber']; // 従業員番号
    this.lastNameInKanji = compParameters['employeeName']['lastNameInKanji']; // 姓
    this.firstNameInKanji = compParameters['employeeName']['firstNameInKanji']; // 名
    this.lastNameFurigana = compParameters['employeeName']['lastNameFurigana']; // 姓(フリガナ)
    this.firstNameFurigana = compParameters['employeeName']['firstNameFurigana']; // 名(フリガナ)
    this.middleName = compParameters['employeeName']['middleName']; // ミドルネーム
    this.birthDate = this.auth.stringToDate(compParameters['birthDate']); // 生年月日
    var gender = compParameters['gender'];
    if (gender && gender.length > 1) {
      gender = gender.substr(0, 1);
    }
    this.genderStr = this.auth.genderStr(gender); // 性別(表示用)
    // this.postCodeStr = this.auth.toPostFmt(compParameters['address']['postCode']); // 郵便番号
    this.postCode = compParameters['address']['postCode']; // 郵便番号
    this.address = compParameters['address']['address']; // 住所
    this.emailAtCompany = compParameters['emailAtCompany']; // 会社メールアドレス
    // this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(compParameters['telephoneNumber']); // 電話番号
    this.telephoneNumber = compParameters['telephoneNumber']; // 電話番号
    this.contractType = compParameters['contractDetail']['contractType']; // 契約形態
    var contractTypeParams = compParameters['contractDetail']['contractType']; // 契約情報
    if (contractTypeParams && contractTypeParams.length >= 2) {
      contractTypeParams = contractTypeParams.substr(0, 2);
    }   
    this.contractTypeStr = this.auth.contractTypeStr(contractTypeParams); // 契約形態（表示用）
    this.unitPriceClass = compParameters['contractDetail']['unitPriceClass']; // 基礎単価区分
    var unitPriceClassParams = compParameters['contractDetail']['unitPriceClass']; // 基礎単価区分
    if (unitPriceClassParams && unitPriceClassParams.length >= 2) {
      unitPriceClassParams = unitPriceClassParams.substr(0, 2);
    }   
    this.unitPriceClassStr = this.auth.unitPriceClassStr(unitPriceClassParams); // 基礎単価区分（表示用）
    this.unitPrice = compParameters['contractDetail']['unitPrice']; // 基礎単価
    // this.basicSalary = compParameters['contractDetail']['basicSalary']; // 基本給
    // this.hourlyWage = compParameters['contractDetail']['hourlyWage']; // 時給
    this.isRetirement = compParameters['isRetirement'] + ''; // 退職フラグ
    this.accountTypeList = compParameters['accountTypeList']; // 口座種別情報リスト
    this.bankAccount = compParameters['bankAccount']; // 振込先口座情報リスト
    let iNum = 1;
    for (let i of this.bankAccount) {
      if (iNum <= 3) {
        if (iNum == 1) {
          this.bank1 = i['bankCode'] + ' ' + i['bankName']; // 金融機関①
          this.bankCode1 = i['bankCode']; // 金融機関コード①
          this.bankName1 = i['bankName']; // 金融機関名①
          this.branch1 = i['branchNumber'] + ' ' + i['branchName']; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber1 = i['branchNumber']; // 支店番号①
          this.branchName1 = i['branchName']; // 支店名①
          this.accountType1 = i['accountType']; // 口座種別①
          this.accountType1Str = this.auth.accountTypeStr(i['accountType']); // 口座種別①（表示用）
          this.accountNumber1 = i['accountNumber']; // 口座番号①
          this.accountHolder1 = i['accountHolder']; // 口座名義人①
        } else if (iNum == 2) {
          this.bank2 = i['bankCode'] + ' ' + i['bankName']; // 金融機関②
          this.bankCode2 = i['bankCode']; // 金融機関コード②
          this.bankName2 = i['bankName']; // 金融機関名②
          this.branch2 = i['branchNumber'] + ' ' + i['branchName']; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber2 = i['branchNumber']; // 支店番号①
          this.branchName2 = i['branchName']; // 支店名①
          this.accountType2 = i['accountType']; // 口座種別②
          this.accountType2Str = this.auth.accountTypeStr(i['accountType']); // 口座種別②（表示用）
          this.accountNumber2 = i['accountNumber']; // 口座番号②
          this.accountHolder2 = i['accountHolder']; // 口座名義人②
        } else if (iNum == 3) {
          this.bank3 = i['bankCode'] + ' ' + i['bankName']; // 金融機関③
          this.bankCode3 = i['bankCode']; // 金融機関コード③
          this.bankName3 = i['bankName']; // 金融機関名③
          this.branch3 = i['branchNumber'] + ' ' + i['branchName']; // 支店③(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber3 = i['branchNumber']; // 支店番号③
          this.branchName3 = i['branchName']; // 支店名③
          this.accountType3 = i['accountType']; // 口座種別③
          this.accountType3Str = this.auth.accountTypeStr(i['accountType']); // 口座種別③（表示用）
          this.accountNumber3 = i['accountNumber']; // 口座番号③
          this.accountHolder3 = i['accountHolder']; // 口座名義人③
        }
        iNum++;
      }
    }
    this.chkIsDisabled = compParameters['isDisabled']; // 従業員利用停止フラグ false: 停止しない true: 停止
    // 入力可否のチェック
    this.employeeModifyForm = this.fb.group({
      'retirementScheduledDate': [compParameters['retirementScheduledDate'] === '-' ? '' : compParameters['retirementScheduledDate'], [Validators.minLength(8)]], // 退職予定日
    });
    this.loading = false;
  }

  // 戻るボタンを押す
  onBackClick() {
    if (this.loading === true) {
      return;
    }
    // 遷移元画面ID保存セッション取得
    const transitionOriginalSession = JSON.parse(sessionStorage.getItem('transitionOriginalSession'));
    if (transitionOriginalSession){
      const transitionOriginalPageID = transitionOriginalSession['transitionOriginalPageID']; // 遷移元画面ID
      if (transitionOriginalPageID === 'SPC0070'){
        sessionStorage.setItem('employeeSearchFlg', '2');
        this.router.navigate(['/companyEmployeeSearch']); // SPC0070 従業員情報照会画面へ
      } else if (transitionOriginalPageID === 'SPC0110'){
        this.router.navigate(['/companyPrepaymentEmployee']); // SPC0110 前払利用従業員一覧画面へ
      }
      // セッション失効
      sessionStorage.removeItem('transitionOriginalSession');
    }
  }

  // 確認ボタンを押す
  onUpdateConfirm() {
    if (this.loading === true) {
      return;
    }
    this.isSubmitFlg = true; // 確認ボタン押下
    // セッション取得
    // ヘッダー
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // パラメータ
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    // APIURL
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    if (this.employeeModifyForm.valid) {
      this.loading = true;
      // 日付型データ → 数値型文字列
      let inputRetirementScheduledDate = this.employeeModifyForm.get('retirementScheduledDate').value ? moment(this.employeeModifyForm.get('retirementScheduledDate').value).format('YYYYMMDD') : null;
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      let bankAccount = [];
      if (this.bankCode1) {
        bankAccount.push(
          {
            accountIndex: 1, // 振込先インデックス
            bankName: this.bankName1, // 金融機関名
            bankCode: this.bankCode1, // 金融機関コード
            branchNumber: this.branchNumber1, // 支店コード
            branchName: this.branchName1, // 支店名
            accountType: this.accountType1, // 口座種別
            accountNumber: this.accountNumber1, // 口座番号
            accountHolder: this.accountHolder1 // 口座名義人名
          }
        );
      }
      if (this.bankCode2) {
        bankAccount.push(
          {
            accountIndex: 2, // 振込先インデックス
            bankName: this.bankName2, // 金融機関名
            bankCode: this.bankCode2, // 金融機関コード
            branchNumber: this.branchNumber2, // 支店コード
            branchName: this.branchName2, // 支店名
            accountType: this.accountType2, // 口座種別
            accountNumber: this.accountNumber2, // 口座番号
            accountHolder: this.accountHolder2 // 口座名義人名
          }
        );
      }
      if (this.bankCode3) {
        bankAccount.push(
          {
            accountIndex: 3, // 振込先インデックス
            bankName: this.bankName3, // 金融機関名
            bankCode: this.bankCode3, // 金融機関コード
            branchNumber: this.branchNumber3, // 支店コード
            branchName: this.branchName3, // 支店名
            accountType: this.accountType3, // 口座種別
            accountNumber: this.accountNumber3, // 口座番号
            accountHolder: this.accountHolder3 // 口座名義人名
          }
        );
      }
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPC0080', // 画面ID
          eventId: 'employee_modify_confirm', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // セッションID 
        },
        // レスポンスパラメータの設定
        parameters: {
          // companyCode: compParameters['companyCode'], // 企業コード
          // employeeNumberFrom: compParameters['employeeNumberFrom'], // 更新元従業員番号
          employeeNumber: compParameters['employeeNumber'], // 従業員番号
          employeeName: {// 従業員氏名情報
            lastNameInKanji: compParameters['employeeName']['lastNameInKanji'], // 姓(氏)
            firstNameInKanji: compParameters['employeeName']['firstNameInKanji'], // 名
            middleName: compParameters['employeeName']['middleName'], //ミドルネーム
            lastNameFurigana: compParameters['employeeName']['lastNameFurigana'], // 姓(氏)(フリガナ)
            firstNameFurigana: compParameters['employeeName']['firstNameFurigana'] // 名(フリガナ)
          },
          birthDate: compParameters['birthDate'], // 生年月日(YYYYMMDD)
          address: { // 住所
            postCode: compParameters['address']['postCode'], // 郵便番号
            address: compParameters['address']['address'] // 住所
          },
          accountTypeList: compParameters['accountTypeList'], // 口座種別リスト
          gender: compParameters['gender'], // 性別
          emailAtCompany: compParameters['emailAtCompany'], // 会社メールアドレス
          telephoneNumber: compParameters['telephoneNumber'], // 電話番号
          retirementScheduledDate: inputRetirementScheduledDate, // 退職予定日
          isRetirement: this.isRetirement, // 退職フラグ
          bankAccount: bankAccount,
          isDisabled: this.chkIsDisabled ? true: false, // 強制使用停止フラグ false: 停止しない true: 停止
          contractDetail: {
            contractType: this.contractType, // 契約情報
            unitPriceClass: this.unitPriceClass, // 基礎単価区分
            unitPrice: this.unitPrice // 基礎単価
            // basicSalary: this.basicSalary, // 基本給
            // hourlyWage: this.hourlyWage // 時給
          }
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}employee_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
            // セッション設定
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/companyEmployeeModifyConfirm']); // SPC0090 従業員情報変更確認画面へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }
  
  // 退職予定日のエラーメッセージ
  getRetirementScheduledDateErrorMessage() {
    return this.employeeModifyForm.get('retirementScheduledDate').hasError ? '正しい退職予定日を入力してください' :
           '';
  };
}