import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataSearch',
  templateUrl: './bankDataSearch.component.html',
  styleUrls: ['./bankDataSearch.component.css']
})

export class AdminBankDataSearchComponent implements OnInit {
  // 一覧の標題項目
  displayedColumns: string[] = ['bankCode', 'bankName', 'transStartTime', 'transEndTime', 'onlineTransStartTime', 'onlineTransEndTime', 'details'];
  dataSource; // 一覧データ明細
  loading: boolean; // ローディング状態
  bank: string = ''; // 金融機関選択肢 1:金融機関コード 2:金融機関名 
  isBankCodeChecked: boolean; // 金融機関コードチェックフラグ
  isBankNameChecked: boolean; // 金融機関名チェックフラグ
  bankDataSearchForm: FormGroup; // 画面フォーム
  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '銀行マスタ照会'); // タイトル
    this.onInit(); // API接続
    this.bank = '1'; // 金融機関選択肢 1:金融機関コード 2:金融機関名 
    this.isBankCodeChecked = true; // 金融機関コードチェックフラグ
    this.isBankNameChecked = false; // 金融機関名チェックフラグ
    // フォームチェンジ
    this.formCheck();
    if (sessionStorage.getItem('fromMenuFlg') !== '1'){
      // 画面項目変更時に画面項目保存セッション
      const bankDataSearchSession = JSON.parse(sessionStorage.getItem('bankDataSearchSession'));
      // 画面項目保存セッション削除
      sessionStorage.removeItem('bankDataSearchSession');
      // 戻るボタン押下時画面再表示処理
      if (bankDataSearchSession) {
        let bankCode = bankDataSearchSession['bankCode']; // 金融機関コード
        let bankName = bankDataSearchSession['bankName']; // 金融機関名
        if (bankCode) {
          this.bank = '1'; // 金融機関コード
          this.bankDataSearchForm = this.fb.group({
            'bankCode': [{ value: bankCode, disabled: false }, Validators.pattern('^[0-9]*')], // 金融機関コード
            'bankName': [{ value: '', disabled: !this.isBankNameChecked }], // 金融機関名
          });
        } else if (bankName) {
          this.bank = '2'; // 金融機関名
          this.bankDataSearchForm = this.fb.group({
            'bankCode': [{ value: '', disabled: true }], // 金融機関コード
            'bankName': [{ value: bankName, disabled: false }], // 金融機関名
          });
        }
        this.loading = false;
        // 検索処理
        this.onSearchClick();
      }
    }
  }

  // API接続
  onInit() {
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    // 共通ヘッダ
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPM0100',  // 画面ID
        eventId: 'bank_data_search_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}bank_data_search_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '02000'){ // 正常の場合            
            sessionStorage.setItem('adminSession',JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 検索ボタンを押す
  onSearchClick() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // APIURL
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    this.dataSource = null;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0100',  // 画面ID
        eventId: 'bank_data_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: this.bankDataSearchForm.get('bankCode').value, // 金融機関コード
        bankName: this.bankDataSearchForm.get('bankName').value  // 金融機関名
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}bank_data_search`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            sessionStorage.setItem('bankDataSearchSession', JSON.stringify({
              bankCode: this.bankDataSearchForm.get('bankCode').value, // 金融機関コード
              bankName: this.bankDataSearchForm.get('bankName').value, // 金融機関名
            }));
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.dataSource = parametersData['bankList'];
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 変更ボタンを押す
  onUpdateClick(bankCode) {
    if (this.loading === true) {
      return;
    }
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダ設定
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0100',  // 画面ID
        eventId: 'bank_data_modify', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: bankCode, // 金融機関コード
      }
    });
    this.http.post(
      `${apiUrl}bank_data_modify`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
          sessionStorage.removeItem('fromMenuFlg');
          this.router.navigate(['/adminBankDataModify']); // 銀行マスタ変更画面へ
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
      }
    );
  }

  // 振込手数料マスタ照会
  onTrsnfrtFeePattern(bankCode) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダ設定
    const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0100',  // 画面ID
        eventId: 'transferfee_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: bankCode, // 金融機関コード
      }
    });
    this.http.post(
      `${apiUrl}transferfee_search`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
          sessionStorage.removeItem('fromMenuFlg');
          this.router.navigate(['/adminTransferFeePatternSearch']); // 振込手数料マスタ照会画面
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 追加ボタンを押す
  onAddBank() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0100',  // 画面ID
        eventId: 'bank_data_add', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}bank_data_add`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.removeItem('fromMenuFlg');
            this.router.navigate(['/adminBankDataAdd']); // 銀行マスタ追加
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 金融機関を選択時
  onSelectBank() {
    if (this.bank === '2') {
      this.isBankCodeChecked = false;
      this.isBankNameChecked = true;
    } else if (this.bank === '1') {
      this.isBankCodeChecked = true;
      this.isBankNameChecked = false;
    }
    // フォームチェック
    this.formCheck();
  }

  // フォームチェック
  formCheck() {
    this.bankDataSearchForm = this.fb.group({
      'bankCode': [{ value: '', disabled: !this.isBankCodeChecked }, !this.isBankCodeChecked ? null : [Validators.pattern('^[0-9]*')]], // 金融機関コード
      'bankName': [{ value: '', disabled: !this.isBankNameChecked },], // 金融機関名
    });
  }

  // 金融機関コードのエラーメッセージ
  getbankCodeErrorMessage() {
    return this.bankDataSearchForm.get('bankCode').hasError('pattern') ? '金融機関コードを半角数字で入力してください' :
      '';
  }
}
