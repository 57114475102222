import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-prepaymentCancelConfirm',
  templateUrl: './prepaymentCancelConfirm.component.html',
  styleUrls: ['./prepaymentCancelConfirm.component.css']
})
export class CompanyPrepaymentCancelConfirmComponent implements OnInit {
  loading: boolean = false; // ローディング状態
  uuId: string; // 共通Id
  receiptNumber: string; // 受付番号
  userCompany: string; // 所属企業
  userName: string; // 氏名
  applicationAmount: string; // 前払申請額
  status: string; // ステータス
  bank: string; // 金融機関
  branch: string; // 支店
  accountType: string; // 口座種別
  accountNumber: string; // 口座番号
  accountHolder: string; // 名義人
  transferAmount: string; // 振込予定金額
  transferFee: string; // 振込手数料(税込)
  prepayFee: string; // サービス利用料(税込)
  reason: string; // 申請理由
  btnDisabledFlg: boolean = false; // ボタン活性フラグ

  // コンストラクタ 
  constructor(
    private http: HttpClient,
    private auth: CompanyAuthService,
    private router: Router,
  ) { }

  // 初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '前払申請取消確認'); // タイトル
    this.loading = true;
    // セッション取得
    // ヘッダー
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // パラメータ
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    // 認証トークン取得
    this.auth.getToken('SPC0121', compSession['managerId'], compSession['companyCode'], compSession['sessionId'], 'prepayment_cancel_commit');
    this.receiptNumber = compParameters['receiptNumber']; // 受付番号
    this.uuId = compParameters['uuId']; // 共通ID
    this.userCompany = compParameters['companyCode'] +' '+ compParameters['companyName']; // 所属企業
    this.userName = compParameters['userName']; // 氏名
    this.applicationAmount = compParameters['applicationAmount']; // 前払申請額
    this.status = this.auth.statusStr(compParameters['status']); // ステータス
    if (compParameters['status'] !== '1') { // ステータスが「1:未送信」以外の場合
      this.btnDisabledFlg = true;
    }
    this.bank = compParameters['bankCode'] + ' ' + compParameters['bankName']; // 金融機関
    this.branch = compParameters['branchNumber'] +  ' ' + compParameters['branchName']; // 支店
    this.accountType = this.auth.accountTypeStr(compParameters['accountType']); // 口座種別
    this.accountNumber = compParameters['accountNumber']; // 口座番号
    this.accountHolder = compParameters['accountHolder']; // 名義人
    this.transferAmount = compParameters['transferAmount']; // 振込予定金額
    this.transferFee = compParameters['transferFee']; // 振込手数料(税込)
    this.prepayFee = compParameters['prepayFee']; // サービス利用料(税込)
    this.reason = compParameters['reason']; // 申請理由
    this.loading = false;
  }

  // 確認を押す
  onBtnApplication() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.prepayCancelUrl;
    // セッション取得
    // ヘッダー
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // 認証トークン
    const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPC0121', // 画面ID
        eventId: 'prepayment_cancel_commit', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      },
      parameters: {
        receiptNumber: this.receiptNumber, // 受付番号
        uuId: this.uuId, // 受付番号
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}prepayment_cancel_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            sessionStorage.removeItem('prepaymentCancelSession')
            this.router.navigate(['/companyPrepaymentCancelInfo']);
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }
  
  // 戻るボタンを押す
  onBackClick() {
    if (this.loading === true) {
      return;
    }
    this.router.navigate(['/companyPrepaymentCancel']); // 前払申請画面へ
  }
}
