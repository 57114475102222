import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

export interface UserCompanyElement {
  select: number; // 選択No
  companyCode: string; // 企業コード
  companyName: string; // 企業名
  employeeNumber: string; // 従業員番号
};

@Component({
  selector: 'app-userCompanyDefault',
  templateUrl: './userCompanyDefault.component.html',
  styleUrls: ['./userCompanyDefault.component.css']
})

export class UserCompanyDefaultComponent implements OnInit {

  title: string = '給与前払いサービス－初期選択企業';
  loading: boolean = false; // ローディング状態
  
  // テーブル元素データ
  ELEMENT_DATA: UserCompanyElement[];
  // テーブルコラム
  displayedColumns: string[] = ['select', 'companyCode', 'companyName', 'employeeNumber'];
  // テーブルデータ
  dataSource = new MatTableDataSource<UserCompanyElement>(this.ELEMENT_DATA);
  // 企業
  company;
  firstCompany; // 初期選択中の所属企業
  companyCode; // デフォルト企業コード
  companyName; // デフォルト企業名
  employeeNumber; // デフォルト従業員番号
  searchType: number; // 選択No

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private router: Router,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', this.title); // タイトル
    const apiUrl = environment.userUrl.companySelApiUrl;
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPU0210',
        eventId: 'default_company_init',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}default_company_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            this.companyCode = commonHeaderData['companyCode']; // デフォルト企業コード
            this.companyName = commonHeaderData['companyName']; // デフォルト企業名
            this.employeeNumber = commonHeaderData['employeeNumber']; // 従業員番号
            this.firstCompany = this.companyCode + ' ' + this.companyName; // 初期選択中の所属企業
            // 企業情報
            this.company = parametersData['company'];
            // テーブル元素データ
            let ELEMENT = Array.from({length: this.company.length}, (_, k) => this.setCopmanyData(this.company, k));
            // テーブルデータ
            this.dataSource = new MatTableDataSource(ELEMENT);
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 企業情報テーブル元素データ設定
  setCopmanyData(company, k: number){
    if(this.companyCode === company[k]['companyCode'] && this.employeeNumber === company[k]['employeeNumber']){
      this.searchType = k;
    }
    return {
      select: k, // 選択No
      companyCode: company[k]['companyCode'], // 企業コード 
      companyName: company[k]['companyName'], // 企業名
      employeeNumber: company[k]['employeeNumber'] // 従業員番号
    }    
  }

  // デフォルト企業選択
  onCompanyChange(select: number){
    this.loading = true;
    const apiUrl = environment.userUrl.companySelApiUrl;
    var selectedCompanyCode = this.company[select].companyCode; // 企業コード
    var selectedEmployeeNumber = this.company[select].employeeNumber; // 従業員番号
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0210',
        eventId: 'default_company_select',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      },
      // パラメータ
      parameters:{
        company:{
          companyCode: selectedCompanyCode,
          employeeNumber: selectedEmployeeNumber
        }
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}default_company_select`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
            // セッションストレージ
            // 共通ヘッダ
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            // パラメータ
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            // 画面遷移
            this.router.navigate(['/userCompanyDefaultUpdate']); // 初期選択企業変更画面へ遷移
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
}