import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyAuthService } from '../auth/auth.service';
import { FormGroup} from '@angular/forms';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-companyModifyConfirm',
  templateUrl: './companyModifyConfirm.component.html',
  styleUrls: ['./companyModifyConfirm.component.css']
})

export class CompanyModifyConfirmComponent implements OnInit {

  loading: boolean = false; // ローディング状態
  companyCode: string; // 企業コード
  contractStartDate: string; // 契約開始日
  contractEndDate: string; // 契約終了日
  companyName: string; // 企業名
  postCode: string; // 事務所郵便番号
  address: string; // 事務所住所
  telephoneNumber: string; // 電話番号
  prepayFee: string; // サービス利用手数料(税込)
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン名称
  prepayFeeBurdenTypeName: string; // サービス利用手数料負担パターン名称
  reasonRequiredName: string; // 前払申請理由要求名称
  prepaymentRatio: string; // 前払可能率
  prepayLimitPerDay: string; // 一日前払申請限度額(円)
  monthlyWorkingHours: number; // 1ヵ月の所定労働時間
  cancelScheduledDate: string; // 企業解約予定日
  companyForm: FormGroup; // 画面フォーム

  constructor(
    private http: HttpClient,
    private auth: CompanyAuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', '企業情報変更確認'); // タイトル
    // 初期
    this.initForm();
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    const sessionId = compSession['sessionId'];
    // 認証トークン取得
    this.auth.getToken('SPC0050', compSession['managerId'], compSession['companyCode'], sessionId, 'company_modify_commit');
  }

  // 企業情報取得の画面の初期表示
  initForm() {
    const parametersData = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    this.companyCode = parametersData['companyCode']; // 企業コード
    this.companyName = parametersData['companyName']; // 企業名
    this.contractStartDate = this.auth.stringToDate(parametersData['contractStartDate']); // 契約開始日
    this.contractEndDate = this.auth.stringToDate(parametersData['contractEndDate']); // 契約終了日
    this.cancelScheduledDate = this.auth.stringToDate(parametersData['cancelScheduledDate']);
    // this.postCode = this.auth.toPostFmt(parametersData['postCode']); // 事務所郵便番号
    this.postCode = parametersData['postCode']; // 事務所郵便番号
    this.address = parametersData['address']; // 事務所住所
    // this.telephoneNumber = this.auth.toTelephoneNumberFmt(parametersData['telephoneNumber']);// 電話番号
    this.telephoneNumber = parametersData['telephoneNumber'];// 電話番号
    this.prepayFee = parametersData['prepayFee']; // サービス利用手数料(税込)
    this.transferFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['transferFeeBurdenTypeList'], parametersData['transferFeeBurdenType']); // 振込手数料負担パターン
    this.prepayFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['prepayFeeBurdenTypeList'], parametersData['prepayFeeBurdenType']); // サービス利用手数料負担パターン
    // this.prepaymentRatioName = this.auth.getValueFromListByKey(parametersData['prepaymentRatioList'], parametersData['prepaymentRatio']); // 前払可能率
    this.prepaymentRatio = parametersData['prepaymentRatio']; // 前払可能率
    parametersData['reasonRequired'] ? this.reasonRequiredName = '要求する' : this.reasonRequiredName = '要求しない';
    this.prepayLimitPerDay = parametersData['prepayLimitPerDay']; // 一日前払申請限度額(円)
    this.monthlyWorkingHours = parametersData['monthlyWorkingHours']; // 1ヵ月の所定労働時間
  }

  // 確認ボタン押下
  onConfirmClick() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.companyInfoUrl;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // 認証トークン
    const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    // ボディー作成
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPC0050', // 画面ID
        eventId: 'company_modify_commit', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      }
    });
    // 初期選択企業設定APIを呼び出す
    this.http.post(
      `${apiUrl}company_modify_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：レストランメッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            // 画面遷移
            this.router.navigate(['/companyModifyInfo']); // 企業情報変更完了画面遷移
          } else {
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {// 異常処理
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 戻るボタン押下
  onBackClick() {
    this.router.navigate(['/companyModify']);
  }
}
