import { Component, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

export interface DialogData {
  errorMsg: string;
  id: string;
  messageCode: string;
}

// エラーのダイアログ
@Component({
  templateUrl: 'errorDialog.html',
  styleUrls: ['errorDialog.css'],
})
export class UserErrorDialog {

  errorMsg: string; // エラーメッセージ
  errorId: string; // エラーID
  messageCode: string; // エラーコード

  constructor(
    private dialogRef: MatDialogRef<UserErrorDialog>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      this.dialogRef.disableClose = true;
      this.errorMsg = data.errorMsg;
      this.errorId = data.id;
      this.messageCode = data.messageCode;
    }

    closeDialog(): void{
      this.dialogRef.close();
      if(this.errorId === 'sysError'){
        this.router.navigate(['/userLogin']); // ログイン画面へ
      }
      if(this.messageCode === '00213'){
        this.router.navigate(['/userCompanyDefault']); // 初期選択企業変更へ
      }
      if(this.messageCode === '00700'){
        this.router.navigate(['/userCompanyAdd']); // 所属企業追加へ
      }
    }
}

// ワンタイムパスワードのダイアログ
@Component({
  templateUrl: 'onetimePasswordDialog.html',
  styleUrls: ['onetimePasswordDialog.css'],
})
export class UserOnetimePasswordDialog {
  constructor(
    private dialogRef: MatDialogRef<UserOnetimePasswordDialog>
  ) {
    this.dialogRef.disableClose = true;
  }

  closeDialog(): void{
    this.dialogRef.close();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  // トーケンの取得
  public getToken(pageId: string, uuId: string, sessionId: string, scope: string){
    const apiUrl = environment.userUrl.authServiceApiUrl;
    // header
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer app_version=1.0.0'
    });
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: pageId,
        eventId: 'access_token_get',
        uuId: uuId,
        appVersion: null,
        sessionId: sessionId
      },
      parameters:{
        scope: scope
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}access_token_get`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // header
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          sessionStorage.setItem('tokenParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] !== '00000'){
            this.resDialog(commonHeaderData['message']);
          }
        },
      error => {
        this.errDialog(error);
      }
    );
  }

  // ログアウト
  public signOut() {
    const apiUrl = environment.userUrl.authServiceApiUrl;
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0120',
        eventId: 'user_logout',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_logout`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.removeItem('userSession');
          sessionStorage.removeItem('userParameters');
          sessionStorage.removeItem('userLoggedIn');
          sessionStorage.setItem('loggedIn','false');
          if(commonHeaderData['messageCode'] !== '00000'){
            this.resDialog(commonHeaderData['message']);
          }
      },
      error => {
        this.errDialog(error);
      }
    );
  }
  
  // ログイン状態の取得
  public isAuthenticated(): boolean {
    if (sessionStorage.getItem('userLoggedIn') === 'true'){
      return true;
    } else {
      return false;
    }
  }

  // 口座種別編集
  public accountTypeStr(str){
    // 口座種別 1：普通、2：当座、4：貯蓄
    if (str === '1') {
      return '普通';
    } else if (str === '2') {
      return '当座';
    } else if (str === '4') {
      return '貯蓄';
    } else {
      return '';
    }
  }

  // 性別編集
  public genderStr(str) {
    if (str === '1') {
      return '男性';
    } else if (str === '2') {
      return '女性';
    } else if (str === '3') {
      return '非公開';
    } else {
      return str;
    }
  }

  // 手数料負担パターン名称取得
  public getBurdenTypeName(key){
    interface BurdenType{
      key: string;
      value: string;
    };
    var burdenTypeList: BurdenType[] = [{key: '0', value:'企業負担'}, {key: '1', value:'個人負担（内）'}, {key: '2', value:'個人負担（外）'}];
    for (let i of burdenTypeList){
      if (i.key === key){
        return i.value;
      }
    }
    return '';
  }
  
  // 数値型文字列 → 日付型文字列(YYYY年MM月DD日)
  public stringToDate(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length < 8) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '年'
        + strDate.substring(4, 6) + '月'
        + strDate.substring(6, 8) + '日';
    }
    return dateFormaStr;
  }
  
  // 郵便番号編集を行う
  // public toPostFmt(postCode){
  //   if(postCode && postCode.trim().length == 7){
  //     var str = postCode.trim();
  //     var h = str.substr(0,3);
  //     var m = str.substr(3);
  //     postCode = h + '-' + m;
  //   }
  //   return postCode
  // }

  // // 郵便番号はフォーカスオン処理（郵便番号編集を解除するFunction）
  // public offPostFmt(postCode){
  //   var reg = new RegExp('-', 'g');
  //   return postCode.replace(reg, '');
  // }

   // 携帯電話チェック
   public isMobileNumber(phoneNumber){
    // チェック条件パターン
    if(phoneNumber && phoneNumber.length > 9){
      if(['050', '070', '080', '090'].includes(phoneNumber.substr(0, 3))){
        return true;
      }
    }
    return false;
  }

  // エラーダイアログ
  public errDialog(e){
    // console.log(e);
    const dialogConfig = new MatDialogConfig();
    if (e['error']['commonHeader']){
      let id = 'sysError';
      let messageCode = e['error']['commonHeader']['messageCode'];
      let errorMsg = e['error']['commonHeader']['message'];
      let errCode = messageCode.substr(2,1);
      if (errCode !== '9' && messageCode !== '00109'){
        id = 'dataError';
      }
      dialogConfig.data = {
        id: id,
        messageCode: messageCode,
        errorMsg: errorMsg.replace('<br>', '\n')
      };
      if(id === 'sysError'){
        sessionStorage.setItem('userLoggedIn','false');
      }
    } else {
      dialogConfig.data = {
        id: 'sysError',
        messageCode: null,
        errorMsg: e['message']
      };
      sessionStorage.setItem('userLoggedIn','false');
    }
    dialogConfig.height = '200px'; 
    this.dialog.open(UserErrorDialog, dialogConfig);
  }

  // リスポンスダイアログ
  public resDialog(commonHeaderData){
    const dialogConfig = new MatDialogConfig();
    while(commonHeaderData['message'] !== commonHeaderData['message'].replace('<br>', '\n')) {
      commonHeaderData['message'] = commonHeaderData['message'].replace('<br>', '\n');
    }
    dialogConfig.data = {
      id : 'dataError',
      errorMsg: commonHeaderData['message'],
      messageCode: commonHeaderData['messageCode']
    };
    dialogConfig.height = '200px';
    this.dialog.open(UserErrorDialog, dialogConfig);
  }
}