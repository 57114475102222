import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signoutInfo',
  templateUrl: './signoutInfo.component.html',
  styleUrls: ['./signoutInfo.component.css']
})

export class UserSignoutInfoComponent implements OnInit {

  title: string = '給与前払いサービス－退会完了';
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('userLoggedIn','false');
  }

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }
}