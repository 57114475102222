import { Component, OnInit, ContentChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-registerAgreement',
  templateUrl: './registerAgreement.component.html',
  styleUrls: ['./registerAgreement.component.css']
})

export class UserRegisterAgreementComponent implements OnInit {
  
  contractUrl;
  checkboxDisabled: boolean = false;
  title: string = '給与前払いサービス－会員規約同意';
  chkContractConfirm = new FormControl();
  loading: boolean = false; // ローディング状態

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) { }

  ngOnInit() {
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters'))
    if(userParameters['contractUrl']){
      this.contractUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(userParameters['contractUrl']);
    }
  }

  // 申請ボタンを押す
  onClickSinup(){
    this.loading = true;
    const apiUrl = environment.userUrl.signupServiceApiUrl;
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0040',
        eventId: 'user_pii_consent',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      },
      parameters:{
        companyCode: userSession['companyCode'], 
        employeeNumber: userSession['employeeNumber']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_pii_consent`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            // this.router.navigate(['/userRegisterUserAgreement']); // 個人情報利用同意へ
            this.router.navigate(['/userRegisterInput']); // 個人情報登録画面へ
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // キャンセルボタンを押す
  onClickCancel(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }
}