import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';

export interface Account{
  accountIndex: string;
  bankInfo: string;
};
@Component({
  selector: 'app-userCompanyDefaultUpdate',
  templateUrl: './userCompanyDefaultUpdate.component.html',
  styleUrls: ['./userCompanyDefaultUpdate.component.css']
})

export class UserCompanyDefaultUpdateComponent implements OnInit {

  title: string = '給与前払いサービス－デフォルト企業変更';
  loading: boolean = false; // ローディング状態
  userCompanyDefaultForm: FormGroup;
  userCompany: string; // 所属企業
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン
  prepayFeeBurdenTypeName: string; // 利用手数料負担パターン
  bankAccount; // 振込先口座
  bankName: string;// 金融機関
  branchNumber: string;// 支店コード
  branchName: string;// 支店名
  accountType: string;// 口座種別
  accountNumber: string;// 口座番号
  accountHolder: string;// 名義人
  selectedAccount: number; // 振込先口座
  accountList: Account[] = []; // 振込先口座リスト

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
  ) { }
  
  // 画面初期化
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // セッションを取得する
    const parametersData = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    // 画面表示項目
    this.userCompany = parametersData['company']['companyCode'] + ' ' + parametersData['company']['companyName']; // 所属企業
    const defaultAmount = parametersData['company']['defaultAmount'] / 1000; // 申請額
    this.transferFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['transferFeeBurdenType'].substring(0, 1)); // 振込手数料負担パターン
    this.prepayFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['prepayFeeBurdenType'].substring(0, 1)); // 利用手数料負担パターン
    this.bankAccount =  parametersData['bankAccount']; // 振込先情報
    var defaultAccountIndex = parametersData['defaultAccountIndex'];// デフォルト振込先インデックス
    for(let i of this.bankAccount){
      this.accountList.push({accountIndex: i['accountIndex'], bankInfo: i['bankName'] + ' ' + i['branchName'] + ' ' + i['accountNumber']})
      if(defaultAccountIndex === i['accountIndex']){
        this.bankName = i['bankName'];// 金融機関
        this.branchNumber = i['branchNumber'];// 支店コード
        this.branchName = i['branchName'];// 支店名
        this.accountType = this.auth.accountTypeStr(i['accountType']); // 口座種別編集
        this.accountNumber = i['accountNumber'];// 口座番号
        this.accountHolder = i['accountHolder'];// 名義人
      }
    }
    this.selectedAccount = defaultAccountIndex; // 振込先口座
    // フォーム初期化
    this.userCompanyDefaultForm = this.fb.group({
      'defaultAmount': [defaultAmount, [Validators.required, Validators.pattern('^[0-9]+$')]]
    });
  }

  // 振込口座リストの選択値が変更された場合
  onSelectedAccount(){
    for(let i of this.bankAccount){
      if(this.selectedAccount === i['accountIndex']){
        this.bankName = i['bankName'];// 金融機関
        this.branchNumber = i['branchNumber'];// 支店コード
        this.branchName = i['branchName'];// 支店名
        this.accountType = this.auth.accountTypeStr(i['accountType']); // 口座種別編集
        this.accountNumber = i['accountNumber'];// 口座番号
        this.accountHolder = i['accountHolder'];// 名義人
        break;
      }
    }
  }
 
  // 初期選択企業に設定(初期選択企業設定APIを呼び出す)
  onUpdate() {
    const apiUrl = environment.userUrl.companySelApiUrl;
    if(this.userCompanyDefaultForm.valid){
      this.loading = true;
      // セッションを取得する
      const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー作成
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPU0211',                       // 画面ID
          eventId: 'default_company_confirm',      // イベントID
          uuId: userSession['uuId'],               // 共通ID
          appVersion: null,                     // アプリバージョン
          sessionId: userSession['sessionId']      // セッションID 
        },
        parameters: {
          companyCode: userSession['companyCode'], // 企業コード
          employeeNumber: userSession['employeeNumber'],// 従業員番号
          defaultAmount: this.userCompanyDefaultForm.get('defaultAmount').value * 1000, // デフォルト申請額
          defaultAccountIndex: this.selectedAccount// デフォルト振込先インデックス
        }
      });
      // 初期選択企業設定APIを呼び出す
      this.http.post(
        `${apiUrl}default_company_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
              // 共通ヘッダ
              sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
              // 当前画面でパラメータセッション設定
              sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            // レストラン情報：レストランメッセージ
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              // 画面遷移
              this.router.navigate(['/userCompanyDefaultUpdateConfirm']); // 初期選択企業変更画面へ遷移
            } else {
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {// 異常処理
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  };

  // 前払申請額のエラーメッセージ
  getDefaultAmountErrorMessage() {
    return this.userCompanyDefaultForm.get('defaultAmount').hasError('required') ? '前払申請額を入力してください' :
           this.userCompanyDefaultForm.get('defaultAmount').hasError('pattern') ? '前払申請額を半角数字で入力してください' :
           '';
  };

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userCompanyDefault']); // 初期選択企業変更画面へ
  }
}