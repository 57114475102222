import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';
import axios from 'axios';
import * as moment from 'moment';

// 振込先口座インターフェース
export interface FileList{
  csvFileDisplayName: string;
  csvFileName: string;
  csvFileId: string;
};

@Component({
  selector: 'app-csvDownload',
  templateUrl: './csvDownload.component.html',
  styleUrls: ['./csvDownload.component.css']
})

export class CompanyCsvdownloadComponent implements OnInit {
  
  loading: boolean; // ローディング状態
  percentage: number = 0; // ダウンロード進捗状況
  dataSource;
  csvFileNameList;
  displayedColumns = ['month','downloadDay','downloadManagerName','btnUpdate'];
 
  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', 'CSVダウンロード'); // タイトル
    this.dataSource = [];
    this.init();
  }

  // 画面初期化
  init(){
    // APIリンク
    const apiUrl = environment.companyUrl.csvInfoUrl;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPC0140', // ページID
        eventId: 'csv_download_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}csv_download_init`, // CSVダウンロード初期化
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            let csvFileNameList_data = parametersData['csvFileNameList'].sort(
              function up(x,y){
                return y.month - x.month
              }
            );;
            this.dataSource = csvFileNameList_data;
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        if (error['error']['commonHeader']){
          let messageCode = error['error']['commonHeader']['messageCode'];
          let errCode = messageCode.substr(2,1);
          if (messageCode === '01999'){
            this.auth.errDialog(error);
          } else if(messageCode !== '01103' && errCode !== '9'){ // 二重ダイアログアラート防止
            this.auth.errDialog(error);
          }
        }
      }
    );
  }

  // ダウンロード処理
  onDownload(downloadSelect: string){
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    this.getFileFromStorage(downloadSelect, (res) =>{
      this.auth.resDialog(res.response.data['commonHeader']);
      this.loading = false;
    }, (res) =>{
      for (let i of this.dataSource){
        let today = moment().format('YYYYMMDD');
        if (i.fileId === downloadSelect && (i.downloadDay !== today || i.downloadManagerName !== res.data['commonHeader']['userName'])){
          i.downloadDay = today;
          i.downloadManagerName = res.data['commonHeader']['userName'];
        }
      }
      this.loading = false;
    });
  }
  
  getFileFromStorage(csvFileId: string, callback1, callback2) {
    // APIリンク
    const apiUrl = environment.companyUrl.csvInfoUrl;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    let companyFileName = null;
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPC0140', // ページID
        eventId: 'csv_download_url_get', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      },
      parameters: {
        csvFileId: csvFileId // ファイルID
      }
    });
    axios
      .post(`${apiUrl}csv_download_url_get`, body) // 署名付きURL取得
      .then(function(res){
        companyFileName = res.data['parameters']['fileKey'];
        axios({
          url: res.data['parameters']['csvSignedUrl'],
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', companyFileName.substring(companyFileName.lastIndexOf('/') + 1, companyFileName.length)); //or any other extension
            document.body.appendChild(link);
            link.click();
        }) // 取得した署名付きURLを用いてファイルをS3からダウンロード
        .then(function(res){
          const jsonBody = JSON.stringify({
              // 共通ヘッダ
            commonHeader:{
              pageId: 'SPC0140', // ページID
              eventId: 'csv_download_sts_update', // イベントID
              companyCode: compSession['companyCode'], // 企業コード
              managerId: compSession['managerId'], // 利用企業管理者ID
              sessionId: compSession['sessionId'] // 利用企業管理者セッションID
            },
            parameters: {
              csvFileStatus: 'Downloaded', // ダウンロードステータス
              csvFileId: csvFileId, // ファイルID
              csvFileName: companyFileName // ファイル名称
            }
          });
          // header
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          axios
            .post(`${apiUrl}csv_download_sts_update`, jsonBody) // ダウンロード状態更新
            .then(function(res){
              callback2(res);
              console.log('successfully downloaded!');
            })
        })
      })
      .catch(function (error) {
        console.log(error);
        callback1(error);
      })
  }
}
