import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-companyModifyInfo',
  templateUrl: './companyModifyInfo.component.html',
  styleUrls: ['./companyModifyInfo.component.css']
})

export class CompanyModifyInfoComponent implements OnInit {

  title: string = '企業情報変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title',  '企業情報変更完了'); // タイトル
  }
  
  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/companySearch']); // SPC0030 企業情報照会画面へ
  }
}
