import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employeeSearch',
  templateUrl: './employeeSearch.component.html',
  styleUrls: ['./employeeSearch.component.css']
})
export class CompanyEmployeeSearchComponent implements OnInit {

  // 一覧の標題項目
  displayedColumns: string[] = ['btnUpdate', 'employeeNumber', 'lastNameInKanji', 'firstNameInKanji', 'lastNameFurigana', 'firstNameFurigana', 'retirementScheduledDate', 'isRetirement'];
  dataSource; // 従業員データ
  loading: boolean; // ローディング状態
  employeeChecked: string; // 従業員番号、従業員氏名チェック
  companyCode: string; // 企業コード
  companyName: string;
  employeeNumber: string; // 従業員番号
  employeeName: string; // 氏名
  employeeSearchForm: FormGroup; // 画面フォーム

  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    this.employeeChecked = '1';
    sessionStorage.setItem('title', "従業員情報検索"); // タイトル
    this.onInit(); // API接続
    this.formCheck();
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    this.companyCode = compSession['companyCode']; // 企業コード
    this.companyName = compSession['companyName'];
    // 画面項目設定状態保存セッション取得
    const employeeSearchSession = JSON.parse(sessionStorage.getItem('employeeSearchSession'));
    // 遷移元画面ID保存セッション取得
    const transitionOriginalSession = JSON.parse(sessionStorage.getItem('transitionOriginalSession'));
    if (transitionOriginalSession) {
      // セッション失効
      sessionStorage.removeItem('transitionOriginalSession');
    }
    if (employeeSearchSession) {
      // セッション失効
      sessionStorage.removeItem('employeeSearchSession');
      let compSession = JSON.parse(sessionStorage.getItem('compSession'));
      this.companyCode = compSession.companyCode; // 企業コード
      this.companyName = compSession.companyName; // 企業名
      this.employeeChecked = employeeSearchSession.employeeChecked;
      this.employeeSearchForm = this.fb.group({
        'employeeNumber': [{ value: employeeSearchSession.employeeNumber, disabled: !(this.employeeChecked === '1') }, (this.employeeChecked === '1') ? [Validators.required, Validators.pattern('^[A-Za-z0-9]*')] : Validators.nullValidator], // 従業員番号
        'nameInKanji': [{ value: employeeSearchSession.nameInKanji, disabled: !(this.employeeChecked === '2') }, (this.employeeChecked === '2') ? [Validators.required] : Validators.nullValidator], // 姓名(漢字)
        'nameFurigana': [{ value: employeeSearchSession.nameFurigana, disabled: !(this.employeeChecked === '3') }, (this.employeeChecked === '3') ? [Validators.required] : Validators.nullValidator], // 姓名(カナ)
      });
      this.loading = false;
      this.onSearchClick();
    }
  }

  // API接続
  onInit() {
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPC0070', // 画面ID
        eventId: 'employee_search_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}employee_search_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 検索ボタンを押す
  onSearchClick() {
    if (this.loading === true) {
      return;
    }
    const employeeSearchFlg = sessionStorage.getItem('employeeSearchFlg');
    if (employeeSearchFlg) {
      sessionStorage.removeItem('employeeSearchFlg');
    }
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    this.dataSource = null;
    if (this.employeeSearchForm.valid) {
      this.loading = true;
      // ヘッダ設定
      const apiUrl = environment.companyUrl.employeeInfoUrl;
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー設定
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPC0070', // 画面ID
          eventId: 'employee_search', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // セッションID
        },
        parameters: {
          employeeNumber: this.employeeSearchForm.get('employeeNumber').value, // 従業員番号
          nameInKanji: this.employeeSearchForm.get('nameInKanji').value, // 姓名(漢字)
          nameFurigana: this.employeeSearchForm.get('nameFurigana').value, // 姓名(カナ)
          // receiptNumber: this.employeeSearchForm.get('receiptNumber').value, // 受付番号
          employeeChecked: this.employeeChecked // チェック 1 = 従業員番号、2 = 姓名(漢字)、3 = 姓名(カナ)
        }
      });
      // 従業員情報検索API送信
      this.http.post(
        `${apiUrl}employee_search`,
        body,
        {
          headers: headers
        }
      ).subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // セッション設定
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            // 戻り場合、画面項目設定状態保存セッション設定
            sessionStorage.setItem('employeeSearchSession', JSON.stringify({
              employeeChecked: this.employeeChecked,
              employeeNumber: this.employeeSearchForm.get('employeeNumber').value,
              nameInKanji: this.employeeSearchForm.get('nameInKanji').value,
              nameFurigana: this.employeeSearchForm.get('nameFurigana').value
            }));
            // 従業員情報リスト
            this.dataSource = parametersData['employeeList'];
            for (let i of this.dataSource) {
              let rsDate = i.retirementScheduledDate + '';
              if (rsDate.length === 8) {
                i.retirementScheduledDate = this.auth.stringToDate(rsDate); // 退職予定日
                i.isRetirementstr = this.auth.retirementStr(i.isRetirement);
              } else {
                i.retirementScheduledDate = '';
              }
            }
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => { // エラーの場合
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  }

  // 選択を変更する
  onSelectEmployee() {
    this.formCheck();
  }

  // 変更ボタンを押す
  onUpdateClick(employeeNumber) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // ヘッダ設定
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0070', // 画面ID
        eventId: 'employee_modify_retrieve', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // セッションID 
      },
      parameters: {
        employeeNumber: employeeNumber // 従業員番号
      }
    });
    // 従業員情報検索API送信
    this.http.post(
      `${apiUrl}employee_modify_retrieve`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // セッション設定
        sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
        sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // 遷移元画面ID保存セッション設定
          sessionStorage.setItem('transitionOriginalSession', JSON.stringify({
            transitionOriginalPageID: 'SPC0070'
          }));
          this.router.navigate(['/companyEmployeeModify']); // SPC0090 従業員情報変更確認画面へ
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
  // フォーチェック
  formCheck() {
    this.employeeSearchForm = this.fb.group({
      'employeeNumber': [{ value: '', disabled: !(this.employeeChecked === '1') }, (this.employeeChecked === '1') ? [Validators.required, Validators.pattern('^[A-Za-z0-9]*')] : Validators.nullValidator], // 従業員番号
      'nameInKanji': [{ value: '', disabled: !(this.employeeChecked === '2') }, (this.employeeChecked === '2') ? [Validators.required] : Validators.nullValidator], // 姓名(漢字)
      'nameFurigana': [{ value: '', disabled: !(this.employeeChecked === '3') }, (this.employeeChecked === '3') ? [Validators.required] : Validators.nullValidator], // 姓名(カナ)
    });
  }

  // 従業員番号のエラーメッセージ
  getEmployeeNumberErrorMessage() {
    return this.employeeSearchForm.get('employeeNumber').hasError('required') ? '従業員番号を入力してください' :
      this.employeeSearchForm.get('employeeNumber').hasError('pattern') ? '従業員番号を半角英数字で入力してください' :
        '';
  };

  // 姓名(漢字)のエラーメッセージ
  getEmployeeNameInKanjiErrorMessage() {
    return this.employeeSearchForm.get('nameInKanji').hasError('required') ? '姓名(漢字)を入力してください' :
      '';
  };

  // 姓名(カナ)のエラーメッセージ
  getEmployeeNameFuriganaErrorMessage() {
    return this.employeeSearchForm.get('nameFurigana').hasError('required') ? '姓名(カナ)を入力してください' :
      '';
  };
}
