import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
  },
  display: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-forgotUserid',
  templateUrl: './forgotUserid.component.html',
  styleUrls: ['./forgotUserid.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class UserForgotUseridComponent implements OnInit {

  forgotUserIdForm: FormGroup; // 画面Form
  title: string = 'ユーザーIDをお忘れた方'; // タイトル
  help: string = '登録済みのメールアドレスにユーザーIDをお知らせします。以下をご入力ください。';
  secretQuestionList = []; // 秘密の質問リスト
  secretQuestionNo;  // 秘密の質問（コード）
  loading: boolean = false; // ローディング状態

  constructor(
    dateAdapter: DateAdapter<NativeDateAdapter>,
    private auth: UserAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) { dateAdapter.setLocale('ja');}

  // 初期
  ngOnInit() {
    this.loading = true;
    this.initForm();
  }
  
  // ユーザーIDをお忘れの方画面の初期表示
  initForm() {
    const apiUrl = environment.userUrl.userAccountUrl;
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPU0100',
        eventId: 'userid_inquiry_init',
        uuId: null,
        appVersion: null,
        sessionId: null
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}userid_inquiry_init`,// ユーザIDをお忘れの方情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            this.secretQuestionList =  parametersData['secretQuestionList']; // 秘密の質問のリスト
            this.secretQuestionNo = this.secretQuestionList[0].secretQuestionNo; // 秘密の質問の番号
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
    this.forgotUserIdForm = this.fb.group({
      'lastNameFurigana': ['', [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]], // 姓（カナ）
      'firstNameFurigana': ['', [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]], // 名（カナ）
      'birthDate': ['', [Validators.required, Validators.minLength(8)]], // 生年月日
      'telNumber': ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(10)]], // 携帯電話番号
      'secretQuestionAnswer': ['', Validators.required] // 秘密の質問の答え
    });
  }

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }

  // ユーザーIDをお忘れの方画面にてメール送信ボタン押下時のエラーチェック
  onSendmail() {
    const apiUrl = environment.userUrl.userAccountUrl;
    if (this.forgotUserIdForm.valid) {
      this.loading = true;
      // header
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // body
      const body = JSON.stringify({
        commonHeader:{
          pageId: 'SPU0100',
          eventId: 'userid_inquiry_commit',
          uuId: null,
          appVersion: null,
          sessionId: null
        },
        parameters:{
          lastNameFurigana: this.forgotUserIdForm.get('lastNameFurigana').value, // 姓（カナ）
          firstNameFurigana: this.forgotUserIdForm.get('firstNameFurigana').value, // 名（カナ）
          birthDate: moment(this.forgotUserIdForm.get('birthDate').value).format('YYYYMMDD'), // 生年月日
          telephoneNumber: this.forgotUserIdForm.get('telNumber').value, // 携帯電話番号
          secretQuestionNo: this.secretQuestionNo, // 秘密の質問（コード）
          secretQuestionAnswer: this.forgotUserIdForm.get('secretQuestionAnswer').value, // 秘密の質問の答え
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}userid_inquiry_commit`, // ユーザIDをお忘れの方情報送信
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // commonHeader
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              this.loading = false;
              this.router.navigate(['/userForgotUseridInfo']); // ユーザーIDを忘れのメール送信完了画面へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.auth.errDialog(error);
            this.loading = false;
          }
        );
    }
  };

  // 姓（カナ）のエラーメッセージ
  getLastNameFuriganaErrorMessage(): any {
    return this.forgotUserIdForm.get('lastNameFurigana').hasError('required') ? '姓（カナ）を入力してください' :
           this.forgotUserIdForm.get('lastNameFurigana').hasError('pattern') ? '姓（カナ）を全角カナで入力してください' :
           '';
  };

  // 名（カナ）のエラーメッセージ
  getFirstNameFuriganaErrorMessage(): any {
    return this.forgotUserIdForm.get('firstNameFurigana').hasError('required') ? '名（カナ）を入力してください' :
           this.forgotUserIdForm.get('firstNameFurigana').hasError('pattern') ? '名（カナ）を全角カナで入力してください' :
           '';
  };

  // 生年月日のエラーメッセージ
  getBirthDateErrorMessage(): any {
    return this.forgotUserIdForm.get('birthDate').hasError('required') ? '生年月日を選択してください' :
           this.forgotUserIdForm.get('birthDate').hasError('minlength') ? '正しい生年月日を入力してください' :
           '';
  };

  // 携帯電話番号のエラーメッセージ
  getTelNumberErrorMessage(): any {
    // 携帯電話番号のエラーメッセージ
    return this.forgotUserIdForm.get('telNumber').hasError('required') ? '電話番号を入力してください' :
           this.forgotUserIdForm.get('telNumber').hasError('pattern') ? '電話番号を半角数字で入力してください' :
           this.forgotUserIdForm.get('telNumber').hasError('minlength') ? '正しい電話番号を入力してください' :
          '';
  };

  // 秘密の質問の答えのエラーメッセージ
  getSecretQuestionAnswerErrorMessage(): any {
    return this.forgotUserIdForm.get('secretQuestionAnswer').hasError('required') ? '秘密の質問の答えを入力してください' :
           '';
  };

}