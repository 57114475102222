import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accountLinkageInfo',
  templateUrl: './accountLinkageInfo.component.html',
  styleUrls: ['./accountLinkageInfo.component.css']
})
export class CompanyAccountLinkageInfoComponent implements OnInit {

  titleHeader: string = '銀行口座連携が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '銀行口座連携完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/companyMain']); // SPC0030.企業情報照会画面へ
  }
}
