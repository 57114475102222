import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher} from '@angular/material/core';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

// バリデーションチェックエラー以外のエラーを取得
export class UserMyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid);
    const invalidParent = !!(control && control.parent && control.parent.invalid);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-userCommon',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css']
})
export class UserCommonComponent implements OnInit {
  
  title: string = ''; // タイトル
  userName: string; // ユーザー氏名
  buttonInformation; // 表示するボタンの情報
  loading: boolean = false; // ローディング状態
  // メニュー
  prePayListName: string; //利用状況の確認
  prePayStartName: string; //前払申請
  userCompanyAddName: string; //所属企業追加
  userProfileName: string; //ユーザー情報の確認・変更
  userCompanyDefaultName: string; //初期選択企業変更
  passwordUpdateName: string; //パスワード変更
  registerAgreementAfterLoginName: string; //会員規約
  signoutName: string; //退会

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
  ) { }
  
  // 初期
  ngOnInit() {
    this.title = sessionStorage.getItem('title'); // タイトル
    this.loading = true;
    const apiUrl = environment.userUrl.userAccountUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPU0XX0',
        eventId: 'service_menu_init',
        uuId: userSession['uuId'],          // 共通ID
        appVersion: null,                // アプリバージョン
        sessionId: userSession['sessionId'] // セッションID 
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}service_menu_init`,// ユーザIDをお忘れの方情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            this.userName = userSession['userName']; // 氏名
            this.buttonInformation = parametersData['buttonInformation']; // 外部リンクリスト
            for (let i of this.buttonInformation){
              switch (i['serviceId']) {
                case '001':
                  this.prePayListName = i['functionName']
                  break;
                case '002':
                  this.prePayStartName = i['functionName']
                  break;
                case '003':
                  this.userCompanyAddName = i['functionName']
                  break;
                case '004':
                  this.userProfileName = i['functionName']
                  break;
                case '005':
                  this.userCompanyDefaultName = i['functionName']
                  break;
                case '006':
                  this.passwordUpdateName = i['functionName']
                  break;
                case '007':
                  this.registerAgreementAfterLoginName = i['functionName']
                  break;
                case '008':
                  this.signoutName = i['functionName']
                  break;
                default:
                  break;
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        if (error['error']['commonHeader']){
          let messageCode = error['error']['commonHeader']['messageCode'];
          let errCode = messageCode.substr(2,1);
          if (messageCode === '00999'){
            this.auth.errDialog(error);
          } else if(messageCode !== '00109' && errCode !== '9'){ // 二重ダイアログアラート防止
            this.auth.errDialog(error);
          }
        }
      }
    );
  }

  // ログアウト
  onClickLogout() {
    this.auth.signOut();
  }
}