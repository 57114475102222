import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transferErrorSet',
  templateUrl: './transferErrorSetConfirm.component.html',
  styleUrls: ['./transferErrorSetConfirm.component.css']
})

export class AdminTransferErrorSetConfirmComponent implements OnInit {

  loading: boolean; // ローディング状態
  errorSetConfirmForm: FormGroup;
  receiptNumber: string; // 受付番号
  userCompany: string; // 企業
  userName: string; // 氏名
  applicationAmount: string; // 申請金額
  status: string; // ステータス
  statusCode:string; // ステータスコード
  bankCode: string; // 金融機関コード
  branchNumber: string; // 支店番号
  accountType: string; // 口座種別
  accountNumber: string; // 口座番号
  accountTypeChecked: string;
  bankName: string; // 金融機関名
  accountHolder: string; // 口座名義人
  transferAmount: string; // 振込金額
  transferFee: string; // 振込手数料(税込)
  prepayFee: string; // サービス利用手数料(税込)
  appReason: string; // 前払い申請理由
  errorReason: string; // 設定理由

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '振込エラー設定確認'); // タイトル
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    this.userCompany = adminParameters['companyCode'] + ' ' + adminParameters['companyName']; // 企業
    this.userName = adminParameters['userName']; // 氏名
    this.applicationAmount = adminParameters['applicationAmount']; // 申請金額
    this.statusCode=adminParameters['status']; // ステータス
    this.status = this.auth.statusStr(adminParameters['status']); // ステータス
    this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName'];
    this.branchNumber = adminParameters['branchNumber'] + ' ' + adminParameters['branchName']; // 支店
    this.accountType = this.auth.accountTypeStr(adminParameters['accountType']); // 口座種別
    this.accountNumber = adminParameters['accountNumber']; // 口座番号
    this.accountHolder = adminParameters['accountHolder']; // 口座名義人
    this.transferAmount = adminParameters['transferAmount']; // 振込金額
    this.transferFee = adminParameters['transferFee']; // 振込手数料(税込)
    this.prepayFee = adminParameters['prepayFee']; // サービス利用手数料(税込)
    this.appReason = adminParameters['reason']; // 前払い申請理由
    this.errorSetConfirmForm = this.fb.group({ 
      'errorReason': [adminParameters['errorReason']?adminParameters['errorReason']:''], // 設定理由
    });
    this.onInit(); // API接続
    // 認証トークン取得
    this.auth.getToken('SPM0240', adminSession['uuId'], adminSession['sessionId'], 'transfer_error_set_commit');
    this.loading = false;
  }

  onInit(){
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // APIURL
    const apiUrl = environment.adminUrl.transferErrorServiceApiUrl;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0240',  // 画面ID
        eventId: 'transfer_error_confirm_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transfer_error_confirm_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合     
            // セッション設定
            sessionStorage.setItem('adminSession',JSON.stringify(commonHeaderData));    
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminTransferErrorSet']); // 前払申請画面へ
  }

  // エラー設定/エラー解除ボタン押下
  onConfirm(){
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    // APIURL
    const apiUrl = environment.adminUrl.transferErrorServiceApiUrl;
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
    const authToken = tokenParameters['authToken']['access_token'];
    // this.dataSource = null;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + authToken,
    'Content-Type': 'application/json'
  });
    // headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0240',  // 画面ID
        eventId: 'transfer_error_set_commit', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        uuId: adminParameters['uuId'], // 共通ID
        receiptNumber: adminParameters['receiptNumber'], // 受付番号
        status: this.statusCode, // ステータス
        errorReason: this.errorSetConfirmForm.get('errorReason').value, // 設定理由
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transfer_error_set_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            // 面項目変更時に画面項目保存セッションを削除する
            sessionStorage.removeItem('transferErrorSetSession');
            this.router.navigate(['/adminTransferErrorSetInfo']); // エラー設定確認画面へ遷移
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }
}
