import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import { UserMyErrorStateMatcher } from '../common/common.component';

@Component({
  selector: 'app-passwordUpdate',
  templateUrl: './passwordUpdate.component.html',
  styleUrls: ['./passwordUpdate.component.css']
})

export class UserPasswordUpdateComponent implements OnInit {

  passwordOldHide: boolean = true;
  passwordNewHide: boolean = true;
  passwordNewConfirmHide: boolean = true;
  title: string = '給与前払いサービス－パスワードの変更';
  passwordUpdateForm: FormGroup;
  loading: boolean = false; // ローディング状態

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: UserAuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    this.loading = true;
    this.onInit(); // API接続
    this.passwordUpdateForm = this.fb.group({
      'passwordOld': ['', Validators.required],
      'passwordNew': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]],
      'passwordNewConfirm': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]]
    });
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // 認証トークン取得
    this.auth.getToken('SPU0230', userSession['uuId'], userSession['sessionId'], 'change_password_commit');
  }

  // API接続
  onInit() {
    const apiUrl = environment.userUrl.userSecret2Url;
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPU0280',
        eventId: 'change_password_init',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}change_password_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
    this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 更新ボタンを押す
  onUpdate() {
    if(this.passwordUpdateForm.valid){
      this.loading = true;
      // 共通ヘッダcommonHeader
      const userSession = JSON.parse(sessionStorage.getItem('userSession'));
      const apiUrl = environment.userUrl.userSecretUrl;
      // 認証トークン
      const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
      // ヘッダ設定
      const headers = new HttpHeaders({
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json',
      });
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPU0230',　　　// 画面ID
          eventId: 'change_password_commit',  // イベントID
          uuId: userSession['uuId'],          // 共通ID
          appVersion: null,                // アプリバージョン
          sessionId: userSession['sessionId'] // セッションID 
        },
        // パラメータ
        parameters: {
          password: this.passwordUpdateForm.get('passwordOld').value,    // 旧パスワード
          passwordNew: this.passwordUpdateForm.get('passwordNew').value, // 新パスワード
        }
      });
      // 送信（前払申請受付APIを呼出す）　
      this.http.post(
        `${apiUrl}change_password_commit`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
              this.loading = false;
              this.router.navigate(['/userPasswordUpdateInfo']); // 画面遷移
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  };

  errorStateMacher(){
    return new UserMyErrorStateMatcher();
  }

  // 旧パスワードのエラーメッセージ
  getPasswordOldErrorMessage() {
    return this.passwordUpdateForm.get('passwordOld').hasError('required') ? '旧パスワードを入力してください' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
           '';
  }

  // 新パスワードのエラーメッセージ
  getPasswordNewErrorMessage() {
    return this.passwordUpdateForm.get('passwordNew').hasError('required') ? '新パスワードを入力してください' :
           this.passwordUpdateForm.get('passwordNew').hasError('pattern') ? '新パスワードを半角英数字で入力してください' :
           this.passwordUpdateForm.get('passwordNew').hasError('minlength') ? '新パスワードを８桁以上でご入力してください' :
           this.passwordUpdateForm.hasError('newPasswordEqual') ? '新パスワードが不一致です。' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
           '';
  }

  // 新パスワード（確認）のエラーメッセージ
  getPasswordNewConfirmErrorMessage() {
    return this.passwordUpdateForm.get('passwordNewConfirm').hasError('required') ? '新パスワード（確認）を入力してください' :
           this.passwordUpdateForm.get('passwordNewConfirm').hasError('pattern') ? '新パスワード（確認）を半角英数字で入力してください' :
           this.passwordUpdateForm.get('passwordNewConfirm').hasError('minlength') ? '新パスワード（確認）を８桁以上でご入力してください' :
           this.passwordUpdateForm.hasError('newPasswordEqual') ? '新パスワードが不一致です。' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
           '';
  }

  // 新パスワードの一致チェック
  newPasswordEqualValidator() {
    const passwordNew = this.passwordUpdateForm.get('passwordNew');
    const passwordNewConfirm = this.passwordUpdateForm.get('passwordNewConfirm');
    if(passwordNew != null && passwordNewConfirm != null
      && passwordNew.valid && passwordNewConfirm.valid
      && passwordNew.value !== passwordNewConfirm.value){
      this.passwordUpdateForm.setErrors({ newPasswordEqual: true});
    }
    return this.passwordUpdateForm.hasError('newPasswordEqual');
  };

  // 新旧パスワードの一致チェック
  newOldPasswordEqualValidator() {
    const passwordOld = this.passwordUpdateForm.get('passwordOld');
    const passwordNew = this.passwordUpdateForm.get('passwordNew');
    const passwordNewConfirm = this.passwordUpdateForm.get('passwordNewConfirm');
    if(passwordOld != null && passwordNew != null && passwordNewConfirm != null
      && passwordOld.valid && passwordNew.valid && passwordNewConfirm.valid
      && passwordOld.value == passwordNew.value && passwordNew.value == passwordNewConfirm.value){
      this.passwordUpdateForm.setErrors({ newOldPasswordEqual: true });
    }
    return this.passwordUpdateForm.hasError('newOldPasswordEqual');
  };
}