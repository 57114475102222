import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataDeleteInfo',
  templateUrl: './transferFeePatternDeleteInfo.component.html',
  styleUrls: ['./transferFeePatternDeleteInfo.component.css']
})
export class AdminTransferFeePatternDeleteInfoComponent implements OnInit {

  title: string = '振込手数料マスタ情報削除が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '振込手数料マスタ情報削除完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick() {
    this.router.navigate(['/adminBankDataSearch']); // 銀行情報照会画面へ
  }
}
