import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-companyMain',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class CompanyMainComponent implements OnInit {

  title: string = 'サービスメニューで行う業務を選んでください。';

  ngOnInit() {
    sessionStorage.setItem('title','');
  }

}
