import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-registerUserAgreement',
  templateUrl: './registerUserAgreement.component.html',
  styleUrls: ['./registerUserAgreement.component.css']
})

export class UserRegisterUserAgreementComponent implements OnInit {

  title: string = '給与前払いサービス－個人情報利用同意';
  companyCode: string; // 所属企業コード
  employeeNumber: string; // 従業員番号
  userName: string;// 従業員名
  privacyUrl; // 個人情報利用同意
  uuId; // uuId
  sessionId; // sessionId
  checkboxDisabled: boolean = false;
  loading: boolean = false; // ローディング状態

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {}

  ngOnInit() {
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    this.companyCode = userSession['companyCode'];
    this.employeeNumber = userSession['employeeNumber'];
    this.userName = userSession['userName'];
    if(userParameters['company']['piiConsenseUrl']){
      this.privacyUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(userParameters['company']['piiConsenseUrl']);
    }
    this.uuId = userSession['uuId'];
    this.sessionId = userSession['sessionId'];
  }

  onConfirm(){
    this.loading = true;
    const apiUrl = environment.userUrl.signupServiceApiUrl;
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0040',
        eventId: 'user_pii_consent',
        uuId: this.uuId,
        appVersion: null,
        sessionId: this.sessionId
      },
      parameters:{
        companyCode: this.companyCode, 
        employeeNumber: this.employeeNumber
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_pii_consent`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '00000'){
            this.loading = false;
            this.router.navigate(['/userRegisterInput']); // 個人情報登録画面へ
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // キャンセルボタンを押す
  onClickCancel(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }
}