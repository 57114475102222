import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.dev';

@Component({
  selector: 'app-prepaymentUserList',
  templateUrl: './prepaymentUserList.component.html',
  styleUrls: ['./prepaymentUserList.component.css']
})
export class AdminPrepaymentUserListComponent implements OnInit {
  prepaymentUserListForm: FormGroup; // 検索条件フォーム
  loading: boolean = false; // ローディング状態
  prepaymentChecked: string; // 検索条件 '1':所属企業情報が選択時 '2':会員情報が選択時
  userType: string; // 会員情報検索条件 '21':ユーザーIDが選択時 '22':姓名(漢字)が選択時 '23':姓名(カナ)が選択時
  dataSource = []; // テーブル
  displayedColumns: string[] = ['onButton', 'companyCode', 'userId', 'uuId', 'nameInKanji', 'nameFurigana', 'isLeaved', 'telphoneNumber']; // テーブルのコラム

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '前払利用会員検索'); // タイトル
    this.loading = true;
    // 画面項目保存セッション取得
    let prepaymentUserList = JSON.parse(sessionStorage.getItem('prepaymentUserList'));
    sessionStorage.removeItem('prepaymentUserList');
    // 画面項目保存セッション取得がある場合
    if (prepaymentUserList) {
      this.prepaymentChecked = prepaymentUserList.prepaymentChecked; // 検索条件
      this.userType = this.prepaymentChecked === '2' ? prepaymentUserList.userType: null; // 会員情報検索条件
      // フォーチェック
      this.formCheck();
      // 画面項目を設定
      this.prepaymentUserListForm.get('companyCode').setValue(prepaymentUserList.companyCode); // 企業コード
      this.prepaymentUserListForm.get('employeeNumber').setValue(prepaymentUserList.employeeNumber); // 従業員番号
      this.prepaymentUserListForm.get('userId').setValue(prepaymentUserList.userId); // ユーザーID
      this.prepaymentUserListForm.get('nameInKanji').setValue(prepaymentUserList.nameInKanji); // 姓名（漢字）
      this.prepaymentUserListForm.get('nameFurigana').setValue(prepaymentUserList.nameFurigana); // 姓名（カナ）
      this.loading = false;
      this.onSearchClick(); // 検索処理を実行
    } else {// 画面項目保存セッション取得がない場合
      this.onInit(); // 前払利用会員検索初期表示API
      this.prepaymentChecked = '1'; // 初期値設定：所属企業情報がディフォルト値に選択される
      this.dataSource = []; // テーブル
      // フォーチェック
      this.formCheck();
      this.loading = false;
    }
  }

  // 検索ボタンを押す
  onSearchClick() {
    if (this.loading === true) {
      return;
    }
    if (this.prepaymentUserListForm.valid) {
      this.loading = true;
      this.dataSource = []; // テーブル
      const apiUrl = environment.adminUrl.userInfo2ServiceApiUrl;
      // セッション取得
      // ヘッダー部 セッション対象
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー設定
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPM0220',
          eventId: 'prepayment_user_search', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          prepaymentChecked: this.prepaymentChecked, // 検索条件
          userType: this.prepaymentChecked === '2' ? this.userType: null, // 会員情報検索条件
          companyCode: this.prepaymentChecked === '1'? this.prepaymentUserListForm.get('companyCode').value: null, // 企業コード
          employeeNumber:  this.prepaymentChecked === '1'? this.prepaymentUserListForm.get('employeeNumber').value: null, // 従業員番号
          userId: (this.prepaymentChecked === '2' && this.userType === '21') ? this.prepaymentUserListForm.get('userId').value: null, // ユーザーID
          nameInKanji: (this.prepaymentChecked === '2' && this.userType === '22') ? this.prepaymentUserListForm.get('nameInKanji').value: null, // 姓名（漢字）
          nameFurigana: (this.prepaymentChecked === '2' && this.userType === '23') ? this.prepaymentUserListForm.get('nameFurigana').value: null, // 姓名（カナ）
        }
      });
      this.http.post(
        `${apiUrl}prepayment_user_search`,
        body,
        {
          headers: headers
        }
      ).subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // 画面項目保存セッション設定
            sessionStorage.setItem('prepaymentUserList', JSON.stringify({
              prepaymentChecked: this.prepaymentChecked, // 検索条件
              userType: this.prepaymentChecked === '2' ? this.userType: null, // 会員情報検索条件
              companyCode: this.prepaymentChecked === '1'? this.prepaymentUserListForm.get('companyCode').value: null, // 企業コード
              employeeNumber:  this.prepaymentChecked === '1'? this.prepaymentUserListForm.get('employeeNumber').value: null, // 従業員番号
              userId: (this.prepaymentChecked === '2' && this.userType === '21') ? this.prepaymentUserListForm.get('userId').value: null, // ユーザーID
              nameInKanji: (this.prepaymentChecked === '2' && this.userType === '22') ? this.prepaymentUserListForm.get('nameInKanji').value: null, // 姓名（漢字）
              nameFurigana: (this.prepaymentChecked === '2' && this.userType === '23') ? this.prepaymentUserListForm.get('nameFurigana').value: null,// 姓名（カナ）
            }));
            // セッション設定
            // ヘッダー部 セッション対象
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            // パラメータ部 セッション対象
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.dataSource = parametersData['prepaymentUserList'];
            this.loading = false;
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
            this.loading = false;
          }
        },
        error => { // エラーの場合
          this.auth.errDialog(error);
          this.loading = false;
        }
      );
    }
  }

  // 所属企業情報または会員情報が選択時
  onSelectPrepayment() {
    // 会員情報を選択時
    if (this.prepaymentChecked === '2') {
      this.userType = '21'; // ユーザーIDを選択
    }
    // フォーチェック
    this.formCheck();
  }

  // 会員情報にユーザーIDまたは姓名(漢字)または姓名(カナ)選択を変更する
  onSelectUser() {
    // フォーチェック
    this.formCheck();
  }

  // 会員情報ボタンを押す
  onUserInfo(uuId: string) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.adminUrl.userInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダ設定
    // ヘッダー部 セッション対象
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0220',
        eventId: 'user_data_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        uuId: uuId // ユーザーの共通ID
      }
    });
    this.http.post(
      `${apiUrl}user_data_search`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          // ヘッダー部 セッション対象
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          // パラメータ部 セッション対象
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
          this.router.navigate(['/adminUserDataSearch']); // 前払利用会員明細画面へ遷移
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // 利用状況ボタンを押す
  onPassenger(uuId: string) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.adminUrl.userInfo2ServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0220',
        eventId: 'prepayment_user_detail', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        uuId: uuId // ユーザーの共通ID
      }
    });
    this.http.post(
      `${apiUrl}prepayment_user_detail`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          // ヘッダー部 セッション対象
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          // パラメータ部 セッション対象
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
          this.router.navigate(['/adminPrepaymentUserDetail']); // 前払利用会員明細画面へ遷移
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // 前払利用会員検索初期表示
  onInit() {
    const apiUrl = environment.adminUrl.userInfo2ServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0220',
        eventId: 'prepayment_user_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    this.http.post(
      `${apiUrl}prepayment_user_init`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          // ヘッダー部 セッション対象
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          // パラメータ部 セッション対象
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // フォーチェック
  formCheck() {
    // 所属企業情報が選択される場合
    if (this.prepaymentChecked === '1') {
      this.prepaymentUserListForm = this.fb.group({
        'companyCode': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'employeeNumber': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'userId': ['', Validators.nullValidator],
        'nameInKanji': ['', Validators.nullValidator],
        'nameFurigana': ['', Validators.nullValidator]
      });
    } else if (this.prepaymentChecked === '2') {
      // 会員情報が選択される場合
      this.prepaymentUserListForm = this.fb.group({
        'companyCode': ['', Validators.nullValidator],
        'employeeNumber': ['', Validators.nullValidator],
        'userId': [{ value: '', disabled: !(this.userType === '21') }, (this.userType === '21') ? [Validators.required, Validators.email] : Validators.nullValidator], // ユーザーID
        'nameInKanji': [{ value: '', disabled: !(this.userType === '22') }, (this.userType === '22') ? [Validators.required] : Validators.nullValidator], // 姓名(漢字)
        'nameFurigana': [{ value: '', disabled: !(this.userType === '23') }, (this.userType === '23') ? [Validators.required] : Validators.nullValidator], // 姓名(カナ)
      });
    }
  }

  // 企業コードのエラーメッセージ
  getCompanyCodeErrorMessage() {
    return this.prepaymentUserListForm.get('companyCode').hasError('required') ? '企業コードを入力してください' :
           this.prepaymentUserListForm.get('companyCode').hasError('pattern') ? '企業コードを半角英数字で入力してください' :
           '';
  };

  // 従業員番号のエラーメッセージ
  getEmployeeNumberErrorMessage() {
    return this.prepaymentUserListForm.get('employeeNumber').hasError('required') ? '従業員番号を入力してください' :
           this.prepaymentUserListForm.get('employeeNumber').hasError('pattern') ? '従業員番号を半角英数字で入力してください' :
           '';
  };

  // ユーザーIDのエラーメッセージ
  getUserIdErrorMessage() {
    return this.prepaymentUserListForm.get('userId').hasError('required') ? 'ユーザーIDを入力してください' :
      this.prepaymentUserListForm.get('userId').hasError('email') ? 'ユーザーIDをメールで入力してください' :
        ''
  };

  // 姓名(漢字)のエラーメッセージ
  getNameInKanjiErrorMessage() {
    return this.prepaymentUserListForm.get('nameInKanji').hasError('required') ? '姓名(漢字)を入力してください' :
      '';
  };

  // 姓名(カナ)のエラーメッセージ
  getNameFuriganaErrorMessage() {
    return this.prepaymentUserListForm.get('nameFurigana').hasError('required') ? '姓名(カナ)を入力してください' :
      '';
  };
}
