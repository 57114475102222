import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from '../auth/auth.service';
import { CompanyAuthService } from '../../company/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-userLogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class UserLoginComponent implements OnInit {

  hide: boolean = true; // パスワード表示フラグ
  loginForm: FormGroup;
  loading: boolean = false; // ローディング状態
  title: string = '給与前払いサービスーログイン画面';
  isGitFlg = false; // GIT
  userLoginFlg: boolean = false; // 年末対応のメンテンナンスフラグ
  userLoginMessage: string; // 年末対応のメンテンナンス文言

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private auth: UserAuthService,
    private compAuth: CompanyAuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.compAuth.isGitClientIP(
      (res) => {
        this.isGitFlg = res['isGitFlg'] === '1';
        this.userLoginFlg = res['userLoginFlg'] === '1'; // 年末対応のメンテンナンスフラグ
        this.userLoginMessage = res['userLoginMessage']; // 年末対応のメンテンナンス文言
      }
    );
    this.initForm();
    // 認証トークン取得
    this.auth.getToken('SPU0000', null, null, 'user_login');
  }

  // 画面初期化
  initForm() {
    this.loginForm = this.fb.group({
      'userId': ['', [Validators.required, Validators.email]],
      'password': ['', Validators.required]
    });
    this.loading = false;
  }

  // 登録ボタンを押す
  onSubmitLogin(value: any) {
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters')); // tokenParameters
    const authToken = tokenParameters['authToken']['access_token'];
    if (this.loginForm.valid){
      const userId = value.userId, password = value.password;
      this.signIn(userId, password, authToken);
    }
  }

  // ログイン
  signIn(userId, password, authToken) {
    this.loading = true;
    const apiUrl = environment.userUrl.authServiceApiUrl;
    // header
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0010',
        eventId: 'user_login',
        uuId: null,
        appVersion: null,
        sessionId: null
      },
      parameters:{
        userId: userId,
        password: password
      }
    });
    this.http.post(
      `${apiUrl}user_login`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          this.loading = false;
          // 正常パスワードログインんする場合、
          if(commonHeaderData['messageCode'] === '00000'){
            sessionStorage.setItem('userLoggedIn','true');
            this.router.navigate(['/userPrePayList']); // 利用状況確認へ
// 要件追加対応:2019-03-06 hitachi RIN, Start.
// 対応概要：ログイン直後に会員規約同意時バージョン番号のチェック
            // 規約が更新された場合、
          } else if(commonHeaderData['messageCode'] === '00123'){
            sessionStorage.setItem('userLoggedIn','true');
            sessionStorage.setItem('userParameters', JSON.stringify(response['parameters']));
            this.auth.resDialog(commonHeaderData);
            this.router.navigate(['/userRegisterAgreementAfterLogin']); // 会員規約同意へ
// 要件追加対応:2019-03-06 hitachi RIN, End.
            // 仮パスワードログインんする場合、
          } else if(commonHeaderData['messageCode'] === '00110'){
            sessionStorage.setItem('userLoggedIn','true');
            this.auth.resDialog(commonHeaderData);
            this.router.navigate(['/userPasswordUpdate']); // パスワード変更へ
          } else {
            sessionStorage.setItem('userLoggedIn','false');
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // ユーザーIDのエラーメッセージ
  getEmailErrorMessage() {
    return this.loginForm.get('userId').hasError('required') ? 'ユーザーIDを入力してください' :
           this.loginForm.get('userId').hasError('email') ? '有効なメールではありません' :
           '';
  };

  // パスワードのエラーメッセージ
  getPasswordErrorMessage() {
    return this.loginForm.get('password').hasError('required') ? 'パスワードを入力してください' :
           '';
  };
}