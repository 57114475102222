import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';

@Component({
  selector: 'app-prePayStartInfo',
  templateUrl: './userCompanyDefaultUpdateInfo.component.html',
  styleUrls: ['./userCompanyDefaultUpdateInfo.component.css']
})
export class UserCompanyDefaultUpdateInfoComponent implements OnInit {

  title: string = '給与前払いサービス－デフォルト企業変更完了';
  userCompany: string; // 所属企業
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン
  prepayFeeBurdenTypeName: string; // 利用手数料負担パターン
  defaultAmount: number; // 申請額
  bankAccount; // 振込先口座
  bankName: string;// 金融機関
  branchNumber: string;// 支店コード
  branchName: string;// 支店名
  accountType: string;// 口座種別
  accountNumber: string;// 口座番号
  accountHolder: string;// 名義人

  // コンストラクタ
  constructor(
    private router: Router,
    private auth: UserAuthService
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // パラメータparameters
    const parametersData = JSON.parse(sessionStorage.getItem('userParameters'));
    // 画面表示項目
    this.userCompany = parametersData['company']['companyCode'] + ' ' + parametersData['company']['companyName']; // 所属企業
    this.transferFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['transferFeeBurdenType'].substring(0, 1)); // 振込手数料負担パターン
    this.prepayFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['prepayFeeBurdenType'].substring(0, 1)); // 利用手数料負担パターン
    this.defaultAmount = parametersData['company']['defaultAmount']; // 申請額
    this.bankAccount =  parametersData['bankAccount']; // 振込先情報
    var defaultAccountIndex = parametersData['defaultAccountIndex'];
    for(let i of this.bankAccount){
      if(defaultAccountIndex === i['accountIndex']){
        this.bankName = i['bankName'];// 金融機関
        this.branchNumber = i['branchNumber'];// 支店コード
        this.branchName = i['branchName'];// 支店名
        this.accountType = this.auth.accountTypeStr(i['accountType']); // 口座種別
        this.accountNumber = i['accountNumber'];// 口座番号
        this.accountHolder = i['accountHolder'];// 名義人
      }
    }
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}