import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminCompanyModifyConfirm',
  templateUrl: './companyModifyConfirm.component.html',
  styleUrls: ['./companyModifyConfirm.component.css']
})
export class AdminCompanyModifyConfirmComponent implements OnInit {
  companyCode: string; // 企業コード
  companyName: string; // 企業名
  parent: string; // 親企業
  parentCode: string; // 親企業コード
  parentName: string;// 親企業名
  postCode: string; // 事務所郵便番号
  address: string; // 事務所住所
  telephoneNumber: string; // 電話番号
  contractStartDate: string; // 契約開始日
  contractEndDate: string; // 契約日終了日
  cancelScheduledDate: string; // 企業解約予定日
  isCancelled: boolean = false; // 解約フラグ
  transferFeeBurdenType: string; // 振込手数料負担パターン
  prepayFee: number; // 利用手数料(円)
  prepayFeeBurdenType: string; // 利用手数料負担パターン
  closingInProcess: boolean = false; // 締め処理中フラグ
  reasonRequired: boolean = false; // 前払申請理由要求フラグ
  prepaymentRatio: string; // 前払可能額掛目
  prepayLimitPerDay: number; // 一日前払申請限度額(円)
  monthlyWorkingHours: number; // 1ヵ月の所定労働時間
  loading: boolean; // ローディング状態

  // コンストラクタ
  constructor(
    private http: HttpClient,
    private auth: AdminAuthService,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '企業情報変更確認'); // タイトル
    this.loading = true;
    // 初期
    this.initForm();
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // 認証トークン取得
    this.auth.getToken('SPM0060', adminSession['uuId'], adminSession['sessionId'], 'admin_company_modify_commit');
    this.loading = false;
  }

  // 企業情報取得の画面の初期表示
  initForm() { 
    const companyParameters = JSON.parse(sessionStorage.getItem('adminParameters')); // companyParameters
    this.companyCode = companyParameters['companyCode']; // 企業コード
    this.companyName = companyParameters['companyName']; // 企業名
    this.parentCode = companyParameters['parentCode'] ? companyParameters['parentCode'] : '' ; // 親企業コード
    this.parentName = companyParameters['parentName'] ? companyParameters['parentName']: ''; // 親企業名
    if (this.parentCode === '') {
      this.parent = '-'; // 親企業
    } else {
      this.parent = this.parentCode + ' ' + this.parentName; // 親企業
    }
    // this.postCode = this.auth.toPostFmt(companyParameters['postCode']); // 事務所郵便番号
    this.postCode = companyParameters['postCode']; // 事務所郵便番号
    this.address = companyParameters['address']; // 事務所住所
    // this.telephoneNumber = this.auth.toTelephoneNumberFmt(companyParameters['telephoneNumber']);// 電話番号
    this.telephoneNumber = companyParameters['telephoneNumber'];// 電話番号
    this.contractStartDate = this.auth.stringToDate(companyParameters['contractStartDate']); // 契約開始日
    this.contractEndDate = this.auth.stringToDate(companyParameters['contractEndDate']); // 契約終了日
    this.cancelScheduledDate = this.auth.stringToDate(companyParameters['cancelScheduledDate']); // 企業解約予定日
    // 解約フラグ false: いいえ(未解約) true: 解約済
    this.isCancelled = !companyParameters['isCancelled']; // 解約フラグ
    this.prepayFee = companyParameters['prepayFee']; // 利用手数料(円) 
    // 締め処理中フラグ false: いいえ(処理) true: 処理中
    this.closingInProcess = !companyParameters['closingInProcess']; // 締め処理中フラグ
    // 前払申請理由要求フラグ false: 要求しない true: 要求する
    this.reasonRequired = !companyParameters['reasonRequired']; // 前払申請理由要求フラグ
    // 選択した振込手数料パターン
    this.transferFeeBurdenType = this.auth.getValueFromListByKey(companyParameters['transferFeeBurdenTypeList'], companyParameters['transferFeeBurdenType']); // 振込手数料パターン
    // 利用料負担パターン
    this.prepayFeeBurdenType = this.auth.getValueFromListByKey(companyParameters['prepayFeeBurdenTypeList'], companyParameters['prepayFeeBurdenType']);
    // 前払可能額掛目
    // this.prepaymentRatio = this.auth.getValueFromListByKey(companyParameters['prepaymentRatioList'], companyParameters['prepaymentRatio']);
    this.prepaymentRatio = companyParameters['prepaymentRatio'];
    // 一日前払申請限度額(円) 
    this.prepayLimitPerDay = companyParameters['prepayLimitPerDay'];
    this.monthlyWorkingHours = companyParameters['monthlyWorkingHours']; // 1ヵ月の所定労働時間
  }

  // 確認ボタン押下
  onAdminCompanyModifyCommit() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession 
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
    const authToken = tokenParameters['authToken']['access_token'];
    // 送信前 ヘッダー部設定
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // ボディー作成
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPM0060', // 画面ID
        eventId: 'admin_company_modify_commit', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      }
    });
    // 初期選択企業設定APIを呼び出す
    this.http.post(
      `${apiUrl}admin_company_modify_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：レスポンスメッセージ
          if(commonHeaderData['messageCode'] === '02000'){ // 正常の場合
            this.loading = false;
            // 画面遷移
            this.router.navigate(['/adminCompanyModifyInfo']); // 企業情報変更完了画面遷移
            sessionStorage.removeItem('adminCompanySearchSession');
          } else {
            this.loading = false;
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {// 異常処理
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 戻るボタン押下
  onBackClick() {
    this.router.navigate(['/adminCompanyModify']);
  }
}
