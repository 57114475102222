import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import { CompanyAuthService } from '../auth/auth.service';
import axios from 'axios';

@Component({
  selector: 'app-csvUpload',
  templateUrl: './csvUpload.component.html',
  styleUrls: ['./csvUpload.component.css']
})
export class CompanyCsvUploadComponent implements OnInit {

  companyCode: string; // 企業コード
  companyName: string; // 企業名
  radioChoose: string; // ファイル種類
  loading: boolean; // ローディング状態
  currentFileUpload: File;
  percentage: number = 0; // アップロード進捗状況
  isClosingInProcessFLG: boolean = false; // 締め中状態
  isAccessible: boolean = false; // csvアップロード権限ロール(false:アクセス不可)

  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
  ) { }
  
  // 画面初期化処理
  ngOnInit(){
    this.loading = true;
    sessionStorage.setItem('title', 'CSVアップロード'); // タイトル
    this.onInit(); // API接続
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // CSVアップロード権限ロールセッションからCSVアップロード権限を取得
    let isAccessibleSession = sessionStorage.getItem('isAccessibleSession'); 
    this.companyCode = compSession['companyCode']; // 企業コード
    this.companyName = compSession['companyName']; // 企業名
    this.radioChoose = '2'; // ファイル種類
    this.loading = false;
    this.isAccessible = isAccessibleSession === 'true' ? true : false;
  }

  // API接続
  onInit() {
    const apiUrl = environment.companyUrl.csvInfoUrl;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPC0130', // ページID
        eventId: 'csv_upload_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}csv_upload_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            if (parametersData['closingInProcess'] === 1){ // 締め中の場合
              this.isClosingInProcessFLG = true;
            }
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // アップロードファイルを選択
  selectFile(e){
    this.currentFileUpload = e.target.files[0]; // 選択するファイル
    this.percentage = 0; // アップロード進捗状況
  }

  // アップロードファイル種類を変更
  onChange(){
    this.percentage = 0; // アップロード進捗状況
    // 選択するファイルをクリアする
    this.currentFileUpload = undefined;
    if(this.radioChoose === '2' && this.isClosingInProcessFLG){
      var dialog = {
        message: '前払いサービスが締め処理中のため、ご利用いただけません。',
        messageCode: ''
      };
      this.auth.resDialog(dialog)
    };
  }

  // アップロード
  onUpload(){
    // 選択するファイル
    var file = this.currentFileUpload;
    this.pushFileToStorage(
      file, 
      (res) =>{
        let loaded = res.loaded,
            total = res.total;
        this.percentage = (loaded/total) * 100; // アップロード進捗状況
      },
      (res) => {
        this.auth.resDialog(res.response.data['commonHeader']);
      });
    // 選択するファイルをクリアする
    this.currentFileUpload = undefined;
  }

  pushFileToStorage(file: File, callback1, callback2) {
    // APIリンク
    const apiUrl = environment.companyUrl.csvInfoUrl;
    // ファイルID
    var csvFileId;
    // ファイル名称
    var csvFileName;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    const fileCode = this.radioChoose;
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPC0130', // ページID
        eventId: 'csv_upload_url_get', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      },
      parameters: {
        filePath: fileCode // ファイル種類
      }
    });
    let config = {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress: function(progressEvent) {
        if(progressEvent.lengthComputable){
          callback1(progressEvent);
        }
      }
    };
    if(file.size > 0){
      axios
        .post(`${apiUrl}csv_upload_url_get`, body) // 署名付きURL取得
        .then(function(res){
          csvFileId = res.data['parameters']['csvFileId']; // ファイルID
          csvFileName = res.data['parameters']['csvFileName']; // ファイル名称
          axios
            .put(res.data['parameters']['csvSignedUrl'], file, config) // 取得した署名付きURLを用いてファイルをS3にアップロード
        })
        .then(function(res){
          const jsonBody = JSON.stringify({
            // 共通ヘッダ
            commonHeader: {
              pageId: 'SPC0130', // ページID
              eventId: 'csv_upload_sts_update', // イベントID
              companyCode: compSession['companyCode'], // 企業コード
              managerId: compSession['managerId'], // 利用企業管理者ID
              sessionId: compSession['sessionId'] // 利用企業管理者セッションID
            },
            parameters: {
              csvFileStatus: 'Uploaded', // アップロードステータス
              csvFileId: csvFileId, // ファイルID
              csvFileName: csvFileName, // ファイル名称
              companyCodeUnionKey: `${compSession['companyCode']}-${fileCode}`// 企業コード連合キー
            }
          });
          // header
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          axios
            .post(`${apiUrl}csv_upload_sts_update`, jsonBody) // アップロード状態更新
            .then(function(res){
              console.log('successfully uploaded!');
            })
        })
        .catch(function (error) {
          callback2(error);
        })
    } else {
      var dialog = {
        message: 'CSVファイル不正です。お確かめください。',
        messageCode: ''
      };
      this.auth.resDialog(dialog);
    }
  }
}