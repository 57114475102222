import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-prepaymentEmployee',
  templateUrl: './prepaymentEmployee.component.html',
  styleUrls: ['./prepaymentEmployee.component.css']
})

export class CompanyPrepaymentEmployeeComponent implements OnInit {

  companyCode: string; // 企業コード
  companyName: string; // 企業名
  dataSource = []; // 従業員テーブル
  displayedColumns: string[] = [
    'onButton',
    'employeeNumber','userName',
    'furiganaName', 'retirementScheduledDate', 'isRetirement', 
    'employeeStatus','contractType'
  ]; // テーブルのコラム
  loading: boolean; // ローディング状態
  errorFlag: boolean; // エラーの展示のマーク
  employeeSearchForm:  FormGroup; // 画面フォーム
  employeeChecked: string; // 従業員番号、姓名(漢字)、姓名(カナ)、受付番号、チェック

  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '前払利用従業員検索'); // タイトル
    // 画面項目保存セッション取得
    let prepaymentEmployee = JSON.parse(sessionStorage.getItem('prepaymentEmployee'));
    sessionStorage.removeItem('prepaymentEmployee');
    // 画面項目保存セッション取得あるの場合
    if(prepaymentEmployee){
      let compSession = JSON.parse(sessionStorage.getItem('compSession'));
      this.companyCode = compSession.companyCode; // 企業コード
      this.companyName = compSession.companyName; // 企業名
      this.employeeChecked = prepaymentEmployee.employeeChecked;
      this.employeeSearchForm = this.fb.group({
        'employeeNumber': [{ value: prepaymentEmployee.employeeNumber, disabled: !(this.employeeChecked === '1') }, (this.employeeChecked === '1') ? [Validators.required, Validators.pattern('^[A-Za-z0-9]*')] : Validators.nullValidator], // 従業員番号
        'nameInKanji': [{ value: prepaymentEmployee.nameInKanji, disabled: !(this.employeeChecked === '2') }, (this.employeeChecked === '2') ? [Validators.required] : Validators.nullValidator], // 姓名(漢字)
        'nameFurigana': [{ value: prepaymentEmployee.nameFurigana, disabled: !(this.employeeChecked === '3') }, (this.employeeChecked === '3') ? [Validators.required] : Validators.nullValidator], // 姓名(カナ)
      });
      this.loading =false;
      this.onSearchClick();
    // 画面項目保存セッション取得ないの場合
    }else{
      this.onInit(); // API接続
      this.employeeChecked = '1'; // 従業員番号の選択を設定
      this.formCheck(); // フォーチェック
      this.dataSource = [];
      this.loading =false;
    }
  }
  
  // 選択を変更する
  onSelectEmployee() {
    this.formCheck();
  }

  // 検索ボタンを押す(前払利用従業員一覧取得)
  onSearchClick(){
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    this.dataSource = [];
    // ヘッダ設定
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0110',
        eventId: 'prepayment_employee_search',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      parameters: {
        companyCode: this.companyCode, // 企業コード
        employeeNumber: this.employeeSearchForm.get('employeeNumber').value, // 従業員番号
        nameInKanji: this.employeeSearchForm.get('nameInKanji').value, // 姓名(漢字)
        nameFurigana: this.employeeSearchForm.get('nameFurigana').value, // 姓名(カナ)
        // receiptNumber: this.employeeSearchForm.get('receiptNumber').value, // 受付番号
        employeeChecked: this.employeeChecked // チェック 1 = 従業員番号、2 = 姓名(漢字)、3 = 姓名(カナ)

      }
    });
    // 前払利用従業員一覧取得API送信
    this.http.post(
      `${apiUrl}prepayment_employee_search`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // セッション設定
        sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
        sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          let employeeList = parametersData['employeeList'];
          for (let i of employeeList) {
            let rsDate = i.retirementScheduledDate + '';
            if (rsDate.length === 8) {
              i.retirementScheduledDate = this.auth.stringToDate(rsDate);
              i.isRetirementstr = this.auth.retirementStr(i.isRetirement);
            } else {
              i.retirementScheduledDate = '';
            }
            if (i.contractType && i.contractType.length >= 2) {
              i.contractType = this.auth.contractTypeStr(i.contractType.substr(0, 2)); // 契約形態（表示用）
            }   
          }
          this.dataSource = employeeList;
        } else { // 異常の場合
          // エラーの場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
  
  // 従業員の情報を表示
  onEmployeeNumber(employeeNumber: string) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0110',
        eventId: 'employee_modify_retrieve',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      parameters: {
        employeeNumber: employeeNumber // 従業員番号
      }
    });
    this.http.post(
      `${apiUrl}employee_modify_retrieve`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // セッション設定
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          // 遷移元画面ID保存セッション設定
          sessionStorage.setItem('transitionOriginalSession',JSON.stringify({transitionOriginalPageID:'SPC0110'}));
          // 画面項目保存セッション設定
          sessionStorage.setItem('prepaymentEmployee',JSON.stringify({
            employeeChecked: this.employeeChecked,
            employeeNumber: this.employeeSearchForm.get('employeeNumber').value,
            nameInKanji: this.employeeSearchForm.get('nameInKanji').value,
            nameFurigana: this.employeeSearchForm.get('nameFurigana').value
          }));
          this.router.navigate(['/companyEmployeeModify']); // 前払利用状況明細画面へ遷移
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // 前払利用状況明細情報を表示
  onPassenger(employeeNumber: string) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0110',
        eventId: 'prepayment_detail_search',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      parameters: {
        // companyCode: this.companyCode, // 企業コード
        employeeNumber: employeeNumber // 従業員番号
      }
    });
    this.http.post(
      `${apiUrl}prepayment_detail_search`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // セッション設定
          // ヘッダー部 セッション対象
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          // パラメータ部 セッション対象
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          // 画面項目保存セッション設定
          sessionStorage.setItem('prepaymentEmployee',JSON.stringify({
            employeeChecked: this.employeeChecked,
            employeeNumber: this.employeeSearchForm.get('employeeNumber').value,
            nameInKanji: this.employeeSearchForm.get('nameInKanji').value,
            nameFurigana: this.employeeSearchForm.get('nameFurigana').value
          }));
          this.router.navigate(['/companyPrepaymentDetailSearch']); // 前払利用状況明細画面へ遷移
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // 前払利用従業員初期化処理API接続
  onInit(){
    const apiUrl = environment.companyUrl.employeeInfo2Url;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPC0110', // 画面ID
        eventId: 'prepayment_employee_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}prepayment_employee_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            // セッション設定
            // ヘッダー部 セッション対象
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            let compSession = JSON.parse(sessionStorage.getItem('compSession'));
            this.companyCode = compSession['companyCode'];
            this.companyName = compSession['companyName'];
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // フォーチェック
  formCheck(){
    this.employeeSearchForm = this.fb.group({
      'employeeNumber' :[{value: '', disabled: !(this.employeeChecked === '1')},(this.employeeChecked === '1') ? [Validators.required, Validators.pattern('^[A-Za-z0-9]*')] : Validators.nullValidator], // 従業員番号
      'nameInKanji' :[{value: '', disabled: !(this.employeeChecked === '2')},(this.employeeChecked === '2') ? [Validators.required] : Validators.nullValidator], // 姓名(漢字)
      'nameFurigana' :[{value: '', disabled: !(this.employeeChecked === '3')},(this.employeeChecked === '3') ? [Validators.required] : Validators.nullValidator], // 姓名(カナ)
    });
  }

  // 従業員番号のエラーメッセージ
  getEmployeeNumberErrorMessage() {
    return this.employeeSearchForm.get('employeeNumber').hasError('required') ? '従業員番号を入力してください' :
           this.employeeSearchForm.get('employeeNumber').hasError('pattern') ? '従業員番号を半角英数字で入力してください' :
           '';
  };

  // 姓名(漢字)のエラーメッセージ
  getEmployeeNameInKanjiErrorMessage() {
    return this.employeeSearchForm.get('nameInKanji').hasError('required') ? '姓名(漢字)を入力してください' :
           '';
  };

  // 姓名(カナ)のエラーメッセージ
  getEmployeeNameFuriganaErrorMessage() {
    return this.employeeSearchForm.get('nameFurigana').hasError('required') ? '姓名(カナ)を入力してください' :
           '';
  };
}
