import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import * as moment from 'moment';
import * as queryString from 'query-string';

@Component({
  selector: 'app-accountLinkageGanbToken',
  templateUrl: './accountLinkageGanbToken.component.html',
  styleUrls: ['./accountLinkageGanbToken.component.css']
})
export class CompanyAccountLinkageGanbTokenComponent implements OnInit {

  loading: boolean = false; // ローディング状態
  bankCode: string; // 金融機関コード
  accessExpiresAtJST: string; // アクセストークン期限日時
  refreshExpiresAtJST: string; // リフレッシュトークン期限日時
  ganbDomainName: string; // GMOあおぞらネット銀行ドメイン名称
  clientId: string; // クライアントID
  scope: string; // スコープ
  state: string; // ステート
  redirectUri: string; // リダイレクトURI
  authorizationUri: string; // 認可エンドポイントURI

  // コンストラクタ
  constructor(
    private router: Router,
    private auth: CompanyAuthService,
    private http: HttpClient
  ) { }
  
  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', 'GMOあおぞらネット銀行口座連携'); // タイトル
    // パラメータ
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters'));
    this.bankCode = compParameters ? compParameters['bankCode'] : ''; // 金融機関コード
    this.accessExpiresAtJST = compParameters && compParameters['accessExpiresAt'] ? moment(compParameters['accessExpiresAt']).format('YYYY年MM月DD日 HH時mm分ss秒') : '-'; // アクセストークン期限日時
    this.refreshExpiresAtJST = compParameters && compParameters['refreshExpiresAt'] ? moment(compParameters['refreshExpiresAt']).format('YYYY年MM月DD日 HH時mm分ss秒') : '-'; // リフレッシュトークン期限日時
    this.ganbDomainName = compParameters ? compParameters['ganbDomainName'] : ''; // GMOあおぞらネット銀行ドメイン名称
    this.clientId = compParameters ? compParameters['clientId'] : ''; // クライアントID
    this.scope = compParameters ? compParameters['scope'] : ''; // スコープ
    this.state = compParameters ? compParameters['state'] : ''; // ステート
    this.redirectUri = compParameters ? compParameters['redirectUri'] : ''; // リダイレクトURI
    this.authorizationUri = compParameters ? compParameters['authorizationUri']: ''; // 認可エンドポイントURI
  };
  
  // 発行ボタンを押す
  onIssue() {
    if (this.loading === true) {
      return;
    }
    let url = `https://${this.ganbDomainName}${this.authorizationUri}?client_id=${this.clientId}&scope=${this.scope}&state=${this.state}&redirect_uri=${this.redirectUri}&response_type=code`;
    var openWindow = window.open(url, 'ganbLogin', 'height=700,width=1200,left=300,top=100,scrollbars=yes,status=no');
    openWindow.focus();
    let isPopup = false;
    var loop = setInterval(callback.bind(this, this.bankCode, this.http, this.auth, this.router, this.redirectUri.replace('https://', '')), 1000);
    function callback(bankCode: string, http: HttpClient, auth: CompanyAuthService, router: Router, redirectUri: string) {
      if (openWindow && openWindow.document && (openWindow.document.domain === 'localhost' || redirectUri.startsWith(openWindow.document.domain))) {
          let qs = queryString.parseUrl(openWindow.document.URL, {parseNumbers: true});
          if (!isPopup && qs.query.code) {
            tokenIssue(qs.query.code, bankCode, http, auth, router);
            isPopup = true;
          }
      }
      if (window.opener != null && !window.opener.closed) {
        openWindow.close();
        clearInterval(loop);
      }
      if (openWindow.closed) {
        clearInterval(loop);
      }
    }
    function tokenIssue(code: any, bankCode: string, http: HttpClient, auth: CompanyAuthService, router: Router) {
      // 共通ヘッダ
      const compSession = JSON.parse(sessionStorage.getItem('compSession'));
      const apiUrl = environment.companyUrl.companyInfo2Url;
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPC0181', // 画面ID
          eventId: 'gmo_account_linkage_issue', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // セッションID 
        },
        // パラメータ
        parameters: {
          code: code, // 認可コード
          bankCode: bankCode, // 金融機関コード
        }
      });
      // 送信（GMOあおぞらネット銀行口座連携を呼出す）
      http.post(
        `${apiUrl}gmo_account_linkage_issue`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
              router.navigate(['/companyAccountLinkageInfo']); // 画面遷移
            } else {
              // エラーの場合
              auth.resDialog(commonHeaderData);
            }
          },
          error => { // 異常処理
            auth.errDialog(error);
          }
        );
    }
  }
  
  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/companyAccountLinkage']);
  }
}