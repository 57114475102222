import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-usageActualResultSearch',
  templateUrl: './usageActualResultSearch.component.html',
  styleUrls: ['./usageActualResultSearch.component.css']
})
export class AdminUsageActualResultSearchComponent implements OnInit {
  parentCompanyCode: string; // 階層１企業コード
  sonCompanyCode: string; // 階層2企業コード
  grandsonCompanyCode: string; // 階層3企業コード
  parentCompanyList = []; // 階層１企業リスト
  sonCompanyList = []; // 階層2企業リスト
  grandsonCompanyList = []; // 階層3企業リスト
  sonCodeFlag: boolean; // 階層2企業フラグ
  sonCodeSelectFlag: boolean; // 階層2企業選択フラグ
  grandSonCodeFlag: boolean; // 階層3企業フラグ
  grandsonCodeSelectFlag: boolean; // 階層3企業選択フラグ
  // 一覧の標題項目
  displayedColumns: string[] = [
    'cutoffYearMonth',
    'employeeCount',
    'userCount',
    'applicationAmountSum',
    'transferFeeSum',
    'prepayFeeSum',
    'deductionAmountSum',
    'timesSucceed',
    'cutoffStatus',
    'cutoffAtJst',
    'isCutoff',
    'cutoffOn'
  ]; // 一覧データ明細
  dataSource = [];
  loading: boolean; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '利用実績照会'); // タイトル
    this.loading = true;
    // 階層1企業リスト取得
    this.retrieveParentCodeList();
    this.loading = false;
  }

  // 階層1が選択時
  selectParentCompany() {
    this.loading = true;
    this.dataSource = [];
    this.sonCodeFlag = false; // 階層2企業表示フラグ: false非表示
    this.grandSonCodeFlag = false; // 階層3企業表示フラグ: false非表示
    this.sonCompanyCode = ''; // 階層2企業コード
    this.grandsonCompanyCode = ''; // 階層3企業コード
    // 階層1企業コードが指定された場合
    if (this.parentCompanyCode !== '') {
      this.sonCodeFlag = true; // 階層2企業表示フラグ: true表示
      this.sonCompanyList = [];
      // 階層2企業コードリスト取得 ('0'：階層1企業コード選択、'1': 階層2企業コード選択)
      this.retrieveSubsidiaryList(this.parentCompanyCode, '0');
    } else {
      this.loading = false;
    }
  }

  // 階層2が選択時
  selectSonCompany() {
    this.loading = true;
    this.dataSource = [];
    this.grandSonCodeFlag = false;
    this.grandsonCompanyCode = '';
    if (this.sonCompanyCode !== '') {
      this.grandSonCodeFlag = true;
      this.grandsonCompanyList = [];
      // 階層3企業コードリスト取得 ('0'：階層1企業コード選択、'1'：階層2企業コード選択)
      this.retrieveSubsidiaryList(this.sonCompanyCode, '1');
    } else {
      this.loading = false;
    }
  }

  // 階層3が選択時
  selectGrandsonCompany() {
    this.dataSource = [];
  }

  // 集計ボタンを押す
  onSummary() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    this.dataSource = [];
    // パラメータ企業コード
    let companyCode = '';
    // 階層3企業コードが存在の場合
    if (this.grandsonCompanyCode) {
      companyCode = this.grandsonCompanyCode; // 階層3企業コード
      // 階層2企業コード存在するの場合
    } else if (this.sonCompanyCode) {
      companyCode = this.sonCompanyCode; // 階層2企業コード
      // 階層1企業コード存在するの場合
    } else if (this.parentCompanyCode) {
      companyCode = this.parentCompanyCode; // 階層1企業コード
    }
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0030', // 画面ID
        eventId: 'usage_actual_result_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 企業親子関係リスト取得
    this.http.post(
      `${apiUrl}usage_actual_result_search`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          let sysUseJissekiList = parametersData['sysUseJissekiList']
          for (let i of sysUseJissekiList) {
            // 月度Format(一行目の「月度」に'当月')
            let cutoffYearMonth = i.cutoffYearMonth;
            i.cutoffYearMonth = cutoffYearMonth === moment().format('YYYYMM') ? '当月' : this.auth.yearMonthDataFormat(cutoffYearMonth);
            // 締めステータス 1:済み 0:未済
            i.cutoffStatus = i.cutoffStatus === 0 ? '未済' : '済み';
            // 請求済フラグ 1:済み 0:未済
            i.isCutoff = i.isCutoff === 0 ? '未済' : '済み';
            // 締め日時Format
            i.cutoffAtJst = i.cutoffAt ? moment(i.cutoffAt).format('YYYY年MM月DD日') : '';
            // 請求日Format(YYYYMMDD => YYYY年MM月DD日)
            i.cutoffOn = this.auth.stringToDate(i.cutoffOn);
          }
          this.dataSource = sysUseJissekiList;
          this.loading = false;
        } else { // 異常の場合
          this.loading = false;
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 階層1企業リスト取得
  retrieveParentCodeList() {
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0030', // 画面ID
        eventId: 'admin_retrieve_parentcode', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      }
    });
    // 階層1企業リスト取得
    this.http.post(
      `${apiUrl}admin_retrieve_parentcode`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // 親企業リスト
          this.parentCompanyList = parametersData['parentCompanyList']; // 階層1企業リスト
          this.parentCompanyList.unshift({ // 階層1企業リスト初期値表示
            companyCode: '',
            companyName: '指定なし'
          });
          this.parentCompanyCode = ''; // 階層1企業コード
          this.loading = false;
        } else { // 異常の場合
          this.loading = false;
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  /**
   * 階層2、3企業コードリスト取得
   * メソッド名 retrieveSubsidiaryList
   * パラメータ {string} companyCode 企業コード
   * パラメータ {string} selectType: '0'：親企業コード選択、'1'：コード企業選択
   */
  retrieveSubsidiaryList(companyCode, selectType) {
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0030', // 画面ID
        eventId: 'admin_retrieve_subsidiary', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 企業リスト取得
    this.http.post(
      `${apiUrl}admin_retrieve_subsidiary`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // 階層1企業コード選択
          if (selectType === '0') {
            // 階層1企業コード選択時、階層2企業リストがある場合
            if (parametersData['companyList'][0]) {
              this.sonCodeSelectFlag = true;　// 子企業選択フラグ：true
              this.sonCompanyList = parametersData['companyList']; // 子企業リスト
              this.sonCompanyList.unshift({
                companyCode: '',
                companyName: '指定なし'
              });
              this.sonCompanyCode = ''; // 子企業コード
            } else {
              this.sonCodeSelectFlag = false;
            }
          }
          // 階層2企業コード選択
          if (selectType === '1') {
            // 階層2コード選択時、階層3企業リストがある場合
            if (parametersData['companyList'][0]) {
              this.grandsonCodeSelectFlag = true; // 孫企業選択フラグ：true
              this.grandsonCompanyList = parametersData['companyList']; // 孫企業リスト
              this.grandsonCompanyList.unshift({
                companyCode: '',
                companyName: '指定なし'
              });
              this.grandsonCompanyCode = ''; // 孫企業コード
            } else {
              this.grandsonCodeSelectFlag = false;
            }
          }
          this.loading = false;
        } else { // 異常の場合
          this.loading = false;
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
}