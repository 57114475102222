import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordUpdateInfo',
  templateUrl: './passwordUpdateInfo.component.html',
  styleUrls: ['./passwordUpdateInfo.component.css']
})
export class AdminPasswordUpdateInfoComponent implements OnInit {

  titleHeader:String = 'パスワードの変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', 'パスワード変更完了画面'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/adminMain']); // パスワード変更画面
  }

}
