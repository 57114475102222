import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transferFeePatternSearch',
  templateUrl: './transferFeePatternSearch.component.html',
  styleUrls: ['./transferFeePatternSearch.component.css']
})

export class AdminTransferFeePatternSearchComponent implements OnInit {
  bankCode: string; // 金融機関コード
  bankName: string; // 金融機関名
  companyName: string; // 企業名
  companyCode: string; // 企業コード
  companyList = []; // 企業リスト
  intimeSameBranchUnder: number; // 同支店時間内手数料(3万円未満)
  offhoursSameBranchUnder: number; // 同支店時間外手数料(3万円未満)
  intimeAnotherBranchUnder: number; // 同行他支店時間内手数料(3万円未満)
  offhoursAnotherBranchUnder: number; // 同行他支店時間外手数料(3万円未満)
  intimeAnotherBankUnder: number; // 他行時間内手数料(3万円未満)
  offhoursAnotherBankUnder: number; // 他行時間外手数料(3万円未満)
  intimeSameBranchOver: number; // 同支店時間内手数料(3万円以上)
  offhoursSameBranchOver: number; // 同支店時間外手数料(3万円以上)
  intimeAnotherBranchOver: number; // 同行他支店時間内手数料(3万円以上)
  offhoursAnotherBranchOver: number; // 同行他支店時間外手数料(3万円以上)
  intimeAnotherBankOver: number; // 他行時間内手数料(3万円以上)
  offhoursAnotherBankOver: number; // 他行時間外手数料(3万円以上)
  changeFlag: boolean; // 銀行振込手数料情報存在フラグ
  loading: boolean; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private router: Router,
    private http: HttpClient,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '振込手数料マスタ照会');
    this.changeFlag = false;
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // 認証トークン取得
    this.auth.getToken('SPM0150', adminSession['uuId'], adminSession['sessionId'], 'transferfee_delete');
    const bankCode = JSON.parse(sessionStorage.getItem('adminParameters'))['bankCode'];
    if (bankCode) {
      this.loading = true;
      // セッション取得
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
      // ヘッダ設定
      const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPM0150',  // 画面ID
          eventId: 'transferfee_search', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: bankCode, // 金融機関コード
        }
      });
      // 情報検索API送信
      this.http.post(
        `${apiUrl}transferfee_search`,
        body,
        {
          headers: headers
        }
      ).subscribe(
        response => {
          this.loading = false;
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.setParameters(parametersData)
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => { // エラーの場合
          this.auth.errDialog(error);
          this.loading = false;
        }
      );
    } else {
      const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
      this.setParameters(adminParameters);
    }
  }

  // テータ初期化処理
  setParameters(adminParameters) {
    this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName']; // 金融機関
    this.bankCode = adminParameters['bankCode']; // 金融機関コード
    let bankTransferFeeList = adminParameters['bankTransferFeeList'];
    if (bankTransferFeeList.length <= 0) {
      this.changeFlag = true;
      this.auth.messageLog({
        title: '振込手数料マスタ照会',
        message: '銀行振込手数料情報が見つかりません。'
      });
    } else {
      this.companyCode = bankTransferFeeList[0].companyCode;
      for (let i of bankTransferFeeList) {
        // 振込手数料パターンリスト
        this.companyList.push({
          'companyCode': i.companyCode,
          'companyName': i.companyCode +' '+ i.companyName
        })
        if(i.companyCode === this.companyCode){
          this.intimeSameBranchUnder = i.intimeSameBranchUnder; // 同支店時間内手数料(3万円未満)
          this.offhoursSameBranchUnder = i.offhoursSameBranchUnder; // 同支店時間外手数料(3万円未満)
          this.intimeAnotherBranchUnder = i.intimeAnotherBranchUnder; // 同行他支店時間内手数料(3万円未満)
          this.offhoursAnotherBranchUnder = i.offhoursAnotherBranchUnder; // 同行他支店時間外手数料(3万円未満)
          this.intimeAnotherBankUnder = i.intimeAnotherBankUnder; // 他行時間内手数料(3万円未満)
          this.offhoursAnotherBankUnder = i.offhoursAnotherBankUnder; // 他行時間外手数料(3万円未満)
          this.intimeSameBranchOver = i.intimeSameBranchOver; // 同支店時間内手数料(3万円以上)
          this.offhoursSameBranchOver = i.offhoursSameBranchOver; // 同支店時間外手数料(3万円以上)
          this.intimeAnotherBranchOver = i.intimeAnotherBranchOver; // 同行他支店時間内手数料(3万円以上)
          this.offhoursAnotherBranchOver = i.offhoursAnotherBranchOver; // 同行他支店時間外手数料(3万円以上)
          this.intimeAnotherBankOver = i.intimeAnotherBankOver; // 他行時間内手数料(3万円以上)
          this.offhoursAnotherBankOver = i.offhoursAnotherBankOver; // 他行時間外手数料(3万円以上)
        }
      };
    }
  }

  // 振込手数料パターン変更
  companyChange() {
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    let bankTransferFeeList = adminParameters['bankTransferFeeList']; // 銀行振込手数料
    for (let i of bankTransferFeeList) {
      if (i.companyCode === this.companyCode) {
        this.intimeSameBranchUnder = i.intimeSameBranchUnder; // 同支店時間内手数料(3万円未満)
        this.offhoursSameBranchUnder = i.offhoursSameBranchUnder; // 同支店時間外手数料(3万円未満)
        this.intimeAnotherBranchUnder = i.intimeAnotherBranchUnder; // 同行他支店時間内手数料(3万円未満)
        this.offhoursAnotherBranchUnder = i.offhoursAnotherBranchUnder; // 同行他支店時間外手数料(3万円未満)
        this.intimeAnotherBankUnder = i.intimeAnotherBankUnder; // 他行時間内手数料(3万円未満)
        this.offhoursAnotherBankUnder = i.offhoursAnotherBankUnder; // 他行時間外手数料(3万円未満)
        this.intimeSameBranchOver = i.intimeSameBranchOver; // 同支店時間内手数料(3万円以上)
        this.offhoursSameBranchOver = i.offhoursSameBranchOver; // 同支店時間外手数料(3万円以上)
        this.intimeAnotherBranchOver = i.intimeAnotherBranchOver; // 同行他支店時間内手数料(3万円以上)
        this.offhoursAnotherBranchOver = i.offhoursAnotherBranchOver; // 同行他支店時間外手数料(3万円以上)
        this.intimeAnotherBankOver = i.intimeAnotherBankOver; // 他行時間内手数料(3万円以上)
        this.offhoursAnotherBankOver = i.offhoursAnotherBankOver; // 他行時間外手数料(3万円以上)
      }
    };
  }
  // 追加ボタンを押す
  onTransferFeePatternAdd() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0150',
        eventId: 'transferfee_add', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: this.bankCode, // 金融機関コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transferfee_add`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.router.navigate(['/adminTransferFeePatternAdd']); // 振込手数料マスタ変更画面
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 変更ボタンを押す
  onTransferFeePatternChange() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // 振込手数料パターン取得
    // ヘッダ設定
    const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0150',  // 画面ID
        eventId: 'transferfee_modify', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: this.bankCode, //金融機関コード
        companyCode: this.companyCode // 企業コード
      }
    });
    // 情報検索API送信
    this.http.post(
      `${apiUrl}transferfee_modify`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
          this.router.navigate(['/adminTransferFeePatternModify']); //振込手数料マスタ変更画面
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 削除ボタンを押す
  onTransferFeePatternDelete() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // 削除警告ダイアログ表示
    this.auth.warnLog({ title: 'delete', message: '振込手数料マスタが削除されます。お確かめください。' }, result => {
      if (result === 'delete') {
        this.loading = true;
        // ヘッダ設定
        const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
        // 認証トークン
        const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
        const authToken = tokenParameters['authToken']['access_token'];
        // 送信前 ヘッダー部設定
        // ヘッダ設定
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + authToken,
          'Content-Type': 'application/json'
        });
        const body = JSON.stringify({
          commonHeader: {
            pageId: 'SPM0150',  // 画面ID
            eventId: 'transferfee_delete', // イベントID
            uuId: adminSession['uuId'], // 共通ID
            sessionId: adminSession['sessionId'] // セッションID 
          },
          parameters: {
            bankCode: this.bankCode, // 金融機関コード
            companyCode: this.companyCode // 振込手数料パターン
          }
        });
        // 情報検索API送信
        this.http.post(
          `${apiUrl}transferfee_delete`,
          body,
          {
            headers: headers
          }
        ).subscribe(
          response => {
            this.loading = false;
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.router.navigate(['/adminTransferFeePatternDeleteInfo']); //振込手数料マスタ変更画面
            } else { // 異常の場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // エラーの場合
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
      }
    });
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminBankDataSearch']); // 銀行マスタ照会画面へ
  }
}
