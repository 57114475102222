import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-userCompanyDefaultUpdateConfirm',
  templateUrl: './userCompanyDefaultUpdateConfirm.component.html',
  styleUrls: ['./userCompanyDefaultUpdateConfirm.component.css']
})
export class UserCompanyDefaultUpdateConfirmComponent implements OnInit {

  title: string = '給与前払いサービス－デフォルト企業変更確認';
  loading: boolean = false; // ローディング状態
  userCompany: string; // 所属企業
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン
  prepayFeeBurdenTypeName: string; // 利用手数料負担パターン
  defaultAmount: number; // 申請額
  bankAccount: string; // 振込先口座
  bankName: string;// 金融機関
  branchNumber: string;// 支店コード
  branchName: string;// 支店
  accountType: string;// 口座種別
  accountNumber: string;// 口座番号
  accountHolder: string;// 名義人

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private router: Router,
    ) { }

  // 画面初期化
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // 共通ヘッダ
    const parametersData = JSON.parse(sessionStorage.getItem('userParameters'));// パラメータ
    // 認証トークン取得
    this.auth.getToken('SPU0211', userSession['uuId'], userSession['sessionId'], 'default_company_commit'); // トーケンの取得
    // 画面表示項目
    this.userCompany = parametersData['company']['companyCode'] + ' ' + parametersData['company']['companyName']; // 所属企業
    this.transferFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['transferFeeBurdenType'].substring(0, 1)); // 振込手数料負担パターン
    this.prepayFeeBurdenTypeName = this.auth.getBurdenTypeName(parametersData['company']['prepayFeeBurdenType'].substring(0, 1)); // 利用手数料負担パターン
    this.defaultAmount = parametersData['company']['defaultAmount']; // 申請額
    this.bankAccount =  parametersData['bankAccount']; // 振込先情報
    var defaultAccountIndex = parametersData['defaultAccountIndex'];// デフォルト振込先インデックス
    for(let i of this.bankAccount){
      if(defaultAccountIndex === i['accountIndex']){
        this.bankName = i['bankName'];// 金融機関
        this.branchNumber = i['branchNumber'];// 支店コード
        this.branchName = i['branchName'];// 支店名
        this.accountType = this.auth.accountTypeStr(i['accountType']); // 口座種別
        this.accountNumber = i['accountNumber'];// 口座番号
        this.accountHolder = i['accountHolder'];// 名義人
      }
    }
  }

  // 初期選択企業に変更する
  onUpdate(){
    this.loading = true;
    const apiUrl = environment.userUrl.companySelApiUrl;
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); 
     // パラメータparameters
    const parametersData = JSON.parse(sessionStorage.getItem('userParameters'));
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters')); 
    const authToken = tokenParameters['authToken']['access_token'];
     // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPU0211',                  // 画面ID
        eventId: 'default_company_commit',  // イベントID
        uuId: userSession['uuId'],          // 共通ID
        appVersion: null,                // アプリバージョン
        sessionId: userSession['sessionId']  // セッションID 
      },
      // パラメータ
      parameters:{
        companyCode: userSession['companyCode'], // 企業コード
        employeeNumber: userSession['employeeNumber'],// 従業員番号
        defaultAmount: this.defaultAmount, // デフォルト申請額
        defaultAccountIndex: parametersData['defaultAccountIndex']// デフォルト振込先インデックス
      }
    });
    // 送信（前払申請受付APIを呼出す）
    this.http.post(
      `${apiUrl}default_company_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
            // セッションストレージ
            // 共通ヘッダ
            sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            // 画面遷移
            this.router.navigate(['/userCompanyDefaultUpdateInfo']); // 初期選択企業変更画面へ
          } else {
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {// 異常処理
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userCompanyDefaultUpdate']); // 初期選択企業変更画面へ
  }
}