import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prePayStartInfo',
  templateUrl: './prePayStartInfo.component.html',
  styleUrls: ['./prePayStartInfo.component.css']
})
export class UserPrePayStartInfoComponent implements OnInit {

  title: string = '給与前払いサービス－前払い申請完了';

  // コンストラクタ 
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}