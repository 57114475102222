import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordUpdateInfo',
  templateUrl: './passwordUpdateInfo.component.html',
  styleUrls: ['./passwordUpdateInfo.component.css']
})

export class UserPasswordUpdateInfoComponent implements OnInit {
  
  title: string = '給与前払いサービス－パスワード変更完了';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}