import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Code404Component } from './code404/code404.component';
// user
import { UserSignoutComponent } from './user/signout/signout.component';
import { UserSignoutInfoComponent } from './user/signoutInfo/signoutInfo.component';
import { UserLoginComponent } from './user/login/login.component';
import { UserForgotUseridComponent } from './user/forgotUserid/forgotUserid.component';
import { UserForgotUseridInfoComponent } from './user/forgotUseridInfo/forgotUseridInfo.component';
import { UserForgotPasswordComponent } from './user/forgotPassword/forgotPassword.component';
import { UserForgotPasswordInfoComponent } from './user/forgotPasswordInfo/forgotPasswordInfo.component';
import { UserPasswordUpdateComponent } from './user/passwordUpdate/passwordUpdate.component';
import { UserPasswordUpdateInfoComponent } from './user/passwordUpdateInfo/passwordUpdateInfo.component';
import { UserRegisterAgreementComponent } from './user/registerAgreement/registerAgreement.component';
import { UserRegisterAgreementAfterLoginComponent } from './user/registerAgreementAfterLogin/registerAgreementAfterLogin.component';
import { UserRegisterStartComponent } from './user/registerStart/registerStart.component';
import { UserRegisterUserAgreementComponent } from './user/registerUserAgreement/registerUserAgreement.component';
import { UserRegisterUserAgreementAfterLoginComponent } from './user/registerUserAgreementAfterLogin/registerUserAgreementAfterLogin.component';
import { UserRegisterInputComponent } from './user/registerInput/registerInput.component';
import { UserRegisterInputConfirmComponent } from './user/registerInputConfirm/registerInputConfirm.component';
import { UserRegisterInfoComponent } from './user/registerInfo/registerInfo.component';
import { UserPrePayStartComponent } from './user/prePayStart/prePayStart.component';
import { UserPrePayListComponent } from './user/prePayList/prePayList.component';
import { UserCompanyDefaultComponent } from './user/userCompanyDefault/userCompanyDefault.component';
import { UserCompanyDefaultUpdateComponent } from './user/userCompanyDefaultUpdate/userCompanyDefaultUpdate.component';
import { UserCompanyDefaultUpdateConfirmComponent } from './user/userCompanyDefaultUpdateConfirm/userCompanyDefaultUpdateConfirm.component';
import { UserCompanyDefaultUpdateInfoComponent } from './user/userCompanyDefaultUpdateInfo/userCompanyDefaultUpdateInfo.component';
import { UserCompanyAddComponent } from './user/userCompanyAdd/userCompanyAdd.component';
import { UserCompanyAddInfoComponent } from './user/userCompanyAddInfo/userCompanyAddInfo.component';
import { UserProfileComponent } from './user/userProfile/userProfile.component';
import { UserProfileUpdateComponent } from './user/userProfileUpdate/userProfileUpdate.component';
import { UserProfileUpdateConfirmComponent } from './user/userProfileUpdateConfirm/userProfileUpdateConfirm.component';
import { UserProfileUpdateInfoComponent } from './user/userProfileUpdateInfo/userProfileUpdateInfo.component';
import { UserPrePayStartConfirmComponent } from './user/prePayStartConfirm/prePayStartConfirm.component';
import { UserPrePayStartInfoComponent } from './user/prePayStartInfo/prePayStartInfo.component';
import { UserAuthGuard } from './user/auth/auth.guard';
// company
import { CompanyAuthService } from './company/auth/auth.service';
import { CompanyMainComponent } from './company/main/main.component';
import { CompanyLoginComponent } from './company/login/login.component';
import { CompanySearchComponent } from './company/companySearch/companySearch.component';
import { CompanyModifyComponent } from './company/companyModify/companyModify.component';
import { CompanyModifyConfirmComponent } from './company/companyModifyConfirm/companyModifyConfirm.component';
import { CompanyModifyInfoComponent } from './company/companyModifyInfo/companyModifyInfo.component';
import { CompanyEmployeeSearchComponent } from './company/employeeSearch/employeeSearch.component';
import { CompanyEmployeeModifyComponent } from './company/employeeModify/employeeModify.component';
import { CompanyEmployeeModifyConfirmComponent } from './company/employeeModifyConfirm/employeeModifyConfirm.component';
import { CompanyEmployeeModifyInfoComponent } from './company/employeeModifyInfo/employeeModifyInfo.component';
import { CompanyPrepaymentEmployeeComponent } from './company/prepaymentEmployee/prepaymentEmployee.component';
import { CompanyPrepaymentCancelConfirmComponent } from './company/prepaymentCancelConfirm/prepaymentCancelConfirm.component';
import { CompanyPrepaymentCancelComponent } from './company/prepaymentCancel/prepaymentCancel.component';
import { CompanyPrepaymentCancelInfoComponent } from './company/prepaymentCancelInfo/prepaymentCancelInfo.component';
import { CompanyPrepaymentDetailSearchComponent } from './company/prepaymentDetailSearch/prepaymentDetailSearch.component';
import { CompanyCsvUploadComponent } from './company/csvUpload/csvUpload.component';
import { CompanyCsvdownloadComponent } from './company/csvDownload/csvDownload.component';
import { CompanyPasswordUpdateComponent } from './company/passwordUpdate/passwordUpdate.component';
import { CompanyPasswordUpdateInfoComponent } from './company/passwordUpdateInfo/passwordUpdateInfo.component';
import { CompanyUsageResultInquiryComponent } from './company/usageResultInquiry/usageResultInquiry.component';
import { CompanyAccountLinkageComponent } from './company/accountLinkage/accountLinkage.component';
import { CompanyAccountLinkageGanbTokenComponent } from './company/accountLinkageGanbToken/accountLinkageGanbToken.component';
import { CompanyAccountLinkageGanbTokenCallbackComponent } from './company/accountLinkageGanbTokenCallback/accountLinkageGanbTokenCallback.component';
import { CompanyAccountLinkageInfoComponent } from './company/accountLinkageInfo/accountLinkageInfo.component';
import { CompanyAuthGuard } from './company/auth/auth.guard';
// admin
import { AdminMainComponent } from './admin/main/main.component';
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminUsageActualResultSearchComponent } from './admin/usageActualResultSearch/usageActualResultSearch.component';
import { AdminCompanySearchComponent } from './admin/companySearch/companySearch.component';
import { AdminCompanyModifyComponent } from './admin/companyModify/companyModify.component';
import { AdminCompanyModifyConfirmComponent } from './admin/companyModifyConfirm/companyModifyConfirm.component';
import { AdminCompanyModifyInfoComponent } from './admin/companyModifyInfo/companyModifyInfo.component';
import { AdminUserDataSearchComponent } from './admin/userDataSearch/userDataSearch.component';
import { AdminBankDataSearchComponent } from './admin/bankDataSearch/bankDataSearch.component';
import { AdminBankDataAddComponent } from './admin/bankDataAdd/bankDataAdd.component';
import { AdminBankDataModifyComponent } from './admin/bankDataModify/bankDataModify.component';
import { AdminBankDataModifyConfirmComponent } from './admin/bankDataModifyConfirm/bankDataModifyConfirm.component';
import { AdminBankDataModifyInfoComponent } from './admin/bankDataModifyInfo/bankDataModifyInfo.component';
import { AdminBankDataDeleteInfoComponent } from './admin/bankDataDeleteInfo/bankDataDeleteInfo.component';
import { AdminTransferFeePatternSearchComponent } from './admin/transferFeePatternSearch/transferFeePatternSearch.component';
import { AdminTransferFeePatternAddComponent } from './admin/transferFeePatternAdd/transferFeePatternAdd.component';
import { AdminTransferFeePatternModifyComponent } from './admin/transferFeePatternModify/transferFeePatternModify.component';
import { AdminTransferFeePatternModifyConfirmComponent } from './admin/transferFeePatternModifyConfirm/transferFeePatternModifyConfirm.component';
import { AdminTransferFeePatternModifyInfoComponent } from './admin/transferFeePatternModifyInfo/transferFeePatternModifyInfo.component';
import { AdminTransferFeePatternDeleteInfoComponent } from './admin/transferFeePatternDeleteInfo/transferFeePatternDeleteInfo.component';
import { AdminPasswordUpdateComponent } from './admin/passwordUpdate/passwordUpdate.component';
import { AdminPasswordUpdateInfoComponent } from './admin/passwordUpdateInfo/passwordUpdateInfo.component';
import { AdminTransferErrorSetComponent } from './admin/transferErrorSet/transferErrorSet.component';
import { AdminTransferErrorSetConfirmComponent } from './admin/transferErrorSetConfirm/transferErrorSetConfirm.component';
import { AdminTransferErrorSetInfoComponent } from './admin/transferErrorSetInfo/transferErrorSetInfo.component';
import { AdminPrepaymentUserListComponent } from './admin/prepaymentUserList/prepaymentUserList.component';
import { AdminPrepaymentUserDetailComponent } from './admin/prepaymentUserDetail/prepaymentUserDetail.component';
import { AdminAuthGuard } from './admin/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'companyLogin', pathMatch: 'full' },
  // user
  { path: 'userSignout', component: UserSignoutComponent, canActivate: [UserAuthGuard] },
  { path: 'userSignoutInfo', component: UserSignoutInfoComponent, canActivate: [UserAuthGuard] },
  { path: 'userLogin', component: UserLoginComponent },
  { path: 'userForgotUserid', component: UserForgotUseridComponent },
  { path: 'userForgotUseridInfo', component: UserForgotUseridInfoComponent },
  { path: 'userForgotPassword', component: UserForgotPasswordComponent },
  { path: 'userForgotPasswordInfo', component: UserForgotPasswordInfoComponent },
  { path: 'userPasswordUpdate', component: UserPasswordUpdateComponent, canActivate: [UserAuthGuard] },
  { path: 'userPasswordUpdateInfo', component: UserPasswordUpdateInfoComponent, canActivate: [UserAuthGuard] },
  { path: 'userRegisterAgreement', component: UserRegisterAgreementComponent },
  { path: 'userRegisterAgreementAfterLogin', component: UserRegisterAgreementAfterLoginComponent, canActivate: [UserAuthGuard] },
  { path: 'userRegisterStart', component: UserRegisterStartComponent },
  { path: 'userRegisterUserAgreement', component: UserRegisterUserAgreementComponent },
  { path: 'userRegisterUserAgreementAfterLogin', component: UserRegisterUserAgreementAfterLoginComponent, canActivate: [UserAuthGuard] },
  { path: 'userRegisterInput', component: UserRegisterInputComponent },
  { path: 'userRegisterInputConfirm', component: UserRegisterInputConfirmComponent },
  { path: 'userRegisterInfo', component: UserRegisterInfoComponent },
  { path: 'userPrePayList', component: UserPrePayListComponent, canActivate: [UserAuthGuard] },
  { path: 'userPrePayStart', component: UserPrePayStartComponent, canActivate: [UserAuthGuard] },
  { path: 'userPrePayStartConfirm', component: UserPrePayStartConfirmComponent, canActivate: [UserAuthGuard] },
  { path: 'userPrePayStartInfo', component: UserPrePayStartInfoComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyDefault', component: UserCompanyDefaultComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyDefaultUpdate', component: UserCompanyDefaultUpdateComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyDefaultUpdateConfirm', component: UserCompanyDefaultUpdateConfirmComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyDefaultUpdateInfo', component: UserCompanyDefaultUpdateInfoComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyAdd', component: UserCompanyAddComponent, canActivate: [UserAuthGuard] },
  { path: 'userCompanyAddInfo', component: UserCompanyAddInfoComponent, canActivate: [UserAuthGuard] },
  { path: 'userProfile', component: UserProfileComponent, canActivate: [UserAuthGuard] },
  { path: 'userProfileUpdate', component: UserProfileUpdateComponent, canActivate: [UserAuthGuard] },
  { path: 'userProfileUpdateConfirm', component: UserProfileUpdateConfirmComponent, canActivate: [UserAuthGuard] },
  { path: 'userProfileUpdateInfo', component: UserProfileUpdateInfoComponent, canActivate: [UserAuthGuard] },
  // company
  { path: 'companyLogin', component: CompanyLoginComponent },
  { path: 'companyMain', component: CompanyMainComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companySearch', component: CompanySearchComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyModify', component: CompanyModifyComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyModifyConfirm', component: CompanyModifyConfirmComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyModifyInfo', component: CompanyModifyInfoComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyEmployeeSearch', component: CompanyEmployeeSearchComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyEmployeeModify', component: CompanyEmployeeModifyComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyEmployeeModifyConfirm', component: CompanyEmployeeModifyConfirmComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyEmployeeModifyInfo', component: CompanyEmployeeModifyInfoComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPrepaymentEmployee', component: CompanyPrepaymentEmployeeComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPrepaymentDetailSearch', component: CompanyPrepaymentDetailSearchComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPrepaymentCancelConfirm', component: CompanyPrepaymentCancelConfirmComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPrepaymentCancel', component: CompanyPrepaymentCancelComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPrepaymentCancelInfo', component: CompanyPrepaymentCancelInfoComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyCsvUpload', component: CompanyCsvUploadComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyCsvDownload', component: CompanyCsvdownloadComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPasswordUpdate', component: CompanyPasswordUpdateComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyPasswordUpdateInfo', component: CompanyPasswordUpdateInfoComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyUsageResultInquiry', component: CompanyUsageResultInquiryComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyAccountLinkage', component: CompanyAccountLinkageComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyAccountLinkage/ganb/token', component: CompanyAccountLinkageGanbTokenComponent, canActivate: [CompanyAuthGuard] },
  { path: 'companyAccountLinkage/ganb/token/callback', component: CompanyAccountLinkageGanbTokenCallbackComponent },
  { path: 'companyAccountLinkageInfo', component: CompanyAccountLinkageInfoComponent, canActivate: [CompanyAuthGuard] },
  // admin
  { path: 'adminLogin', component: AdminLoginComponent, canActivate: [CompanyAuthService]},
  { path: 'adminMain', component: AdminMainComponent , canActivate: [AdminAuthGuard] },
  { path: 'adminUsageActualResultSearch', component: AdminUsageActualResultSearchComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminCompanySearch', component: AdminCompanySearchComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminCompanyModify', component: AdminCompanyModifyComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminCompanyModifyConfirm', component: AdminCompanyModifyConfirmComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminCompanyModifyInfo', component: AdminCompanyModifyInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminUserDataSearch', component: AdminUserDataSearchComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminBankDataSearch', component: AdminBankDataSearchComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminBankDataAdd', component: AdminBankDataAddComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminBankDataModify', component: AdminBankDataModifyComponent , canActivate: [AdminAuthGuard] } ,
  { path: 'adminBankDataModifyConfirm', component: AdminBankDataModifyConfirmComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminBankDataModifyInfo', component: AdminBankDataModifyInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminBankDataDeleteInfo', component: AdminBankDataDeleteInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternSearch', component: AdminTransferFeePatternSearchComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternAdd', component: AdminTransferFeePatternAddComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternModify', component: AdminTransferFeePatternModifyComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternModifyConfirm', component: AdminTransferFeePatternModifyConfirmComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternModifyInfo', component: AdminTransferFeePatternModifyInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferFeePatternDeleteInfo', component: AdminTransferFeePatternDeleteInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminPasswordUpdate', component: AdminPasswordUpdateComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminPasswordUpdateInfo', component: AdminPasswordUpdateInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferErrorSet', component: AdminTransferErrorSetComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferErrorSetConfirm', component: AdminTransferErrorSetConfirmComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminTransferErrorSetInfo', component: AdminTransferErrorSetInfoComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminPrepaymentUserList', component: AdminPrepaymentUserListComponent, canActivate: [AdminAuthGuard] },
  { path: 'adminPrepaymentUserDetail', component: AdminPrepaymentUserDetailComponent, canActivate: [AdminAuthGuard] },
  { path: '**', component: Code404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }