import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordUpdateInfo',
  templateUrl: './passwordUpdateInfo.component.html',
  styleUrls: ['./passwordUpdateInfo.component.css']
})
export class CompanyPasswordUpdateInfoComponent implements OnInit {

  titleHeader:String = 'パスワードの変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() {
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/companyMain']); // SPC0030.企業情報照会画面へ
  }
}
