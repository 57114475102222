import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminLogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class AdminLoginComponent implements OnInit {
  hide: boolean = true; // パスワード
  loading: boolean = false;// ローディング状態
  title: string; // タイトル
  loginForm:  FormGroup; // 画面フォーム

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    this.auth.getToken('SPM0010', null, null, 'admin_login');
    this.title = '前払業務管理者ログイン';
    // フォーム初期化
    this.loginForm = this.fb.group({
      'administratorId': ['', [Validators.required]],
      'password': ['', [Validators.required]]
    });
    this.loading = false;
  }

  // 登録ボタンを押す
  onSubmitLogin() {
    if(this.loading === true){
      return;
    }
    this.loginForm = this.fb.group({
      'administratorId': [this.loginForm.get('administratorId').value, [Validators.required]],
      'password': [this.loginForm.get('password').value],
    });
    if (this.loginForm.valid) {
      this.loading = true;
      const apiUrl = environment.adminUrl.authServiceApiUrl;
      // 認証トークン
      const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
      const token = tokenParameters['authToken']['access_token'];
      // ヘッダ設定
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      });
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPM0010',　　　// 画面ID
          eventId: 'admin_login',  // イベントID
          uuId: null,          // 共通ID
          sessionId: null // セッションID 
        },
        // パラメータ
        parameters: {
          administratorId: this.loginForm.get('administratorId').value,    // システム管理者ID
          password: this.loginForm.get('password').value, // パスワード
        }
      });
      // 送信　
      this.http.post(
        `${apiUrl}admin_login`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              sessionStorage.setItem('adminSession',JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminLoggedIn', 'true');
              this.loading = false;
              this.router.navigate(['/adminMain']);
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData['message']);
            }
          },
          error => { // 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // システム管理者IDのエラーメッセージ
  getEmailErrorMessage() {
    return this.loginForm.get('administratorId').hasError('required') ? 'ユーザーIDを入力してください' :
        '';
  };

  // パスワードのエラーメッセージ
  getPasswordErrorMessage() {
    return this.loginForm.get('password').hasError('required') ? 'パスワードを入力してください' :
      '';
  };
}