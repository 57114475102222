import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import * as moment from 'moment';

@Component({
  selector: 'app-usageResultInquiry',
  templateUrl: './usageResultInquiry.component.html',
  styleUrls: ['./usageResultInquiry.component.css']
})
export class CompanyUsageResultInquiryComponent implements OnInit {

  dataSource: JSON[] = []; // テーブル
  displayedColumns: string[] = [
    'cutoffYearMonth',
    'employeeCount',
    'userCount',
    'applicationAmountSum',
    'transferFeeSum',
    'prepayFeeSum',
    'deductionAmountSum',
    'timesSucceed',
    'cutoffStatus',
    'cutoffAtJst',
    'isCutoff',
    'cutoffOn'
  ]; // テーブルのコラム
  parentCode: string; // 階層１企業コード
  parentCodeName: string; // 階層１企業名
  sonCodeList = []; // 階層2企業コードリスト
  grandsonCodeList = []; // 階層3企業コードリスト
  secretSonCode: string; // 選択した階層2企業
  secretSonCodeName: string; // 選択した階層2企業名
  sonListFlag: boolean; // 階層2企業リスト存在フラグ
  grandSonFlag: boolean; // 階層3企業フラグ
  grandSonListFlag: boolean; // 階層3企業リスト存在フラグ
  grandSonCode: string; // 階層2企業コード
  secretGrandsonCode: string; // 選択した階層3企業
  secretGrandsonName: string; // 階層3企業名

  loading: boolean = false; // ローディング状態


  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', '利用実績照会'); // タイトル
    this.loading = true;
    this.dataSource = [];
    this.findCompanyDetail();
  }

  // 階層2企業の選択値が変更された場合
  onSelectedSonCodeChange() {
    this.loading = true;
    this.dataSource = [];
    if (this.secretSonCode != '') {
      this.grandSonFlag = true; // 階層3企業を表示する
      // 階層3企業リスト取得
      this.retrieveSubsidiaryList(this.secretSonCode);
    } else {
      this.grandSonFlag = false;
      this.loading = false;
    }
  }

  // 階層3企業の選択値が変更された場合
  onSelectedGrandsonSonCodeChange() {
    this.dataSource = [];
  }

  // 集計ボタンを押す
  onBtnAggregate() {
    this.loading = true;
    this.dataSource = [];
    // 企業コード取得
    let companyCode = '';
    if (this.grandSonFlag) {
      if (this.grandSonListFlag && !this.secretGrandsonCode) {
        companyCode = this.secretSonCode;
      } else if (this.grandSonListFlag && this.secretGrandsonCode) {
        companyCode = this.secretGrandsonCode;
      } else if (!this.grandSonListFlag && this.secretGrandsonName !== '-') {
        companyCode = this.secretGrandsonCode;
      } else if (!this.grandSonListFlag && this.secretGrandsonName === '-') {
        companyCode = this.secretSonCode;
      }
    } else {
      companyCode = this.parentCode;
    }
    // API URL設定
    const apiUrl = environment.companyUrl.companyInfo2Url;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0111',
        eventId: 'usage_result_inquiry',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // セッションID
      },
      parameters: {
        companyCode: companyCode // 企業の選択値
      }
    });
    // 企業階層2関係リスト取得
    this.http.post(
      `${apiUrl}usage_result_inquiry`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // セッション設定
          // ヘッダー部 セッション対象
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          // パラメータ部 セッション対象
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          let usageResultInquiryList = parametersData['usageResultInquiryList'];
          for (let usageResultInquiry of usageResultInquiryList) {
            // 月度Format(一行目の「月度」に'当月')
            let cutoffYearMonth = usageResultInquiry['cutoffYearMonth'];
            usageResultInquiry['cutoffYearMonth'] = cutoffYearMonth === moment().format('YYYYMM') ? '当月' : this.auth.yearMonthDataFormat(cutoffYearMonth);
            // 締めステータス 1:済み 0:未済
            usageResultInquiry['cutoffStatus'] = usageResultInquiry['cutoffStatus'] === 0 ? '未済' : '済み';
            // 請求済フラグ 1:済み 0:未済
            usageResultInquiry['isCutoff'] = usageResultInquiry['isCutoff'] === 0 ? '未済' : '済み';
            // 締め日時Format
            usageResultInquiry['cutoffAtJst'] = usageResultInquiry['cutoffAt'] ? moment(usageResultInquiry['cutoffAt']).format('YYYY年MM月DD日') : '';
            // 請求日Format(YYYYMMDD => YYYY年MM月DD日)
            usageResultInquiry['cutoffOn'] = this.auth.stringToDate(usageResultInquiry['cutoffOn']);
          }
          this.dataSource = usageResultInquiryList;
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 企業階層2関係リスト取得
  findCompanyDetail() {
    // API URL設定
    const apiUrl = environment.companyUrl.companyInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0111',
        eventId: 'find_company_relation',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // セッションID
      }
    });
    // 企業階層2関係リスト取得
    this.http.post(
      `${apiUrl}find_company_relation`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // 階層１企業リスト
          for (let i of parametersData['parentCompanyList']) {
            this.parentCodeName = `${i.companyCode} ${i.companyName}`; // 階層１企業
            this.parentCode = i.companyCode; // 階層１企業コード
            break;
          }
          // 本企業は階層１企業の場合
          if (parametersData['companyPartFlag'] === 0) {
            // 階層3企業を表示しない
            this.grandSonFlag = false;
            // 階層2企業がある場合
            if (parametersData['sonCompanyList'][0]) {
              // 階層2企業リストを表示する
              this.sonListFlag = true;
              // 階層2企業リスト
              this.sonCodeList = parametersData['sonCompanyList'];
              this.sonCodeList.unshift({ companyCode: '', companyName: '指定なし' }); // 階層2企業リスト
              this.secretSonCode = '';
            } else { // 階層2企業がなし場合
              // 階層2企業リストを表示しない
              this.sonListFlag = false;
              // 階層2企業名
              this.secretSonCodeName = '-';
            }
          }
          // 本企業は階層2企業の場合
          if (parametersData['companyPartFlag'] === 1) {
            // 階層3企業を表示する
            this.grandSonFlag = true;
            // 階層2企業名
            this.secretSonCodeName = `${parametersData['sonCompanyList'][0]['companyCode']} ${parametersData['sonCompanyList'][0]['companyName']}`;
            // 階層2企業コード
            this.secretSonCode = parametersData['sonCompanyList'][0]['companyCode'];
            // 階層3企業リストがある場合 
            if (parametersData['grandsonCompanyList'][0]) {
              // 階層3企業リストを表示する
              this.grandSonListFlag = true;
              // 階層3企業リスト
              this.grandsonCodeList = parametersData['grandsonCompanyList'];
              this.grandsonCodeList.unshift({ companyCode: '', companyName: '指定なし' }); // 階層3企業リスト
              this.secretGrandsonCode = '';
            } else { // 階層3企業リストがなし場合
              // 階層3企業リストを表示しない
              this.grandSonListFlag = false;
              // 階層3企業名
              this.secretGrandsonName = '-';
            }
          }
          // 本企業は階層3企業の場合
          if (parametersData['companyPartFlag'] === 2) {
            this.grandSonFlag = true;
            // 階層2企業リストを表示しない
            this.sonListFlag = false;
            // 階層3企業リストを表示しない
            this.grandSonListFlag = false;
            // 階層2企業名
            this.secretSonCodeName = `${parametersData['sonCompanyList'][0]['companyCode']} ${parametersData['sonCompanyList'][0]['companyName']}`;
            // 階層3企業名
            this.secretGrandsonName = `${parametersData['grandsonCompanyList'][0]['companyCode']} ${parametersData['grandsonCompanyList'][0]['companyName']}`;
            // 階層3企業コード
            this.secretGrandsonCode = parametersData['grandsonCompanyList'][0]['companyCode'];
          }
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 階層3企業リスト取得
  retrieveSubsidiaryList(companyCode: string) {
    const apiUrl = environment.companyUrl.companyInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ
      commonHeader: {
        pageId: 'SPC0111',
        eventId: 'retrieve_subsidiary_list',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}retrieve_subsidiary_list`,// 企業情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            // 階層3企業リストがある場合 
            if (parametersData['sonCompanyCodeList'][0]) {
              // 階層3企業リストを表示する
              this.grandSonListFlag = true;
              // 階層3企業リスト
              this.grandsonCodeList = parametersData['sonCompanyCodeList'];
              let grandsonCodeList_empty = { companyCode: '', companyName: '指定なし' };
              this.grandsonCodeList.unshift(grandsonCodeList_empty);
              this.secretGrandsonCode = '';
            } else {
              // 階層3企業リストを表示しない
              this.grandSonListFlag = false;
              // 階層3企業名
              this.secretGrandsonName = '-';
            }
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }
}
