import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';
import { Router } from '@angular/router';

// 振込先口座インターフェース
export interface FileList{
  csvFileDisplayName: string;
  csvFileName: string;
  csvFileId: string;
};

@Component({
  selector: 'app-accountLinkage',
  templateUrl: './accountLinkage.component.html',
  styleUrls: ['./accountLinkage.component.css']
})

export class CompanyAccountLinkageComponent implements OnInit {
  
  loading: boolean; // ローディング状態
  dataSource = [];
  displayedColumns = ['bankCode','bankName','btnLinkage'];
 
  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '口座連携'); // タイトル
    this.init();
  }

  // 画面初期化
  init(){
    // APIリンク
    const apiUrl = environment.companyUrl.companyInfo2Url;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPC0180', // ページID
        eventId: 'account_linkage_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}account_linkage_init`, // 口座連携初期化
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            this.dataSource = parametersData['companyBankList'];
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        if (error['error']['commonHeader']){
          let messageCode = error['error']['commonHeader']['messageCode'];
          let errCode = messageCode.substr(2,1);
          if (messageCode === '01999'){
            this.auth.errDialog(error);
          } else if(messageCode !== '01103' && errCode !== '9'){ // 二重ダイアログアラート防止
            this.auth.errDialog(error);
          }
        }
      }
    );
  }

  // 口座連携ボタンを押す
  onLinkage(linkageBankCode: string){
    if (this.loading === true) {
      return;
    }
    // GMOあおぞらネット銀行の場合
    if (linkageBankCode === '0310'){
      this.loading = true;
      // APIリンク
      const apiUrl = environment.companyUrl.companyInfo2Url;
      // セッションを取得する
      const compSession = JSON.parse(sessionStorage.getItem('compSession'));
      // header
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // body
      const body = JSON.stringify({
        // 共通ヘッダ
        commonHeader:{
          pageId: 'SPC0180', // ページID
          eventId: 'gmo_account_linkage_init', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // 利用企業管理者セッションID
        },
        parameters: {
          bankCode: linkageBankCode, // 金融機関コード
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}gmo_account_linkage_init`, // 口座連携初期化
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            const commonHeaderData = response['commonHeader'];
            const parametersData = response['parameters'];
            sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
              // 画面遷移
              this.router.navigate(['/companyAccountLinkage/ganb/token']);
            } else {
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          if (error['error']['commonHeader']){
            let messageCode = error['error']['commonHeader']['messageCode'];
            let errCode = messageCode.substr(2,1);
            if (messageCode === '01999'){
              this.auth.errDialog(error);
            } else if(messageCode !== '01103' && errCode !== '9'){ // 二重ダイアログアラート防止
              this.auth.errDialog(error);
            }
          }
        }
      );
    }
  }
}
