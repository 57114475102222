import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

export interface UsageSituationElement {
  applicationTimeJST: string; // 申込日
  affiliatedCompany: string; // 所属企業
  bankName: string; // 金融機関
  branchNumber: string; // 支店コード
  branchName: string; // 支店名
  accountNumber: string; // 口座番号
  applicationAmount: number; // 前払申請額
  transferFee: number; // 振込手数料
  prepaymentFee: number; // 利用手数料
  status: string; // 申請状況 1:未送信；2:送信済；3:エラー
};

@Component({
  selector: 'app-prePayList',
  templateUrl: './prePayList.component.html',
  styleUrls: ['./prePayList.component.css']
})

export class UserPrePayListComponent implements OnInit {

  title: string = '給与前払いサービス－利用状況一覧';

  date: Date = new Date(Date.now());
  ELEMENT_DATA: UsageSituationElement[];
  displayedColumns: string[] = ['applicationTimeJST', 'affiliatedCompany', 'bankName', 'branchNumber', 'accountNumber', 'applicationAmount', 'transferFee', 'prepaymentFee', 'status'];
  dataSource = new MatTableDataSource<UsageSituationElement>(this.ELEMENT_DATA);
  userName: string; // 氏名
  selectTime: string; // 利用状況確認期間
  prepayLimit: number = 0; // 前払申請可能額
  loading: boolean = false; // ローディング状態
  isClosingInProcessFLG: boolean = false; // 締め中状態

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    this.getPrepaymentDetailData();
  }

  onStartClick(){
    this.router.navigate(['/userPrePayStart']); // 前払申請画面へ
  }

  getPrepaymentDetailData(){
    this.loading = true;
    const apiUrl = environment.userUrl.prePaymentApiUrl;
    //　画面表示項目
    var durationFrom: string; // 利用状況確認期間From
    var prepayDetail; // 利用状況情報
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0110',
        eventId: 'prepayment_detail_search',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}prepayment_detail_search`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          if(response){
            this.loading = false;
            // commonHeader
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            this.userName = commonHeaderData['userName'];// 氏名
            if(commonHeaderData['messageCode'] === '00000' // 正常の場合
            || commonHeaderData['messageCode'] === '00213' // 退社した場合
            || commonHeaderData['messageCode'] === '00214' // 所属企業利用契約終了の場合
            || commonHeaderData['messageCode'] === '00217' // 支給対象締め処理中の場合
            ){
              if (parametersData && parametersData['closingInProcess'] === 1){ // 締め中の場合
                this.isClosingInProcessFLG = true;
              }
              if(commonHeaderData['messageCode'] !== '00000'
              && commonHeaderData['messageCode'] !== '00214'){
                commonHeaderData['messageCode'] = '00000'; // ダイアログ閉じる
                this.auth.resDialog(commonHeaderData);
              }
              if(commonHeaderData['messageCode'] === '00214'){ // 所属企業利用契約終了の場合
                this.auth.resDialog(commonHeaderData);
              } else { // 所属企業利用契約中の場合
                this.prepayLimit = parametersData['prepayLimit'] ? parametersData['prepayLimit'] : 0; // 前払申請可能額
                durationFrom = parametersData['durationFrom']; // 利用状況確認期間From
                this.selectTime = durationFrom.substring(0,4) + '年' + durationFrom.substring(4,6) + '月～本日'; // 利用状況確認期間
                prepayDetail = parametersData['prepayDetail']; // 利用状況情報
                const ELEMENT = Array.from({length: prepayDetail.length}, (_, k) => this.setPrepayDetailData(prepayDetail, k));
                this.dataSource = new MatTableDataSource(ELEMENT);
              }
            } else {
              this.auth.resDialog(commonHeaderData);
              if(commonHeaderData['messageCode'] === '00206'){ // 三ヶ月以内の利用明細はみつかりませんの場合
                this.prepayLimit = parametersData['prepayLimit'] ? parametersData['prepayLimit'] : 0; // 前払申請可能額
                durationFrom = parametersData['durationFrom']; // 利用状況確認期間From
                this.selectTime = durationFrom.substring(0,4) + '年' + durationFrom.substring(4,6) + '月～本日'; // 利用状況確認期間
              }
            }
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  setPrepayDetailData(prepay, k: number){
    return {
      applicationTimeJST: prepay[k]['applicationTimeJST'], // 申込日
      affiliatedCompany: prepay[k]['companyCode'] + '\n' + prepay[k]['companyName'], // 企業コード + ' ' + 企業名
      bankName: prepay[k]['bankName'], // 金融機関
      branchNumber: prepay[k]['branchNumber'], // 支店コード
      branchName: prepay[k]['branchName'], // 支店名
      accountNumber: prepay[k]['accountNumber'], // 口座番号
      applicationAmount: prepay[k]['applicationAmount'], // 前払申請額
      transferFee: prepay[k]['transferFeeBurdenType'] === '0' ? '0' : prepay[k]['transferFee'], // 振込手数料
      prepaymentFee: prepay[k]['prepayFeeBurdenType'] === '0' ? '0' : prepay[k]['prepaymentFee'], // 利用手数料
      status: prepay[k]['status'] === '1' ? '未送信' : prepay[k]['status'] === '2' ? '送信済' : prepay[k]['status'] === '3' ? 'エラー' : prepay[k]['status'] === '4' ? '取消' : '' // 申請状況 1:未送信；2:送信済；3:エラー；4:取消
    }
  }
}