import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotUseridInfo',
  templateUrl: './forgotUseridInfo.component.html',
  styleUrls: ['./forgotUseridInfo.component.css']
})

export class UserForgotUseridInfoComponent implements OnInit {

  title: string = 'ユーザーIDをお忘れの方送信完了';

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  // ログイン画面に戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }
}