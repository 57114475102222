import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserMyErrorStateMatcher } from '../common/common.component';
import { environment } from './../../../environments/environment.dev';
import { UserAuthService } from '../auth/auth.service';

@Component({
  selector: 'app-registerInput',
  templateUrl: './registerInput.component.html',
  styleUrls: ['./registerInput.component.css']
})

export class UserRegisterInputComponent implements OnInit {

  title: string = '給与前払いサービス－個人情報登録';
  hidePassword = true;
  hidePasswordConfirm = true;
  registerInputForm: FormGroup;
  companyCode: string; // 企業コード
  employeeNumber: string; // 従業員番号
  gender: string; // 性別
  postCode: string; // 郵便番号
  birthDateStr: string; // 生年月日（表示用）
  birthDate: string; // 生年月日
  secretQuestionList; // 秘密の質問リスト
  secretQuestionNo: string; // 選択した秘密の質問番号
  secretQuestion: string; // 選択した秘密の質問
  secretQuestionAnswer: string; // 秘密の質問の答え
  uuId; // uuId
  sessionId; // sessionId
  loading: boolean = false; // ローディング状態
  isSubmitFlg: boolean = false; // 登録ボタン押下FLG

  constructor(
    private auth: UserAuthService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    this.companyCode = userSession['companyCode']; // 企業コード
    this.employeeNumber = userSession['employeeNumber']; // 従業員番号
    if (userParameters['employee']){
      this.gender = userParameters['employee']['gender'];// 性別
      this.birthDateStr = this.auth.stringToDate(userParameters['employee']['birthDate']); // 生年月日（表示用）
      this.birthDate = userParameters['employee']['birthDate']; // 生年月日
      this.postCode = userParameters['employee']['address']['postCode']; // 郵便番号
    } else {
      this.gender = userParameters['gender'];// 性別
      this.birthDateStr = this.auth.stringToDate(userParameters['birthDate']); // 生年月日（表示用）
      this.birthDate = userParameters['birthDate']; // 生年月日
      this.postCode = userParameters['postCode']; // 郵便番号
    }
    this.secretQuestionList = userParameters['secretQuestionList']; // 秘密の質問リスト
    if (userParameters['secretQuestionNo']){
      this.secretQuestionNo = userParameters['secretQuestionNo'];
      this.secretQuestionAnswer = userParameters['secretQuestionAnswer'];
    } else {
      this.secretQuestionNo = this.secretQuestionList[0].secretQuestionNo; // 選択した秘密の質問番号
    }
    this.uuId = userSession['uuId'];
    this.sessionId = userSession['sessionId'];
    // 郵便番号編集
    // this.postCode = this.auth.toPostFmt(this.postCode);
    this.registerInputForm = this.fb.group({
      'companyCode': [userSession['companyCode']], // 企業コード
      'employeeNumber': [userSession['employeeNumber']], // 従業員番号
      'lastNameInKanji': [userParameters['employee'] ? userParameters['employee']['name']['lastNameInKanji'] : userParameters['lastNameInKanji'],  [Validators.required, Validators.pattern('^[\u3000-\u301C\u3041-\u3093\u309B-\u309E\u30A1-\u30F6\u30FB-\u30FD\u4E00-\u9FA0\uFF01-\uFF5D]+$')]], // 姓
      'firstNameInKanji': [userParameters['employee'] ? userParameters['employee']['name']['firstNameInKanji'] : userParameters['firstNameInKanji'],  [Validators.required, Validators.pattern('^[\u3000-\u301C\u3041-\u3093\u309B-\u309E\u30A1-\u30F6\u30FB-\u30FD\u4E00-\u9FA0\uFF01-\uFF5D]+$')]], // 名
      'lastNameFurigana': [userParameters['employee'] ? userParameters['employee']['name']['lastNameFurigana'] : userParameters['lastNameFurigana'], [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]], // セイ
      'firstNameFurigana': [userParameters['employee'] ? userParameters['employee']['name']['firstNameFurigana'] : userParameters['firstNameFurigana'], [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]], // メイ
      'birthDate': [this.birthDateStr], // 生年月日（表示用）
      'postCode': [userParameters['employee'] ? userParameters['employee']['address']['postCode'] : userParameters['postCode'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]], // 郵便番号 
      'address': [userParameters['employee'] ? userParameters['employee']['address']['address'] : userParameters['address'], Validators.required], // 住所
      'mobileNumber': [userParameters['employee'] ? '' : userParameters['mobileNumber'], [Validators.pattern('^[0-9]+$'), Validators.minLength(11)]], // 携帯電話番号
      'telphoneNumber': [userParameters['employee'] ? '' : userParameters['telphoneNumber'], [Validators.pattern('^[0-9]+$'), Validators.minLength(10)]], // 固定電話番号
      'mailAddress1': [userParameters['employee'] ? '' : userParameters['mailAddress1'], [Validators.required, Validators.email]], // メールアドレス1
      'mailAddress1Confirm': [userParameters['employee'] ? '' : userParameters['mailAddress1'], [Validators.required, Validators.email]], // メールアドレス1（再入力）
      'mailAddress2': [userParameters['employee'] ? '' : userParameters['mailAddress2'], [Validators.email]], // メールアドレス2
      'mailAddress2Confirm': [userParameters['employee'] ? '' : userParameters['mailAddress2'], [Validators.email]], // メールアドレス2（再入力）
      'secretQuestionAnswer': [userParameters['employee'] ? '' : userParameters['secretQuestionAnswer'], Validators.required], // [Validators.required, Validators.minLength(2)]
      'password': [userParameters['employee'] ? '' : userParameters['password'], [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]],
      'passwordConfirm': [userParameters['employee'] ? '' : userParameters['password'], [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]]
    });
  }

  // 登録するボタンを押す
  onRegister() {
    this.isSubmitFlg = true; // 登録ボタン押下
    const apiUrl = environment.userUrl.signupServiceApiUrl;
    // 携帯電話番号、固定電話番号がいずれか入力チェック
    this.hasTelNumberNullValidator();
    // 携帯電話番号チェック
    this.isMobileNumberValidator();
    // 固定電話番号チェック
    this.isTelNumberValidator();
    // メールアドレス１の一致チェック
    this.mailAddress1EqualValidator();
    // メールアドレス２の一致チェック
    this.mailAddress2EqualValidator()
    // メールアドレス１、２の一致チェック
    this.mailAddress12EqualValidator();
    // パスワードの一致チェック
    this.passwordEqualValidator();
    for(let question of this.secretQuestionList){
      if(this.secretQuestionNo === question.secretQuestionNo){
        this.secretQuestion = question.secretQuestion;
      }
    }
    if (this.registerInputForm.valid){
      this.loading = true;
      // header
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // body
      const body = JSON.stringify({
        commonHeader:{
          pageId: 'SPU0050',
          eventId: 'user_signup_confirm',
          uuId: this.uuId,
          appVersion: null,
          sessionId: this.sessionId
        },
        parameters:{
          companyCode: this.companyCode, 
          employeeNumber: this.employeeNumber, 
          lastNameInKanji: this.registerInputForm.get('lastNameInKanji').value, 
          firstNameInKanji: this.registerInputForm.get('firstNameInKanji').value, 
          lastNameFurigana: this.registerInputForm.get('lastNameFurigana').value, 
          firstNameFurigana: this.registerInputForm.get('firstNameFurigana').value, 
          gender: this.gender, 
          birthDate: this.birthDate,
          postCode: this.registerInputForm.get('postCode').value, 
          address: this.registerInputForm.get('address').value, 
          mobileNumber: this.registerInputForm.get('mobileNumber').value, 
          telphoneNumber: this.registerInputForm.get('telphoneNumber').value, 
          mailAddress1: this.registerInputForm.get('mailAddress1').value, 
          mailAddress2: this.registerInputForm.get('mailAddress2').value, 
          password: this.registerInputForm.get('password').value, 
          secretQuestionNo: this.secretQuestionNo, 
          secretQuestion: this.secretQuestion, 
          secretQuestionAnswer: this.registerInputForm.get('secretQuestionAnswer').value
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}user_signup_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // commonHeader
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              this.loading = false;
              this.router.navigate(['/userRegisterInputConfirm']); // 個人情報登録確認へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  };

  // キャンセルボタンを押す
  onClickCancel(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }

  errorStateMacher(){
    return new UserMyErrorStateMatcher();
  }

  // // 郵便番号編集を行うFunction(XXX-XXXX)
  // toPostFmt(){
  //   this.postCode = this.auth.toPostFmt(this.registerInputForm.get('postCode').value);
  // }

  // // 郵便番号編集を解除するFunction
  // offPostFmt(){
  //   this.postCode = this.auth.offPostFmt(this.registerInputForm.get('postCode').value);
  // }

  // 姓のエラーメッセージ
  getLastNameInKanjiErrorMessage(): any {
    return this.registerInputForm.get('lastNameInKanji').hasError('required') ? '姓を入力してください' :
           this.registerInputForm.get('lastNameInKanji').hasError('pattern') ? '姓を全角文字で入力してください' :
           '';
  };

  // 名のエラーメッセージ
  getFirstNameInKanjiErrorMessage(): any {
    return this.registerInputForm.get('firstNameInKanji').hasError('required') ? '名を入力してください' :
           this.registerInputForm.get('firstNameInKanji').hasError('pattern') ? '名を全角文字で入力してください' :
           '';
  };

  // 姓（カナ）のエラーメッセージ
  getLastNameFuriganaErrorMessage(): any {
    return this.registerInputForm.get('lastNameFurigana').hasError('required') ? '姓（カナ）を入力してください' :
           this.registerInputForm.get('lastNameFurigana').hasError('pattern') ? '姓（カナ）を全角カナで入力してください' :
           '';
  };

  // 名（カナ）のエラーメッセージ
  getFirstNameFuriganaErrorMessage(): any {
    return this.registerInputForm.get('firstNameFurigana').hasError('required') ? '名（カナ）を入力してください' :
           this.registerInputForm.get('firstNameFurigana').hasError('pattern') ? '名（カナ）を全角カナで入力してください' :
           '';
  };

  // 郵便番号のエラーメッセージ
  getPostCodeErrorMessage(): any {
    return this.registerInputForm.get('postCode').hasError('required') ? '郵便番号を入力してください' :
           this.registerInputForm.get('postCode').hasError('pattern') ? '郵便番号を半角数字で入力してください' :
           this.registerInputForm.get('postCode').hasError('minlength') ? '正しい郵便番号を入力してください' :
           '';
  };

  // 住所のエラーメッセージ
  getAddressErrorMessage(): any {
    return this.registerInputForm.get('address').hasError('required') ? '住所を入力してください' :
           '';
  };

  // 携帯電話番号のエラーメッセージ
  getMobileNumberErrorMessage(): any {
    return this.registerInputForm.get('mobileNumber').hasError('pattern') ? '携帯電話番号を半角数字で入力してください' :
           this.registerInputForm.get('mobileNumber').hasError('minlength') ? '正しい携帯電話番号を入力してください' :
           this.registerInputForm.hasError('hasTelNumberNull') ? '携帯電話番号、固定電話番号はいずれか入力してください。' :
           this.registerInputForm.get('mobileNumber').hasError('isNotMobileNumber') ? '携帯電話番号ではありません、お確かめください。' :
           '';
  };

  // 固定電話番号のエラーメッセージ
  getTelphoneNumberErrorMessage(): any {
    return this.registerInputForm.get('telphoneNumber').hasError('pattern') ? '固定電話番号を半角数字で入力してください' :
           this.registerInputForm.get('telphoneNumber').hasError('minlength') ? '正しい固定電話番号を入力してください' : 
           this.registerInputForm.hasError('hasTelNumberNull') ? '携帯電話番号、固定電話番号はいずれか入力してください。' :
           this.registerInputForm.get('telphoneNumber').hasError('isNotTelNumber') ? '固定電話番号ではありません、お確かめください。' :
           '';
  };

  // 携帯電話番号、固定電話番号がいずれか入力チェック
  hasTelNumberNullValidator() {
    const mobileNumber = this.registerInputForm.get('mobileNumber');
    const telphoneNumber = this.registerInputForm.get('telphoneNumber');
    if(mobileNumber.value === '' && telphoneNumber.value === ''){
      this.registerInputForm.setErrors({ hasTelNumberNull: true});
    }
  };

  // 携帯電話番号チェック
  isMobileNumberValidator() {
    const mobileNumber = this.registerInputForm.get('mobileNumber').value;
    if(mobileNumber && !this.auth.isMobileNumber(mobileNumber)) {
      this.registerInputForm.get('mobileNumber').setErrors({ isNotMobileNumber: true});
    }
  };

  // 固定電話番号チェック
  isTelNumberValidator() {
    const telphoneNumber = this.registerInputForm.get('telphoneNumber').value;
    if(telphoneNumber && this.auth.isMobileNumber(telphoneNumber)) {
      this.registerInputForm.get('telphoneNumber').setErrors({ isNotTelNumber: true});
    }
  };

  // メールアドレス１のエラーメッセージ
  getMailAddress1ErrorMessage(): any {
    return this.registerInputForm.get('mailAddress1').hasError('required') ? 'メールアドレスを入力してください' :
           this.registerInputForm.get('mailAddress1').hasError('email') ? '有効なメールではありません' :
           this.registerInputForm.hasError('mailAddress1Equal') ? 'メールアドレス１が不一致です。' :
           this.registerInputForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス１（再入力）のエラーメッセージ
  getMailAddress1ConfirmErrorMessage() {
    return this.registerInputForm.get('mailAddress1Confirm').hasError('required') ? 'メールアドレスを入力してください' :
           this.registerInputForm.get('mailAddress1Confirm').hasError('email') ? '有効なメールではありません' :
           this.registerInputForm.hasError('mailAddress1Equal') ? 'メールアドレス１が不一致です。' :
           this.registerInputForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス１の一致チェック
  mailAddress1EqualValidator() {
    const mailAddress1 = this.registerInputForm.get('mailAddress1');
    const mailAddress1Confirm = this.registerInputForm.get('mailAddress1Confirm');
    if(mailAddress1 != null && mailAddress1Confirm != null
      && mailAddress1.valid && mailAddress1Confirm.valid
      && mailAddress1.value !== mailAddress1Confirm.value){
      this.registerInputForm.setErrors({ mailAddress1Equal: true});
    }
  };

  // メールアドレス２のエラーメッセージ
  getMailAddress2ErrorMessage() {
    return this.registerInputForm.get('mailAddress2').hasError('email') ? '有効なメールではありません' :
           this.registerInputForm.hasError('mailAddress2Equal') ? 'メールアドレス２が不一致です。' :
           this.registerInputForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス２（再入力）のエラーメッセージ
  getMailAddress2ConfirmErrorMessage() {
    return this.registerInputForm.get('mailAddress2Confirm').hasError('email') ? '有効なメールではありません' :
           this.registerInputForm.hasError('mailAddress2Equal') ? 'メールアドレス２が不一致です。' :
           this.registerInputForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス２の一致チェック
  mailAddress2EqualValidator(): any {
    const mailAddress2 = this.registerInputForm.get('mailAddress2') as FormControl;
    const mailAddress2Confirm = this.registerInputForm.get('mailAddress2Confirm') as FormControl;
    if( mailAddress2 != null && mailAddress2Confirm != null
      && mailAddress2.valid && mailAddress2Confirm.valid
      && mailAddress2.value !== mailAddress2Confirm.value ) {
      this.registerInputForm.setErrors({ mailAddress2Equal: true});
    }
  };

  // メールアドレス１、２の一致チェック
  mailAddress12EqualValidator(): any {
    const mailAddress1 = this.registerInputForm.get('mailAddress1') as FormControl;
    const mailAddress1Confirm = this.registerInputForm.get('mailAddress1Confirm') as FormControl;
    const mailAddress2 = this.registerInputForm.get('mailAddress2') as FormControl;
    const mailAddress2Confirm = this.registerInputForm.get('mailAddress2Confirm') as FormControl;
    if( mailAddress1 != null && mailAddress1Confirm != null && mailAddress2 != null && mailAddress2Confirm != null
      && mailAddress1.valid && mailAddress1Confirm.valid && mailAddress2.valid && mailAddress2Confirm.valid
      && mailAddress1.value === mailAddress1Confirm.value === mailAddress2.value === mailAddress2Confirm.value ) {
      this.registerInputForm.setErrors({ mailAddress12Equal: true});
    }
  };

  // 秘密の質問の答えのエラーメッセージ
  getSecretQuestionAnswerErrorMessage(): any {
    return this.registerInputForm.get('secretQuestionAnswer').hasError('required') ? '秘密の質問の答えを入力してください' :
          //  this.registerInputForm.get('secretQuestionAnswer').hasError('minlength') ? '秘密の質問の答えを２桁以上でご入力してください' :
           '';
  };

  // パスワードのエラーメッセージ
  getPasswordErrorMessage(): any {
    return this.registerInputForm.get('password').hasError('required') ? 'パスワードを入力してください' :
           this.registerInputForm.get('password').hasError('pattern') ? 'パスワードを半角英数字で入力してください' :
           this.registerInputForm.get('password').hasError('minlength') ? 'パスワードを８桁以上でご入力してください' :
           this.registerInputForm.hasError('passwordEqual') ? 'パスワードが不一致です。' :
           '';
  };

  // パスワード（再入力）のエラーメッセージ
  getPasswordConfirmErrorMessage(): any {
    return this.registerInputForm.get('passwordConfirm').hasError('required') ? 'パスワード（再入力）を入力してください' :
           this.registerInputForm.get('passwordConfirm').hasError('pattern') ? 'パスワード（再入力）を半角英数字で入力してください' :
           this.registerInputForm.get('passwordConfirm').hasError('minlength') ? 'パスワード（再入力）を８桁以上でご入力してください' :
           this.registerInputForm.hasError('passwordEqual') ? 'パスワードが不一致です。' :
           '';
  };

  // パスワードの一致チェック
  passwordEqualValidator() {
    const password = this.registerInputForm.get('password') as FormControl;
    const passwordConfirm = this.registerInputForm.get('passwordConfirm') as FormControl;
    if( password != null && passwordConfirm != null
      && password.valid && passwordConfirm.valid
      && password.value !== passwordConfirm.value ) {
      this.registerInputForm.setErrors({ passwordEqual: true});
    }
  };
}