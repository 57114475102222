import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataModifyConfirm',
  templateUrl: './bankDataModifyConfirm.component.html',
  styleUrls: ['./bankDataModifyConfirm.component.css']
})

export class AdminBankDataModifyConfirmComponent implements OnInit {
  bankCode: string; // 金融機関コード
  bankName: string; // 金融機関名
  transStartTime: string; // 営業日取引時間帯FROM
  transEndTime: string; // 営業日取引時間帯TO
  onlineTransStartTime: string; // オンライン取引時間帯FROM
  onlineTransEndTime: string; // オンライン取引時間帯TO
  loading: boolean; // ローディング状態
  updateAddFlag: number; // 更新追加FLG

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    this.updateAddFlag = adminParameters['updateAddFlag']; // 更新追加FLG
    if (this.updateAddFlag === 1) {
      sessionStorage.setItem('title', '銀行マスタ変更確認'); // タイトル
    } else {
      sessionStorage.setItem('title', '銀行マスタ追加確認'); // タイトル
    }
    this.bankCode = adminParameters['bankCode']; // 金融機関コード
    this.bankName = adminParameters['bankName'];　// 金融機関名
    this.transStartTime = adminParameters['transStartTime']; // 営業日取引時間帯FROM
    this.transEndTime = adminParameters['transEndTime']; // 営業日取引時間帯TO
    this.onlineTransStartTime = adminParameters['onlineTransStartTime']; // オンライン取引時間帯FROM
    this.onlineTransEndTime = adminParameters['onlineTransEndTime']; // オンライン取引時間帯TO
    // 認証トークン取得
    this.auth.getToken('SPM0130', adminSession['uuId'], adminSession['sessionId'], 'bank_data_modify_commit');
    this.loading = false;
  }

  // 確定ボタンを押す
  onCommit() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession 
    // APIURL
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    this.loading = true;
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
    const authToken = tokenParameters['authToken']['access_token'];
    // 送信前 ヘッダー部設定
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0130',  // 画面ID
        eventId: 'bank_data_modify_commit', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: this.bankCode, // 金融機関コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}bank_data_modify_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.loading = false;
            sessionStorage.removeItem('bankDataSearchSession');
            // 画面遷移
            if (this.updateAddFlag === 1) {
              this.router.navigate(['/adminBankDataModifyInfo']); // 銀行情報変更完了画面
            } else {
              this.router.navigate(['/adminTransferFeePatternAdd']); // 振込手数料マスタ追加画面
              sessionStorage.setItem('bankDataAddFlg', '1'); // 振込手数料マスタ追加画面FLG
            }
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 戻るボタンを押す
  onBackClick() {
    if (this.updateAddFlag === 1) {
      this.router.navigate(['/adminBankDataModify']); // 銀行マスタ変更画面
    } else {
      this.router.navigate(['/adminBankDataAdd']); // 銀行マスタ追加画面
    }
  }
}