import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-transferErrorSet',
  templateUrl: './transferErrorSet.component.html',
  styleUrls: ['./transferErrorSet.component.css']
})

export class AdminTransferErrorSetComponent implements OnInit {

  loading: boolean; // ローディング状態
  transferErrorSetForm: FormGroup; // 検索条件フォーム
  bankCode: string; // 金融機関コード
  branchNumber: string; // 支店番号
  accountType: string; // 口座種別
  accountNumber: string; // 口座番号
  accountTypeChecked: string; // 口座種別
  applicationTimeJST: string; // 申請日時
  branch: string; // 支店
  status: string; // ステータス
  dataSource = []; // 従業員テーブル
  displayedColumns: string[] = [
    'applicationTimeJST', // 申請日時
    'bankName', // 金融機関名
    'branch', // 支店
    'accountNumber', // 口座番号
    'applicationAmount', // 申請金額
    'status', // ステータス
    'onButton'
  ];

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '振込エラー設定'); // タイトル
    this.loading = true;
    this.onInit(); // API接続
    this.accountTypeChecked = '1'; // 口座種別 
    // フォームチェンジ
    this.formCheck();
    // 画面項目変更時に画面項目保存セッション
    const transferErrorSetSession = JSON.parse(sessionStorage.getItem('transferErrorSetSession'));
    // 画面項目保存セッション削除
    sessionStorage.removeItem('transferErrorSetSession');
    // 戻るボタン押下時画面再表示処理
    if (transferErrorSetSession) {
      let bankCode = transferErrorSetSession['bankCode']; // 金融機関コード
      let branchNumber = transferErrorSetSession['branchNumber']; // 支店番号      
      let accountType = transferErrorSetSession['accountType']; // 口座種別
      let accountNumber = transferErrorSetSession['accountNumber']; // 口座番号
      this.transferErrorSetForm = this.fb.group({ // 画面初期設定
        'bankCode': [bankCode, [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(4)]], // 金融機関コード
        'branchNumber': [branchNumber, [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(3)]], // 支店番号
        'accountNumber': [accountNumber, [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(7)]], // 口座番号
      });
      this.accountTypeChecked = accountType;
      this.loading = false;
      // 検索処理
      this.onSearchClick();
    }
    this.loading = false;
  }

  // フォームチェンジ
  formCheck() {
    this.transferErrorSetForm = this.fb.group({ // 画面初期設定
      'bankCode': ['', [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(4)]], // 金融機関コード
      'branchNumber': ['', [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(3)]], // 支店番号
      'accountNumber': ['', [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(7)]], // 口座番号
    });
  }

  // 初期表示
  onInit() {
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // APIURL
    const apiUrl = environment.adminUrl.transferErrorServiceApiUrl;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0260',  // 画面ID
        eventId: 'transfer_error_set_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transfer_error_set_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合     
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 検索ボタンを押す
  onSearchClick() {
    if (this.loading === true) {
      return;
    }
    if(this.transferErrorSetForm.invalid){
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // APIURL
    const apiUrl = environment.adminUrl.transferErrorServiceApiUrl;
    this.dataSource = [];
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0260',  // 画面ID
        eventId: 'transfer_error_set_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        bankCode: this.transferErrorSetForm.get('bankCode').value,  // 金融機関コード
        branchNumber: this.transferErrorSetForm.get('branchNumber').value, // 支店番号
        accountType: this.accountTypeChecked, // 口座種別
        accountNumber: this.transferErrorSetForm.get('accountNumber').value, // 口座番号
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transfer_error_set_search`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            // 画面項目変更時に画面項目保存セッションを設定する
            sessionStorage.setItem('transferErrorSetSession', JSON.stringify({
              bankCode: this.transferErrorSetForm.get('bankCode').value, // 金融機関コード
              branchNumber: this.transferErrorSetForm.get('branchNumber').value, // 支店番号
              accountType: this.accountTypeChecked, // 口座種別
              accountNumber: this.transferErrorSetForm.get('accountNumber').value, // 口座番号
            }));
            // 前払い利用明細リスト
            let prepayDetailList = parametersData['prepayDetailList'];
            for (let i of prepayDetailList) {
              i.applicationTimeJST = moment(i['applicationTime']).format('YYYY年MM月DD日'); // 申請日時YYYY年MM月DD日
              i.applicationTimeJSTHm = moment(i['applicationTime']).format('HH時mm分'); // 申請日時HH時mm分
              i.branch = i.branchNumber + ' ' + i.branchName; // 支店
              i.bank = i.bankCode + ' ' + i.bankName; // 金融機関コード
              i.statusCode = i.status; // ステータス
              i.status = this.auth.statusStr(i['status']); // ステータス
            }
            // 検索結果一覧のソート順が申請日時の「年月日時分」の降順になること
            let prepayDetailList_array = Array.from({ length: Object.keys(prepayDetailList).length }, (_, k) => prepayDetailList[k]);
            prepayDetailList_array.sort((a, b) => {
              return b.applicationTime - a.applicationTime;
            });
            this.dataSource = prepayDetailList_array;
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // エラーボタン/エラー解除ボタンを押下
  onChangeError(uuId: string, receiptNumber: string, status: string) {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // APIURL
    const apiUrl = environment.adminUrl.transferErrorServiceApiUrl;
    // 送信前 ヘッダー部設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0260',  // 画面ID
        eventId: 'transfer_error_set_confirm', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        uuId: uuId,  // イベントID
        receiptNumber: receiptNumber // 受け入れ
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}transfer_error_set_confirm`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.router.navigate(['/adminTransferErrorSetConfirm']); // エラー設定確認画面へ遷移
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 金融機関コードのエラーメッセージ
  getBankCodeErrorMessage() {
    return this.transferErrorSetForm.get('bankCode').hasError('required') ? '金融機関コードを入力してください' :
      this.transferErrorSetForm.get('bankCode').hasError('pattern') ? '金融機関コードを半角数字で入力してください' :
      this.transferErrorSetForm.get('bankCode').hasError('minlength') ? '金融機関コード4桁でご入力してください' :
      '';
  }
  // 支店番号のエラーメッセージ  
  getBranchNumberErrorMessage() {
    return this.transferErrorSetForm.get('branchNumber').hasError('required') ? '支店番号を入力してください' :
    this.transferErrorSetForm.get('branchNumber').hasError('pattern') ? '支店番号を半角数字で入力してください' :
    this.transferErrorSetForm.get('branchNumber').hasError('minlength') ? '支店番号3桁でご入力してください' :
      '';
  }
  // 口座番号のエラーメッセージ  
  getAccountNumberErrorMessage() {
    return this.transferErrorSetForm.get('accountNumber').hasError('required') ? '口座番号を入力してください' :
    this.transferErrorSetForm.get('accountNumber').hasError('pattern') ? '口座番号を半角数字で入力してください' :
    this.transferErrorSetForm.get('accountNumber').hasError('minlength') ? '口座番号7桁でご入力してください' :
      '';
  }
}
