import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css']
})

export class UserForgotPasswordComponent implements OnInit {

  title: string = 'パスワード初期化';
  titleSub: string = 'パスワード初期化のための仮パスワードを発行します。';
  titleSub1: string = '登録済みのユーザーID（メールアドレス）をご入力ください。';
  forgotPasswordForm: FormGroup; // フォーム
  secretQuestionList; // 秘密の質問のリスト
  secretQuestionNo; // 秘密の質問を選択
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient
  ) { }

  // 画面初期化
  ngOnInit() {
    this.loading = true;
    this.initForm();
    this.getPasswordInitializeInit();
  }

  // パスワード初期化画面の初期表示
  getPasswordInitializeInit() {
    const apiUrl = environment.userUrl.userSecretUrl;
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0080',                 // 画面ID
        eventId: 'password_initialize_init', // イベントID
        uuId: null,     // 共通ID
        appVersion: null,                // アプリバージョン
        sessionId: null  // セッションID 
      }
    });
    // 送信（パスワード初期化情報取得APIを呼出す)
    this.http.post(
      `${apiUrl}password_initialize_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
            // 認証トークン取得
            this.auth.getToken('SPU0080', commonHeaderData['uuId'], commonHeaderData['sessionId'], 'password_initialize_commit');
            // 秘密の質問のリスト取得
            this.secretQuestionList = parametersData['secretQuestionList'];
            // 秘密の質問のデフォルト選択
            this.secretQuestionNo = this.secretQuestionList[0]['secretQuestionNo'];
          } else {
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => { // 異常処理
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // フォーム初期化
  initForm() {
    this.forgotPasswordForm = this.fb.group({
      'secretQuestionAnswer': ['', Validators.required], // 秘密のアンサー
      'userId': ['', [Validators.required, Validators.email]], // ユーザーID
    });
  }

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }

  // 「再発行」ボタン押下時処理
  onSubmit() {
    const apiUrl = environment.userUrl.userSecretUrl;
    if (this.forgotPasswordForm.valid) {
      this.loading = true;
      // ヘッダ設定
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'],
      });
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPU0080', // 画面ID
          eventId: 'password_initialize_commit', // イベントID
          uuId: null, // 共通ID
          appVersion: null, // アプリバージョン
          sessionId: null // セッションID 
        },
        parameters:{
          userId: this.forgotPasswordForm.get('userId').value,
          secretQuestionNo: this.secretQuestionNo, // 秘密の質問（コード）
          secretQuestionAnswer: this.forgotPasswordForm.get('secretQuestionAnswer').value // 秘密の質問の答え
        }
      });
      // 送信（パスワード初期化APIを呼出す)
      this.http.post(
        `${apiUrl}password_initialize_commit`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
              sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
              this.loading = false;
              this.router.navigate(['/userForgotPasswordInfo']); // パスワード初期化のメール送信完了画面へ
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // 異常処理
            this.auth.errDialog(error);
            this.loading = false;
          }
        );
    }
  };

  // ユーザーIDのエラーメッセージ
  getUserIdErrorMessage() {
    return this.forgotPasswordForm.get('userId').hasError('required') ? 'ユーザーIDを入力してください' :
      this.forgotPasswordForm.get('userId').hasError('email') ? '有効なメールではありません' :
        '';
  };

  // 秘密の質問の答えのエラーメッセージ
  getSecretQuestionAnswerErrorMessage() {
    return this.forgotPasswordForm.get('secretQuestionAnswer').hasError('required') ? '秘密の質問の答えを入力してください' :
      '';
  };
}