import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/auth.service';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment.dev';

// 日付型フォーマット
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
  },
  display: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-userCompanyAdd',
  templateUrl: './userCompanyAdd.component.html',
  styleUrls: ['./userCompanyAdd.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class UserCompanyAddComponent implements OnInit {

  title: string = '給与前払いサービス－所属企業追加';
  userCompanyForm: FormGroup;
  loading: boolean = false; // ローディング状態
  userNameKanji: string; // 氏名

  // コンストラクタ
  constructor(
    dateAdapter: DateAdapter<NativeDateAdapter>,
    private auth: UserAuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    dateAdapter.setLocale('ja');
  }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', this.title); // タイトル
    this.getAffiliationCompanyInit();
    if(sessionStorage.getItem('userCompanyAddBackFLG')){
      const userCompanyParameters = JSON.parse(sessionStorage.getItem('userCompanyParameters'));
      this.userCompanyForm = this.fb.group({
        'companyCode': [userCompanyParameters['companyCode'], [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'employeeNumber': [userCompanyParameters['employeeCode'], [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'birthDate': [new Date(userCompanyParameters['birthDate']), [Validators.required, Validators.minLength(8)]],
        'accountNumber': [userCompanyParameters['userAccount'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]]
      });
      sessionStorage.removeItem('userCompanyParameters');
      sessionStorage.removeItem('userCompanyAddBackFLG');
    } else {
      // 画面入力項目初期化
      this.userCompanyForm = this.fb.group({
        'companyCode': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'employeeNumber': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        'birthDate': ['', [Validators.required, Validators.minLength(8)]],
        'accountNumber': ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]]
      });
    }
  }
  // 所属企業追加画面の初期表示
  getAffiliationCompanyInit() {
    const apiUrl = environment.userUrl.userCompany2Url;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0170', // 画面ID
        eventId: 'affiliation_company_init', // イベントID
        uuId: userSession['uuId'], // 共通ID
        appVersion: null, // アプリバージョン
        sessionId: userSession['sessionId'] // セッションID 
      }
    });
    // 送信（パスワード初期化情報取得APIを呼出す)
    this.http.post(
      `${apiUrl}affiliation_company_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            sessionStorage.setItem("userSession", JSON.stringify(commonHeaderData));
            // セッション取得
            const userSession = JSON.parse(sessionStorage.getItem('userSession')); 
            // 認証トークン取得
            this.auth.getToken('SPU0170', userSession['uuId'], userSession['sessionId'], 'affiliation_company_commit'); // トーケンの取得
            // 氏名初期値設定
            this.userNameKanji = userSession['userName']; // 氏名 
          } else {
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => { // 異常処理
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 追加ボタンを押す
  onSubmitAdd(value: any) {
    const apiUrl = environment.userUrl.userCompanyUrl;
    if(this.userCompanyForm.valid){
      this.loading = true;
      // 画面項目値を取得する
      const companyCode = value.companyCode, 
            employeeNumber = value.employeeNumber, 
            birthDate = new Date(value.birthDate), 
            accountNumber = value.accountNumber;
      // セッションを取得する
      const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
      // ヘッダ設定
      const headers = new HttpHeaders({
        'Authorization':'Bearer ' + JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'],
        'Content-Type': 'application/json'
      });
      // ボディー作成
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPU0170', // 画面ID
          eventId: 'affiliation_company_commit', // イベントID
          uuId: userSession['uuId'],               // 共通ID
          appVersion: null,                     // アプリバージョン
          sessionId: userSession['sessionId']      // セッションID 
        },
        parameters: {
          companyCode: companyCode, // 企業コード
          employeeNumber: employeeNumber,// 従業員番号
          birthDate: moment(birthDate).format('YYYYMMDD'),// 生年月日(YYYYMMDD)
          accountNumber: accountNumber // 口座番号
        }
      });
      // 所属企業追加APIを呼び出す
      this.http.post(
        `${apiUrl}affiliation_company_commit`, // localhost
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
            // レストラン情報：レストランメッセージ
            if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
              // 共通ヘッダ
              sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
              // 当前画面でパラメータセッション設定
              sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
              const userCompanyParameters = {
                companyCode: companyCode,
                employeeCode: employeeNumber,
                birthDate: value.birthDate,
                userAccount: accountNumber
              };
              sessionStorage.setItem('userCompanyParameters',JSON.stringify(userCompanyParameters));
              // 画面遷移
              // this.router.navigate(['/userRegisterUserAgreementAfterLogin']); // 会員規約画面へ遷移
              this.router.navigate(['/userCompanyAddInfo']); // 所属企業追加完了画面へ
            } else {
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {// 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }
  
  // 所属企業コードのエラーメッセージ
  getCompanyCodeErrorMessage() {
    return this.userCompanyForm.get('companyCode').hasError('required') ? '所属企業コードを入力してください' :
           this.userCompanyForm.get('companyCode').hasError('pattern') ? '所属企業コードを半角英数字で入力してください' :
           '';
  };

  // 従業員番号のエラーメッセージ
  getEmployeeNumberErrorMessage() {
    return this.userCompanyForm.get('employeeNumber').hasError('required') ? '従業員番号を入力してください' :
           this.userCompanyForm.get('employeeNumber').hasError('pattern') ? '従業員番号を半角英数字で入力してください' :
           '';
  };

  // 生年月日のエラーメッセージ
  getBirthDateErrorMessage() {
    return this.userCompanyForm.get('birthDate').hasError('required') ? '生年月日を選択してください' :
           this.userCompanyForm.get('birthDate').hasError('minlength') ? '正しい生年月日を入力してください' :
           '';
  };

  // 口座番号のエラーメッセージ
  getAccountNumberErrorMessage() {
    return this.userCompanyForm.get('accountNumber').hasError('required') ? '口座番号を入力してください' :
          this.userCompanyForm.get('accountNumber').hasError('pattern') ? '口座番号を半角数字で入力してください' :
           this.userCompanyForm.get('accountNumber').hasError('minlength') ? '口座番号を７桁で入力してください' :
           '';
  };
}