import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-adminMain',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class AdminMainComponent implements OnInit {

  title: string = '前払いサービスシステム管理へようこそ ';

  ngOnInit() {
    sessionStorage.setItem('title', '前払いサービスシステム管理');
  }

}
