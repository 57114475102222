import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyAuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import { CompanyMyErrorStateMatcher } from '../common/common.component';

@Component({
  selector: 'app-passwordUpdate',
  templateUrl: './passwordUpdate.component.html',
  styleUrls: ['./passwordUpdate.component.css']
})
export class CompanyPasswordUpdateComponent implements OnInit {

  passwordOldHide: boolean = true;
  passwordNewHide: boolean = true;
  passwordNewConfirmHide: boolean = true;
  passwordUpdateForm:  FormGroup; // 画面フォーム
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: CompanyAuthService,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    // 画面初期設定
    this.passwordUpdateForm = this.fb.group({
      'passwordOld': ['', Validators.required],
      'passwordNew': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]],
      'passwordNewConfirm': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(8)]]
    });
    sessionStorage.setItem('title', 'パスワード変更'); // タイトル
    this.onInit(); // API接続
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // 認証トークン取得
    this.auth.getToken('SPC0150', compSession['managerId'], compSession['companyCode'], compSession['sessionId'], 'password_change');
  }

  // API接続
  onInit() {
    const apiUrl = environment.companyUrl.companyAuthUrl;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader:{
        pageId: 'SPC0150', // 画面ID
        eventId: 'password_change_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信(デフォルト企業取得取得API)
    this.http.post(
      `${apiUrl}password_change_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 更新ボタンを押す
  onUpdate() {
    if (this.loading === true) {
      return;
    }
    if (this.passwordUpdateForm.valid) {
      this.loading = true;
      // 共通ヘッダcommonHeader
      const compSession = JSON.parse(sessionStorage.getItem('compSession'));
      const apiUrl = environment.companyUrl.companyAuthUrl;
      // 認証トークン
      const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
      // ヘッダ設定
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      });
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPC0150', // 画面ID
          eventId: 'password_change', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // セッションID 
        },
        // パラメータ
        parameters: {
          password: this.passwordUpdateForm.get('passwordOld').value, // 旧パスワード
          passwordNew: this.passwordUpdateForm.get('passwordNew').value, // 新パスワード
        }
      });
      // 送信（前払申請受付APIを呼出す）
      this.http.post(
        `${apiUrl}password_change`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
              this.loading = false;
              this.router.navigate(['/companyPasswordUpdateInfo']); // 画面遷移
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  };

  errorStateMacher() {
    return new CompanyMyErrorStateMatcher();
  }

  // 旧パスワードのエラーメッセージ
  getPasswordOldErrorMessage() {
    return this.passwordUpdateForm.get('passwordOld').hasError('required') ? '旧パスワードを入力してください' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
           '';
  }

  // 新パスワードのエラーメッセージ
  getPasswordNewErrorMessage() {
    return this.passwordUpdateForm.get('passwordNew').hasError('required') ? '新パスワードを入力してください' :
           this.passwordUpdateForm.get('passwordNew').hasError('pattern') ? '新パスワードを半角英数字で入力してください' :
           this.passwordUpdateForm.get('passwordNew').hasError('minlength') ? '新パスワードを８桁以上でご入力してください' :
           this.passwordUpdateForm.hasError('newPasswordEqual') ? '新パスワードが不一致です。' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
           '';
  }

  // 新パスワード（確認）のエラーメッセージ
  getPasswordNewConfirmErrorMessage() {
    return this.passwordUpdateForm.get('passwordNewConfirm').hasError('required') ? '新パスワード（確認）を入力してください' :
           this.passwordUpdateForm.get('passwordNewConfirm').hasError('pattern') ? '新パスワード（確認）を半角英数字で入力してください' :
           this.passwordUpdateForm.get('passwordNewConfirm').hasError('minlength') ? '新パスワード（確認）を８桁以上でご入力してください' :
           this.passwordUpdateForm.hasError('newPasswordEqual') ? '新パスワードが不一致です。' :
           this.passwordUpdateForm.hasError('newOldPasswordEqual') ? '新旧パスワードが一致です。' :
          '';
  }

  // 新パスワードの一致チェック
  newPasswordEqualValidator() {
    const passwordNew = this.passwordUpdateForm.get('passwordNew');
    const passwordNewConfirm = this.passwordUpdateForm.get('passwordNewConfirm');
    if (passwordNew != null && passwordNewConfirm != null
      && passwordNew.valid && passwordNewConfirm.valid
      && passwordNew.value !== passwordNewConfirm.value) {
      this.passwordUpdateForm.setErrors({ newPasswordEqual: true });
    }
    return this.passwordUpdateForm.hasError('newPasswordEqual');
  };

  // 新旧パスワードの一致チェック
  newOldPasswordEqualValidator() {
    const passwordOld = this.passwordUpdateForm.get('passwordOld');
    const passwordNew = this.passwordUpdateForm.get('passwordNew');
    const passwordNewConfirm = this.passwordUpdateForm.get('passwordNewConfirm');
    if (passwordOld != null && passwordNew != null && passwordNewConfirm != null
      && passwordOld.valid && passwordNew.valid && passwordNewConfirm.valid
      && passwordOld.value == passwordNew.value && passwordNew.value == passwordNewConfirm.value) {
      this.passwordUpdateForm.setErrors({ newOldPasswordEqual: true });
    }
    return this.passwordUpdateForm.hasError('newOldPasswordEqual');
  };
}
