import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataModifyInfo',
  templateUrl: './bankDataModifyInfo.component.html',
  styleUrls: ['./bankDataModifyInfo.component.css']
})

export class AdminBankDataModifyInfoComponent implements OnInit {
  title: string = '銀行情報変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '銀行情報変更完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick() {
    this.router.navigate(['/adminBankDataSearch']); // 銀行情報照会画面へ
  }
}
