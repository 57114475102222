import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registerAgreementAfterLogin',
  templateUrl: './registerAgreementAfterLogin.component.html',
  styleUrls: ['./registerAgreementAfterLogin.component.css']
})

export class UserRegisterAgreementAfterLoginComponent implements OnInit {
  
  contractUrlAfterLogin;　// 会員規約url
  title: string = '給与前払いサービス－会員規約';
  buttonFlag: boolean = false; // 同意ボタン表示
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    if(userSession['messageCode'] === '00123' || sessionStorage.getItem('pageId') === 'SPU0261') {
      // 認証トークン取得
      this.auth.getToken('SPU0261', userSession['uuId'], userSession['sessionId'], 'user_contract_update');
      this.buttonFlag = true;
      sessionStorage.setItem('pageId', 'SPU0261');
    }
    sessionStorage.setItem('title', this.title); // タイトル
    // 会員規約取得
    this.getcontract() 
  }

  // 会員規約取得
  getcontract(){
    const apiUrl = environment.userUrl.userAccountUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader:{
        pageId: this.buttonFlag ? 'SPU0261' : 'SPU0260',
        eventId: this.buttonFlag ? 'user_contract_reagree' : 'user_contract_display',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}user_contract_display`, // localhost
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          const parametersData = response['parameters'];
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
             const contractUrl = parametersData['contractUrl'];
             if(contractUrl){
               // 会員規約urlを取得
               this.contractUrlAfterLogin = this.domSanitizer.bypassSecurityTrustResourceUrl(contractUrl);
             }
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        if(error['error']['commonHeader']['messageCode'] === '00214'){ // 所属企業利用契約終了の場合
          error['error']['commonHeader']['messageCode'] = '00213'; // 初期選択企業変更へ
        }
        this.auth.errDialog(error);
      }
    );
  }

  // 同意ボタンを押す
  onClickAgree(){
    this.loading = true;
    const apiUrl = environment.userUrl.userAccount2Url;
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // 認証トークン
    const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0261',
        eventId: 'user_contract_update',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_contract_update`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          this.loading = false;
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
            this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // OKボタンを押す
  onOKClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}