import { Component, Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

export interface DialogData {
  errorMsg: string;
  id: string;
  messageCode: string;
}

// エラーのダイアログ
@Component({
  templateUrl: 'errorDialog.html',
  styleUrls: ['errorDialog.css'],
})
export class CompanyErrorDialog {

  errorMsg: string; // エラーメッセージ
  errorId: string; // エラーID
  messageCode: string; // エラーコード

  constructor(
    private dialogRef: MatDialogRef<CompanyErrorDialog>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      this.dialogRef.disableClose = true;
      this.errorMsg = data.errorMsg;
      this.errorId = data.id;
      this.messageCode = data.messageCode;
    }

    closeDialog(): void{
      this.dialogRef.close();
      if(this.errorId === 'sysError'){
        this.router.navigate(['/companyLogin']); // ログイン画面へ
      }
    }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyAuthService  implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var isGitFlg = false;
      if (sessionStorage.getItem('gitParameters')){
        isGitFlg = JSON.parse(sessionStorage.getItem('gitParameters'))['isGitFlg'] === '1';
      }
      // isGitFlg = true;
      if(!isGitFlg) {
        alert('アクセス制限');
        this.router.navigate(['/companyLogin']);
      }
      return isGitFlg;
    }
    
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog
  ) { }

  // GITアクセスIP判断
  isGitClientIP(callback) {
    const apiUrl = environment.companyUrl.companyInfo2Url;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0000',
        eventId: 'get_git_client_ip',
        companyCode: null,
        managerId: null,
        sessionId: null
      }
    });
    this.http.post(
      `${apiUrl}get_git_client_ip`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('gitParameters', JSON.stringify(parametersData));
          if(parametersData){
            callback(parametersData);
            // callback('1');
          } else {
            callback({
              isGitFlg: '0',
              userLoginFlg: '0',
              userLoginMessage: '',
            });
          }
        },
        error => {
          this.errDialog(error);
        }
      );
  }

  // トーケンの取得
  public getToken(pageId: string, managerId: string, companyCode: string, sessionId: string, scope: string){
    const apiUrl = environment.companyUrl.companyAuthUrl;
    // header
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer app_version=1.0.0'
    });
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: pageId,
        eventId: 'access_token_get',
        managerId: managerId,
        companyCode: companyCode,
        sessionId: sessionId
      },
      parameters:{
        scope: scope
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}access_token_get`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // header
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          sessionStorage.setItem('tokenParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] !== '01000'){
            this.resDialog(commonHeaderData['message']);
          }
        },
      error => {
        this.errDialog(error);
      }
    );
  }

  // ログアウト
  public signOut() {
    const apiUrl = environment.companyUrl.companyAuthUrl;
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPC0020',
        eventId: 'company_logout',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}company_logout`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.removeItem('compSession');
          sessionStorage.removeItem('compParameters');
          sessionStorage.removeItem('companyLoggedIn');
          sessionStorage.removeItem('employeeSearchSession');
          if(commonHeaderData['messageCode'] !== '01000'){
            this.resDialog(commonHeaderData);
          }
      },
      error => {
        this.errDialog(error);
      }
    );
  }
  
  // ログイン状態の取得
  public isAuthenticated(): boolean {
    if (sessionStorage.getItem('companyLoggedIn') === 'true'){
      return true;
    } else {
      return false;
    }
  }

  // 口座種別編集
  public accountTypeStr(str){
    // 口座種別 1：普通、2：当座、4：貯蓄
    if (str === '1') {
      return '普通';
    } else if (str === '2') {
      return '当座';
    } else if (str === '4') {
      return '貯蓄';
    } else {
      return '';
    }
  }

  // 性別編集
  public genderStr(str){
    if(str === '1'){
      return '男性';
    } else if(str === '2'){
      return '女性';
    } else {
      return '非公開';
    }
  }

  // 退職文字列
  public retirementStr(isRetirement){
    // 退職、在職
    let retirementStr = '';
    if (isRetirement == '0') {
      retirementStr = '退職';
    } else if (isRetirement == '1') {
      retirementStr = '在職';
    }
    return retirementStr;
  }

  // ステータス文字列編集
  public statusStr(status){
    // ステータス 
    return status === '1' ? '未送信' : status === '2' ? '送信済' : status === '3' ? 'エラー' : status === '4' ? '取消' :'';
  }
  
  // サービス手数料負担パターン文字列編集
  public toFeeBurdenTypeStr(feeBurdenType){
    // ０：企業負担；１：個人負担（内）；２：個人負担（外）
    return feeBurdenType === '0' ? '企業負担' : feeBurdenType === '1' ? '個人負担（内）' : feeBurdenType === '2' ? '個人負担（外）' : '';
  }

  // 契約形態編集処理文字列編集
  public contractTypeStr(contractType){
    // 契約形態 01:一般正社員、02:パート・アルバイト、03:その他
    return contractType === '01' ? '一般正社員' : contractType === '02' ? 'パート・アルバイト' : contractType === '03' ? 'その他' : '';
  }

  // 基礎単価区分文字列編集
  public unitPriceClassStr(unitPriceClass){
    // 基礎単価区分 h0:時給、m0:月給、y0:年俸
    return unitPriceClass === 'h0' ? '時給' : unitPriceClass === 'm0' ? '月給' : unitPriceClass === 'y0' ? '年俸' : '';
  }
  
  // 数値型文字列 → 日付型文字列(YYYY年MM月DD日)
  public stringToDate(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length < 8) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '年'
        + strDate.substring(4, 6) + '月'
        + strDate.substring(6, 8) + '日';
    }
    return dateFormaStr;
  }
  
  // 数値型文字列 → 日付型文字列(YYYY年MM月)
  public yearMonthDataFormat(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length < 6) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '年'
        + strDate.substring(4, 6) + '月';
    }
    return dateFormaStr;
  }

  // 数値型文字列 → 日付型文字列(YYYY/MM/DD)
  public stringToDateFormat(strDate) {
    let dateFormaStr = '';
    if (!strDate || strDate.length < 8) {
      dateFormaStr = '';
    } else {
      dateFormaStr = strDate.substring(0, 4) + '/'
        + strDate.substring(4, 6) + '/'
        + strDate.substring(6, 8);
    }
    return dateFormaStr;
  }

  // 名称取得
  public getValueFromListByKey(list, key){
    for (let i of list){
      if (i.key === key){
        return i.value;
      }
    }
    return '';
  }

  // 数値型文字列 → 時刻型文字列
  public stringToHMFormat(strHM){
    let dateFormaStr = '';
    if(strHM && strHM.length >= 5){
      dateFormaStr = strHM.substring(0,5) 
     } else {
        dateFormaStr = '';
     }
    return dateFormaStr;
  }
  
  // 支店名を取得
  public getBranchName(accountIndex, bankAccount) {
    let branchNameStr = '';
    for (let i of bankAccount) {
      if (accountIndex === i['accountIndex']) {
        branchNameStr = i['branchName'];// 金融機関
        break;
      }
    }
    return branchNameStr === '' ? null : branchNameStr;
  }

  // 金融機関名を取得
  public getBankName(bankCode, bankCodeList) {
    let BankNameStr = '';
    for (let i of bankCodeList) {
      if (bankCode === i['bankCode']) {
        BankNameStr = i['bankName']; // 金融機関
        break;
      }
    }
    return BankNameStr === '' ? null : BankNameStr;
  }

  // エラーダイアログ
  public errDialog(e){
    const dialogConfig = new MatDialogConfig();
    if (e['error']['commonHeader']){
      let id = 'sysError';
      let messageCode = e['error']['commonHeader']['messageCode'];
      let errorMsg = e['error']['commonHeader']['message'];
      let errCode = messageCode.substr(2,1);
      // 2重ログイン
      if (errCode !== '9' && messageCode !== '01103'){
        id = 'dataError';
      }
      dialogConfig.data = {
        id: id,
        messageCode: messageCode,
        errorMsg: errorMsg.replace('<br>', '\n')
      };
      if(id === 'sysError'){
        sessionStorage.setItem('companyLoggedIn','false');
      }
    } else {
      dialogConfig.data = {
        id: 'sysError',
        messageCode: null,
        errorMsg: e['message']
      };
      sessionStorage.setItem('companyLoggedIn','false');
    }  
    dialogConfig.height = '200px'; 
    this.dialog.open(CompanyErrorDialog, dialogConfig);
  }

  // リスポンスダイアログ
  public resDialog(commonHeaderData){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id : 'dataError',
      errorMsg: commonHeaderData['message'],
      messageCode: commonHeaderData['messageCode']
    };
    dialogConfig.height = '200px';
    this.dialog.open(CompanyErrorDialog, dialogConfig);
  }
}
