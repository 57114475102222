import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-userProfile',
  templateUrl: './userProfile.component.html',
  styleUrls: ['./userProfile.component.css']
})

export class UserProfileComponent implements OnInit {

  title: string = '給与前払いサービス－ユーザー情報の確認';
  loading: boolean = false; // ローディング状態
  userName: string;// 氏名
  userNameFurigana: string;// 氏名（カナ）
  genderStr: string; // 性別(表示用)
  postCode: string;// 郵便番号
  address: string;// 住所
  mailAddress1: string;// メールアドレス１（ユーザーID）
  mailAddress2: string;// メールアドレス２
  mobileNumber: string;// 携帯電話番号
  telphoneNumber: string;// 固定電話番号
  company: string;// 所属企業
  bank: string;// 振込先口座
  defaultAmount: number;// 申請金額

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private router: Router,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', this.title); // タイトル
    const apiUrl = environment.userUrl.userInfoServiceApiUrl;
    // セッション取得
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // 画面処理表示設定
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0180',
        eventId: 'user_information_get',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      }
    });
    // ユーザー情報確認API送信
    this.http.post(
      `${apiUrl}user_information_get`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // セッション設定
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '00000' // 正常の場合
          || commonHeaderData['messageCode'] === '00213' // 退社した場合
          || commonHeaderData['messageCode'] === '00214' // 所属企業利用契約終了の場合
          ){
            if(commonHeaderData['messageCode'] === '00213'){ // 退社した場合
              commonHeaderData['messageCode'] = '00000'; // ダイアログ閉じる
              this.auth.resDialog(commonHeaderData);
            }
            if(commonHeaderData['messageCode'] === '00214'){ // 所属企業利用契約終了の場合
              this.auth.resDialog(commonHeaderData);
            }
            this.userName = commonHeaderData['userName'];// 氏名
            if(parametersData['name']['middleName']){
              this.userNameFurigana = parametersData['name']['lastNameFurigana']
                                    + '　' + parametersData['name']['middleName']
                                    + '　' + parametersData['name']['firstNameFurigana'];// 氏名（カナ）
            } else {
              this.userNameFurigana = parametersData['name']['lastNameFurigana']
                                    + '　' + parametersData['name']['firstNameFurigana'];// 氏名（カナ）
            }
            this.genderStr = this.auth.genderStr(parametersData['gender']); // 性別
            // this.postCode = this.auth.toPostFmt(parametersData['address']['postCode']);// 郵便番号
            this.postCode = parametersData['address']['postCode'];// 郵便番号
            this.address = parametersData['address']['address'];// 住所
            this.mailAddress1 = parametersData['mailAddress1'];// メールアドレス１（ユーザーID）
            this.mailAddress2 = parametersData['mailAddress2'];// メールアドレス２
            this.mobileNumber = parametersData['mobileNumber'];// 携帯電話番号
            this.telphoneNumber = parametersData['telphoneNumber'];// 固定電話番号
            this.company = parametersData['company']['companyCode'] + ' ' + parametersData['company']['companyName'];// 所属企業
            this.bank = parametersData['company']['bankName']
             + ' ' + parametersData['company']['branchNumber']
             + ' ' + parametersData['company']['accountNumber'];// 振込先口座
            this.defaultAmount = parametersData['company']['defaultAmount'];// 申請金額
            this.loading = false;
          } else { // 異常の場合
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // ユーザー情報変更ボタンを押す
  onUpdate(){
    this.router.navigate(['/userProfileUpdate']); // 個人情報登録画面へ
  }
}