import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataModify',
  templateUrl: './bankDataModify.component.html',
  styleUrls: ['./bankDataModify.component.css']
})
export class AdminBankDataModifyComponent implements OnInit {
  bankDataModifyForm:  FormGroup; // 画面フォーム
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AdminAuthService,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '銀行マスタ変更');
    this.loading = true;
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // 認証トークン取得
    this.auth.getToken('SPM0120', adminSession['uuId'], adminSession['sessionId'], 'bank_data_delete');
    // フォワードの初期化
    this.bankDataModifyForm = this.fb.group({
      // 金融機関コード
      'bankCode': [{value: adminParameters['bankCode'], disabled: true}],
      // 金融機関名
      'bankName': [{value: adminParameters['bankName'], disabled: true}],
      // 営業日取引時間帯FROM
      'transStartTime': [adminParameters['transStartTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]],
      // 営業日取引時間帯TO
      'transEndTime': [adminParameters['transEndTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]],
      // オンライン取引時間帯FROM
      'onlineTransStartTime': [adminParameters['onlineTransStartTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]],
      // オンライン取引時間帯TO
      'onlineTransEndTime': [adminParameters['onlineTransEndTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]],
    });
    this.loading = false;
  }

  // 確認ボタンを押す
  onConfirm() {
    if (this.loading === true) {
      return;
    }
    // フォワードの状態チェック
    if (this.bankDataModifyForm.valid) {
      this.loading = true;
      // セッション取得
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
      // APIURL
      const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
      // 送信前 ヘッダー部設定
      // ヘッダ設定
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPM0120',  // 画面ID
          eventId: 'bank_data_modify_confirm', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: this.bankDataModifyForm.get('bankCode').value, // 金融機関コード
          bankName: this.bankDataModifyForm.get('bankName').value, // 金融機関名
          transStartTime: this.bankDataModifyForm.get('transStartTime').value, // 取引開始時間
          transEndTime: this.bankDataModifyForm.get('transEndTime').value, // 取引終了時間
          onlineTransStartTime: this.bankDataModifyForm.get('onlineTransStartTime').value, // オンライン取引開始時間
          onlineTransEndTime: this.bankDataModifyForm.get('onlineTransEndTime').value, // オンライン引終了時間取
          updateAddFlag: 1
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}bank_data_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/adminBankDataModifyConfirm']); // SPM0130 銀行マスタ追加変更確認画面へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminBankDataSearch']);
  }

  // 削除ボタンを押す
  onTransferFeePatternDelete() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    this.auth.warnLog({ title: 'delete', message: '金融機関(' + this.bankDataModifyForm.get('bankCode').value + ')が削除されます。お確かめください。' }, result => {
      if (result === 'delete') {
        // ヘッダ設定
        const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
        const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
        const authToken = tokenParameters['authToken']['access_token'];
        // 送信前 ヘッダー部設定
        // ヘッダ設定
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + authToken,
          'Content-Type': 'application/json'
        });
        const body = JSON.stringify({
          commonHeader: {
            pageId: 'SPM0120',  // 画面ID
            eventId: 'bank_data_delete', // イベントID
            uuId: adminSession['uuId'], // 共通ID
            sessionId: adminSession['sessionId'] // セッションID 
          },
          parameters: {
            bankCode: adminParameters['bankCode'], //金融機関コード
          }
        });
        // 銀行マスタ削除API送信
        this.http.post(
          `${apiUrl}bank_data_delete`,
          body,
          {
            headers: headers
          }
        ).subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.router.navigate(['/adminBankDataDeleteInfo']); //振込手数料マスタ変更画面
            } else { // 異常の場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // エラーの場合
            this.auth.errDialog(error);
          }
        );
      }
    });
  }

  // 営業日取引時間帯FROMのエラーメッセージ
  getTransStartTimeErrorMessage() {
    return this.bankDataModifyForm.get('transStartTime').hasError('required') ? '営業日取引時間帯FROMを入力してください' :
      this.bankDataModifyForm.get('transStartTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // 営業日取引時間帯TOのエラーメッセージ
  getTransEndTimeErrorMessage() {
    return this.bankDataModifyForm.get('transEndTime').hasError('required') ? '営業日取引時間帯TOを入力してください' :
      this.bankDataModifyForm.get('transEndTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // オンライン取引時間帯FROMのエラーメッセージ
  getOnlineTransStartTimeErrorMessage() {
    return this.bankDataModifyForm.get('onlineTransStartTime').hasError('required') ? 'オンライン取引時間帯FROMを入力してください' :
      this.bankDataModifyForm.get('onlineTransStartTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // オンライン取引時間帯TOのエラーメッセージ
  getOnlineTransEndTimeErrorMessage() {
    return this.bankDataModifyForm.get('onlineTransEndTime').hasError('required') ? 'オンライン取引時間帯TOを入力してください' :
      this.bankDataModifyForm.get('onlineTransEndTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }
}
