import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transferFeePatternModifyConfirm-confirm',
  templateUrl: './transferFeePatternModifyConfirm.component.html',
  styleUrls: ['./transferFeePatternModifyConfirm.component.css']
})
export class AdminTransferFeePatternModifyConfirmComponent implements OnInit {
  bankCode: string; // 金融機関コード
  bankName: string; // 金融機関名
  company: string; // 企業
  // bankTransferFeeTypeList = []; // 振込手数料パターンリスト
  intimeSameBranchUnder: number; // 同支店時間内手数料(3万円未満)
  offhoursSameBranchUnder: number; // 同支店時間外手数料(3万円未満)
  intimeAnotherBranchUnder: number; // 同行他支店時間内手数料(3万円未満)
  offhoursAnotherBranchUnder: number; //同行他支店時間外手数料(3万円未満)
  intimeAnotherBankUnder: number; // 他行時間内手数料(3万円未満)
  offhoursAnotherBankUnder: number; //他行時間外手数料(3万円未満)
  intimeSameBranchOver: number; // 同支店時間内手数料(3万円以上)
  offhoursSameBranchOver: number; // 同支店時間外手数料(3万円以上)
  intimeAnotherBranchOver: number; // 同行他支店時間内手数料(3万円以上)
  offhoursAnotherBranchOver: number; // 同行他支店時間外手数料(3万円以上)
  intimeAnotherBankOver: number; // 他行時間内手数料(3万円以上)
  offhoursAnotherBankOver: number; // 他行時間外手数料(3万円以上)
  loading: boolean; // ローディング状態

  constructor(
    private router: Router,
    private auth: AdminAuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.loading = true;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    // adminParameters['updateAddFlag'] = 0;
    if (adminParameters['updateAddFlag'] === 1) {
      sessionStorage.setItem('title', '振込手数料マスタ変更確認画面');
    } else {
      sessionStorage.setItem('title', '振込手数料マスタ追加確認画面');
    }
    this.bankCode = adminParameters['bankCode']; //金融機関コード
    this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName']; // 金融機関
    this.company = adminParameters['companyCode'] + ' ' + adminParameters['companyName'];
    this.intimeSameBranchUnder = adminParameters['intimeSameBranchUnder']; // 同支店時間内手数料(3万円未満)
    this.offhoursSameBranchUnder = adminParameters['offhoursSameBranchUnder']; // 同支店時間外手数料(3万円未満)
    this.intimeAnotherBranchUnder = adminParameters['intimeAnotherBranchUnder']; // 同行他支店時間内手数料(3万円未満)
    this.offhoursAnotherBranchUnder = adminParameters['offhoursAnotherBranchUnder']; // 同行他支店時間外手数料(3万円未満)
    this.intimeAnotherBankUnder = adminParameters['intimeAnotherBankUnder']; // 他行時間内手数料(3万円未満)
    this.offhoursAnotherBankUnder = adminParameters['offhoursAnotherBankUnder']; // 他行時間外手数料(3万円未満)
    this.intimeSameBranchOver = adminParameters['intimeSameBranchOver']; // 同支店時間内手数料(3万円以上)
    this.offhoursSameBranchOver = adminParameters['offhoursSameBranchOver']; // 同支店時間外手数料(3万円以上)
    this.intimeAnotherBranchOver = adminParameters['intimeAnotherBranchOver']; // 同行他支店時間内手数料(3万円以上)
    this.offhoursAnotherBranchOver = adminParameters['offhoursAnotherBranchOver']; // 同行他支店時間外手数料(3万円以上)
    this.intimeAnotherBankOver = adminParameters['intimeAnotherBankOver']; // 他行時間内手数料(3万円以上)
    this.offhoursAnotherBankOver = adminParameters['offhoursAnotherBankOver']; // 他行時間外手数料(3万円以上)
    // 認証トークン取得
    this.auth.getToken('SPM0180', adminSession['uuId'], adminSession['sessionId'], 'transferfee_modify_commit'); // トーケンの取得
    this.loading = false;
  }

  // 確定ボタンを押す
  onCommit() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession 
    // APIURL
    const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
    this.loading = true;
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
    const authToken = tokenParameters['authToken']['access_token'];
    // 送信前 ヘッダー部設定
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPM0180',  // 画面ID
        eventId: 'transferfee_modify_commit', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}transferfee_modify_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レスポンス情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // レスポンス情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
            this.loading = false;
            sessionStorage.removeItem('bankDataAddFlg');
            // 画面遷移
            this.router.navigate(['/adminTransferFeePatternModifyInfo']);
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 戻るボタンを押す
  onBackClick() {
    // セッション取得
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    if (adminParameters['updateAddFlag'] === 1) {
      this.router.navigate(['/adminTransferFeePatternModify']); // 振込手数料マスタ変更画面
    } else {
      this.router.navigate(['/adminTransferFeePatternAdd']); // 振込手数料マスタ追加画面
    }
  }
}
