import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// バリデーションチェックエラー以外のエラーを取得
export class AdminMyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid);
    const invalidParent = !!(control && control.parent && control.parent.invalid);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-adminCommon',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css']
})
export class AdminCommonComponent implements OnInit {

  title: string = ''; // タイトル
  userName: string; // システム管理者
  loading: boolean = false; // ローディング状態
  // メニュー
  usageActualResultSearch: string; // 利用実績
  adminCompanySearch: string; // 企業情報
  adminPrepaymentUserList: string; // 会員情報
  bankDataSearch: string; // 銀行情報
  passwordUpdate: string; // パスワード変更
  userListSearch: string; // 前払利用会員検索
  transferErrorSet: string; // 振込エラー設定 
  // userPrepayDetail: string; // 前払利用会員明細

  buttonInformation; // 外部リンクリスト

  constructor(
    private auth: AdminAuthService,
    private http: HttpClient,
  ) { }

  // 初期
  ngOnInit() {
    this.loading = true;
    this.title = sessionStorage.getItem('title'); // タイトル
    const apiUrl = environment.adminUrl.authServiceApiUrl;
    // セッションを取得する
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPM0020',
        eventId: 'retrieve_admin_menu',
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}retrieve_admin_menu`,// ユーザIDをお忘れの方情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          sessionStorage.setItem('menuParameters', JSON.stringify(parametersData));
          if(commonHeaderData['messageCode'] === '02000'){ // 正常の場合
            this.userName = commonHeaderData['name']; // システム管理者氏名
            this.buttonInformation = parametersData['buttonInformation']; // 外部リンクリスト
            for (let i of this.buttonInformation){
              switch (i['serviceId']) {
                case '001': // 利用実績 
                  this.usageActualResultSearch = i['functionName']
                  break;
                case '002': // 企業情報  
                  this.adminCompanySearch = i['functionName']
                  break;
                case '003': // 前払利用会員検索
                  this.adminPrepaymentUserList = i['functionName']
                  break;
                case '004': // 振込エラー設定 
                  this.transferErrorSet = i['functionName']
                  break;
                case '005': // 銀行情報  
                  this.bankDataSearch = i['functionName']
                  break;
                case '006': // パスワード変更 
                  this.passwordUpdate = i['functionName']
                  break;
                default:
                  break;
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        if (error['error']['commonHeader']){
          let messageCode = error['error']['commonHeader']['messageCode'];
          let errCode = messageCode.substr(2,1);
          if (messageCode === '02999'){
            this.auth.errDialog(error);
          } else if(messageCode !== '02103' && errCode !== '9'){ // 二重ダイアログアラート防止
            this.auth.errDialog(error);
          }
        }
      }
    );
  }

  // 利用実績を押す
  onClickUsageSearch() {
    sessionStorage.removeItem('bankDataSearchSession');
    sessionStorage.removeItem('adminCompanySearchSession');
    sessionStorage.removeItem('bankDataAddFlg');
    sessionStorage.removeItem('adminParameters');
  }

  // 企業情報を押す
  onClickCompanySearch() {
    sessionStorage.setItem('fromMenuFlg', '1');
    sessionStorage.removeItem('bankDataSearchSession');
    sessionStorage.removeItem('adminCompanySearchSession');
    sessionStorage.removeItem('bankDataAddFlg');
    sessionStorage.removeItem('adminParameters');
  }

  // 前払利用会員検索を押す
  onClickPrepaymentUserList() {
    sessionStorage.removeItem('prepaymentUserList');
    sessionStorage.removeItem('adminParameters');
  }

  // 振込エラー設定
  onClickTransferErrorSet() {
    sessionStorage.removeItem('transferErrorSetSession');
    sessionStorage.removeItem('adminParameters');
  }

  // 銀行情報を押す
  onClickBankDataSearch() {
    sessionStorage.setItem('fromMenuFlg', '1');
    sessionStorage.removeItem('bankDataSearchSession');
    sessionStorage.removeItem('adminCompanySearchSession');
    sessionStorage.removeItem('bankDataAddFlg');
    sessionStorage.removeItem('adminParameters');
  }

  // パスワード変更を押す
  onClickPasswordUpdate() {
    sessionStorage.removeItem('bankDataSearchSession');
    sessionStorage.removeItem('adminCompanySearchSession');
    sessionStorage.removeItem('bankDataAddFlg');
    sessionStorage.removeItem('adminParameters');
  }

  // ログアウト
  onClickLogout() {
    this.auth.signOut();
  }
}
