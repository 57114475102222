import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeprecatedDecimalPipe } from '@angular/common';

@Component({
  selector: 'app-transferFeePatternAdd',
  templateUrl: './transferFeePatternAdd.component.html',
  styleUrls: ['./transferFeePatternAdd.component.css']
})
export class AdminTransferFeePatternAddComponent implements OnInit {
  bankName: string; // 金融機関名
  transferFeePatternFrom: FormGroup; // 画面フォーム
  companyCodeCheckValue: boolean;
  companyCodeCheckMessage: string;
  bankCode: string; // 金融機関コード
  companyCodeRepeatFlag: string; // 企業コード重複チェックフラグ 
  loading: boolean; // ローディング状態
  bankTransferFeeTypeFrom: FormGroup; // 画面フォーム
  intimeSameBranchUnderStr: string; // 同支店時間外手数料(3万円未満)表示項目
  offhoursSameBranchUnderStr: string; // 同支店時間外手数料(3万円未満)表示項目
  intimeAnotherBranchUnderStr: string; // 同行他支店時間内手数料(3万円未満)表示項目
  offhoursAnotherBranchUnderStr: string; // 同行他支店時間外手数料(3万円未満)表示項目
  intimeAnotherBankUnderStr: string; // 他行時間内手数料(3万円未満)表示項目
  offhoursAnotherBankUnderStr: string; // 他行時間外手数料(3万円未満)表示項目
  intimeSameBranchOverStr: string; // 同支店時間内手数料(3万円以上)表示項目
  offhoursSameBranchOverStr: string; // 同支店時間外手数料(3万円以上)表示項目
  intimeAnotherBranchOverStr: string; // 同行他支店時間内手数料(3万円以上)表示項目
  offhoursAnotherBranchOverStr: string; // 同行他支店時間外手数料(3万円以上)表示項目
  intimeAnotherBankOverStr: string; // 他行時間内手数料(3万円以上)表示項目
  offhoursAnotherBankOverStr: string; // 他行時間外手数料(3万円以上)表示項目
  digits: string = '1.0-0';
  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '振込手数料マスタ追加');
    this.loading = true;
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    if (adminParameters && adminParameters['updateAddFlag'] === 0) {
      this.companyCodeRepeatFlag = '0'; // 企業コード重複チェックフラグ (1：重複、 0：重複していない)
      this.bankCode = adminParameters['bankCode']; // 金融機関コード
      this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName']; // 金融機関
      this.bankTransferFeeTypeFrom = this.fb.group({
        'companyCode': [adminParameters['companyCode'], [Validators.required, Validators.pattern('^[A-Za-z0-9]*'), Validators.minLength(12)]], // 企業コード
      });
      this.transferFeePatternFrom = this.fb.group({
        'intimeSameBranchUnder': [adminParameters['intimeSameBranchUnder'], [Validators.pattern('^[0-9]*')]], // 同支店時間内手数料(3万円未満)
        'offhoursSameBranchUnder': [adminParameters['offhoursSameBranchUnder'], [Validators.pattern('^[0-9]*')]], // 同支店時間外手数料(3万円未満)
        'intimeAnotherBranchUnder': [adminParameters['intimeAnotherBranchUnder'], [Validators.pattern('^[0-9]*')]], // 同行他支店時間内手数料(3万円未満)
        'offhoursAnotherBranchUnder': [adminParameters['offhoursAnotherBranchUnder'], [Validators.pattern('^[0-9]*')]], // 同行他支店時間外手数料(3万円未満)
        'intimeAnotherBankUnder': [adminParameters['intimeAnotherBankUnder'], [Validators.pattern('^[0-9]*')]], // 他行時間内手数料(3万円未満)
        'offhoursAnotherBankUnder': [adminParameters['offhoursAnotherBankUnder'], [Validators.pattern('^[0-9]*')]], // 他行時間外手数料(3万円未満)
        'intimeSameBranchOver': [adminParameters['intimeSameBranchOver'], [Validators.pattern('^[0-9]*')]], // 同支店時間内手数料(3万円以上)
        'offhoursSameBranchOver': [adminParameters['offhoursSameBranchOver'], [Validators.pattern('^[0-9]*')]], // 同支店時間外手数料(3万円以上)
        'intimeAnotherBranchOver': [adminParameters['intimeAnotherBranchOver'], [Validators.pattern('^[0-9]*')]], // 同行他支店時間内手数料(3万円以上)
        'offhoursAnotherBranchOver': [adminParameters['offhoursAnotherBranchOver'], [Validators.pattern('^[0-9]*')]], // 同行他支店時間外手数料(3万円以上)
        'intimeAnotherBankOver': [adminParameters['intimeAnotherBankOver'], [Validators.pattern('^[0-9]*')]], // 他行時間内手数料(3万円以上)
        'offhoursAnotherBankOver': [adminParameters['offhoursAnotherBankOver'], [Validators.pattern('^[0-9]*')]] // 他行時間外手数料(3万円以上)
      });
    } else {
      this.companyCodeRepeatFlag = '1'; // 企業コード重複チェックフラグ (1：重複、 0：重複していない)
      this.bankCode = adminParameters['bankCode']; // 金融機関コード
      this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName']; // 金融機関
      this.bankTransferFeeTypeFrom = this.fb.group({
        'companyCode': ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*'), Validators.minLength(12)]] // 企業コード
      });
      this.transferFeePatternFrom = this.fb.group({
        'intimeSameBranchUnder': [0, [Validators.pattern('^[0-9]*')]], // 同支店時間内手数料(3万円未満)
        'offhoursSameBranchUnder': [0, [Validators.pattern('^[0-9]*')]], // 同支店時間外手数料(3万円未満)
        'intimeAnotherBranchUnder': [0, [ Validators.pattern('^[0-9]*')]], // 同行他支店時間内手数料(3万円未満)
        'offhoursAnotherBranchUnder': [0, [ Validators.pattern('^[0-9]*')]], // 同行他支店時間外手数料(3万円未満)
        'intimeAnotherBankUnder': [0, [Validators.pattern('^[0-9]*')]], // 他行時間内手数料(3万円未満)
        'offhoursAnotherBankUnder': [0, [Validators.pattern('^[0-9]*')]], // 他行時間外手数料(3万円未満)
        'intimeSameBranchOver': [0, [Validators.pattern('^[0-9]*')]], // 同支店時間内手数料(3万円以上)
        'offhoursSameBranchOver': [0, [Validators.pattern('^[0-9]*')]], // 同支店時間外手数料(3万円以上)
        'intimeAnotherBranchOver': [0, [Validators.pattern('^[0-9]*')]], // 同行他支店時間内手数料(3万円以上)
        'offhoursAnotherBranchOver': [0, [Validators.pattern('^[0-9]*')]], // 同行他支店時間外手数料(3万円以上)
        'intimeAnotherBankOver': [0, [Validators.pattern('^[0-9]*')]], // 他行時間内手数料(3万円以上)
        'offhoursAnotherBankOver': [0, [Validators.pattern('^[0-9]*')]] // 他行時間外手数料(3万円以上)
      });
    }
    this.intimeSameBranchUnderStr = this.transform(this.transferFeePatternFrom.get('intimeSameBranchUnder').value); // 同支店時間内手数料(3万円未満)
    this.offhoursSameBranchUnderStr = this.transform(this.transferFeePatternFrom.get('offhoursSameBranchUnder').value); // 同支店時間外手数料(3万円未満)
    this.intimeAnotherBranchUnderStr = this.transform(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').value); // 同行他支店時間内手数料(3万円未満)
    this.offhoursAnotherBranchUnderStr = this.transform(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').value); // 同行他支店時間外手数料(3万円未満)
    this.intimeAnotherBankUnderStr = this.transform(this.transferFeePatternFrom.get('intimeAnotherBankUnder').value); // 他行時間内手数料(3万円未満)
    this.offhoursAnotherBankUnderStr = this.transform(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').value); // 他行時間外手数料(3万円未満)
    this.intimeSameBranchOverStr = this.transform(this.transferFeePatternFrom.get('intimeSameBranchOver').value); // 同支店時間内手数料(3万円以上)
    this.offhoursSameBranchOverStr = this.transform(this.transferFeePatternFrom.get('offhoursSameBranchOver').value); // 同支店時間外手数料(3万円以上)
    this.intimeAnotherBranchOverStr = this.transform(this.transferFeePatternFrom.get('intimeAnotherBranchOver').value); // 同行他支店時間内手数料(3万円以上)
    this.offhoursAnotherBranchOverStr = this.transform(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').value); // 同行他支店時間外手数料(3万円以上)
    this.intimeAnotherBankOverStr = this.transform(this.transferFeePatternFrom.get('intimeAnotherBankOver').value); // 他行時間内手数料(3万円以上)
    this.offhoursAnotherBankOverStr = this.transform(this.transferFeePatternFrom.get('offhoursAnotherBankOver').value); // 他行時間外手数料(3万円以上)
    this.loading = false;
  }

  // 振込手数料パターン変更
  companyCodeChange() {
    this.companyCodeRepeatFlag = '1'; // 企業コード重複チェックフラグ (1：重複、 0：重複していない)
  }

  // 重複チェックボタンを押す
  companyCodeCheck() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // パラメータ
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    // APIURL
    const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
    if (this.bankTransferFeeTypeFrom.valid) {
      this.loading = true;
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPM0160',  // 画面ID
          eventId: 'transferfee_duplicate_check', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: adminParameters['bankCode'], // 金融機関コード
          companyCode: this.bankTransferFeeTypeFrom.get('companyCode').value, // 
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}transferfee_duplicate_check`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // セッション設定
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // 企業コード重複チェックフラグ (1：重複、 0：重複していない)
              this.companyCodeRepeatFlag = parametersData['companyCodeRepeatFlag'];
              let message = '';
              // 企業コード重複チェックフラグ (1：重複、 0：重複しない)
              if (this.companyCodeRepeatFlag === '0') {
                message = '入力した企業コードが重複していません。';
              } else {
                message = '入力した企業コードが既に存在します。再入力してください。';
                this.bankTransferFeeTypeFrom = this.fb.group({
                  'companyCode': ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*'), Validators.minLength(12)]], // 企業コード
                });
              }
              this.auth.messageLog({
                title: '企業コード重複チェック',
                message: message
              });
              this.loading = false;
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // 確認ボタンを押す
  onConfirm() {
    if (this.loading === true) {
      return;
    }
    if (this.transferFeePatternFrom.valid && this.bankTransferFeeTypeFrom && this.companyCodeRepeatFlag === '0') {
      // セッション取得
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
      // パラメータ
      const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters')); // adminParameters  
      // APIURL
      const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
      this.loading = true;
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPM0160',  // 画面ID
          eventId: 'transferfee_modify_confirm', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: adminParameters['bankCode'], // 金融機関コード
          bankName: adminParameters['bankName'], // 金融機関
          companyCode: this.bankTransferFeeTypeFrom.get('companyCode').value,// 企業コード     
          intimeSameBranchUnder: new Number(this.transferFeePatternFrom.get('intimeSameBranchUnder').value), // 同支店時間内手数料(3万円未満)
          offhoursSameBranchUnder: new Number(this.transferFeePatternFrom.get('offhoursSameBranchUnder').value), // 同支店時間外手数料(3万円未満)
          intimeAnotherBranchUnder: new Number(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').value), // 同行他支店時間内手数料(3万円未満)
          offhoursAnotherBranchUnder: new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').value), // 同行他支店時間外手数料(3万円未満)
          intimeAnotherBankUnder: new Number(this.transferFeePatternFrom.get('intimeAnotherBankUnder').value), // 他行時間内手数料(3万円未満)
          offhoursAnotherBankUnder: new Number(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').value), // 他行時間外手数料(3万円未満)
          intimeSameBranchOver: new Number(this.transferFeePatternFrom.get('intimeSameBranchOver').value), // 同支店時間内手数料(3万円以上)
          offhoursSameBranchOver: new Number(this.transferFeePatternFrom.get('offhoursSameBranchOver').value), // 同支店時間外手数料(3万円以上)
          intimeAnotherBranchOver: new Number(this.transferFeePatternFrom.get('intimeAnotherBranchOver').value), // 同行他支店時間内手数料(3万円以上)
          offhoursAnotherBranchOver: new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').value), // 同行他支店時間外手数料(3万円以上)
          intimeAnotherBankOver: new Number(this.transferFeePatternFrom.get('intimeAnotherBankOver').value), // 他行時間内手数料(3万円以上)
          offhoursAnotherBankOver: new Number(this.transferFeePatternFrom.get('offhoursAnotherBankOver').value), // 他行時間外手数料(3万円以上)
          updateAddFlag: 0, // 情報変更フラグ 0：add、 1：update
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}transferfee_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/adminTransferFeePatternModifyConfirm']);
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }
  
  // 金額型項目フォマード処理Function(XXXXXXXXXX → XXX,XXX,XXXX)
  transform(value: any, digits?: string): string {
    if(this.transferFeePatternFrom.valid){
      return new DeprecatedDecimalPipe('ja').transform(value, digits);
    }
    return value;
  }

  // 同支店時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeSameBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeSameBranchUnder').valid){
      this.transferFeePatternFrom.get('intimeSameBranchUnder').setValue(new Number(this.transferFeePatternFrom.get('intimeSameBranchUnder').value) + '');
      let str = this.transferFeePatternFrom.get('intimeSameBranchUnder').value;
      this.intimeSameBranchUnderStr = this.transform(str);
    }
  }

  // 同支店時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offIntimeSameBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeSameBranchUnder').valid){
      this.intimeSameBranchUnderStr = new Number(this.transferFeePatternFrom.get('intimeSameBranchUnder').value).toString();
    }
  }
  // 同支店時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursSameBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursSameBranchUnder').valid){
      this.transferFeePatternFrom.get('offhoursSameBranchUnder').setValue(new Number(this.transferFeePatternFrom.get('offhoursSameBranchUnder').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursSameBranchUnder').value;
      this.offhoursSameBranchUnderStr = this.transform(str);
    }
  }

  // 同支店時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursSameBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursSameBranchUnder').valid){
      this.offhoursSameBranchUnderStr = new Number(this.transferFeePatternFrom.get('offhoursSameBranchUnder').value).toString();
    }
  }

  // 同行他支店時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').valid){
      this.transferFeePatternFrom.get('intimeAnotherBranchUnder').setValue(new Number(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').value) + '');
      let str = this.transferFeePatternFrom.get('intimeAnotherBranchUnder').value;
      this.intimeAnotherBranchUnderStr = this.transform(str);
    }
  }

  // 同行他支店時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction)
  offIntimeAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').valid){
      this.intimeAnotherBranchUnderStr = new Number(this.transferFeePatternFrom.get('intimeAnotherBranchUnder').value).toString();
    }
  }

  // 同行他支店時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').valid){
      this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').setValue(new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').value;
      this.offhoursAnotherBranchUnderStr = this.transform(str);
    }
  }

  // 同行他支店時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').valid){
      this.offhoursAnotherBranchUnderStr = new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').value).toString();
    }
  }

  // 他行時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBankUnder').valid){
      this.transferFeePatternFrom.get('intimeAnotherBankUnder').setValue(new Number(this.transferFeePatternFrom.get('intimeAnotherBankUnder').value) + '');
      let str = this.transferFeePatternFrom.get('intimeAnotherBankUnder').value;
      this.intimeAnotherBankUnderStr = this.transform(str);
    }
  }

  // 他行時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBankUnder').valid){
      this.intimeAnotherBankUnderStr = new Number(this.transferFeePatternFrom.get('intimeAnotherBankUnder').value).toString();
    }
  }

  // 他行時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').valid){
      this.transferFeePatternFrom.get('offhoursAnotherBankUnder').setValue(new Number(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursAnotherBankUnder').value;
      this.offhoursAnotherBankUnderStr = this.transform(str);
    }
  }

  // 他行時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').valid){
      this.offhoursAnotherBankUnderStr = new Number(this.transferFeePatternFrom.get('offhoursAnotherBankUnder').value).toString();
    }
  }

  // 同支店時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeSameBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeSameBranchOver').valid){
      this.transferFeePatternFrom.get('intimeSameBranchOver').setValue(new Number(this.transferFeePatternFrom.get('intimeSameBranchOver').value) + '');
      let str = this.transferFeePatternFrom.get('intimeSameBranchOver').value;
      this.intimeSameBranchOverStr = this.transform(str);
    }
  }

  // 同支店時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeSameBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeSameBranchOver').valid){
      this.intimeSameBranchOverStr = new Number(this.transferFeePatternFrom.get('intimeSameBranchOver').value).toString();
    }
  }

  // 同支店時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursSameBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursSameBranchOver').valid){
      this.transferFeePatternFrom.get('offhoursSameBranchOver').setValue(new Number(this.transferFeePatternFrom.get('offhoursSameBranchOver').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursSameBranchOver').value;
      this.offhoursSameBranchOverStr = this.transform(str);
    }
  }

  // 同支店時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursSameBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursSameBranchOver').valid){
      this.offhoursSameBranchOverStr = new Number(this.transferFeePatternFrom.get('offhoursSameBranchOver').value).toString();
    }
  }

  // 同行他支店時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBranchOver').valid){
      this.transferFeePatternFrom.get('intimeAnotherBranchOver').setValue(new Number(this.transferFeePatternFrom.get('intimeAnotherBranchOver').value) + '');
      let str = this.transferFeePatternFrom.get('intimeAnotherBranchOver').value;
      this.intimeAnotherBranchOverStr = this.transform(str);
    }
  }

  // 同行他支店時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBranchOver').valid){
      this.intimeAnotherBranchOverStr = new Number(this.transferFeePatternFrom.get('intimeAnotherBranchOver').value).toString();
    }
  }

  // 同行他支店時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').valid){
      this.transferFeePatternFrom.get('offhoursAnotherBranchOver').setValue(new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursAnotherBranchOver').value;
      this.offhoursAnotherBranchOverStr = this.transform(str);
    }
  }

  // 同行他支店時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').valid){
      this.offhoursAnotherBranchOverStr = new Number(this.transferFeePatternFrom.get('offhoursAnotherBranchOver').value).toString();
    }
  }

  // 他行時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toIntimeAnotherBankOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBankOver').valid){
      this.transferFeePatternFrom.get('intimeAnotherBankOver').setValue(new Number(this.transferFeePatternFrom.get('intimeAnotherBankOver').value) + '');
      let str = this.transferFeePatternFrom.get('intimeAnotherBankOver').value;
      this.intimeAnotherBankOverStr = this.transform(str);
    }
  }

  // 他行時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBankOverNumberFmt() {
    if(this.transferFeePatternFrom.get('intimeAnotherBankOver').valid){
      this.intimeAnotherBankOverStr = new Number(this.transferFeePatternFrom.get('intimeAnotherBankOver').value).toString();
    }
  }

  // 他行時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXXX)
  toOffhoursAnotherBankOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBankOver').valid){
      this.transferFeePatternFrom.get('offhoursAnotherBankOver').setValue(new Number(this.transferFeePatternFrom.get('offhoursAnotherBankOver').value) + '');
      let str = this.transferFeePatternFrom.get('offhoursAnotherBankOver').value.toString().trim();
      this.offhoursAnotherBankOverStr = this.transform(str);
    }
  }

  // 他行時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBankOverNumberFmt() {
    if(this.transferFeePatternFrom.get('offhoursAnotherBankOver').valid){
      this.offhoursAnotherBankOverStr = new Number(this.transferFeePatternFrom.get('offhoursAnotherBankOver').value).toString();
    }
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminTransferFeePatternSearch']); // 振込手数料マスタ照会画面
  }

  // 企業コードのエラーメッセージ
  getCompanyCodeMessage() {
    return this.bankTransferFeeTypeFrom.get('companyCode').hasError('required') ? '企業コードを入力してください' :
      this.bankTransferFeeTypeFrom.get('companyCode').hasError('pattern') ? '企業コードを半角英数字で入力してください' :
        this.bankTransferFeeTypeFrom.get('companyCode').hasError('minlength') ? '正しい企業コードを入力してください' :
           '';
  }

  // 同支店時間内手数料(3万円未満)のエラーメッセージ
  getintimeSameBranchUnderMessage() {
    return this.transferFeePatternFrom.get('intimeSameBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursSameBranchUnderMessage() {
    return this.transferFeePatternFrom.get('offhoursSameBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間内手数料(3万円未満)のエラーメッセージ
  getintimeAnotherBranchUnderMessage() {
    return this.transferFeePatternFrom.get('intimeAnotherBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursAnotherBranchUnderMessage() {
    return this.transferFeePatternFrom.get('offhoursAnotherBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間内手数料(3万円未満)のエラーメッセージ
  getintimeAnotherBankUnderMessage() {
    return this.transferFeePatternFrom.get('intimeAnotherBankUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursAnotherBankUnderMessage() {
    return this.transferFeePatternFrom.get('offhoursAnotherBankUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間内手数料(3万円以上)のエラーメッセージ
  getintimeSameBranchOverMessage() {
    return this.transferFeePatternFrom.get('intimeSameBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursSameBranchOverMessage() {
    return this.transferFeePatternFrom.get('offhoursSameBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間内手数料(3万円以上)のエラーメッセージ
  getintimeAnotherBranchOverMessage() {
    return this.transferFeePatternFrom.get('intimeAnotherBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursAnotherBranchOverMessage() {
    return this.transferFeePatternFrom.get('offhoursAnotherBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間内手数料(3万円以上)のエラーメッセージ
  getintimeAnotherBankOverMessage() {
    return this.transferFeePatternFrom.get('intimeAnotherBankOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursAnotherBankOverMessage() {
    return this.transferFeePatternFrom.get('offhoursAnotherBankOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }
}
