import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminCompanyModifyInfo',
  templateUrl: './companyModifyInfo.component.html',
  styleUrls: ['./companyModifyInfo.component.css']
})
export class AdminCompanyModifyInfoComponent implements OnInit {
  title: string = '企業情報変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() { 
    sessionStorage.setItem('title', '企業情報変更完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/adminCompanySearch']);
  }
}
