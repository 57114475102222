import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService, UserOnetimePasswordDialog } from '../auth/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-userProfileUpdateConfirm',
  templateUrl: './userProfileUpdateConfirm.component.html',
  styleUrls: ['./userProfileUpdateConfirm.component.css']
})

export class UserProfileUpdateConfirmComponent implements OnInit {

  title: string = '給与前払いサービス－ユーザー情報の変更確認';
  loading: boolean = false; // ローディング状態
  confirmDisabled: boolean = true; // ワンタイムパスワード非入力フラグ
  companyCode: string; // 企業コード
  employeeNumber: string; // 従業員番号
  lastNameInKanji: string; // 姓
  firstNameInKanji: string; // 名
  lastNameFurigana: string; // セイ
  firstNameFurigana: string; // メイ
  gender: string; // 性別
  genderStr: string; // 性別(表示用)
  postCode: string; // 郵便番号
  // postCodeStr: string; // 郵便番号(表示用)
  address: string; // 住所
  mobileNumber: string; // 携帯電話番号
  telphoneNumber: string; // 固定電話番号
  isMailAddressChanged: string; // メールアドレス変更フラグ
  mailAddress1: string; // メールアドレス1
  mailAddress2: string; // メールアドレス2
  isSecretQuestionChanged: string; // 秘密の質問変更フラグ
  secretQuestionList; // 秘密の質問リスト
  secretQuestion: string; // 秘密の質問
  secretQuestionNo: string; // 秘密の質問番号
  secretQuestionAnswer: string; // 秘密の質問の答え
  uuId; // 共通ID
  sessionId; // セッションID

  constructor(
    private auth: UserAuthService,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
  ) { }

  oneTimePassword = new FormControl();

  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    this.companyCode = userSession['companyCode']; // 企業コード
    this.employeeNumber = userSession['employeeNumber']; // 従業員番号
    this.uuId = userSession['uuId'];
    this.sessionId = userSession['sessionId'];
    // 認証トークン取得
    this.auth.getToken('SPU0190', this.uuId, this.sessionId, 'user_information_update');
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    this.lastNameInKanji = userParameters['name']['lastNameInKanji']; // 姓
    this.firstNameInKanji = userParameters['name']['firstNameInKanji']; // 名
    this.lastNameFurigana = userParameters['name']['lastNameFurigana']; // セイ
    this.firstNameFurigana = userParameters['name']['firstNameFurigana']; // メイ
    this.gender = userParameters['gender']; // 性別(表示用)
    this.genderStr = this.auth.genderStr(this.gender); // 性別(表示用)
    this.postCode = userParameters['address']['postCode']; // 郵便番号
    // this.postCodeStr = this.auth.toPostFmt(userParameters['address']['postCode']); // 郵便番号(表示用)
    this.address = userParameters['address']['address']; // 住所
    this.mobileNumber = userParameters['mobileNumber']; // 携帯電話番号
    this.telphoneNumber = userParameters['telphoneNumber']; // 固定電話番号
    this.isMailAddressChanged = userParameters['isMailAddressChanged']; // メールアドレス変更フラグ
    this.mailAddress1 = userParameters['mailAddress1']; // メールアドレス1
    this.mailAddress2 = userParameters['mailAddress2']; // メールアドレス2
    this.isSecretQuestionChanged = userParameters['isSecretQuestionChanged']; // 秘密の質問変更フラグ
    this.secretQuestionList = userParameters['secretQuestionList']; // 秘密の質問リスト
    this.secretQuestionNo = userParameters['secretQuestionNo']; // 秘密の質問番号
    for(let question of this.secretQuestionList){
      if(this.secretQuestionNo === question.secretQuestionNo){
        this.secretQuestion = question.secretQuestion;
      }
    }
    this.secretQuestionAnswer = userParameters['secretQuestionAnswer']; // 秘密の質問の答え
    this.oneTimePassword = new FormControl({value: '', disabled: this.confirmDisabled});
  }

  openDialog() {
    this.loading = true;
    const apiUrl = environment.userUrl.authServiceApiUrl;
    this.dialog.open(UserOnetimePasswordDialog, {});
    this.confirmDisabled = false;
    this.oneTimePassword = new FormControl({value: '', disabled: this.confirmDisabled}, [Validators.required]);
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0190',
        eventId: 'one_time_password_send',
        uuId: this.uuId,
        appVersion: null,
        sessionId: this.sessionId
      },
      parameters:{
        mailAddress1: this.mailAddress1
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}one_time_password_send`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          if(commonHeaderData['messageCode'] !== '00000'){ // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
          this.loading = false;
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 確定ボタンを押す
  onConfirm() {
    if (this.loading){
      return false;
    }
    const apiUrl = environment.userUrl.userInfoServiceApiUrl;
    if(this.oneTimePassword.valid){
      this.loading = true;
      const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters')); // tokenParameters
      const authToken = tokenParameters['authToken']['access_token'];
      // header
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + authToken,
        'Content-Type': 'application/json'
      });
      // body
      const body = JSON.stringify({
        commonHeader:{
          pageId: 'SPU0190',
          eventId: 'user_information_update',
          uuId: this.uuId,
          appVersion: null,
          sessionId: this.sessionId
        },
        parameters:{
          lastNameInKanji: this.lastNameInKanji, 
          firstNameInKanji: this.firstNameInKanji, 
          lastNameFurigana: this.lastNameFurigana, 
          firstNameFurigana: this.firstNameFurigana, 
          gender: this.gender, 
          postCode: this.postCode, 
          address: this.address, 
          mobileNumber: this.mobileNumber, 
          telphoneNumber: this.telphoneNumber, 
          isMailAddressChanged: this.isMailAddressChanged,
          mailAddress1: this.mailAddress1, 
          mailAddress2: this.mailAddress2, 
          isSecretQuestionChanged: this.isSecretQuestionChanged,
          secretQuestionNo: this.secretQuestionNo, 
          // secretQuestion: this.secretQuestion, 
          secretQuestionAnswer: this.secretQuestionAnswer, 
          oneTimePassword: this.oneTimePassword.value
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}user_information_update`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // commonHeader
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              this.loading = false;
              this.router.navigate(['/userProfileUpdateInfo']); // ユーザー情報の変更結果へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  };

  // 戻るボタンを押す
  onBackClick(){
    if (this.loading){
      return false;
    }
    this.router.navigate(['/userProfileUpdate']); // ユーザー情報変更画面へ
  }

  // ワンタイムパスワードのエラーメッセージ
  getOneTimePasswordErrorMessage(): any {
    return this.oneTimePassword.hasError('required') ? 'ワンタイムパスワードを入力してください' :
           '';
  };
}