import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-userDataSearch',
  templateUrl: './userDataSearch.component.html',
  styleUrls: ['./userDataSearch.component.css']
})

export class AdminUserDataSearchComponent implements OnInit {

  loading: boolean; // ローディング状態
  uuId: string; // 会員UUID
  leavedStr: string = '退会'; // 退会
  // 会員本人確認情報
  userIdUserCheck: string; // 会員ID
  userNameUserCheck: string; // 氏名フリガナ
  birthDateUserCheck: string; // 生年月日
  addressUserCheck: string; // 住所
  lockStatusFlag: boolean; // 会員ロックフラグ
  // 振込先銀行口座
  authToken: any; // 認証トークン(振込先銀行口座ステータス変更)
  companyList: any; // 勤務先企業リスト
  companyEmployee: string; // 勤務先企業コード
  accountStatusList: any; // ステータスリスト
  bankCode1: string; // 金融機関コード1
  bankCode2: string; // 金融機関コード2
  bankCode3: string; // 金融機関コード3
  bankName1: string; // 金融機関名1
  bankName2: string; // 金融機関名2
  bankName3: string; // 金融機関名3
  bank1: string; // 金融機関1
  bank2: string; // 金融機関2
  bank3: string; // 金融機関3
  branch1: string; // 支店1
  branch2: string; // 支店2
  branch3: string; // 支店3
  accountNumber1: string; // 口座番号1
  accountNumber2: string; // 口座番号2
  accountNumber3: string; // 口座番号3
  accountType1: string; // 口座種別1
  accountType2: string; // 口座種別2
  accountType3: string; // 口座種別3
  accountHolder1: string; // 名義人1
  accountHolder2: string; // 名義人2
  accountHolder3: string; // 名義人3
  accountStatus1: string; // ステータス1
  accountStatus2: string; // ステータス2
  accountStatus3: string; // ステータス3
  // 会員情報詳細
  lastNameInKanji: string; // 姓（漢字）
  firstNameInKanji: string; // 名（漢字）
  lastNameFurigana: string; // 姓（カナ）
  firstNameFurigana: string; // 名（カナ）
  gender: string; // 性別
  birthDate: string; // 生年月日
  postCode: string; // 連絡先郵便番号
  address: string; // 連絡先住所
  mobileNumber: string; // 携帯電話番号
  telphoneNumber: string; // 固定電話番号
  mailAddress1: string; // メールアドレス1（ユーザーID)
  mailAddress2: string; // メールアドレス2（連絡用）
  lockStatus: string; // ステータス
  contractAgreedAt: string; // 会員規約同意日時
  leavedAt: string; // 退会日時

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '会員情報照会変更'); // タイトル
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'))
    // 認証トークン取得API(会員ロックボタン或いはロック解除ボタンを押下)
    this.auth.getToken('SPM0080', adminSession['uuId'], adminSession['sessionId'], 'user_lock_status_modify'); // トーケンの取得
    // 認証トークン取得API(振込先銀行口座ステータス変更)
    this.getToken();
    // データ初期化処理
    this.initData(adminParameters);
    this.onInit(); // API接続
    this.loading = false;
  }

  // API接続
  onInit() {
    const apiUrl = environment.adminUrl.userInfoServiceApiUrl;
    // 共通ヘッダ
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPM0080',  // 画面ID
        eventId: 'user_data_search_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_data_search_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 会員ロックボタンを押下('0:利用中'、'2:アカウントロック')
  onUserLock() {
    if(this.loading === true){
      return;
    }
    // ヒントダイアログ
    this.auth.warnLog({
      title: 'change',
      message: 'この会員のステータスをロックにする。お確かめください。'
    }, result => {
      // 確認ボタンを押下
      if (result === 'change') {
        // ロック変更
        this.changeLockStatus(false);
        // 戻るボタンを押下
      } else {
        this.lockStatusFlag = true; // 会員ロックフラグ
        this.lockStatus = '会員'; // ステータス
      }
    });
  }

  // ロック解除ボタンを押下
  onUserUnLock() {
    if(this.loading === true){
      return;
    }
    // ヒントダイアログ
    this.auth.warnLog({
      title: 'change',
      message: 'この会員のステータスのロック状態を解除する。お確かめください。'
    }, result => {
      // 確認ボタン押下
      if (result === 'change') {
        // ロック変更
        this.changeLockStatus(true);
      } else {
        this.lockStatusFlag = false; // 会員ロックフラグ
        this.lockStatus = 'ロック'; // ステータス
      }
    });
  }

  // 振込先銀行口座ステータスプルダウン選択された時
  companyChange() {
    this.loading = true;
    this.bankCode1 = ''; // 金融機関コード1
    this.bankName1 = ''; // 金融機関名1
    this.bank1 = ''; // 金融機関1
    this.branch1 = ''; // 支店1
    this.accountNumber1 = ''; // 口座番号1
    this.accountType1 = ''; // 口座種別1
    this.accountHolder1 = ''; // 名義人1
    this.accountStatus1 = ''; // ステータス1
    this.bankCode2 = ''; // 金融機関コード2
    this.bankName2 = ''; // 金融機関名2
    this.bank2 = ''; // 金融機関2
    this.branch2 = ''; // 支店2
    this.accountNumber2 = ''; // 口座番号2
    this.accountType2 = ''; // 口座種別2
    this.accountHolder2 = ''; // 名義人2
    this.accountStatus2 = ''; // ステータス2
    this.bankCode3 = ''; // 金融機関コード3
    this.bankName3 = ''; // 金融機関名3
    this.bank3 = ''; // 金融機関3
    this.branch3 = ''; // 支店3
    this.accountNumber3 = ''; // 口座番号3
    this.accountType3 = ''; // 口座種別3
    this.accountHolder3 = ''; // 名義人3
    this.accountStatus3 = ''; // ステータス3
    const apiUrl = environment.adminUrl.userInfo2ServiceApiUrl;
    // 共通ヘッダ
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    let companyCode = this.companyEmployee.split(' ')[0].trim();
    let employeeNumber = this.companyEmployee.split(' ')[1].trim();
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPM0080',  // 画面ID
        eventId: 'user_bank_account_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        companyCode: companyCode,
        employeeNumber: employeeNumber
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}user_bank_account_search`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            this.bankAccountData(parametersData['bankAccountList']);
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // ステータス変更ボタンを押下
  accountStatusChange() {
    if(this.loading === true){
      return;
    }
    // ヒントダイアログ
    this.auth.warnLog({
      title: 'change',
      message: '振込先銀行口座ステータスを設定する。お確かめください。' 
      // 会員のステータスのロック状態を解除する
    }, result => {
      // 確認ボタン押下
      if (result === 'change') {
        this.loading = true;
        const apiUrl = environment.adminUrl.userInfoServiceApiUrl;
        // セッション取得
        const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
        // 認証トークン
        const tokenParameters = this.authToken;
        const authToken = tokenParameters['authToken']['access_token'];
        let companyCode = this.companyEmployee.split(' ')[0];
        let employeeNumber = this.companyEmployee.split(' ')[1];
        // ヘッダ設定
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + authToken,
          'Content-Type': 'application/json'
        });
        const body = JSON.stringify({
          commonHeader: {
            pageId: 'SPM0080',  // 画面ID
            eventId: 'account_status_modify', // イベントID
            uuId: adminSession['uuId'], // 共通ID
            sessionId: adminSession['sessionId'] // セッションID 
          },
          parameters: {
            account1Status: this.accountStatus1, // 振込先口座1ステータス
            account2Status: this.accountStatus2, // 振込先口座2ステータス
            account3Status: this.accountStatus3, // 振込先口座3ステータス
            companyCode: companyCode, // 企業コード
            employeeNumber: employeeNumber, // 従業員番号
            uuId: this.uuId // 会員UUID
          }
        });
        // 振込先銀行口座ステータス変更API送信
        this.http.post(
          `${apiUrl}account_status_modify`,
          body,
          {
            headers: headers
          }
        ).subscribe(
          response => {
            this.loading = false;
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              // 認証トークン取得API(振込先銀行口座ステータス変更)
              this.getToken();
            } else { // 異常の場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => { // エラーの場合
            this.auth.errDialog(error);
            this.loading = false;
          }
        );
      }
    });
  }

  // データ初期化処理
  initData(parametersData: JSON) {
    // 本人確認情報
    this.uuId = parametersData['uuId'];
    this.userIdUserCheck = parametersData['userId']; // 会員ID
    let userName = parametersData['userName'];
    // 連絡先氏名
    if (userName.firstNameInKanji || userName.lastNameInKanji) { // 漢字氏名設定あり
      this.userNameUserCheck = `${userName.lastNameInKanji} ${userName.firstNameInKanji}(${userName.lastNameFurigana} ${userName.firstNameFurigana})`;
    } else if (userName.middleName) { // ミドル・ネーcompanyCodeム設定あり
      this.userNameUserCheck = `${userName.lastNameFurigana} ${userName.middleName} ${userName.firstNameFurigana}`;
    } else { // その他
      this.userNameUserCheck = `${userName.lastNameFurigana} ${userName.firstNameFurigana}`;
    }
    this.birthDateUserCheck = this.auth.stringToDate(parametersData['birthDate']); // 生年月日
    // this.addressUserCheck = '〒' + this.auth.toPostFmt(parametersData['address']['postCode']) + ' ' + parametersData['address']['address']; // 住所
    this.addressUserCheck = '〒' + parametersData['address']['postCode'] + ' ' + parametersData['address']['address']; // 住所
    this.lockStatusFlag = parametersData['lockStatus'] === '0';// 会員ロックフラグ
    // 振込先銀行口座
    this.companyList = parametersData['companyList']; // 企業リスト
    this.companyEmployee = parametersData['defaultCompanyCode'] + ' ' + parametersData['defaultEmployeeNumber']; // 勤務先企業コード
    this.accountStatusList = parametersData['accountStatusList']; // ステータスリスト
    this.bankAccountData(parametersData['bankAccountList']); // 企業振込先リストを設定
    // 会員情報詳細
    this.lastNameInKanji = parametersData['userName']['lastNameInKanji']; // 姓（漢字）
    this.firstNameInKanji = parametersData['userName']['firstNameInKanji']; // 名（漢字）
    this.lastNameFurigana = parametersData['userName']['lastNameFurigana']; // 姓（カナ）
    this.firstNameFurigana = parametersData['userName']['firstNameFurigana']; // 名（カナ）
    this.gender = this.auth.genderStr(parametersData['gender']); // 性別
    // this.postCode = '〒' + this.auth.toPostFmt(parametersData['address']['postCode']); // 連絡先郵便番号
    this.postCode = '〒' + parametersData['address']['postCode']; // 連絡先郵便番号
    this.address = parametersData['address']['address']; // 連絡先住所
    this.birthDate = this.auth.stringToDate(parametersData['birthDate']); // 生年月日
    // this.mobileNumber = this.auth.toTelephoneNumberFmt(parametersData['mobileNumber']); // 携帯電話番号
    this.mobileNumber = parametersData['mobileNumber']; // 携帯電話番号
    // this.telphoneNumber = this.auth.toTelephoneNumberFmt(parametersData['telphoneNumber']); // 固定電話番号
    this.telphoneNumber = parametersData['telphoneNumber']; // 固定電話番号
    this.mailAddress1 = parametersData['mailAddress1']; // メールアドレス1（ユーザーID)
    this.mailAddress2 = parametersData['mailAddress2']; // メールアドレス2（連絡用）
    this.lockStatus = this.lockStatusFlag ? '会員' : 'ロック'; // ステータス
    this.contractAgreedAt = parametersData['contractAgreedAt'] ? moment(parametersData['contractAgreedAt']).format('YYYY年MM月DD日 hh:mm') : ''; // 会員規約同意日時
    this.leavedAt = parametersData['leavedAt'] ? moment(parametersData['leavedAt']).format('YYYY年MM月DD日 hh:mm') : '';  // 退会日時
  }

  // 企業振込先リストデータを設定
  bankAccountData(bankAccountList) {
    // 銀行口座1存在の場合
    if (bankAccountList[0]) {
      this.bankCode1 = bankAccountList[0].bankCode; // 金融機関コード1
      this.bankName1 = bankAccountList[0].bankName; // 金融機関名1
      this.bank1 = this.bankCode1 + ' ' + this.bankName1; // 金融機関1
      this.branch1 = bankAccountList[0].branchNumber + ' ' + bankAccountList[0].branchName; // 支店1
      this.accountNumber1 = bankAccountList[0].accountNumber; // 口座番号1
      this.accountType1 = this.auth.accountTypeStr(bankAccountList[0].accountType); // 口座種別1
      this.accountHolder1 = bankAccountList[0].accountHolder; // 名義人1
      this.accountStatus1 = bankAccountList[0].accountStatus; // ステータス1
      // 銀行口座1存在しないの場合
    } else {
      this.bankCode1 = ''; // 金融機関コード1
      this.bankName1 = ''; // 金融機関名1
      this.bank1 = this.bankCode1 + ' ' + this.bankName1; // 金融機関1
      this.branch1 = ''; // 支店1
      this.accountNumber1 = ''; // 口座番号1
      this.accountType1 = ''; // 口座種別1
      this.accountHolder1 = ''; // 名義人1
      this.accountStatus1 = ''; // ステータス1
    }
    // 銀行口座2存在の場合
    if (bankAccountList[1]) {
      this.bankCode2 = bankAccountList[1].bankCode; // 金融機関コード2
      this.bankName2 = bankAccountList[1].bankName; // 金融機関名2
      this.bank2 = this.bankCode2 + ' ' + this.bankName2; // 金融機関2
      this.branch2 = bankAccountList[1].branchNumber + ' ' + bankAccountList[1].branchName; // 支店2
      this.accountNumber2 = bankAccountList[1].accountNumber; // 口座番号2
      this.accountType2 = this.auth.accountTypeStr(bankAccountList[1].accountType); // 口座種別2
      this.accountHolder2 = bankAccountList[1].accountHolder; // 名義人2
      this.accountStatus2 = bankAccountList[1].accountStatus; // ステータス2
      // 銀行口座2存在しないの場合
    } else {
      this.bankCode2 = ''; // 金融機関コード2
      this.bankName2 = ''; // 金融機関名2
      this.bank2 = this.bankCode2 + ' ' + this.bankName2; // 金融機関2
      this.branch2 = ''; // 支店2
      this.accountNumber2 = ''; // 口座番号2
      this.accountType2 = ''; // 口座種別2
      this.accountHolder2 = ''; // 名義人2
      this.accountStatus2 = ''; // ステータス2
    }
    // 銀行口座3存在の場合
    if (bankAccountList[2]) {
      this.bankCode3 = bankAccountList[2].bankCode; // 金融機関コード3
      this.bankName3 = bankAccountList[2].bankName; // 金融機関名3
      this.bank3 = this.bankCode3 + ' ' + this.bankName3; // 金融機関3
      this.branch3 = bankAccountList[2].branchNumber + ' ' + bankAccountList[2].branchName; // 支店3
      this.accountNumber3 = bankAccountList[2].accountNumber; // 口座番号3
      this.accountType3 = this.auth.accountTypeStr(bankAccountList[2].accountType); // 口座種別3
      this.accountHolder3 = bankAccountList[2].accountHolder; // 名義人3
      this.accountStatus3 = bankAccountList[2].accountStatus; // ステータス3
      // 銀行口座3存在しないの場合
    } else {
      this.bankCode3 = ''; // 金融機関コード3
      this.bankName3 = ''; // 金融機関名3
      this.bank3 = this.bankCode3 + ' ' + this.bankName3; // 金融機関3
      this.branch3 = ''; // 支店3
      this.accountNumber3 = ''; // 口座番号3
      this.accountType3 = ''; // 口座種別3
      this.accountHolder3 = ''; // 名義人3
      this.accountStatus3 = ''; // ステータス3
    }
  }

  // ロック変更
  changeLockStatus(lockStatusFlag: boolean) {
    this.loading = true;
    const apiUrl = environment.adminUrl.userInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // 認証トークン
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters'));
    const authToken = tokenParameters['authToken']['access_token'];
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0080',  // 画面ID
        eventId: 'user_lock_status_modify', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      },
      parameters: {
        uuId: this.uuId, // ユーザーID
        lockStatus: lockStatusFlag ? '1' : '0' // ステータス
      }
    });
    // API送信
    this.http.post(
      `${apiUrl}user_lock_status_modify`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // セッション設定
          sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
          // 検索データ初期化処理
          this.lockStatusFlag = lockStatusFlag;
          this.lockStatus = this.lockStatusFlag ? '会員' : 'ロック'; // ステータス
          // 認証トークン取得API(会員ロックボタン或いはロック解除ボタンを押下)
          this.auth.getToken('SPM0080', adminSession['uuId'], adminSession['sessionId'], 'user_lock_status_modify'); // トーケンの取得
          this.loading = false;
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
          this.loading = false;
        }
      },
      error => { // エラーの場合
        this.auth.errDialog(error);
        this.loading = false;
      }
    );
  }

  // 認証トークン取得API(振込先銀行口座ステータス変更)
  getToken() {
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    const apiUrl = environment.adminUrl.authServiceApiUrl;
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer app_version=1.0.0'
    });
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0080',
        eventId: 'access_token_get',
        uuId: adminSession['uuId'],
        sessionId: adminSession['sessionId']
      },
      parameters: {
        scope: 'account_status_modify'
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}access_token_get`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') {
            this.authToken = parametersData; // 認証トークン(振込先銀行口座ステータス変更)
          } else { // 異常の場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.auth.errDialog(error);
        }
      );
  }
}