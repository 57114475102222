import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminCompanySearch',
  templateUrl: './companySearch.component.html',
  styleUrls: ['./companySearch.component.css']
})

export class AdminCompanySearchComponent implements OnInit {
  parentCompanyCode: string; // 親企業コード
  sonCompanyCode: string; // 子企業コード
  grandsonCompanyCode: string; // 孫企業コード
  parentCompanyList = []; // 親企業リスト
  sonCompanyList = []; // 子企業リスト
  grandsonCompanyList = []; // 孫企業リスト
  sonCodeFlag: boolean; // 子企業表示フラグ false:非表示　true:非常
  sonCodeSelectFlag: boolean; // 子企業選択フラグ
  grandSonCodeFlag: boolean; // 孫企業表示フラグ false:非表示　true:非常
  grandsonCodeSelectFlag: boolean; // 孫企業選択フラグ
  companyCodeInfoFlag:boolean; // 企業情報エリア表示フラグ false:非表示　true:非常
  loading: boolean; // ローディング状態
  companyCode: string; // 企業コード
  companyName: string; // 企業名
  company: string; // 企業
  parentCode: string; // 親企業コード
  parentName: string; // 親企業名
  parent: string; // 親企業
  contractStartDate: string; // 契約開始日
  isCancelled: boolean = false; // 解約フラグ false: いいえ(未解約) true: 解約済
  contractEndDate: string; // 契約日終了日
  cancelScheduledDate: string; // 企業解約予定日
  postCode: string; // 事務所郵便番号
  address: string; // 事務所住所
  telephoneNumber: string; // 電話番号
  prepayFee: number; // 利用手数料(円)
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン名称
  prepayFeeBurdenTypeName: string; // 前払いサービス利用料負担パターン名称
  closingInProcess: boolean = false; //締め処理中フラグ false: いいえ true: 処理中
  reasonRequired: boolean = false; // 前払申請理由要求フラグ false: 要求しない true: 要求する
  // prepaymentRatioName: string; // 前払可能額掛目名称
  prepaymentRatio: string; // 前払可能額掛目
  prepayLimitPerDay: number; // 一日前払申請限度額(円)
  monthlyWorkingHours: number; // 1ヵ月の所定労働時間
  parentIsCancelled; // 親企業解約フラグ
  errorlog: boolean;

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.errorlog = true;
    sessionStorage.setItem('title', '企業情報照会'); // タイトル
    this.loading = true;
    this.retrieveParentCodeList(); // 親企業リスト取得 
    this.companyCodeInfoFlag = false; // 企業情報エリア表示フラグ
    if (sessionStorage.getItem('fromMenuFlg') !== '1'){
      // 画面状態保持セッション取得
      let adminCompanySearchSession = JSON.parse(sessionStorage.getItem('adminCompanySearchSession'));
      sessionStorage.removeItem('adminCompanySearchSession');
      if(adminCompanySearchSession){
        let companyCode = '';
        // 親企業コードが選択された場合
        if(adminCompanySearchSession.parentCompanyCode){
          this.parentCompanyCode = adminCompanySearchSession.parentCompanyCode;
          companyCode = this.parentCompanyCode;
          this.sonCodeFlag = true; // 子企業表示フラグ: true表示
          // 子企業コードリスト取得 '0'：親企業コード選択、'1'：コード企業選択
          this.retrieveSubsidiaryList(this.parentCompanyCode, '0');
        }
        // 子企業コードが選択された場合
        if (adminCompanySearchSession.sonCompanyCode) {
          this.sonCompanyCode = adminCompanySearchSession.sonCompanyCode;
          companyCode = this.sonCompanyCode;
          this.grandSonCodeFlag = true;
          this.grandsonCompanyCode = '';
          // 子企業コードリスト取得 '0'：親企業コード選択、'1'：コード企業選択
          this.retrieveSubsidiaryList(this.sonCompanyCode, '1');
          this.selectSonCompany();
        }else{
          this.sonCompanyCode = ''; // 子企業コード
        }
        // 孫企業コードが選択された場合
        if(adminCompanySearchSession.grandsonCompanyCode){
          this.grandsonCompanyCode = adminCompanySearchSession.grandsonCompanyCode;
          companyCode = this.grandsonCompanyCode;
        }else{
          this.grandsonCompanyCode = ''; // 孫企業コード
        }
        this.retrieveCompany(companyCode);
      } else {
        this.parentCompanyCode = ''; // 親企業コード
      }
    } else {
      this.parentCompanyCode = ''; // 親企業コード
    }
  }

  // 親企業選択値が選択された場合
  selectParentCompany() {
    this.loading = true;
    this.errorlog = true;
    this.companyCodeInfoFlag = false; // 企業情報エリア表示フラグ: false非表示
    this.sonCodeFlag = false; // 子企業表示フラグ: false非表示
    this.grandSonCodeFlag = false; // 孫企業表示フラグ: false非表示
    this.sonCompanyCode = ''; // 子企業コード
    this.grandsonCompanyCode = ''; // 孫企業コード
    // 親企業コードが指定された場合
    if (this.parentCompanyCode != '') {
      this.sonCodeFlag = true; // 子企業表示フラグ: true表示
      this.sonCompanyCode = ''; // 子企業コード
      this.sonCompanyList = [];
      // 子企業コードリスト取得 '0'：親企業コード選択、'1'：コード企業選択
      this.retrieveSubsidiaryList(this.parentCompanyCode, '0');
      // 親企業情報表示
      this.retrieveCompany(this.parentCompanyCode);
    } else {
      this.loading = false;
    }
  }

  // 子企業の選択値が変更された場合
  selectSonCompany() {
    this.loading = true;
    this.errorlog = true;
    this.companyCodeInfoFlag = false;
    this.grandSonCodeFlag = false;
    this.grandsonCompanyCode = '';
    if (this.sonCompanyCode != '') {
      this.grandSonCodeFlag = true;
      this.grandsonCompanyCode = '';
      this.grandsonCompanyList = [];
      // 孫企業コードリスト取得 '0'：親企業コード選択、'1'：子企業コード選択
      this.retrieveSubsidiaryList(this.sonCompanyCode, '1');
      // 子企業情報表示
      this.retrieveCompany(this.sonCompanyCode);
    }else{
      this.grandSonCodeFlag = false;
      this.grandsonCompanyCode = '';
      // 親企業情報表示
      this.retrieveCompany(this.parentCompanyCode);
    }
  }

  // 孫企業の選択値が変更された場合
  selectGrandsonCompany() {
    this.loading = true;
    this.companyCodeInfoFlag = false;
    this.errorlog = true;
    // 孫企業情報表示
    if (this.grandsonCompanyCode != '') {
      this.retrieveCompany(this.grandsonCompanyCode);
    }else{
      // 親企業情報表示
      this.retrieveCompany(this.sonCompanyCode);
    }
  }

  // 親企業リスト取得
  retrieveParentCodeList() {
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0040', // 画面ID
        eventId: 'admin_retrieve_parentcode', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      }
    });
    // 企業親子関係リスト取得
    this.http.post(
      `${apiUrl}admin_retrieve_parentcode`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：パラメータ
        const parametersData = response['parameters'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // 親企業リスト
          this.parentCompanyList = parametersData['parentCompanyList']; // 親企業リスト
          this.parentCompanyList.unshift({ // 親企業リスト初期値表示
            companyCode: '',
            companyName: '指定なし'
          });
        } else { // 異常の場合
          if(this.errorlog){
            this.errorlog = false;
            this.auth.resDialog(commonHeaderData);
          }
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  /**
   * 子企業リスト取得
   * メソッド名 retrieveSubsidiaryList
   * パラメータ {string} companyCode 企業コード
   * パラメータ {string} selectType: '0'：親企業コード選択、'1'：コード企業選択
   */
  retrieveSubsidiaryList(companyCode, selectType) {
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0040', // 画面ID
        eventId: 'admin_retrieve_subsidiary', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 企業親子関係リスト取得
    this.http.post(
      `${apiUrl}admin_retrieve_subsidiary`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        // レスポンス情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レスポンス情報：メッセージ
        if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
          // レスポンス情報：パラメータ
          const parametersData = response['parameters'];
          // 親企業コード選択時、子企業リストがある場合
          if (selectType === '0') {
            if (parametersData['companyList'][0]) {
              this.sonCodeSelectFlag = true;　// 子企業選択フラグ：true
              this.sonCompanyList = parametersData['companyList']; // 子企業リスト
              this.sonCompanyList.unshift({
                companyCode: '',
                companyName: '指定なし'
              });
            } else {
              this.sonCodeSelectFlag = false;
            } 
          }
          // 子企業コード選択時、孫企業リストがある場合
          if (selectType === '1') {
            if (parametersData['companyList'][0]) {
              this.grandsonCodeSelectFlag = true; // 孫企業選択フラグ：true
              this.grandsonCompanyList = parametersData['companyList']; // 子企業リスト
              this.grandsonCompanyList.unshift({
                companyCode: '',
                companyName: '指定なし'
              });
            } else {
              this.grandsonCodeSelectFlag = false;
            }
          }
        } else { // 異常の場合
          if(this.errorlog){
            this.errorlog = false;
            this.auth.resDialog(commonHeaderData);
          }
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  /**
   * 企業情報検索
   * メソッド名 retrieveCompany
   * パラメータ {string} companyCode 企業コード
   */
  retrieveCompany(companyCode) {
    // API URL設定
    const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPM0040', // 画面ID
        eventId: 'admin_company_search', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}admin_company_search`, // 企業情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          if(commonHeaderData['messageCode'] === '02000'){ // 正常の場合
            // レスポンス情報：パラメータ
            sessionStorage.setItem('adminParameters',JSON.stringify(parametersData));
            this.companyCode = parametersData['companyCode']; // 企業コード
            this.companyName = parametersData['companyName']; // 企業名
            this.company = `${this.companyCode}: ${this.companyName}`; // 企業
            this.parentCode = parametersData['parentCode']; // 親企業コード
            this.parentName = parametersData['parentName']; // 親企業名
            this.parent = `${this.parentCode}: ${this.parentName}`; // 親企業
            this.contractStartDate = this.auth.stringToDate(parametersData['contractStartDate']); // 契約開始日
            this.contractEndDate = this.auth.stringToDate(parametersData['contractEndDate']); // 契約終了日 
            // 解約フラグ false: いいえ(未解約) true: 解約済
            this.isCancelled = !parametersData['isCancelled']; // 解約フラグ 
            // this.postCode = this.auth.toPostFmt(parametersData['postCode']); // 事務所郵便番号
            this.postCode = parametersData['postCode']; // 事務所郵便番号
            this.address = parametersData['address']; // 事務所住所
            // this.telephoneNumber = this.auth.toTelephoneNumberFmt(parametersData['telephoneNumber']); // 電話番号
            this.telephoneNumber = parametersData['telephoneNumber']; // 電話番号
            this.prepayFee = parametersData['prepayFee']; // 利用手数料(円)
            this.transferFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['transferFeeBurdenTypeList'], parametersData['transferFeeBurdenType']); // 振込手数料負担パターン名称
            this.prepayFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['prepayFeeBurdenTypeList'], parametersData['prepayFeeBurdenType']); // 前払いサービス利用料負担パターン名称
            // 締め処理中フラグ false: いいえ(処理) true: 処理中
            this.closingInProcess = !parametersData['closingInProcess']; // 締め処理中フラグ
            // 前払申請理由要求フラグ false: 要求しない true: 要求する
            this.reasonRequired = !parametersData['reasonRequired']; // 前払申請理由要求フラグ
            // this.prepaymentRatioName = this.auth.getValueFromListByKey(parametersData['prepaymentRatioList'], parametersData['prepaymentRatio']); // 前払可能額掛目名称
            this.prepaymentRatio = parametersData['prepaymentRatio']; // 前払可能額掛目名称
            this.parentIsCancelled = parametersData['parentIsCancelled']; // 親企業解約フラグ
            this.prepayLimitPerDay = parametersData['prepayLimitPerDay'] ? parametersData['prepayLimitPerDay'] : 0; // 一日前払申請限度額(円)
            this.monthlyWorkingHours = parametersData['monthlyWorkingHours'] ? parametersData['monthlyWorkingHours'] : 160; // 1ヵ月の所定労働時間
            this.companyCodeInfoFlag = true;　// 企業情報エリア表示フラグ false:非表示　true:非常
            this.cancelScheduledDate = this.auth.stringToDate(parametersData['cancelScheduledDate']);
          } else {
            if(this.errorlog){
              this.errorlog = false;
              this.auth.resDialog(commonHeaderData);
            }
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 変更ボタンを押す
  onAdminCompanyChange() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    if (this.parentIsCancelled) {
      this.auth.messageLog({
        title:'親企業解約',
        message:`親企業が既に解約された、親企業のサービス契約状況は変更するが必要です。お確かめください。`
      });
      this.loading = false;
    } else {
      // API URL設定
      const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
      // セッション取得
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
      // ヘッダー設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー設定
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPM0040', // 画面ID
          eventId: 'admin_company_modify', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID
        },
        // パラメータ
        parameters: {
          companyCode: this.companyCode //企業コード 
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}admin_company_modify`, // 企業情報取得
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              sessionStorage.removeItem('fromMenuFlg');
              // 画面状態保持セッション設定
              sessionStorage.setItem('adminCompanySearchSession', JSON.stringify({
                parentCompanyCode: this.parentCompanyCode,
                sonCompanyCode: this.sonCompanyCode,
                grandsonCompanyCode: this.grandsonCompanyCode
              }));
              this.router.navigate(['/adminCompanyModify']); // SPM0050 企業情報変更画面へ
            } else {
              this.errorlog = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
      );
    }
  }
}