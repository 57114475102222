import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bankDataAdd',
  templateUrl: './bankDataAdd.component.html',
  styleUrls: ['./bankDataAdd.component.css']
})
export class AdminBankDataAddComponent implements OnInit {
  bankDataAddForm: FormGroup; // 画面フォーム
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '銀行マスタ追加');
    this.loading = true;
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    if (adminParameters && adminParameters['updateAddFlag'] == 0) {
      this.bankDataAddForm = this.fb.group({
        'bankCode': [adminParameters['bankCode'], [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(4)]], // 金融機関コード
        'bankName': [adminParameters['bankName'], Validators.required], // 金融機関名
        'transStartTime': [adminParameters['transStartTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // 取引開始時間
        'transEndTime': [adminParameters['transEndTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // 取引終了時間
        'onlineTransStartTime': [adminParameters['onlineTransStartTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // オンライン取引開始時間
        'onlineTransEndTime': [adminParameters['onlineTransEndTime'], [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // オンライン引終了時間取
      });
    } else {
      this.bankDataAddForm = this.fb.group({
        'bankCode': ['', [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(4)]], // 金融機関コード 
        'bankName': ['', Validators.required], // 金融機関名
        'transStartTime': ['', [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // 取引開始時間
        'transEndTime': ['', [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // 取引終了時間
        'onlineTransStartTime': ['', [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // オンライン取引開始時間
        'onlineTransEndTime': ['', [Validators.required, Validators.pattern('^(((1?\\d)|([0-1]\\d)|(2[0-3])):([012345]\\d)|24:00)$')]], // オンライン引終了時間取
      });
    }
    this.loading = false;
  }

  // 確認ボタンを押す
  onConfirm() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
    const apiUrl = environment.adminUrl.bankInfoServiceApiUrl;
    if (this.bankDataAddForm.valid) {
      this.loading = true;
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPM0110',  // 画面ID
          eventId: 'bank_data_modify_confirm', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: this.bankDataAddForm.get('bankCode').value, // 金融機関コード 
          bankName: this.bankDataAddForm.get('bankName').value, // 金融機関名
          transStartTime: this.bankDataAddForm.get('transStartTime').value, // 取引開始時間
          transEndTime: this.bankDataAddForm.get('transEndTime').value, // 取引終了時間
          onlineTransStartTime: this.bankDataAddForm.get('onlineTransStartTime').value, // オンライン取引開始時間 
          onlineTransEndTime: this.bankDataAddForm.get('onlineTransEndTime').value, // オンライン引終了時間取
          updateAddFlag: 0, // 追加変更フラッグ
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}bank_data_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/adminBankDataModifyConfirm']); // SPM0120 銀行マスタ追加内容確認
            } else if (commonHeaderData['messageCode'] === '02205') { // 新規の場合、金融機関コード重複チェック
              this.auth.messageLog({
                title: '金融機関コード重複チェック',
                message: '入力した金融機関コードが既に存在します。再入力してください。'
              });
              this.loading = false;
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminBankDataSearch']);
  }

  // 金融機関コードチェックのエラーメッセージ
  getbankCodeErrorMessage() {
    return this.bankDataAddForm.get('bankCode').hasError('required') ? '金融機関コードを入力してください' :
      this.bankDataAddForm.get('bankCode').hasError('pattern') ? '金融機関コードを半角数字で入力してください' :
      this.bankDataAddForm.get('bankCode').hasError('minlength') ? '金融機関コード4桁でご入力してください' :
        '';
  }

  //金融機関名チェックのエラーメッセージ
  getBankNameErrorMessage() {
    return this.bankDataAddForm.get('bankName').hasError('required') ? '金融機関名を入力してください' :
      '';
  }


  // 営業日取引時間帯FROMチェックのエラーメッセージ
  getTransStartTimeErrorMessage() {
    return this.bankDataAddForm.get('transStartTime').hasError('required') ? '営業日取引時間帯FROMを入力してください' :
      this.bankDataAddForm.get('transStartTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // 営業日取引時間帯TOチェックのエラーメッセージ
  getTransEndTimeErrorMessage() {
    return this.bankDataAddForm.get('transEndTime').hasError('required') ? '営業日取引時間帯TOを入力してください' :
      this.bankDataAddForm.get('transEndTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // オンライン取引時間帯FROMチェックのエラーメッセージ
  getOnlineTransStartTimeErrorMessage() {
    return this.bankDataAddForm.get('onlineTransStartTime').hasError('required') ? 'オンライン取引時間帯FROMを入力してください' :
      this.bankDataAddForm.get('onlineTransStartTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }

  // オンライン取引時間帯TOチェックのエラーメッセージ
  getOnlineTransEndTimeErrorMessage() {
    return this.bankDataAddForm.get('onlineTransEndTime').hasError('required') ? 'オンライン取引時間帯TOを入力してください' :
      this.bankDataAddForm.get('onlineTransEndTime').hasError('pattern') ? '入力形式はHH:MMです．お確かめください。' :
        '';
  }
}
