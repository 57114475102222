import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-prePayStartConfirm',
  templateUrl: './prePayStartConfirm.component.html',
  styleUrls: ['./prePayStartConfirm.component.css']
})
export class UserPrePayStartConfirmComponent implements OnInit {

  title: string = '給与前払いサービス－前払い申請確認';
  loading: boolean = false; // ローディング状態
  uuid; // 共通ID:uuId
  sessionid; // セッションID:sessionId
  accountType: string; // 口座種別
  employeeNumber: string; // 従業員番号 
  userCompany: string; // 所属企業
  userName: string; // 氏名
  prepayLimit: number; // 前払申請可能額
  applicationAmount: number; // 前払申請額
  // 振込情報
  bankName: string; // 金融機関
  branchNumber: string; // 支店コード 
  branchName: string; // 支店名
  accountNumber: string; // 口座番号
  accountHolder: string; // 名義人
  transferAmount: number; // 振込予定金額
  transferFee: number; // 振込手数料(税込)
  prepaymentFee: number; // 利用手数料　サービス利用料(税込)　
  note: string; // 入金予定日に関する説明文
  showReasonRequiredFLG: boolean;  // パラメータ・前払申請理由要求フラグ
  appReason: string; // 申請理由
  isClosingInProcessFLG: boolean = false; // 締め中状態

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private router: Router,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', this.title); // タイトル
    // 画面項目初期値設定
    this.initForm();
    // 認証トークン取得
    this.auth.getToken('SPU0140', this.uuid, this.sessionid, 'prepayment_apply_commit');
    this.loading = false;
  }

  // 戻るボタンを押す
  onBackClick(){
    if (this.loading) {
      return;
    }
    sessionStorage.setItem('prePayStartBackFLG', '1');
    this.router.navigate(['/userPrePayStart']); // 前払申請画面へ
  }

  // 画面初期化
  initForm() {
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // パラメータparameters
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters'));
    // 共通ID:uuId
    this.uuid = userSession['uuId'];
    // セッションID:sessionId
    this.sessionid = userSession['sessionId'];
    // 氏名
    this.employeeNumber = userSession['employeeNumber'];
    // 申請情報
    this.userCompany = userSession['companyCode'] + ' ' + userSession['companyName']; // 所属企業
    this.userName = userSession['userName']; // 氏名
    this.prepayLimit = userParameters['prepayLimit'];  // 前払申請可能額
    this.applicationAmount = userParameters['applicationAmount']; // 前払申請額
    // 振込情報
    this.bankName = userParameters['prepayDetail']['bankName'];  // 金融機関
    this.branchNumber = userParameters['prepayDetail']['branchNumber']; // 支店コード
    this.branchName = userParameters['prepayDetail']['branchName']; // 支店名
    this.accountNumber = userParameters['prepayDetail']['accountNumber']; // 口座番号
    this.accountHolder = userParameters['prepayDetail']['accountHolder']; // 名義人
    this.transferAmount = userParameters['prepayDetail']['transferAmount']; // 振込予定金額
    this.transferFee = userParameters['transferFeeBurdenType'].substring(0, 1) === '0' ? 0 : userParameters['prepayDetail']['transferFee']; // 振込手数料(税込)
    this.prepaymentFee = userParameters['prepayFeeBurdenType'].substring(0, 1) === '0' ? 0 : userParameters['prepayDetail']['prepaymentFee']; // サービス利用料(税込)
    // 入金予定日に関する説明文      　
    this.note = userParameters['prepayDetail']['note'];  // 入金予定日に関する説明文
    // パラメータ・前払申請理由要求フラグ
    this.showReasonRequiredFLG = (userParameters['reasonRequired'] == 1);
    // 申請理由
    // 応答電文・パラメータ・前払申請理由要求フラグ＝'1'の場合、  表示
    // 応答電文・パラメータ・前払申請理由要求フラグ＝'0'の場合、項目タイトルを含めて全体を非表示とする
    this.appReason = userParameters['reason'];  // 申請理由   
    // 口座種別
    this.accountType = this.auth.accountTypeStr(userParameters['prepayDetail']['accountType']);
  }

  // 申請ボタン押下処理(前払申請受付APIを呼び出す)
  onBtnApplication() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.userUrl.prePaymentApiUrl;
    // 共通ヘッダcommonHeader
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // パラメータparameters
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters'));
    // 認証トークン
    const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0140', // 画面ID
        eventId: 'prepayment_apply_commit', // イベントID
        uuId: userSession['uuId'], // 共通ID
        appVersion: null, // アプリバージョン
        sessionId: userSession['sessionId'] // セッションID 
      },
      // パラメータ
      parameters: {
        companyCode: userSession['companyCode'], // 企業コード
        employeeNumber: userSession['employeeNumber'], // 従業員番号
        // 前払申請額  = No.13前払申請画面・前払申請額の入力
        applicationAmount: userParameters['applicationAmount'],
        // 振込先インデックス = No.4振込先口座リストに選択している口座に該当する応答電文・パラメータ・振込先情報・振込先インデックス
        accountIndex: userParameters['accountIndex'],
        // 前払申請理由要求フラグ
        reasonRequired: userParameters['reasonRequired'],
        // 申請理由
        // ・応答電文・パラメータ・前払申請理由要求フラグ＝'1'の場合、画面項目No.12申請理由の入力値を設定
        // ・応答電文・パラメータ・前払申請理由要求フラグ＝'0'の場合、空文字””を設定
        reason: userParameters['reasonRequired'] == '1'? userParameters['reason'] : '',
      }
    });
    // 送信（前払申請受付APIを呼出す）　
    this.http.post(
      `${apiUrl}prepayment_apply_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // パラメータ
          const parametersData = response['parameters'];
          // セッションストレージ
          sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            sessionStorage.removeItem('prePayParameters');
            // 画面遷移
            this.router.navigate(['/userPrePayStartInfo']); // 前払申請受付完了画面へ遷移
          } else {
            if (parametersData && parametersData['closingInProcess'] === 1){ // 締め中の場合
              this.isClosingInProcessFLG = true;
            }
            // エラーの場合
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => { // 異常処理
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  };
}