// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let userUrl;
let companyUrl;
let adminUrl;

let env = 'DEV';
if(env === 'LOCAL'){ // LOCAL
  userUrl = {
    companySelApiUrl: 'http://localhost:3000/api/v1/',
    userAccountUrl: 'http://localhost:3000/api/v1/',
    userAccount2Url: 'http://localhost:3000/api/v1/', 
    authServiceApiUrl: 'http://localhost:3000/api/v1/',
    userCompanyUrl: 'http://localhost:3000/api/v1/',
    userCompany2Url: 'http://localhost:3000/api/v1/',
    userInfoServiceApiUrl: 'http://localhost:3000/api/v1/',
    prePaymentApiUrl: 'http://localhost:3000/api/v1/',
    userSecretUrl: 'http://localhost:3000/api/v1/',
    userSecret2Url: 'http://localhost:3000/api/v1/',
    signupServiceApiUrl: 'http://localhost:3000/api/v1/',
  },
  companyUrl = {
    companyAuthUrl: 'http://localhost:4000/api/v1/company-auth/',
    companyInfoUrl: 'http://localhost:4000/api/v1/company-info/',
    companyInfo2Url: 'http://localhost:4000/api/v1/company-info2/',
    csvInfoUrl: 'http://localhost:4000/api/v1/company-file/',
    employeeInfoUrl: 'http://localhost:4000/api/v1/employee-info/',
    employeeInfo2Url: 'http://localhost:4000/api/v1/employee-info2/',
    prepayCancelUrl: 'http://localhost:4000/api/v1/prepayment-cancel/',
  },
  adminUrl = {
    authServiceApiUrl: 'http://localhost:5000/api/v1/admin-auth/',
    bankInfoServiceApiUrl: 'http://localhost:5000/api/v1/admin-bank-info/',
    companyInfoServiceApiUrl: 'http://localhost:5000/api/v1/admin-company-info/',
    transferErrorServiceApiUrl: 'http://localhost:5000/api/v1/admin-trans-error/',
    transInfoServiceApiUrl: 'http://localhost:5000/api/v1/admin-trans-info/',
    userInfoServiceApiUrl: 'http://localhost:5000/api/v1/admin-user-info/',
    userInfo2ServiceApiUrl: 'http://localhost:5000/api/v1/admin-user-info2/',
  }
} else if (env === 'DEV'){ // DEV
  userUrl = {
    companySelApiUrl: 'https://demo-serverless-app.com/api/v1/company-sel/', 
    userAccountUrl: 'https://demo-serverless-app.com/api/v1/user-account/', 
    userAccount2Url: 'https://demo-serverless-app.com/api/v1/user-account2/', 
    authServiceApiUrl: 'https://demo-serverless-app.com/api/v1/user-auth/', 
    userCompanyUrl: 'https://demo-serverless-app.com/api/v1/user-company/', 
    userCompany2Url: 'https://demo-serverless-app.com/api/v1/user-company2/', 
    userInfoServiceApiUrl: 'https://demo-serverless-app.com/api/v1/user-info/', 
    prePaymentApiUrl: 'https://demo-serverless-app.com/api/v1/user-prepay/', 
    userSecretUrl: 'https://demo-serverless-app.com/api/v1/user-secret/', 
    userSecret2Url: 'https://demo-serverless-app.com/api/v1/user-secret2/', 
    signupServiceApiUrl: 'https://demo-serverless-app.com/api/v1/user-signup/', 
  },
  companyUrl = {
    companyAuthUrl: 'https://demo-serverless-app.com/api/v1/company-auth/',
    companyInfoUrl: 'https://demo-serverless-app.com/api/v1/company-info/',
    companyInfo2Url: 'https://demo-serverless-app.com/api/v1/company-info2/',
    csvInfoUrl: 'https://demo-serverless-app.com/api/v1/company-file/',
    employeeInfoUrl: 'https://demo-serverless-app.com/api/v1/employee-info/',
    employeeInfo2Url: 'https://demo-serverless-app.com/api/v1/employee-info2/',
    prepayCancelUrl: 'https://demo-serverless-app.com/api/v1/prepayment-cancel/',
  },
  adminUrl = {
    authServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-auth/',
    bankInfoServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-bank-info/',
    companyInfoServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-company-info/',
    transInfoServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-trans-info/',
    transferErrorServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-trans-error/',
    userInfoServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-user-info/',
    userInfo2ServiceApiUrl: 'https://demo-serverless-app.com/api/v1/admin-user-info2/',
  }
} else if (env === 'STG'){ // STG
  userUrl = {
    companySelApiUrl: 'https://stg.xxxx.com/api/v1/company-sel/', 
    userAccountUrl: 'https://stg.xxxx.com/api/v1/user-account/', 
    userAccount2Url: 'https://stg.xxxx.com/api/v1/user-account2/',
    authServiceApiUrl: 'https://stg.xxxx.com/api/v1/user-auth/', 
    userCompanyUrl: 'https://stg.xxxx.com/api/v1/user-company/', 
    userCompany2Url: 'https://stg.xxxx.com/api/v1/user-company2/', 
    userInfoServiceApiUrl: 'https://stg.xxxx.com/api/v1/user-info/', 
    prePaymentApiUrl: 'https://stg.xxxx.com/api/v1/user-prepay/', 
    userSecretUrl: 'https://stg.xxxx.com/api/v1/user-secret/', 
    userSecret2Url: 'https://stg.xxxx.com/api/v1/user-secret2/', 
    signupServiceApiUrl: 'https://stg.xxxx.com/api/v1/user-signup/', 
  },
  companyUrl = {
    companyAuthUrl: 'https://stg.xxxx.com/api/v1/company-auth/',
    companyInfoUrl: 'https://stg.xxxx.com/api/v1/company-info/',
    companyInfo2Url: 'https://stg.xxxx.com/api/v1/company-info2/',
    csvInfoUrl: 'https://stg.xxxx.com/api/v1/company-file/',
    employeeInfoUrl: 'https://stg.xxxx.com/api/v1/employee-info/',
    employeeInfo2Url: 'https://stg.xxxx.com/api/v1/employee-info2/',
    prepayCancelUrl: 'https://stg.xxxx.com/api/v1/prepayment-cancel/',
  },
  adminUrl = {
    authServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-auth/',
    bankInfoServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-bank-info/',
    companyInfoServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-company-info/',
    transInfoServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-trans-info/',
    transferErrorServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-trans-error/',
    userInfoServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-user-info/',
    userInfo2ServiceApiUrl: 'https://stg.xxxx.com/api/v1/admin-user-info2/',
  }
}

export const environment = {
  production: false,
  userUrl: userUrl,
  companyUrl: companyUrl,
  adminUrl: adminUrl
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-Error',  // Included with Angular CLI.
