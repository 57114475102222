import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companyModify',
  templateUrl: './companyModify.component.html',
  styleUrls: ['./companyModify.component.css']
})

export class CompanyModifyComponent implements OnInit {

  loading: boolean = false; // ローディング状態
  companyCode: string; // 企業コード
  contractStartDate: string; // 契約開始日
  contractEndDate: string; // 契約終了日
  companyName: string; // 企業名
  postCode: string; // 事務所郵便番号
  // postCodeStr: string; // 事務所郵便番号（表示用）
  address: string; // 事務所住所
  telephoneNumber: string; // 電話番号
  // telephoneNumberStr: string; // 電話番号（表示用）
  prepayFee: string; // サービス利用手数料(税込)
  transferFeeBurdenType: string; // 選択した振込手数料パターン
  prepayFeeBurdenType: string; // 選択した手数料負担パターン
  chkReasonRequired: boolean = false; // 前払申請理由要求フラグ
  // secretPrepaymentRatio: string; // 前払可能率
  sonCodeList = []; // 子企業コードリスト
  secretOptSonCodeNo; // 子企業コード
  sonCodeFlag: boolean; // 子企業フラグ
  grandSonCodeFlag: boolean; // 孫企業フラグ
  transferFeeBurdenTypeList = []; // 振込手数料負担パターンリスト
  prepayFeeBurdenTypeList = []; // 前払手数料負担パターンリスト
  // prepaymentRatioList = []; // 前払可能率リスト
  transferFeeBurdenTypeName: string; // 振込手数料パターン名称
  prepayFeeBurdenTypeName: string; // 手数料負担パターン名称
  reasonRequiredName: string; // 前払申請理由要求名称
  // prepaymentRatioName: string; // 前払可能率名称
  prepayLimitPerDay: string; // 一日前払申請限度額(円)
  cancelScheduledDate: string; // 企業解約予定日
  companyForm: FormGroup; // 画面フォーム
  prepaymentRatio: string; // 前払可能率

  constructor(
    private http: HttpClient,
    private auth: CompanyAuthService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    sessionStorage.setItem('title', '企業情報変更'); // タイトル
    // 初期
    this.initForm();
  }

  initForm() {
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    this.companyCode = compParameters['companyCode']; // 企業コード
    this.contractStartDate = this.auth.stringToDate(compParameters['contractStartDate']); // 契約開始日
    this.contractEndDate = this.auth.stringToDate(compParameters['contractEndDate']); // 契約終了日
    this.companyName = compParameters['companyName']; // 企業名
    this.postCode = compParameters['postCode']; // 事務所郵便番号
    this.address = compParameters['address']; // 事務所住所
    this.telephoneNumber = compParameters['telephoneNumber'];// 電話番号
    this.prepayFee = compParameters['prepayFee']; // サービス利用手数料(税込)
    this.transferFeeBurdenTypeName = this.auth.getValueFromListByKey(compParameters['transferFeeBurdenTypeList'], compParameters['transferFeeBurdenType']); // 振込手数料パターン
    this.prepayFeeBurdenTypeName = this.auth.getValueFromListByKey(compParameters['prepayFeeBurdenTypeList'], compParameters['prepayFeeBurdenType']); // 手数料負担パターン
    this.chkReasonRequired = !compParameters['reasonRequired']; // 前払申請理由要求フラグ false: 要求しない true: 要求する
    this.prepayLimitPerDay = compParameters['prepayLimitPerDay'] ? compParameters['prepayLimitPerDay'] : 0; // 一日前払申請限度額(円)
    this.cancelScheduledDate = this.auth.stringToDate(compParameters['cancelScheduledDate']); // 企業解約予定日
    this.prepaymentRatio = compParameters['prepaymentRatio']; // 前払可能率
    // 入力可否のチェック
    this.companyForm = this.fb.group({
      'postCode': [this.postCode, [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(7)]],
      'address': [this.address, [Validators.required]],
      'telephoneNumber': [this.telephoneNumber, [Validators.required, Validators.pattern('^[0-9]*'), Validators.minLength(10)]],
      'chkReasonRequired': [this.chkReasonRequired],
      'prepayLimitPerDay': [parseInt(this.prepayLimitPerDay) / 1000, [Validators.pattern('^[0-9]*')]],
      'monthlyWorkingHours': [compParameters['monthlyWorkingHours'] ? compParameters['monthlyWorkingHours'] : 160, [Validators.required, Validators.pattern('^[0-9]*'), Validators.min(1)]], // 1ヵ月の所定労働時間
    });
    // 郵便番号はフォーカスアウト処理（編集処理）
    // this.postCodeStr = this.auth.toPostFmt(this.companyForm.get('postCode').value);
    // 電話番号はフォーカスアウト処理（編集処理）
    // this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(this.companyForm.get('telephoneNumber').value);
  }

  changePrepayLimitPerDay(){
    this.companyForm.get('prepayLimitPerDay').setValue(new Number(this.companyForm.get('prepayLimitPerDay').value) + '');
  }

  // // 郵便番号編集を行うFunction(XXX-XXXX)
  // toPostFmt() {
  //   this.postCodeStr = this.auth.toPostFmt(this.companyForm.get('postCode').value);
  // }

  // // 郵便番号はフォーカスオン処理（郵便番号編集を解除するFunction）
  // offPostFmt() {
  //   this.postCodeStr = this.auth.offPostFmt(this.companyForm.get('postCode').value);
  // }

  // 電話番号編集を行うFunction(XXX-XXXX-XXXX)
  // toTelephoneNumberFmt() {
  //   this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(this.companyForm.get('telephoneNumber').value);
  // }

  // // 電話番号はフォーカスオン処理（電話番号編集を解除するFunction）
  // offTelephoneNumberFmt() {
  //   this.telephoneNumberStr = this.auth.offTelephoneNumberFmt(this.companyForm.get('telephoneNumber').value);
  // }

  // 戻るボタン押下
  onBackClick() {
    this.router.navigate(['/companySearch']);
  }

  // 郵便番号のエラーメッセージ
  getPostCodeErrorMessage(): any {
    return this.companyForm.get('postCode').hasError('required') ? '郵便番号を入力してください' :
      this.companyForm.get('postCode').hasError('pattern') ? '郵便番号を半角数字で入力してください' :
        this.companyForm.get('postCode').hasError('minlength') ? '正しい郵便番号を入力してください' :
          '';
  };

  // 住所のエラーメッセージ
  getAddressErrorMessage(): any {
    return this.companyForm.get('address').hasError('required') ? '住所を入力してください' :
      '';
  };

  // 固定電話番号のエラーメッセージ
  getTelephoneNumberErrorMessage(): any {
    return this.companyForm.get('telephoneNumber').hasError('required') ? '固定電話番号を入力してください' :
           this.companyForm.get('telephoneNumber').hasError('pattern') ? '固定電話番号を半角数字で入力してください' :
           this.companyForm.get('telephoneNumber').hasError('minlength') ? '正しい固定電話番号を入力してください' :
          '';
  };

  // 一日前払申請上限額のエラーメッセージ
  getPrepayLimitPerDayErrorMessage(): any {
    return this.companyForm.get('prepayLimitPerDay').hasError('pattern') ? '上限額を半角数字で入力してください' :
      // this.companyForm.get('prepayLimitPerDay').hasError('minlength') ? '正しい上限額を入力してください' :
      // this.companyForm.get('prepayLimitPerDay').hasError('required') ? '上限額を入力してください' :
          '';
  };

  // 1ヵ月の所定労働時間のエラーメッセージ
  getMonthlyWorkingHoursErrorMessage(): any {
    return this.companyForm.get('monthlyWorkingHours').hasError('required') ? '所定労働時間を入力してください' :
           this.companyForm.get('monthlyWorkingHours').hasError('pattern') ? '所定労働時間を半角数字で入力してください' :
           this.companyForm.get('monthlyWorkingHours').hasError('min') ? '所定労働時間を0以外の数字で入力してください' :
           '';
  }

  // 確認ボタン押下
  onConfirmClick() {
    if (this.loading === true) {
      return;
    }
    // フォームチェック
    if (this.companyForm.valid) {
      this.loading = true;
      const apiUrl = environment.companyUrl.companyInfoUrl;
      // セッションを取得する
      const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
      const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー作成
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPC0040', // 画面ID
          eventId: 'company_modify_confirm', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId']
        },
        parameters: {
          companyCode: compParameters['companyCode'], // 企業コード
          contractStartDate: compParameters['contractStartDate'], // 契約開始日
          contractEndDate: compParameters['contractEndDate'], // 契約終了日
          companyName: compParameters['companyName'], // 企業名
          postCode: this.companyForm.get('postCode').value === '' ? null : this.companyForm.get('postCode').value, // 事務所郵便番号
          address: this.companyForm.get('address').value === '' ? null : this.companyForm.get('address').value, // 事務所住所 
          telephoneNumber: this.companyForm.get('telephoneNumber').value === '' ? null : this.companyForm.get('telephoneNumber').value, // 電話番号
          prepayFee: compParameters['prepayFee'], // サービス利用手数料(税込)
          transferFeeBurdenType: compParameters['transferFeeBurdenType'], // 選択した振込手数料負担パターン
          prepayFeeBurdenType: compParameters['prepayFeeBurdenType'], // 選択したサービス利用手数料負担パターン
          reasonRequired: !this.chkReasonRequired, // 前払申請理由要求フラグ false: 要求しない true: 要求する
          prepaymentRatio: compParameters['prepaymentRatio'], // 前払可能率
          prepayLimitPerDay: this.companyForm.get('prepayLimitPerDay').value * 1000, // 一日前払申請限度額(円)
          monthlyWorkingHours: parseInt(this.companyForm.get('monthlyWorkingHours').value), // 1ヵ月の所定労働時間
          cancelScheduledDate: compParameters['cancelScheduledDate'] // 企業解約予定日
        }
      });
      // 初期選択企業設定APIを呼び出す
      this.http.post(
        `${apiUrl}company_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
            // レストラン情報：レストランメッセージ
            if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
              // 当前画面でパラメータセッション設定
              sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/companyModifyConfirm']); // 企業情報変更確認画面遷移
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {// 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }
}
