import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userProfileUpdateInfo',
  templateUrl: './userProfileUpdateInfo.component.html',
  styleUrls: ['./userProfileUpdateInfo.component.css']
})

export class UserProfileUpdateInfoComponent implements OnInit {

  title = '給与前払いサービス－ユーザー情報の変更完了';

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { 
    sessionStorage.setItem('title', this.title); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}