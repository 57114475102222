import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employeeModifyConfirm',
  templateUrl: './employeeModifyConfirm.component.html',
  styleUrls: ['./employeeModifyConfirm.component.css']
})
export class CompanyEmployeeModifyConfirmComponent implements OnInit {

  company: string; // 所属企業
  employeeNumber: string; // 従業員番号
  lastNameInKanji: string; // 姓
  firstNameInKanji: string; // 名
  lastNameFurigana: string; // 姓(フリガナ)
  firstNameFurigana: string; // 名(フリガナ)
  middleName: string; // ミドルネーム
  birthDate: string; // 生年月日
  // postCodeStr: string; // 郵便番号（表示用）
  postCode: string; // 郵便番号（表示用）
  gender: string; // 性別
  genderStr: string; // 性別(表示用)
  address: string; // 住所
  emailAtCompany: string; // 会社メールアドレス
  telephoneNumberStr: string; // 電話番号（表示用）
  retirementScheduledDate: string; // 退職予定日
  isRetirement: string; //退職フラグ
  isRetirementStr: string; // 退職、在職
  bankAccount; // 振込先口座情報
  bank1: string; // 金融機関①(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode1: string; // 金融機関コード①
  bankName1: string; // 金融機関名①
  branch1: string; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber1: string; // 支店番号①
  branchName1: string; // 支店名①
  accountType1: string; // 口座種別①
  accountType1Str: string; // 口座種別①（表示用）
  accountNumber1: string; // 口座番号①
  accountHolder1: string; // 口座名義人①
  bank2: string; // 金融機関②(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode2: string; // 金融機関コード②
  bankName2: string; // 金融機関名②
  branch2: string; // 支店②(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber2: string; // 支店番号②
  branchName2: string; // 支店名②
  accountType2: string; // 口座種別②
  accountType2Str: string; // 口座種別②（表示用）
  accountNumber2: string; // 口座番号②
  accountHolder2: string; // 口座名義人②
  bank3: string; // 金融機関③(表示用:”金融機関コード”＋半角スペース＋”金融機関名”)
  bankCode3: string; // 金融機関コード③
  bankName3: string; // 金融機関名③
  branch3: string; // 支店③(表示用:”支店番号”＋半角スペース＋”支店名”)
  branchNumber3: string; // 支店番号③
  branchName3: string; // 支店名③
  accountType3: string; // 口座種別③
  accountType3Str: string; // 口座種別③（表示用）
  accountNumber3: string; // 口座番号③
  accountHolder3: string; // 口座名義人③
  loading: boolean; // ローディング状態
  isConfirmFlg: boolean; // 修正確認フラグ
  isDisabledName: string; // 強制使用停止フラグ名称
  contractType: string; // 契約形態
  // hourlyWage: string; // 時給
  // basicSalary: string; // 基本給
  contractTypeStr: string; // 契約形態（表示用）
  unitPriceClass: string; // 基礎単価区分
  unitPriceClassStr: string; // 基礎単価区分（表示用）
  unitPrice: string; // 基礎単価
  
  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private router: Router,
    private http: HttpClient
  ) { }

  // 画面初期化処理
  ngOnInit() {
    this.loading = true;
    sessionStorage.setItem('title', '従業員情報変更確認'); // タイトル
    // セッション取得
    // ヘッダー
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // パラメータ
    const compParameters = JSON.parse(sessionStorage.getItem('compParameters')); // compParameters
    // 画面項目
    this.company = compSession['companyCode'] +' '+ compSession['companyName']; // 所属企業
    this.employeeNumber = compParameters['employeeNumber']; // 従業員番号
    this.lastNameInKanji = compParameters['employeeName']['lastNameInKanji']; // 姓
    this.firstNameInKanji = compParameters['employeeName']['firstNameInKanji']; // 名
    this.lastNameFurigana = compParameters['employeeName']['lastNameFurigana']; // 姓(フリガナ)
    this.firstNameFurigana = compParameters['employeeName']['firstNameFurigana']; // 名(フリガナ)
    this.firstNameFurigana = compParameters['employeeName']['firstNameFurigana']; // 名(フリガナ)
    this.middleName = compParameters['employeeName']['middleName']; // ミドルネーム
    // 郵便番号編集
    // this.postCodeStr = this.auth.toPostFmt(compParameters['address']['postCode']);
    this.postCode = compParameters['address']['postCode'];
    this.address = compParameters['address']['address']; // 住所
    this.gender = compParameters['gender']; // 性別
    if(this.gender.length > 1){
      this.gender = this.gender.substr(0,1);
    }
    this.genderStr = this.auth.genderStr(this.gender); // 性別(表示用)
    this.birthDate = this.auth.stringToDate(compParameters['birthDate']); // 生年月日
    this.emailAtCompany = compParameters['emailAtCompany']; // 会社メールアドレス
    // this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(compParameters['telephoneNumber']); // 電話番号編集
    this.telephoneNumberStr = compParameters['telephoneNumber']; // 電話番号編集
    this.contractType = compParameters['contractDetail']['contractType']; // 契約情報
    // this.basicSalary = compParameters['contractDetail']['basicSalary']; // 基本給
    // this.hourlyWage = compParameters['contractDetail']['hourlyWage']; // 時給
    var contractTypeParams = compParameters['contractDetail']['contractType']; // 契約情報
    if (contractTypeParams.length >= 2) {
      contractTypeParams = contractTypeParams.substr(0, 2);
    }   
    this.contractTypeStr = this.auth.contractTypeStr(contractTypeParams); // 契約形態（表示用）
    this.unitPriceClass = compParameters['contractDetail']['unitPriceClass']; // 基礎単価区分
    var unitPriceClassParams = compParameters['contractDetail']['unitPriceClass']; // 基礎単価区分
    if (unitPriceClassParams.length >= 2) {
      unitPriceClassParams = unitPriceClassParams.substr(0, 2);
    }   
    this.unitPriceClassStr = this.auth.unitPriceClassStr(unitPriceClassParams); // 基礎単価区分（表示用）
    this.unitPrice = compParameters['contractDetail']['unitPrice']; // 基礎単価
    this.retirementScheduledDate = this.auth.stringToDate(compParameters['retirementScheduledDate']); // 退職予定日
    this.isRetirement = compParameters['isRetirement'] +''; // 退職フラグ
    this.isRetirementStr = this.auth.retirementStr(this.isRetirement); // 退職、在職表示用
    this.bankAccount = compParameters['bankAccount']; // 振込先口座情報リスト
    let iNum = 1;
    for(let i of this.bankAccount){
      if(iNum <= 3){
        if (iNum == 1) {
          this.bank1 = i['bankCode'] + ' ' + i['bankName']; // 金融機関①
          this.bankCode1 = i['bankCode']; // 金融機関コード①
          this.bankName1 = i['bankName']; // 金融機関名①
          this.branch1 = i['branchNumber'] + ' ' + i['branchName']; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber1 = i['branchNumber']; // 支店番号①
          this.branchName1 = i['branchName']; // 支店名①
          this.accountType1 = i['accountType']; // 口座種別①
          this.accountType1Str = this.auth.accountTypeStr(i['accountType']); // 口座種別①（表示用）
          this.accountNumber1 = i['accountNumber']; // 口座番号①
          this.accountHolder1 = i['accountHolder']; // 口座名義人①
        } else if (iNum == 2) {
          this.bank2 = i['bankCode'] + ' ' + i['bankName']; // 金融機関②
          this.bankCode2 = i['bankCode']; // 金融機関コード②
          this.bankName2 = i['bankName']; // 金融機関名②
          this.branch2 = i['branchNumber'] + ' ' + i['branchName']; // 支店①(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber2 = i['branchNumber']; // 支店番号①
          this.branchName2 = i['branchName']; // 支店名①
          this.accountType2 = i['accountType']; // 口座種別②
          this.accountType2Str = this.auth.accountTypeStr(i['accountType']); // 口座種別②（表示用）
          this.accountNumber2 = i['accountNumber']; // 口座番号②
          this.accountHolder2 = i['accountHolder']; // 口座名義人②
        } else if (iNum == 3) {
          this.bank3 = i['bankCode'] + ' ' + i['bankName']; // 金融機関③
          this.bankCode3 = i['bankCode']; // 金融機関コード③
          this.bankName3 = i['bankName']; // 金融機関名③
          this.branch3 = i['branchNumber'] + ' ' + i['branchName']; // 支店③(表示用:”支店番号”＋半角スペース＋”支店名”)
          this.branchNumber3 = i['branchNumber']; // 支店番号③
          this.branchName3 = i['branchName']; // 支店名③
          this.accountType3 = i['accountType']; // 口座種別③
          this.accountType3Str = this.auth.accountTypeStr(i['accountType']); // 口座種別③（表示用）
          this.accountNumber3 = i['accountNumber']; // 口座番号③
          this.accountHolder3 = i['accountHolder']; // 口座名義人③
        }
        iNum++; 
      }
    }
    const sessionId = compSession['sessionId']; // sessionId
    // 認証トークン取得
    this.auth.getToken('SPC0090', compSession['managerId'], compSession['companyCode'], sessionId, 'employee_modify_commit');
    this.loading = false;
    compParameters['isDisabled'] ? this.isDisabledName = '停止する' : this.isDisabledName = '停止しない';
  }

   // 戻るボタンを押す
   onBackClick(){
    if (this.loading === true) {
      return;
    }
    this.router.navigate(['/companyEmployeeModify']); // 従業員情報変更画面へ
  }

  // 確定ボタンを押す
  onMondifyConfirm() {
    if (this.loading === true) {
      return;
    }
    // セッション取得
    // ヘッダー
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // 認証トークン
    const token = JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'];
    // APIURL
    const apiUrl = environment.companyUrl.employeeInfoUrl;
    this.loading = true;
    // 送信前 ヘッダー部設定
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    // 送信前 ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダー
      commonHeader: {
        pageId: 'SPC0090', // 画面ID
        eventId: 'employee_modify_commit', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // セッションID
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}employee_modify_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // console.log(response);
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // セッション設定
          sessionStorage.setItem('compSession',JSON.stringify(commonHeaderData));
          // レストラン情報：メッセージ
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            this.loading = false;
            // 画面遷移
            this.router.navigate(['/companyEmployeeModifyInfo']); // SPC0100.従業員情報変更完了画面へ
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }
}
