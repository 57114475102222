import { Component, OnInit } from '@angular/core';
import { CompanyAuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prepaymentCancel',
  templateUrl: './prepaymentCancel.component.html',
  styleUrls: ['./prepaymentCancel.component.css']
})
export class CompanyPrepaymentCancelComponent implements OnInit {
  loading: boolean = false; // ローディング状態
  prepaymentCancelForm: FormGroup; // 画面フォーム
  prepaymentFlag: boolean; // 画面フラッグ
  uuId: string; // 共通Id
  receiptNumber: string; // 受付番号
  userCompany: string; // 所属企業
  userName: string; // 氏名
  applicationAmount: string; // 前払申請額
  status: string; // ステータス
  bank: string; // 金融機関
  branch: string; // 支店
  accountType: string; // 口座種別
  accountNumber: string; // 口座番号
  accountHolder: string; // 名義人
  transferAmount: string; // 振込予定金額
  transferFee: string; // 振込手数料(税込)
  prepayFee: string; // サービス利用料(税込)
  reason: string; // 申請理由
  btnDisabledFlg: boolean = false; // ボタン活性フラグ

  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient
  ) { }

  // 初期処理  
  ngOnInit() {
    sessionStorage.setItem('title', '前払申請取消'); // タイトル
    this.loading = true;
    this.prepaymentFlag = false;
    // 画面フォーム
    this.prepaymentCancelForm = this.fb.group({
      'receiptNumberSearch': ['', [Validators.required]],
    });
    this.onInit();
    this.loading = false;
    // 画面項目設定状態保存セッション取得
    const prepaymentCancelSession = JSON.parse(sessionStorage.getItem('prepaymentCancelSession'));
    // メニューから遷移以外の場合
    if(prepaymentCancelSession){
      this.prepaymentCancelForm = this.fb.group({
        'receiptNumberSearch': [prepaymentCancelSession.receiptNumber, [Validators.required]],
      });
      this.onSearchClick();
    }
  }

  // 前払申請取消初期処理API
  onInit() {
    const apiUrl = environment.companyUrl.prepayCancelUrl;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPC0170', // 画面ID
        eventId: 'prepayment_cancel_init', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}prepayment_cancel_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 検索を押す
  onSearchClick() {
    if (this.loading === true) {
      return;
    }
    if (this.prepaymentCancelForm.valid) {
      this.loading = true;
      this.prepaymentFlag = false;
      const apiUrl = environment.companyUrl.prepayCancelUrl;
      // 共通ヘッダ
      const compSession = JSON.parse(sessionStorage.getItem('compSession'));
      // 
      sessionStorage.setItem('prepaymentCancelSession',JSON.stringify({
        receiptNumber: this.prepaymentCancelForm.get('receiptNumberSearch').value
      }));
      // ヘッダ
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダ設定
        commonHeader: {
          pageId: 'SPC0170', // 画面ID
          eventId: 'prepayment_cancel_search', // イベントID
          companyCode: compSession['companyCode'], // 企業コード
          managerId: compSession['managerId'], // 利用企業管理者ID
          sessionId: compSession['sessionId'] // 利用企業管理者セッションID
        },
        parameters: {
          receiptNumber: this.prepaymentCancelForm.get('receiptNumberSearch').value, // 受付番号
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}prepayment_cancel_search`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
            // レストラン情報：メッセージ
            if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
              sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
              this.prepaymentFlag = true;
              this.uuId = parametersData['uuId']; // 共通ID
              this.receiptNumber = parametersData['receiptNumber']; // 受付番号
              this.userCompany = parametersData['companyCode'] +' '+ parametersData['companyName']; // 所属企業
              this.userName = parametersData['userName']; // 氏名
              this.applicationAmount = parametersData['applicationAmount']; // 前払申請額
              this.status = this.auth.statusStr(parametersData['status']); // ステータス
              if (parametersData['status'] !== '1') { // ステータスが「1:未送信」以外の場合
                this.btnDisabledFlg = true;
              } else { // ステータスが「1:未送信」の場合
                this.btnDisabledFlg = false;
              }
              this.bank = parametersData['bankCode'] + ' ' + parametersData['bankName']; // 金融機関
              this.branch = parametersData['branchNumber'] + ' ' + parametersData['branchName']; // 支店
              this.accountType = this.auth.accountTypeStr(parametersData['accountType']); // 口座種別
              this.accountNumber = parametersData['accountNumber']; // 口座番号
              this.accountHolder = parametersData['accountHolder']; // 名義人
              this.transferAmount = parametersData['transferAmount']; // 振込予定金額
              this.transferFee = parametersData['transferFee']; // 振込手数料(税込)
              this.prepayFee = parametersData['prepayFee']; // サービス利用料(税込)
              this.reason = parametersData['reason']; // 申請理由
            } else {
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // 前払取消を押す
  onBtnCancel() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.prepayCancelUrl;
    // 共通ヘッダ
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPC0170', // 画面ID
        eventId: 'prepayment_cancel_confirm', // イベントID
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      },
      parameters: {
        receiptNumber: this.receiptNumber, // 受付番号
        uuId: this.uuId, // 受付番号
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}prepayment_cancel_confirm`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            this.router.navigate(['/companyPrepaymentCancelConfirm']);
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 受付番号のエラーメッセージ
  getReceiptNumberErrorMessage() {
    return this.prepaymentCancelForm.get('receiptNumberSearch').hasError('required') ? '受付番号を入力してください' :
      ''
    }
}
