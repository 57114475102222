import { HttpHeaders, HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher} from '@angular/material/core';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

// バリデーションチェックエラー以外のエラーを取得
export class CompanyMyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid);
    const invalidParent = !!(control && control.parent && control.parent.invalid);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-companyCommon',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css']
})
export class CompanyCommonComponent implements OnInit {
  
  title: string = ''; // タイトル
  companyName: string; // 階層１企業
  userName: string; // 管理者
  buttonInformation; // 表示するボタンの情報
  loading: boolean = false; // ローディング状態
  // メニュー
  companySearch: string; // 企業情報 
  employeeSearch: string; // 従業員情報
  prepaymentEmployee: string; // 前払利用従業員
  prepaymentCancel: string; // 前払申請取消
  companyUsageResultInquiry: string; // 前払企業検索
  csvUpload: string; // CSVアップロード
  csvDownload: string; // CSVダウンロード
  passwordUpdate: string; // パスワード変更
  accountLinkage: string; // 口座連携
  parentCompanyHierarchy: string; // 階層
  isAccessible: boolean = false; // アップロード権限ロール(false:アクセス不可)

  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
  ) { }
  
  // 初期
  ngOnInit() {
    this.loading = true;
    this.title = '給与前払いサービス利用企業管理システム' + (sessionStorage.getItem("title") ? '-'+sessionStorage.getItem("title") : ""); // タイトル
    const apiUrl = environment.companyUrl.companyAuthUrl;
    // セッションを取得する
    const compSession = JSON.parse(sessionStorage.getItem('compSession'));
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
       // 共通ヘッダ
      commonHeader:{
        pageId: 'SPC0020',
        eventId: 'retrieve_company_menu',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // 利用企業管理者セッションID
      },
    });
    // 送信
    this.http.post(
      `${apiUrl}retrieve_company_menu`, // メニュー情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          const commonHeaderData = response['commonHeader'];
          const parametersData = response['parameters'];
          if(commonHeaderData['messageCode'] === '01000'){ // 正常の場合
            this.isAccessible = parametersData['isAccessible'];  // CSVアップロード権限ロールの取得
            sessionStorage.setItem('isAccessibleSession', this.isAccessible + ''); // CSVアップロード権限セッション
            this.parentCompanyHierarchy = parametersData['parentCompany']['parentCompanyHierarchy']; // 階層
            this.userName = commonHeaderData['userName']; // 氏名
            this.companyName = parametersData['parentCompany']['parentCode'] + ' ' +parametersData['parentCompany']['parentName'];
            this.buttonInformation = parametersData['buttonInformation']; // 外部リンクリスト
            for (let i of this.buttonInformation){
              switch (i['serviceId']) {
                case '001': // 企業情報 
                    this.companySearch = i['functionName'];
                  break;
                case '002': // 従業員情報 
                    this.employeeSearch = i['functionName'];
                  break;
                case '003': // 前払従業員検索 
                    this.prepaymentEmployee = i['functionName'];
                  break;
                case '004': // 前払申請取消
                    this.prepaymentCancel = i['functionName'];
                  break;
                case '005': // 利用実績照会  
                    this.companyUsageResultInquiry = i['functionName'];
                  break;
                case '006': // CSVアップロード 
                    this.csvUpload = i['functionName'];
                  break;
                case '007': // CSVダウンロード 
                    this.csvDownload = i['functionName'];
                  break;
                case '008': // パスワード変更
                    this.passwordUpdate = i['functionName'];
                  break;
                case '009': // 口座連携
                    this.accountLinkage = i['functionName'];
                  break;
                default:
                  break;
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.loading = false;
        if (error['error']['commonHeader']){
          let messageCode = error['error']['commonHeader']['messageCode'];
          let errCode = messageCode.substr(2,1);
          if (messageCode === '01999'){
            this.auth.errDialog(error);
          } else if(messageCode !== '01103' && errCode !== '9'){ // 二重ダイアログアラート防止
            this.auth.errDialog(error);
          }
        }
      }
    );
  }

  // 従業員情報を押す
  onClickEmployeeSearch() {
    sessionStorage.removeItem('employeeSearchSession');
  }

  // 前払申請取消を押す
  onClickPrepaymentCancel(){
    sessionStorage.removeItem('prepaymentCancelSession');
  }

  // 前払従業員検索を押す
  onClickPrepaymentEmployee(){
    sessionStorage.removeItem('prepaymentEmployee');
  }
  // ログアウト
  onClickLogout() {
    this.auth.signOut();
  }
}
