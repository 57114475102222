import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';

// 振込先口座インターフェース
export interface Account{
  accountIndex: string;
  bankInfo: string;
};

@Component({
  selector: 'app-prePayStart',
  templateUrl: './prePayStart.component.html',
  styleUrls: ['./prePayStart.component.css']
})

export class UserPrePayStartComponent implements OnInit {
  title: string = '給与前払いサービス－前払申請';
  prepayForm: FormGroup;
  loading: boolean = false; // ローディング状態
  prepayLimit: number = 0; // 前払申請可能額
  userCompany: string; // 所属企業
  accountList: Account[] = []; // 振込先口座リスト
  selectedAccount: number; // 振込先口座選択値
  bankAccount; // 振込先口座
  bankName: string; // 金融機関
  branchNumber: string; //支店コード
  branchName: string; // 支店名
  accountType: string; // 口座種別
  accountNumber: string; // 口座番号
  accountHolder: string; // 名義人
  note: string; // 入金予定日に関する説明文
  prePayParameters; // パラメータ対象
  reasonRequired: boolean; // 前払申請理由要求フラグ
  isClosingInProcessFLG: boolean = false; // 締め中状態
  isButtonDisabled: boolean = true; // ボタン禁止状態

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // 初期化フォーム 
    this.prepayForm = this.fb.group({
      'paymentAmount': [],
      'appReason': []
    });
    // 画面項目初期値設定
    // 画面項目変更時に画面項目保存セッション
    if(!sessionStorage.getItem('prePayStartBackFLG')){
      // メニュー遷移へ画面初期表示処理
      this.initDataInfo();
    } else {
      // 確認画面で戻るボタン押下時画面再表示処理
      this.updateDataInfo();
      // 画面項目保存セッション削除
      sessionStorage.removeItem('prePayParameters');
      sessionStorage.removeItem('prePayStartBackFLG');
    }
  }
  
  // メニュー遷移へ画面初期表示処理
  initDataInfo() {
    this.loading = true;
    const apiUrl = environment.userUrl.prePaymentApiUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    const uuId = userSession['uuId']; // 共通ID
    const sessionId = userSession['sessionId']; // セッションID
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0130', // 画面ID
        eventId: 'prepayment_apply', // イベントID
        uuId: uuId, // 共通ID
        appVersion: null, // アプリバージョン
        sessionId: sessionId // セッションID 
      }
    });
    // 送信 (前払申請情報取得APIを呼出す)
    this.http.post(
      `${apiUrl}prepayment_apply`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          this.prePayParameters = parametersData;
          // セッション設定
          sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000' // 正常の場合
          || commonHeaderData['messageCode'] === '00217' // 支給対象締め処理中の場合
          ){
            if (parametersData && parametersData['closingInProcess'] === 1){ // 締め中の場合
              this.isClosingInProcessFLG = true;
              this.isButtonDisabled = true;
            }
            if(commonHeaderData['messageCode'] !== '00000'){ // 正常以外の場合
              this.auth.resDialog(commonHeaderData);
            }
            // フォーム値設定
            this.formSet(commonHeaderData, parametersData);
          } else {
            if (commonHeaderData['messageCode'] === '00214'){ // 所属企業利用契約終了の場合
              commonHeaderData['messageCode'] = '00213'; // 初期選択企業変更へ
            }
            if (this.prepayLimit === 0){
              this.isClosingInProcessFLG = true;
              this.isButtonDisabled = true;
            }
            // 請求異常の場合 
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 確認画面で戻るボタン押下時画面再表示処理
  updateDataInfo(){
    this.loading = true;
    const commonHeaderData = JSON.parse(sessionStorage.getItem('userSession'));
    const parametersData = JSON.parse(sessionStorage.getItem('prePayParameters'));
    this.prePayParameters = parametersData;
    // フォーム値設定
    this.formSet(commonHeaderData, parametersData);
    this.loading = false;
  }

  // フォーム値設定
  formSet(commonHeaderData, parametersData){
    this.prepayLimit = parametersData['prepayLimit'] ? parametersData['prepayLimit'] : 0; //前払申請可能額
    this.userCompany = (commonHeaderData['companyCode'] ? commonHeaderData['companyCode'] : '') + ' ' + (commonHeaderData['companyName'] ? commonHeaderData['companyName'] : ''); //所属企業
    let paymentAmount = parametersData['defaultAmount'] / 1000; //前払申請額
    let bankAccountInfo = parametersData['bankAccount']; // 振込先情報
    this.bankAccount = Array.from({length: Object.keys(bankAccountInfo).length},(_,i) => bankAccountInfo[i]);
    var defaultAccountIndex = parametersData['defaultAccountIndex']; //デフォルト振込先インデックス
    if (this.bankAccount.length < 1){
      this.isClosingInProcessFLG = true;
      this.isButtonDisabled = true;
      var dialog = {
        message: '利用可能な振込先口座が存在しないため、前払申請できません。',
        messageCode: ''
      };
      this.auth.resDialog(dialog)
    }
    for(let i of this.bankAccount){
      this.accountList.push({ accountIndex: i['accountIndex'], bankInfo: i['bankName'] + '　' + i['branchName'] + '　' + i['accountNumber'] });
      if(defaultAccountIndex === i['accountIndex']){
        this.bankName = i['bankName']; // 金融機関
        this.branchName = i['branchName']; // 支店名
        this.branchNumber = i['branchNumber']; // 支店コード
        this.accountType = this.auth.accountTypeStr(i['accountType']); // 口座種別編集
        this.accountNumber = i['accountNumber']; // 口座番号
        this.accountHolder = i['accountHolder']; // 名義人
        this.note = i['note'];
        this.isButtonDisabled = false;
      }
    }
    this.selectedAccount = defaultAccountIndex; // 振込先口座
    this.reasonRequired = (parametersData['reasonRequired'] == '1');//前払申請理由要求フラグ
    this.prepayForm = this.fb.group({
      'paymentAmount': [{value: paymentAmount, disabled: this.isClosingInProcessFLG}, [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(1), Validators.max(this.prepayLimit / 1000)]],
      'appReason': [{value: parametersData['reason'] ? parametersData['reason'] : '', disabled: this.isClosingInProcessFLG}, this.reasonRequired ? Validators.required : Validators.nullValidator]
    });
  }

  // ボタンを押す
  onConfirm() {
    if (this.loading) {
      return;
    }
    const apiUrl = environment.userUrl.prePaymentApiUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    if (this.prepayForm.valid) {
      this.loading = true;
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー作成
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPU0130', // 画面ID
          eventId: 'prepayment_apply_confirm', // イベントID
          uuId: userSession['uuId'], // 共通ID
          appVersion: null, // アプリバージョン
          sessionId: userSession['sessionId'] // セッションID 
        },
        parameters: {
          companyCode: userSession['companyCode'],  // 企業コード
          employeeNumber: userSession['employeeNumber'], // 従業員番号
          applicationAmount: this.prepayForm.get('paymentAmount').value *1000, // 前払申請額
          accountIndex: this.selectedAccount, // 振込先インデックス
          reasonRequired: this.reasonRequired ? 1 : 0, // 前払申請理由要求フラグ
          reason: this.reasonRequired ? this.prepayForm.get('appReason').value : '' // 申請理由
        }
      });
      // 前払申請情報確認取得APIを呼び出す
      this.http.post(
        `${apiUrl}prepayment_apply_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            this.loading = false;
            // 共通ヘッダー
            const commonHeaderData = response['commonHeader'];
            // パラメータ
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if (parametersData && parametersData['closingInProcess'] === 1){ // 締め中の場合
              this.isClosingInProcessFLG = true;
              this.isButtonDisabled = true;
              this.prepayForm = this.fb.group({
                'paymentAmount': [{value: this.prepayForm.get('paymentAmount').value, disabled: this.isClosingInProcessFLG}],
                'appReason': [{value: this.prepayForm.get('appReason').value, disabled: this.isClosingInProcessFLG}]
              });
            }
            // レストランメッセージ
            if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
              this.prePayParameters.defaultAmount =  this.prepayForm.get('paymentAmount').value * 1000; // 前払申請額
              this.prePayParameters.defaultAccountIndex = this.selectedAccount; // 振込先インデックス
              this.prePayParameters['reason'] = this.reasonRequired ? this.prepayForm.get('appReason').value : ''; // 申請理由
              sessionStorage.setItem('prePayParameters', JSON.stringify(this.prePayParameters));
              this.router.navigate(['/userPrePayStartConfirm']); // 前払申請確認へ遷移
            } else {
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    };
  }
  
  // 振込口座リストの選択値が変更された場合
  onSelectedAccount() {
    for(let i of this.bankAccount){
      if(this.selectedAccount === i['accountIndex']){
        this.bankName = i['bankName']; // 金融機関
        this.branchNumber = i['branchNumber']; // 支店コード
        this.branchName = i['branchName']; // 支店名
        this.auth.accountTypeStr(i['accountType']); // 口座種別編集
        this.accountNumber = i['accountNumber']; // 口座番号
        this.accountHolder = i['accountHolder']; // 名義人
        this.note = i['note'];
        this.isButtonDisabled = false;
        break;
      }
    }
  }

  // 前払申請額のエラーメッセージ
  getPaymentAmountErrorMessage() {
    return this.prepayForm.get('paymentAmount').hasError('required') ? '前払申請額を入力してください' :
           this.prepayForm.get('paymentAmount').hasError('pattern') ? '前払申請額を半角数字で入力してください' :
           this.prepayForm.get('paymentAmount').hasError('max') && this.prepayLimit !== 0 ? '前払申請額の入力値が前払申請可能額を超えていた' :
           this.prepayForm.get('paymentAmount').hasError('max') && this.prepayLimit === 0 ? '前払い可能額がありません' :
           this.prepayForm.get('paymentAmount').hasError('min') ? '前払申請額を0以上に入力してください' :
           '';
  };

  // 申請理由のエラーメッセージ
  getAppReasonErrorMessage() {
    return this.prepayForm.get('appReason').hasError('required') ? '申請理由を入力してください' :
      '';
  };
}