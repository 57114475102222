import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
// user
import { UserAuthService, UserOnetimePasswordDialog, UserErrorDialog } from './user/auth/auth.service';
import { UserAuthGuard } from './user/auth/auth.guard';
import { UserCommonComponent, UserMyErrorStateMatcher } from './user/common/common.component';
import { UserLoginComponent } from './user/login/login.component';
import { UserForgotUseridComponent } from './user/forgotUserid/forgotUserid.component';
import { UserForgotUseridInfoComponent } from './user/forgotUseridInfo/forgotUseridInfo.component';
import { UserForgotPasswordComponent } from './user/forgotPassword/forgotPassword.component';
import { UserForgotPasswordInfoComponent } from './user/forgotPasswordInfo/forgotPasswordInfo.component';
import { UserPasswordUpdateComponent } from './user/passwordUpdate/passwordUpdate.component';
import { UserPasswordUpdateInfoComponent } from './user/passwordUpdateInfo/passwordUpdateInfo.component';
import { UserRegisterStartComponent } from './user/registerStart/registerStart.component';
import { UserRegisterAgreementComponent } from './user/registerAgreement/registerAgreement.component';
import { UserRegisterAgreementAfterLoginComponent } from './user/registerAgreementAfterLogin/registerAgreementAfterLogin.component';
import { UserRegisterUserAgreementComponent } from './user/registerUserAgreement/registerUserAgreement.component';
import { UserRegisterUserAgreementAfterLoginComponent } from './user/registerUserAgreementAfterLogin/registerUserAgreementAfterLogin.component';
import { UserRegisterInputComponent } from './user/registerInput/registerInput.component';
import { UserRegisterInputConfirmComponent } from './user/registerInputConfirm/registerInputConfirm.component';
import { UserRegisterInfoComponent } from './user/registerInfo/registerInfo.component';
import { UserSignoutComponent } from './user/signout/signout.component';
import { UserSignoutInfoComponent } from './user/signoutInfo/signoutInfo.component';
import { UserPrePayStartComponent } from './user/prePayStart/prePayStart.component';
import { UserPrePayListComponent } from './user/prePayList/prePayList.component';
import { UserCompanyDefaultComponent } from './user/userCompanyDefault/userCompanyDefault.component';
import { UserCompanyDefaultUpdateComponent } from './user/userCompanyDefaultUpdate/userCompanyDefaultUpdate.component';
import { UserCompanyDefaultUpdateConfirmComponent } from './user/userCompanyDefaultUpdateConfirm/userCompanyDefaultUpdateConfirm.component';
import { UserCompanyDefaultUpdateInfoComponent } from './user/userCompanyDefaultUpdateInfo/userCompanyDefaultUpdateInfo.component';
import { UserCompanyAddComponent } from './user/userCompanyAdd/userCompanyAdd.component';
import { UserCompanyAddInfoComponent } from './user/userCompanyAddInfo/userCompanyAddInfo.component';
import { UserProfileComponent } from './user/userProfile/userProfile.component';
import { UserProfileUpdateComponent } from './user/userProfileUpdate/userProfileUpdate.component';
import { UserProfileUpdateConfirmComponent } from './user/userProfileUpdateConfirm/userProfileUpdateConfirm.component';
import { UserProfileUpdateInfoComponent } from './user/userProfileUpdateInfo/userProfileUpdateInfo.component';
import { UserPrePayStartConfirmComponent } from './user/prePayStartConfirm/prePayStartConfirm.component';
import { UserPrePayStartInfoComponent } from './user/prePayStartInfo/prePayStartInfo.component';
// company
import { CompanyAuthService, CompanyErrorDialog } from './company/auth/auth.service';
import { CompanyAuthGuard } from './company/auth/auth.guard';
import { CompanyCommonComponent, CompanyMyErrorStateMatcher } from './company/common/common.component';
import { CompanyLoginComponent } from './company/login/login.component';
import { CompanyMainComponent } from './company/main/main.component';
import { CompanySearchComponent } from './company/companySearch/companySearch.component';
import { CompanyModifyComponent } from './company/companyModify/companyModify.component';
import { CompanyModifyConfirmComponent } from './company/companyModifyConfirm/companyModifyConfirm.component';
import { CompanyModifyInfoComponent } from './company/companyModifyInfo/companyModifyInfo.component';
import { CompanyEmployeeSearchComponent } from './company/employeeSearch/employeeSearch.component';
import { CompanyEmployeeModifyComponent } from './company/employeeModify/employeeModify.component';
import { CompanyEmployeeModifyConfirmComponent } from './company/employeeModifyConfirm/employeeModifyConfirm.component';
import { CompanyEmployeeModifyInfoComponent } from './company/employeeModifyInfo/employeeModifyInfo.component';
import { CompanyPrepaymentEmployeeComponent } from './company/prepaymentEmployee/prepaymentEmployee.component';
import { CompanyPrepaymentDetailSearchComponent } from './company/prepaymentDetailSearch/prepaymentDetailSearch.component';
import { CompanyPrepaymentCancelConfirmComponent } from './company/prepaymentCancelConfirm/prepaymentCancelConfirm.component';
import { CompanyPrepaymentCancelComponent } from './company/prepaymentCancel/prepaymentCancel.component';
import { CompanyPrepaymentCancelInfoComponent } from './company/prepaymentCancelInfo/prepaymentCancelInfo.component';
import { CompanyCsvUploadComponent } from './company/csvUpload/csvUpload.component';
import { CompanyCsvdownloadComponent } from './company/csvDownload/csvDownload.component';
import { CompanyPasswordUpdateComponent } from './company/passwordUpdate/passwordUpdate.component';
import { CompanyPasswordUpdateInfoComponent } from './company/passwordUpdateInfo/passwordUpdateInfo.component';
import { CompanyUsageResultInquiryComponent } from './company/usageResultInquiry/usageResultInquiry.component';
import { CompanyAccountLinkageComponent } from './company/accountLinkage/accountLinkage.component';
import { CompanyAccountLinkageGanbTokenComponent } from './company/accountLinkageGanbToken/accountLinkageGanbToken.component';
import { CompanyAccountLinkageGanbTokenCallbackComponent } from './company/accountLinkageGanbTokenCallback/accountLinkageGanbTokenCallback.component';
import { CompanyAccountLinkageInfoComponent } from './company/accountLinkageInfo/accountLinkageInfo.component';
// admin
import { AdminAuthService, AdminErrorDialog, AdminMessageLog, AdminWarnLog } from './admin/auth/auth.service';
import { AdminAuthGuard } from './admin/auth/auth.guard';
import { AdminCommonComponent, AdminMyErrorStateMatcher } from './admin/common/common.component';
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminMainComponent } from './admin/main/main.component';
import { AdminUsageActualResultSearchComponent } from './admin/usageActualResultSearch/usageActualResultSearch.component';
import { AdminCompanySearchComponent } from './admin/companySearch/companySearch.component';
import { AdminCompanyModifyComponent } from './admin/companyModify/companyModify.component';
import { AdminCompanyModifyConfirmComponent } from './admin/companyModifyConfirm/companyModifyConfirm.component';
import { AdminCompanyModifyInfoComponent } from './admin/companyModifyInfo/companyModifyInfo.component';
import { AdminUserDataSearchComponent } from './admin/userDataSearch/userDataSearch.component';
import { AdminBankDataSearchComponent } from './admin/bankDataSearch/bankDataSearch.component';
import { AdminBankDataAddComponent } from './admin/bankDataAdd/bankDataAdd.component';
import { AdminBankDataModifyComponent } from './admin/bankDataModify/bankDataModify.component';
import { AdminBankDataModifyConfirmComponent } from './admin/bankDataModifyConfirm/bankDataModifyConfirm.component';
import { AdminBankDataModifyInfoComponent } from './admin/bankDataModifyInfo/bankDataModifyInfo.component';
import { AdminBankDataDeleteInfoComponent } from './admin/bankDataDeleteInfo/bankDataDeleteInfo.component';
import { AdminTransferFeePatternSearchComponent } from './admin/transferFeePatternSearch/transferFeePatternSearch.component';
import { AdminTransferFeePatternAddComponent } from './admin/transferFeePatternAdd/transferFeePatternAdd.component';
import { AdminTransferFeePatternModifyComponent } from './admin/transferFeePatternModify/transferFeePatternModify.component';
import { AdminTransferFeePatternModifyConfirmComponent } from './admin/transferFeePatternModifyConfirm/transferFeePatternModifyConfirm.component';
import { AdminTransferFeePatternModifyInfoComponent } from './admin/transferFeePatternModifyInfo/transferFeePatternModifyInfo.component';
import { AdminTransferFeePatternDeleteInfoComponent } from './admin/transferFeePatternDeleteInfo/transferFeePatternDeleteInfo.component';
import { AdminPasswordUpdateComponent } from './admin/passwordUpdate/passwordUpdate.component';
import { AdminPasswordUpdateInfoComponent } from './admin/passwordUpdateInfo/passwordUpdateInfo.component';
import { AdminTransferErrorSetComponent } from './admin/transferErrorSet/transferErrorSet.component';
import { AdminTransferErrorSetConfirmComponent } from './admin/transferErrorSetConfirm/transferErrorSetConfirm.component';
import { AdminTransferErrorSetInfoComponent } from './admin/transferErrorSetInfo/transferErrorSetInfo.component';
import { AdminPrepaymentUserListComponent } from './admin/prepaymentUserList/prepaymentUserList.component';
import { AdminPrepaymentUserDetailComponent } from './admin/prepaymentUserDetail/prepaymentUserDetail.component';

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from '@angular/material';
import { Code404Component } from './code404/code404.component';
@NgModule({
  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule
  ],
  declarations: [Code404Component]
})
export class DemoMaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    // user
    UserCommonComponent,
    UserLoginComponent,
    UserForgotUseridComponent,
    UserForgotUseridInfoComponent,
    UserForgotPasswordComponent,
    UserForgotPasswordInfoComponent,
    UserPasswordUpdateComponent,
    UserPasswordUpdateInfoComponent,
    UserRegisterAgreementComponent,
    UserRegisterAgreementAfterLoginComponent,
    UserRegisterStartComponent,
    UserRegisterUserAgreementComponent,
    UserRegisterUserAgreementAfterLoginComponent,
    UserRegisterInputComponent,
    UserRegisterInputConfirmComponent,
    UserRegisterInfoComponent,
    UserSignoutComponent,
    UserSignoutInfoComponent,
    UserPrePayStartComponent,
    UserPrePayListComponent,
    UserCompanyDefaultComponent,
    UserCompanyDefaultUpdateComponent,
    UserCompanyDefaultUpdateConfirmComponent,
    UserCompanyDefaultUpdateInfoComponent,
    UserCompanyAddComponent,
    UserCompanyAddInfoComponent,
    UserProfileComponent,
    UserProfileUpdateComponent,
    UserProfileUpdateConfirmComponent,
    UserProfileUpdateInfoComponent,
    UserPrePayStartConfirmComponent,
    UserPrePayStartInfoComponent,
    UserOnetimePasswordDialog,
    UserErrorDialog,
    // company
    CompanyCommonComponent,
    CompanyLoginComponent,
    CompanyMainComponent,
    CompanySearchComponent,
    CompanyModifyComponent,
    CompanyModifyConfirmComponent,
    CompanyModifyInfoComponent,
    CompanyEmployeeSearchComponent,
    CompanyEmployeeModifyComponent,
    CompanyEmployeeModifyConfirmComponent,
    CompanyEmployeeModifyInfoComponent,
    CompanyPrepaymentEmployeeComponent,
    CompanyPrepaymentDetailSearchComponent,
    CompanyPrepaymentCancelConfirmComponent,
    CompanyPrepaymentCancelComponent,
    CompanyPrepaymentCancelInfoComponent,
    CompanyCsvUploadComponent,
    CompanyCsvdownloadComponent,
    CompanyPasswordUpdateComponent,
    CompanyPasswordUpdateInfoComponent,
    CompanyUsageResultInquiryComponent,
    CompanyAccountLinkageComponent,
    CompanyAccountLinkageGanbTokenComponent,
    CompanyAccountLinkageGanbTokenCallbackComponent,
    CompanyAccountLinkageInfoComponent,
    CompanyErrorDialog,
    // admin
    AdminCommonComponent,
    AdminLoginComponent,
    AdminMainComponent,
    AdminUsageActualResultSearchComponent,
    AdminCompanySearchComponent,
    AdminCompanyModifyComponent,
    AdminCompanyModifyConfirmComponent,
    AdminCompanyModifyInfoComponent,
    AdminUserDataSearchComponent,
    AdminBankDataSearchComponent,
    AdminBankDataAddComponent,
    AdminBankDataModifyComponent,
    AdminBankDataModifyConfirmComponent,
    AdminBankDataModifyInfoComponent,
    AdminBankDataDeleteInfoComponent,
    AdminTransferFeePatternSearchComponent,
    AdminTransferFeePatternAddComponent,
    AdminTransferFeePatternModifyComponent,
    AdminTransferFeePatternModifyConfirmComponent,
    AdminTransferFeePatternModifyInfoComponent,
    AdminTransferFeePatternDeleteInfoComponent,
    AdminPasswordUpdateComponent,
    AdminPasswordUpdateInfoComponent,
    AdminTransferErrorSetComponent,
    AdminTransferErrorSetConfirmComponent,
    AdminTransferErrorSetInfoComponent,
    AdminPrepaymentUserListComponent,
    AdminPrepaymentUserDetailComponent,
    AdminErrorDialog,
    AdminMessageLog,
    AdminWarnLog
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '14px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'}),
    DemoMaterialModule
  ],
  entryComponents:[
    // user
    UserOnetimePasswordDialog,
    UserErrorDialog,
    // company
    CompanyErrorDialog,
    // admin
    AdminErrorDialog,
    AdminMessageLog,
    AdminWarnLog,
  ],
  providers: [
    // user
    UserAuthGuard,
    UserLoginComponent,
    UserAuthService,
    UserMyErrorStateMatcher,
    // company
    CompanyAuthGuard,
    CompanyLoginComponent,
    CompanyAuthService,
    CompanyMyErrorStateMatcher,
    // admin
    AdminAuthGuard,
    AdminLoginComponent,
    AdminAuthService,
    AdminMyErrorStateMatcher,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
