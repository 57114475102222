import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Url } from 'url';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})

export class UserSignoutComponent implements OnInit {

  title: string = '給与前払いサービス－退会';
  userName: string; // 氏名
  withdrawalUrl: Url; // 退会に関する注意事項
  checkboxDisabled: boolean = false; // チェックボックス選択
  loading: boolean = false; // ローディング状態

  // コンストラクタ
  constructor(
    private router: Router,
    private auth: UserAuthService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) { }

  // 画面初期化処理
  ngOnInit() { 
    sessionStorage.setItem('title', this.title); // タイトル
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    this.user_withdrawal_init();
    // 認証トークン取得
    this.auth.getToken('SPU0250', userSession['uuId'], userSession['sessionId'], 'user_withdrawal_commit');
  }

  // 退会画面の初期処理
  user_withdrawal_init() {
    this.loading = true;
    const apiUrl = environment.userUrl.userAccountUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0250',                           // 画面ID
        eventId: 'user_withdrawal_init',             // イベントID
        uuId: userSession['uuId'],                   // 共通ID
        appVersion: null,                         // アプリバージョン
        sessionId: userSession['sessionId']          // セッションID 
      }
    });
    // 送信 (前払申請情報取得APIを呼出す)
    this.http.post(
      `${apiUrl}user_withdrawal_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            // 氏名
            this.userName = commonHeaderData['userName'];
            // 退会に関する注意事項
            const withdrawalUrl = parametersData['withdrawalUrl'];
            if(withdrawalUrl){
              // 退会に関する注意事項
              this.withdrawalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(withdrawalUrl);
            }
            this.loading = false;
          } else {
            this.loading = false;
            // 請求異常の場合 
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {// エラーの場合 
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 退会ボタン押下時の処理
  onClickSignout() {
    this.loading = true;
    const apiUrl = environment.userUrl.userAccountUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'],
      'Content-Type': 'application/json',
    });
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPU0250',                             // 画面ID
        eventId: 'user_withdrawal_commit',             // イベントID
        uuId: userSession['uuId'],                     // 共通ID
        appVersion: null,                           // アプリバージョン
        sessionId: userSession['sessionId']            // セッションID 
      }
    });
    // 送信 (前払申請情報取得APIを呼出す)
    this.http.post(
      `${apiUrl}user_withdrawal_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '00000') { // 正常の場合
            // セッションストレージ
            // 共通ヘッダ
            sessionStorage.setItem('userSession', JSON.stringify(commonHeaderData));
            this.loading = false;
             // 画面遷移
             this.router.navigate(['/userSignoutInfo']); // 退会完了画面へ遷移
          } else {
            // 請求異常の場合 
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {// エラーの場合 
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 戻るボタンを押す
  onBackClick(){
    this.router.navigate(['/userPrePayList']); // 利用状況の確認画面へ
  }
}