import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { CompanyAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-companySearch',
  templateUrl: './companySearch.component.html',
  styleUrls: ['./companySearch.component.css']
})

export class CompanySearchComponent implements OnInit {

  loading: boolean = false; // ローディング状態
  loginCompanyCode: string; // デフォルト企業コード
  companyCode: string; // 企業コード
  companyName: string; // 企業名
  contractStartDate: string; // 契約開始日
  contractEndDate: string; // 契約終了日
  cancelScheduledDate: string; // 企業解約予定日
  postCode: string; // 事務所郵便番号
  address: string; // 事務所住所
  telephoneNumber: string; // 電話番号
  prepayFee: string; // サービス利用料(税込)
  transferFeeBurdenTypeName: string; // 振込手数料負担パターン名称
  prepayFeeBurdenTypeName: string; // サービス利用手数料負担パターン名称
  reasonRequiredName: string; // 前払申請理由要求名称
  // prepaymentRatioName: string; // 前払可能率名称
  prepaymentRatio: string; // 前払可能率名称
  prepayLimitPerDay: string; // 一日前払申請限度額(円)
  monthlyWorkingHours: number; // 1ヵ月の所定労働時間
  buttonDisabled: boolean = false; // 変更フラグ
  parentCode: string; // 階層１企業コード
  parentCodeName: string; // 階層１企業名
  sonCodeList = []; // 子企業コードリスト
  grandsonCodeList = []; // 孫企業コードリスト
  secretSonCode: string; // 選択した子企業
  secretSonCodeName: string; // 選択した子企業名
  sonFlag: boolean; // 子企業フラグ
  grandSonFlag: boolean; // 孫企業フラグ
  companyPartFlag: number; // 企業の種類  0:階層１企業 ,1:階層2企業, 2:階層3企業
  secretGrandsonCode: string; // 選択した孫企業
  secretGrandsonName: string; // 孫企業名

  // コンストラクタ
  constructor(
    private auth: CompanyAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '企業情報照会'); // タイトル
    this.loading = true;
    // 企業関連情報取得
    this.findCompanyDetail();
  }

  // 子企業の選択値が変更された場合
  onSelectedSonCodeChange() {
    this.loading = true;
    if (this.secretSonCode != '') {
      this.companyPartFlag = 1; // 孫企業を表示するように、「0」以外を設定
      // 孫企業リスト取得
      this.retrieveSubsidiaryList(this.secretSonCode);
      // 選択する企業情報を取得
      this.companySearch(this.secretSonCode);
      this.buttonDisabled = true;
    } else {
      this.companyPartFlag = 0; // 孫企業を表示しないように、「0」を設定
      // 選択する企業情報を取得
      this.companySearch(this.loginCompanyCode);
      this.grandSonFlag = false;
      this.buttonDisabled = false;
    }
  }

  // 孫企業の選択値が変更された場合
  onSelectedGrandSonCodeChange() {
    this.loading = true;
    this.buttonDisabled = true;
    if (this.secretGrandsonCode != '') {
      this.companySearch(this.secretGrandsonCode);
    } else {
      this.companySearch(this.secretSonCode);
      if (this.secretSonCode === this.loginCompanyCode) {
        this.buttonDisabled = false;
      }
    }
  }

  // 変更ボタンを押す
  onBtnChange() {
    if (this.loading === true) {
      return;
    }
    this.loading = true;
    const apiUrl = environment.companyUrl.companyInfoUrl;
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      // 共通ヘッダ
      commonHeader: {
        pageId: 'SPC0030',
        eventId: 'company_modify',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}company_modify`,// 企業情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：パラメータ
          const parametersData = response['parameters'];
          // parameters
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            // セッション設定
            sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            this.router.navigate(['/companyModify']);
            this.loading = false;
          } else {
            this.loading = false;
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 企業情報検索
  companySearch(companyCode: string) {
    const apiUrl = environment.companyUrl.companyInfoUrl;
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      // 共通ヘッダ
      commonHeader: {
        pageId: 'SPC0030',
        eventId: 'company_search',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}company_search`, // 企業情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            // レストラン情報：パラメータ
            sessionStorage.setItem('compParameters', JSON.stringify(parametersData));
            this.companyCode = parametersData['companyCode']; // 企業コード
            this.contractStartDate = this.auth.stringToDate(parametersData['contractStartDate']); // 契約開始日
            this.contractEndDate = this.auth.stringToDate(parametersData['contractEndDate']); // 契約終了日
            this.cancelScheduledDate = this.auth.stringToDate(parametersData['cancelScheduledDate']); // 企業解約予定日
            this.companyName = parametersData['companyName']; // 企業名
            // this.postCode = this.auth.toPostFmt(parametersData['postCode']);// 郵便番号
            this.postCode = parametersData['postCode'];// 郵便番号
            this.address = parametersData['address']; // 事務所住所
            // this.telephoneNumber = this.auth.toTelephoneNumberFmt(parametersData['telephoneNumber']); // 電話番号
            this.telephoneNumber = parametersData['telephoneNumber']; // 電話番号
            this.prepayFee = parametersData['prepayFee']; // サービス利用手数料(税込)
            this.transferFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['transferFeeBurdenTypeList'], parametersData['transferFeeBurdenType']); // 振込手数料負担パターン
            this.prepayFeeBurdenTypeName = this.auth.getValueFromListByKey(parametersData['prepayFeeBurdenTypeList'], parametersData['prepayFeeBurdenType']); // サービス利用手数料負担パターン
            // this.prepaymentRatioName = this.auth.getValueFromListByKey(parametersData['prepaymentRatioList'], parametersData['prepaymentRatio']); // 前払可能率
            this.prepaymentRatio = parametersData['prepaymentRatio']; // 前払可能率
            parametersData['reasonRequired'] ? this.reasonRequiredName = '要求する' : this.reasonRequiredName = '要求しない';
            this.prepayLimitPerDay = parametersData['prepayLimitPerDay'] ? parametersData['prepayLimitPerDay'] : 0; // 一日前払申請限度額(円)
            this.monthlyWorkingHours = parametersData['monthlyWorkingHours'] ? parametersData['monthlyWorkingHours'] : 160; // 1ヵ月の所定労働時間
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 企業関係リスト取得
  findCompanyDetail() {
    // API URL設定
    const apiUrl = environment.companyUrl.companyInfoUrl;
    // セッション取得
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    this.loginCompanyCode = compSession['companyCode']; // デフォルト企業コード
    this.companyCode = compSession['companyCode']; // 企業コード
    // ヘッダー設定
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0030',
        eventId: 'find_company_relation',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId'] // セッションID
      }
    });
    // 企業リスト取得
    this.http.post(
      `${apiUrl}find_company_relation`,
      body,
      {
        headers: headers
      }
    ).subscribe(
      response => {
        this.loading = false;
        // レストラン情報：共通ヘッダ
        const commonHeaderData = response['commonHeader'];
        // レストラン情報：パラメータ
        const parametersData = response['parameters'];
        // レストラン情報：メッセージ
        if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
          // 階層１企業リスト
          for (let i of parametersData['parentCompanyList']) {
            this.parentCodeName = `${i.companyCode} ${i.companyName}`;
            break;
          }
          this.companyPartFlag = parametersData['companyPartFlag'];
          // 本企業は階層１企業の場合
          if (this.companyPartFlag === 0) {
            // 子企業がある場合
            if (parametersData['sonCompanyList'][0]) {
              // 子企業リストを表示する
              this.sonFlag = true;
              // 子企業リスト
              this.sonCodeList = parametersData['sonCompanyList'];
              this.sonCodeList.unshift({ companyCode: '', companyName: '指定なし' }); // 子企業リスト
              this.secretSonCode = '';
            } else { // 子企業がなし場合
              // 子企業リストを表示しない
              this.sonFlag = false;
              // 子企業名
              this.secretSonCodeName = '-';
            }
            // 孫企業リストを表示しない
            this.grandSonFlag = false;
            // 孫企業名
            this.secretGrandsonName = '-';
          }
          // 本企業は子企業の場合
          if (this.companyPartFlag === 1) {
            // 子企業名
            this.secretSonCodeName = `${parametersData['sonCompanyList'][0]['companyCode']} ${parametersData['sonCompanyList'][0]['companyName']}`;
            this.secretSonCode = parametersData['sonCompanyList'][0]['companyCode'];
            // 孫企業リストがある場合 
            if (parametersData['grandsonCompanyList'][0]) {
              // 孫企業リストを表示する
              this.grandSonFlag = true;
              // 孫企業リスト
              this.grandsonCodeList = parametersData['grandsonCompanyList'];
              this.grandsonCodeList.unshift({ companyCode: '', companyName: '指定なし' }); // 孫企業リスト
              this.secretGrandsonCode = '';
            } else { // 孫企業リストがなし場合
              // 孫企業リストを表示しない
              this.grandSonFlag = false;
              // 孫企業名
              this.secretGrandsonName = '-';
            }
          }
          // 本企業は孫企業の場合
          if (this.companyPartFlag === 2) {
            // 子企業リストを表示しない
            this.sonFlag = false;
            // 孫企業リストを表示しない
            this.grandSonFlag = false;
            // 子企業名
            this.secretSonCodeName = `${parametersData['sonCompanyList'][0]['companyCode']} ${parametersData['sonCompanyList'][0]['companyName']}`;
            // 孫企業名
            this.secretGrandsonName = `${parametersData['grandsonCompanyList'][0]['companyCode']} ${parametersData['grandsonCompanyList'][0]['companyName']}`;
          }
          this.companySearch(this.companyCode);
        } else { // 異常の場合
          this.auth.resDialog(commonHeaderData);
        }
      },
      error => { // エラーの場合
        this.loading = false;
        this.auth.errDialog(error);
      }
    );
  }

  // 子会社リスト取得
  retrieveSubsidiaryList(companyCode: string) {
    const apiUrl = environment.companyUrl.companyInfoUrl;
    const compSession = JSON.parse(sessionStorage.getItem('compSession')); // compSession
    // header
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // body
    const body = JSON.stringify({
      // 共通ヘッダ
      commonHeader: {
        pageId: 'SPC0030',
        eventId: 'retrieve_subsidiary_list',
        companyCode: compSession['companyCode'], // 企業コード
        managerId: compSession['managerId'], // 利用企業管理者ID
        sessionId: compSession['sessionId']
      },
      // パラメータ
      parameters: {
        companyCode: companyCode // 企業コード
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}retrieve_subsidiary_list`,// 企業情報取得
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          // parameters
          const parametersData = response['parameters'];
          if (commonHeaderData['messageCode'] === '01000') { // 正常の場合
            // 孫企業リストがある場合 
            if (parametersData['sonCompanyCodeList'][0]) {
              this.grandSonFlag = true;
              this.grandsonCodeList = parametersData['sonCompanyCodeList']; // 孫企業リスト
              let grandsonCodeList_empty = { companyCode: '', companyName: '指定なし' };
              this.grandsonCodeList.unshift(grandsonCodeList_empty);
              this.secretGrandsonCode = '';
            } else {
              this.grandSonFlag = false;
              // 孫企業名
              this.secretGrandsonName = '-';
            }
          } else {
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }
}
