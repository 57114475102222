import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DeprecatedDecimalPipe } from '@angular/common';

// 日付型フォーマット
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
  },
  display: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-adminCompanyModify',
  templateUrl: './companyModify.component.html',
  styleUrls: ['./companyModify.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class AdminCompanyModifyComponent implements OnInit {

  loading: boolean = false; // ローディング状態
  parentCode: string; // 親企業コード
  parentName: string; // 親企業名
  parent: string; // 親企業
  // postCodeStr: string; // 事務所郵便番号（表示用）
  // telephoneNumberStr: string; // 電話番号（表示用）
  isCancelled: boolean = false; // 解約フラグ
  transferFeeBurdenType: string; // 振込手数料負担パターン
  prepayFeeStr: string; // 利用手数料(税込)円表示項目
  prepayFeeBurdenType: string; // 前払手数料負担パターン
  closingInProcess: boolean = false; // 締め処理中フラグ
  reasonRequired: boolean = false; // 前払申請理由要求フラグ
  // prepaymentRatio: string; // 前払可能額掛目
  transferFeeBurdenTypeList = []; // 振込手数料負担パターンリスト
  prepayFeeBurdenTypeList = []; // 前払手数料負担パターンリスト
  // prepaymentRatioList = []; // 前払可能額掛目リスト
  companyModifyForm: FormGroup; // 画面フォーム

  // コンストラクタ
  constructor(
    private http: HttpClient,
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private router: Router,
    dateAdapter: DateAdapter<NativeDateAdapter>
  ) {
    dateAdapter.setLocale('ja');
  }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '企業情報変更'); // タイトル
    this.loading = true;
    // セッション取得
    const companyParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    this.parentCode = companyParameters['parentCode']; // 親企業コード
    this.parentName = companyParameters['parentName']; // 親企業名
    this.parent = `${this.parentCode} ${this.parentName}`; // 親企業
    // 解約フラグ false: いいえ(未解約) true: 解約済
    this.isCancelled = !companyParameters['isCancelled']; // 解約フラグ 
    this.transferFeeBurdenType = companyParameters['transferFeeBurdenType']; // 振込手数料負担パターン
    this.prepayFeeBurdenType = companyParameters['prepayFeeBurdenType']; // 利用料負担パターン
    // 締め処理中フラグ false: いいえ(処理) true: 処理中
    this.closingInProcess = !companyParameters['closingInProcess']; // 締め処理中フラグ
    // 前払申請理由要求フラグ false: 要求しない true: 要求する
    this.reasonRequired = !companyParameters['reasonRequired']; // 前払申請理由要求フラグ
    // this.prepaymentRatio = companyParameters['prepaymentRatio']; // 前払可能額掛目
    this.transferFeeBurdenTypeList = companyParameters['transferFeeBurdenTypeList']; // 振込手数料負担パターンリスト 
    this.prepayFeeBurdenTypeList = companyParameters['prepayFeeBurdenTypeList']; // 前払手数料負担パターンリスト
    // this.prepaymentRatioList = companyParameters['prepaymentRatioList']; // 前払可能額掛目リスト
    // 入力可否のチェック
    this.companyModifyForm = this.fb.group({
      'companyCode': [{value: companyParameters['companyCode'], disabled: true}],
      'companyName': [companyParameters['companyName'], Validators.required],
      'postCode': [companyParameters['postCode'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]],
      'address': [companyParameters['address'], [Validators.required]],
      'telephoneNumber': [companyParameters['telephoneNumber'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(10)]],
      'contractStartDate': [companyParameters['contractStartDate'], [Validators.required, Validators.minLength(8)]],
      'contractEndDate': [companyParameters['contractEndDate'], [Validators.minLength(8)]],
      'cancelScheduledDate': [companyParameters['cancelScheduledDate'], [Validators.minLength(8)]],
      'prepayFee': [companyParameters['prepayFee'], Validators.pattern('^[0-9]+$')],
      'closingInProcess': [this.closingInProcess],
      'reasonRequired': [this.reasonRequired],
      'isCancelled': [this.isCancelled],
      'prepayLimitPerDay': [parseInt(companyParameters['prepayLimitPerDay'] ? companyParameters['prepayLimitPerDay'] : 0) / 1000, Validators.pattern('^[0-9]*')],
      'monthlyWorkingHours': [companyParameters['monthlyWorkingHours'] ? companyParameters['monthlyWorkingHours'] : 160, [Validators.required, Validators.pattern('^[0-9]*'), Validators.min(1)]], // 1ヵ月の所定労働時間
      'prepaymentRatio': [companyParameters['prepaymentRatio'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(1), Validators.max(100)]]
    });
    // 郵便番号はフォーカスアウト処理（編集処理）
    // this.postCodeStr = this.auth.toPostFmt(this.companyModifyForm.get('postCode').value);
    // 利用手数料(税込)(円)（編集処理）
    this.prepayFeeStr = this.transform(this.companyModifyForm.get('prepayFee').value); 
    // 電話番号はフォーカスアウト処理（編集処理）
    // this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(this.companyModifyForm.get('telephoneNumber').value);
    this.loading = false;
  }

  // 確認ボタン押下
  onSysCompayModifyConfirm() {
    if (this.loading === true) {
      return;
    }
    // フォームチェック
    if (this.companyModifyForm.valid) {
      this.loading = true;
      const apiUrl = environment.adminUrl.companyInfoServiceApiUrl;
      // セッションを取得する
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession')); // adminSession
      // ヘッダ設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // ボディー作成
      const body = JSON.stringify({
        commonHeader: {
          pageId: 'SPM0050', // 画面ID
          eventId: 'admin_company_modify_confirm', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          companyCode: this.companyModifyForm.get('companyCode').value, // 企業コード
          companyName: this.companyModifyForm.get('companyName').value, // 企業名
          parentCode: this.parentCode, // 親企業コード
          parentName: this.parentName, // 親企業名
          contractStartDate: moment(this.companyModifyForm.get('contractStartDate').value).format('YYYYMMDD'), // 契約開始日
          // contractEndDate: moment(this.companyModifyForm.get('contractEndDate').value).format('YYYYMMDD'), // 契約終了日 
          contractEndDate: this.companyModifyForm.get('contractEndDate').value?moment(this.companyModifyForm.get('contractEndDate').value).format('YYYYMMDD'):null, // 契約終了日 
          cancelScheduledDate: this.companyModifyForm.get('cancelScheduledDate').value?moment(this.companyModifyForm.get('cancelScheduledDate').value).format('YYYYMMDD'):null, // 契約終了日 
          // 解約フラグ false: いいえ(未解約) true: 解約済
          isCancelled: !this.isCancelled, // 解約フラグ
          postCode: this.companyModifyForm.get('postCode').value === '' ? null : this.companyModifyForm.get('postCode').value, // 事務所郵便番号 
          address: this.companyModifyForm.get('address').value === '' ? null : this.companyModifyForm.get('address').value, // 事務所住所 
          telephoneNumber: this.companyModifyForm.get('telephoneNumber').value === '' ? null : this.companyModifyForm.get('telephoneNumber').value, // 電話番号 
          prepayFee: this.companyModifyForm.get('prepayFee').value === '' ? '0' : new Number(this.companyModifyForm.get('prepayFee').value), // 利用手数料(円) 
          transferFeeBurdenType: this.transferFeeBurdenType, // 振込手数料負担パターン 
          prepayFeeBurdenType: this.prepayFeeBurdenType, // 前払いサービス利用料負担パターン
          // 締め処理中フラグ false: いいえ(処理) true: 処理中
          closingInProcess: !this.closingInProcess, // 締め処理中フラグ 
          // 前払申請理由要求フラグ false: 要求しない true: 要求する
          reasonRequired: !this.reasonRequired, // 前払申請理由要求フラグ 
          prepaymentRatio: this.companyModifyForm.get('prepaymentRatio').value * 1, // 前払可能額掛目 
          transferFeeBurdenTypeList: this.transferFeeBurdenTypeList, // 振込手数料負担パターンリスト
          prepayFeeBurdenTypeList: this.prepayFeeBurdenTypeList, // 前払手数料負担パターンリスト
          prepayLimitPerDay: this.companyModifyForm.get('prepayLimitPerDay').value * 1000, // 一日前払申請限度額(円)
          monthlyWorkingHours: parseInt(this.companyModifyForm.get('monthlyWorkingHours').value), // 1ヵ月の所定労働時間
        }
      });
      // 初期選択企業設定APIを呼び出す
      this.http.post(
        `${apiUrl}admin_company_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：レスポンスメッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // 当前画面でパラメータセッション設定
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/adminCompanyModifyConfirm']); // 企業情報変更確認画面遷移
            } else {
              this.loading = false;
              // エラーの場合
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {// 異常処理
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  changePrepayLimitPerDay(){
    if(this.companyModifyForm.get('prepayLimitPerDay').valid){
      this.companyModifyForm.get('prepayLimitPerDay').setValue(new Number(this.companyModifyForm.get('prepayLimitPerDay').value) + '');
    }
  }

  // 戻るボタン押下
  onBackClick() {
    this.loading = true;
    this.router.navigate(['/adminCompanySearch']);
  }

  // 金額型項目フォマード処理Function(XXXXXXXXX → XXX,XXX,XXX)
  transform(value: any, digits?: string): string {
    if(this.companyModifyForm.get('prepayFee').valid){
      return new DeprecatedDecimalPipe('ja').transform(value, digits);
    }
    return value;
  }

  // 利用手数料(税込)(円)編集を行うFunction(XXX,XXX,XXX)
  toPrepayFeeNumberFmt() {
    if(this.companyModifyForm.get('prepayFee').valid){
      this.companyModifyForm.get('prepayFee').setValue(new Number(this.companyModifyForm.get('prepayFee').value) + '');
      let str = this.companyModifyForm.get('prepayFee').value;
      this.prepayFeeStr = this.transform(str);
    }
  }

  // 利用手数料(税込)(円)はフォーカスオン処理（編集を解除するFunction）
  offPrepayFeeNumberFmt() {
    if(this.companyModifyForm.get('prepayFee').valid){
      this.prepayFeeStr = new Number(this.companyModifyForm.get('prepayFee').value).toString();
    }
  }

  // 企業名のエラーメッセージ
  getCompanyNameErrorMessage(): any {
    return this.companyModifyForm.get('companyName').hasError('required') ? '企業名を入力してください' :
           '';
  };

  // 郵便番号のエラーメッセージ
  getPostCodeErrorMessage(): any {
    return this.companyModifyForm.get('postCode').hasError('required') ? '郵便番号を入力してください' :
           this.companyModifyForm.get('postCode').hasError('pattern') ? '郵便番号を半角数字で入力してください' :
           this.companyModifyForm.get('postCode').hasError('minlength') ? '正しい郵便番号を入力してください' :
           '';
  };

  // 事務所住所のエラーメッセージ
  getAddressErrorMessage(): any {
    return this.companyModifyForm.get('address').hasError('required') ? '事務所住所を入力してください' :
           '';
  };

  // 電話番号のエラーメッセージ
  getTelephoneNumberErrorMessage(): any {
    return this.companyModifyForm.get('telephoneNumber').hasError('required') ? '電話番号を入力してください' :
           this.companyModifyForm.get('telephoneNumber').hasError('pattern') ? '電話番号を半角数字で入力してください' :
           this.companyModifyForm.get('telephoneNumber').hasError ? '正しい電話番号を入力してください' :
           '';
  };

  // 契約開始日のエラーメッセージ
  getContractStartDateErrorMessage(): any {
    return this.companyModifyForm.get('contractStartDate').hasError('required') ? '契約開始日を入力してください' :
           this.companyModifyForm.get('contractStartDate').hasError ? '正しい契約開始日を入力してください' :
           '';
  };

  // 契約日終了日のエラーメッセージ
  getContractEndDateErrorMessage(): any {
    return this.companyModifyForm.get('contractEndDate').hasError ? '正しい契約終了日を入力してください' :
           '';
  };

  // 企業解約予定日のエラーメッセージ
  getCancelScheduledDateErrorMessage(): any {
      return this.companyModifyForm.get('cancelScheduledDate').hasError ? '正しい企業解約予定日を入力してください' :
             '';
  };

  // 利用手数料(円)のエラーメッセージ
  getPrepayFeeErrorMessage(): any {
    return this.companyModifyForm.get('prepayFee').hasError('pattern') ? '利用手数料を半角数字で入力してください' :
           '';
  };

  // 一日前払申請上限額のエラーメッセージ
  getPrepayLimitPerDayErrorMessage(): any {
    return this.companyModifyForm.get('prepayLimitPerDay').hasError('pattern') ? '上限額を半角数字入力してください' :
           '';
  };
  
  // 1ヵ月の所定労働時間のエラーメッセージ
  getMonthlyWorkingHoursErrorMessage(): any {
    return this.companyModifyForm.get('monthlyWorkingHours').hasError('required') ? '所定労働時間を入力してください' :
           this.companyModifyForm.get('monthlyWorkingHours').hasError('pattern') ? '所定労働時間を半角数字で入力してください' :
           this.companyModifyForm.get('monthlyWorkingHours').hasError('min') ? '所定労働時間を0以外の数字で入力してください' :
           '';
  }
  // 前払可能率のエラーメッセージ
  getPrepaymentRatioErrorMessage(): any {
    return this.companyModifyForm.get('prepaymentRatio').hasError('required') ? '前払可能率を入力してください' :
      this.companyModifyForm.get('prepaymentRatio').hasError('pattern') ? '前払可能率を半角数字で入力してください' :
        this.companyModifyForm.get('prepaymentRatio').hasError('min') ? '前払可能率を1-100の数字で入力してください' :
        this.companyModifyForm.get('prepaymentRatio').hasError('max') ? '前払可能率を1-100の数字で入力してください' :
          '';
  }

  // // 郵便番号編集を行うFunction(XXX-XXXX)
  // toPostFmt() {
  //   this.postCodeStr = this.auth.toPostFmt(this.companyModifyForm.get('postCode').value);
  // }

  // // 郵便番号はフォーカスオン処理（郵便番号編集を解除するFunction）
  // offPostFmt() {
  //   this.postCodeStr = this.auth.offPostFmt(this.companyModifyForm.get('postCode').value);
  // }

  // // 電話番号編集を行うFunction(XXX-XXXX-XXXX)
  // toTelephoneNumberFmt() {
  //   this.telephoneNumberStr = this.auth.toTelephoneNumberFmt(this.companyModifyForm.get('telephoneNumber').value);
  // }

  // // 電話番号はフォーカスオン処理（電話番号編集を解除するFunction）
  // offTelephoneNumberFmt() {
  //   this.telephoneNumberStr = this.auth.offTelephoneNumberFmt(this.companyModifyForm.get('telephoneNumber').value);
  // }
}