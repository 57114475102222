import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserMyErrorStateMatcher } from '../common/common.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-userProfileUpdate',
  templateUrl: './userProfileUpdate.component.html',
  styleUrls: ['./userProfileUpdate.component.css']
})

export class UserProfileUpdateComponent implements OnInit {

  title: string = '給与前払いサービス－ユーザー情報の変更';
  loading: boolean = false; // ローディング状態
  lastNameInKanji: string; // 姓
  firstNameInKanji: string; // 名
  lastNameFurigana: string; // 姓（カナ）
  firstNameFurigana: string; // 名（カナ）
  gender: string; // 性別
  // postCodeStr: string; // 郵便番号（表示用）
  mailAddressChecked: boolean = false; // メールアドレス変更チェックフラグ
  isMailAddressChanged: string; // メールアドレス変更フラグ
  secretQuestionChecked: boolean = false; // 秘密の質問変更チェックフラグ
  isSecretQuestionChanged: string; // 秘密の質問変更フラグ
  secretQuestionList; // 秘密の質問リスト
  secretQuestionNo: string; // 選択した秘密の質問番号
  secretQuestion: string; // 選択した秘密の質問
  uuId; // uuId
  sessionId; // sessionId
  userProfileForm: FormGroup;
  isSubmitFlg: boolean = false; // 変更ボタン押下FLG

  // コンストラクタ
  constructor(
    private auth: UserAuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // セッション取得
    // ヘッダー
    const userSession = JSON.parse(sessionStorage.getItem('userSession')); // userSession
    // パラメータ
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
    this.lastNameInKanji = userParameters['name']['lastNameInKanji']; // 姓
    this.firstNameInKanji = userParameters['name']['firstNameInKanji']; // 名
    this.lastNameFurigana = userParameters['name']['lastNameFurigana']; // 姓（カナ）
    this.firstNameFurigana = userParameters['name']['firstNameFurigana']; // 名（カナ）
    this.gender = userParameters['gender']; // 性別
    if(!userParameters['isSecretQuestionChanged'] || userParameters['isSecretQuestionChanged'] === '0'){
      this.isSecretQuestionChanged = '0'; // 秘密の質問変更フラグ 1：変更あり、0：変更なし
      this.secretQuestionChecked = false; // 秘密の質問変更チェックフラグ
    } else {
      this.isSecretQuestionChanged = '1'; // 秘密の質問変更フラグ 1：変更あり、0：変更なし
      this.secretQuestionChecked = true; // 秘密の質問変更チェックフラグ
    }
    if(!userParameters['isMailAddressChanged'] || userParameters['isMailAddressChanged'] === '0'){
      this.isMailAddressChanged = '0'; // メールアドレス変更フラグ 1：変更あり、0：変更なし
      this.mailAddressChecked = false; // メールアドレス変更チェックフラグ
    } else {
      this.isMailAddressChanged = '1'; // メールアドレス変更フラグ 1：変更あり、0：変更なし
      this.mailAddressChecked = true; // メールアドレス変更チェックフラグ
    }
    this.secretQuestionList = userParameters['secretQuestionList']; // 秘密の質問リスト
    this.secretQuestionNo = userParameters['secretQuestionNo']; // 選択した秘密の質問番号
    this.uuId = userSession['uuId']; // 共通ID
    this.sessionId = userSession['sessionId']; // セッションID
    // 入力可否のチェック
    this.userProfileForm = this.fb.group({
      'lastNameInKanji': [this.lastNameInKanji, [Validators.required, Validators.pattern('^[\u3000-\u301C\u3041-\u3093\u309B-\u309E\u30A1-\u30F6\u30FB-\u30FD\u4E00-\u9FA0\uFF01-\uFF5D]+$')]],
      'firstNameInKanji': [this.firstNameInKanji, [Validators.required, Validators.pattern('^[\u3000-\u301C\u3041-\u3093\u309B-\u309E\u30A1-\u30F6\u30FB-\u30FD\u4E00-\u9FA0\uFF01-\uFF5D]+$')]],
      'lastNameFurigana': [this.lastNameFurigana, [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]],
      'firstNameFurigana': [this.firstNameFurigana, [Validators.required, Validators.pattern('^[\u30A1-\u30F6\u30FC]+$')]],
      'postCode': [userParameters['address']['postCode'], [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]],
      'address': [userParameters['address']['address'], Validators.required],
      'mailAddress1': [{value: userParameters['mailAddress1'], disabled: !this.mailAddressChecked}],
      'mailAddress1Confirm': [{value: userParameters['mailAddress1'], disabled: !this.mailAddressChecked}],
      'mailAddress2': [{value: userParameters['mailAddress2'], disabled: !this.mailAddressChecked}],
      'mailAddress2Confirm': [{value: userParameters['mailAddress2'], disabled: !this.mailAddressChecked}],
      'mobileNumber': [userParameters['mobileNumber'], [Validators.pattern('^[0-9]+$'), Validators.minLength(11)]],
      'telphoneNumber' :[userParameters['telphoneNumber'], [Validators.pattern('^[0-9]+$'), Validators.minLength(10)]],
      'secretQuestionAnswer' :[{value: !this.secretQuestionChecked ? '' : userParameters['secretQuestionAnswer'], disabled: !this.secretQuestionChecked}]
    });
    // 郵便番号はフォーカスアウト処理（編集処理）
    // this.postCodeStr = this.auth.toPostFmt(this.userProfileForm.get('postCode').value);
  }
  
  // 郵便番号編集を行うFunction(XXX-XXXX)
  // toPostFmt(){
  //   this.postCodeStr = this.auth.toPostFmt(this.userProfileForm.get('postCode').value);
  // }

  // // 郵便番号はフォーカスオン処理（郵便番号編集を解除するFunction）
  // offPostFmt(){
  //   this.postCodeStr = this.auth.offPostFmt(this.userProfileForm.get('postCode').value);
  // }
  
  // 秘密の質問を変更
  onQuestionCheckChange() {
    if(this.userProfileForm.valid){
      this.isSubmitFlg = false; // 変更ボタン押下
    }
    if(!this.secretQuestionChecked){
      this.isSecretQuestionChanged = '0'; // 秘密の質問変更フラグ 1：変更あり、0：変更なし
      this.userProfileForm.get('secretQuestionAnswer').disable({'onlySelf': true});
    } else {
      this.isSecretQuestionChanged = '1'; // 秘密の質問変更フラグ 1：変更あり、0：変更なし
      this.userProfileForm.get('secretQuestionAnswer').enable({'onlySelf': true});
    }
  }

  // メールアドレスを変更
  onMailCheckChange() {
    if(this.userProfileForm.valid){
      this.isSubmitFlg = false; // 変更ボタン押下
    }
    if(!this.mailAddressChecked){
      this.isMailAddressChanged = '0'; // メールアドレス変更フラグ 1：変更あり、0：変更なし
      this.userProfileForm.get('mailAddress1').disable({'onlySelf': true});
      this.userProfileForm.get('mailAddress1Confirm').disable({'onlySelf': true});
      this.userProfileForm.get('mailAddress2').disable({'onlySelf': true});
      this.userProfileForm.get('mailAddress2Confirm').disable({'onlySelf': true});
    } else {
      this.isMailAddressChanged = '1'; // メールアドレス変更フラグ 1：変更あり、0：変更なし
      this.userProfileForm.get('mailAddress1').enable({'onlySelf': true});
      this.userProfileForm.get('mailAddress1Confirm').enable({'onlySelf': true});
      this.userProfileForm.get('mailAddress2').enable({'onlySelf': true});
      this.userProfileForm.get('mailAddress2Confirm').enable({'onlySelf': true});
    }
  }
  
  //「変更」ボタン押下処理
  onUpdate() {
    if (this.loading){
      return false;
    }
    this.isSubmitFlg = true; // 変更ボタン押下
    const apiUrl = environment.userUrl.userInfoServiceApiUrl;
    // 携帯電話番号、固定電話番号がいずれか入力チェック
    this.hasTelNumberNullValidator();
    // 携帯電話番号チェック
    this.isMobileNumberValidator();
    // 固定電話番号チェック
    this.isTelNumberValidator();
    // メールアドレス１の一致チェック
    this.mailAddress1EqualValidator();
    // メールアドレス２の一致チェック
    this.mailAddress2EqualValidator()
    // メールアドレス１、２の一致チェック
    this.mailAddress12EqualValidator();
    // 選択した秘密の質問
    for(let question of this.secretQuestionList){
      if(this.secretQuestionNo === question.secretQuestionNo){
        this.secretQuestion = question.secretQuestion;
      }
    }
    if(this.userProfileForm.valid){
      this.loading = true;
      // パラメータ
      const userParameters = JSON.parse(sessionStorage.getItem('userParameters')); // userParameters
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader:{
          pageId: 'SPU0190',
          eventId: 'user_information_confirm',
          uuId: this.uuId,
          appVersion: null,
          sessionId: this.sessionId
        },
        // パラメータ
        parameters:{ 
          lastNameInKanji: this.userProfileForm.get('lastNameInKanji').value, // 姓 
          firstNameInKanji: this.userProfileForm.get('firstNameInKanji').value, // 名
          lastNameFurigana: this.userProfileForm.get('lastNameFurigana').value, // セイ
          firstNameFurigana: this.userProfileForm.get('firstNameFurigana').value, // メイ
          gender: this.gender, // 性別
          postCode: this.userProfileForm.get('postCode').value, // 郵便番号
          address: this.userProfileForm.get('address').value,  // 住所
          mobileNumber: this.userProfileForm.get('mobileNumber').value, // 携帯電話番号
          telphoneNumber: this.userProfileForm.get('telphoneNumber').value, // 携帯電話番号
          isMailAddressChanged: this.isMailAddressChanged, // メールアドレス変更フラグ
          mailAddress1: this.isMailAddressChanged === '1' ? this.userProfileForm.get('mailAddress1').value : userParameters['mailAddress1'], // メールアドレス１
          mailAddress2: this.isMailAddressChanged === '1' ? this.userProfileForm.get('mailAddress2').value : userParameters['mailAddress2'], // メールアドレス２
          isSecretQuestionChanged: this.isSecretQuestionChanged,
          secretQuestionNo: this.isSecretQuestionChanged === '1' ? this.secretQuestionNo : userParameters['secretQuestionNo'], // 選択した秘密の質問番号
          secretQuestionAnswer: this.isSecretQuestionChanged === '1' ? this.userProfileForm.get('secretQuestionAnswer').value : '' // 秘密の質問の答え
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}user_information_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レストラン情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レストラン情報：パラメータ
            const parametersData = response['parameters'];
            // セッション設定
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            // レストラン情報：メッセージ
            if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/userProfileUpdateConfirm']); // ユーザー情報変更確認画面へ
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  };

  // 戻るボタンを押す
  onBackClick(){
    if (this.loading){
      return false;
    }
    this.router.navigate(['/userProfile']); // ユーザー情報の確認画面へ
  }

  errorStateMacher(){
    return new UserMyErrorStateMatcher();
  }

  // 姓のエラーメッセージ
  getLastNameInKanjiErrorMessage(){
    return this.userProfileForm.get('lastNameInKanji').hasError('required') ? '姓を入力してください' :
           this.userProfileForm.get('lastNameInKanji').hasError('pattern') ? '姓を全角文字で入力してください' :
           '';
  };

  // 名のエラーメッセージ
  getFirstNameInKanjiErrorMessage(){
    return this.userProfileForm.get('firstNameInKanji').hasError('required') ? '名を入力してください' :
           this.userProfileForm.get('firstNameInKanji').hasError('pattern') ? '名を全角文字で入力してください' :
           '';
  };

  // 姓（カナ）のエラーメッセージ
  getLastNameFuriganaErrorMessage(){
    return this.userProfileForm.get('lastNameFurigana').hasError('required') ? '姓（カナ）を入力してください' :
           this.userProfileForm.get('lastNameFurigana').hasError('pattern') ? '姓（カナ）を全角カナで入力してください' :
           '';
  };

  // 名（カナ）のエラーメッセージ
  getFirstNameFuriganaErrorMessage(){
    return this.userProfileForm.get('firstNameFurigana').hasError('required') ? '名（カナ）を入力してください' :
           this.userProfileForm.get('firstNameFurigana').hasError('pattern') ? '名（カナ）を全角カナで入力してください' :
           '';
  };

  // 郵便番号のエラーメッセージ
  getPostCodeErrorMessage(){
    return this.userProfileForm.get('postCode').hasError('required') ? '郵便番号を入力してください' :
           this.userProfileForm.get('postCode').hasError('pattern') ? '郵便番号を半角数字で入力してください' :
           this.userProfileForm.get('postCode').hasError('minlength') ? '正しい郵便番号を入力してください' :
           '';
  };

  // 住所のエラーメッセージ
  getAddressErrorMessage(){
    return this.userProfileForm.get('address').hasError('required') ? '住所を入力してください' :
           '';
  };

  // 携帯電話番号のエラーメッセージ
  getMobileNumberErrorMessage(){
    return this.userProfileForm.get('mobileNumber').hasError('pattern') ? '携帯電話番号を半角数字で入力してください' :
           this.userProfileForm.get('mobileNumber').hasError('minlength') ? '正しい携帯電話番号を入力してください' :
           this.userProfileForm.hasError('hasTelNumberNull') ? '携帯電話番号、固定電話番号はいずれか入力してください。' :
           this.userProfileForm.get('mobileNumber').hasError('isNotMobileNumber') ? '携帯電話番号ではありません、お確かめください。' :
           '';
  };

  // 固定電話番号のエラーメッセージ
  getTelphoneNumberErrorMessage(){
    return this.userProfileForm.get('telphoneNumber').hasError('pattern') ? '固定電話番号を半角数字で入力してください' :
           this.userProfileForm.get('telphoneNumber').hasError('minlength') ? '正しい固定電話番号を入力してください' :
           this.userProfileForm.hasError('hasTelNumberNull') ? '携帯電話番号、固定電話番号はいずれか入力してください。' :
           this.userProfileForm.get('telphoneNumber').hasError('isNotTelNumber') ? '固定電話番号ではありません、お確かめください。' :
           '';
  };

  // 携帯電話番号、固定電話番号がいずれか入力チェック
  hasTelNumberNullValidator() {
    const mobileNumber = this.userProfileForm.get('mobileNumber');
    const telphoneNumber = this.userProfileForm.get('telphoneNumber');
    if(mobileNumber.value === '' && telphoneNumber.value === ''){
      this.userProfileForm.setErrors({ hasTelNumberNull: true});
    }
  };

  // 携帯電話番号チェック
  isMobileNumberValidator() {
    const mobileNumber = this.userProfileForm.get('mobileNumber').value;
    if(mobileNumber && !this.auth.isMobileNumber(mobileNumber)) {
      this.userProfileForm.get('mobileNumber').setErrors({ isNotMobileNumber: true});
    }
  };

  // 固定電話番号チェック
  isTelNumberValidator() {
    const telphoneNumber = this.userProfileForm.get('telphoneNumber').value;
    if(telphoneNumber && this.auth.isMobileNumber(telphoneNumber)) {
      this.userProfileForm.get('telphoneNumber').setErrors({ isNotTelNumber: true});
    }
  };

  // メールアドレス１のエラーメッセージ
  getMailAddress1ErrorMessage(){
    return this.userProfileForm.get('mailAddress1').hasError('required') ? 'メールアドレスを入力してください' :
           this.userProfileForm.get('mailAddress1').hasError('email') ? '有効なメールではありません' :
           this.userProfileForm.hasError('mailAddress1Equal') ? 'メールアドレス１が不一致です。' :
           this.userProfileForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス１（再入力）のエラーメッセージ
  getMailAddress1ConfirmErrorMessage() {
    return this.userProfileForm.get('mailAddress1Confirm').hasError('required') ? 'メールアドレスを入力してください' :
           this.userProfileForm.get('mailAddress1Confirm').hasError('email') ? '有効なメールではありません' :
           this.userProfileForm.hasError('mailAddress1Equal') ? 'メールアドレス１が不一致です。' :
           this.userProfileForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス１の一致チェック
  mailAddress1EqualValidator() {
    const mailAddress1 = this.userProfileForm.get('mailAddress1');
    const mailAddress1Confirm = this.userProfileForm.get('mailAddress1Confirm');
    if(mailAddress1 != null && mailAddress1Confirm != null
      && mailAddress1.valid && mailAddress1Confirm.valid
      && mailAddress1.value !== mailAddress1Confirm.value){
        this.userProfileForm.setErrors({ mailAddress1Equal: true });
    }
  };

  // メールアドレス２のエラーメッセージ
  getMailAddress2ErrorMessage() {
    return this.userProfileForm.get('mailAddress2').hasError('required') ? 'メールアドレスを入力してください' :
           this.userProfileForm.get('mailAddress2').hasError('email') ? '有効なメールではありません' :
           this.userProfileForm.hasError('mailAddress2Equal') ? 'メールアドレス２が不一致です。' :
           this.userProfileForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス２（再入力）のエラーメッセージ
  getMailAddress2ConfirmErrorMessage() {
    return this.userProfileForm.get('mailAddress2Confirm').hasError('required') ? 'メールアドレスを再入力してください' :
           this.userProfileForm.get('mailAddress2Confirm').hasError('email') ? '有効なメールではありません' :
           this.userProfileForm.hasError('mailAddress2Equal') ? 'メールアドレス２が不一致です。' :
           this.userProfileForm.hasError('mailAddress12Equal') ? 'メールアドレス１、２が一致です。' :
           '';
  };

  // メールアドレス２の一致チェック
  mailAddress2EqualValidator(){
    const mailAddress2 = this.userProfileForm.get('mailAddress2') as FormControl;
    const mailAddress2Confirm = this.userProfileForm.get('mailAddress2Confirm') as FormControl;
    if( mailAddress2 != null && mailAddress2Confirm != null
      && mailAddress2.valid && mailAddress2Confirm.valid
      && mailAddress2.value !== mailAddress2Confirm.value ) {
        this.userProfileForm.setErrors({ mailAddress2Equal: true });
    }
  };

  // メールアドレス１、２の一致チェック
  mailAddress12EqualValidator(): any {
    const mailAddress1 = this.userProfileForm.get('mailAddress1') as FormControl;
    const mailAddress1Confirm = this.userProfileForm.get('mailAddress1Confirm') as FormControl;
    const mailAddress2 = this.userProfileForm.get('mailAddress2') as FormControl;
    const mailAddress2Confirm = this.userProfileForm.get('mailAddress2Confirm') as FormControl;
    if( mailAddress1 != null && mailAddress1Confirm != null && mailAddress2 != null && mailAddress2Confirm != null
      && mailAddress1.valid && mailAddress1Confirm.valid && mailAddress2.valid && mailAddress2Confirm.valid
      && mailAddress1.value === mailAddress1Confirm.value === mailAddress2.value === mailAddress2Confirm.value ) {
      this.userProfileForm.setErrors({ mailAddress12Equal: true});
    }
  };

  // 秘密の質問の答えのエラーメッセージ
  getSecretQuestionAnswerErrorMessage(){
    return this.userProfileForm.get('secretQuestionAnswer').hasError('required') ? '秘密の質問の答えを入力してください' :
          //  this.userProfileForm.get('secretQuestionAnswer').hasError('minlength') ? '秘密の質問の答えを２桁以上でご入力してください' :
           '';
  };
}