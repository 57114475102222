import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employeeModifyInfo',
  templateUrl: './employeeModifyInfo.component.html',
  styleUrls: ['./employeeModifyInfo.component.css']
})
export class CompanyEmployeeModifyInfoComponent implements OnInit {

  title: string = '従業員情報変更が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() { 
    sessionStorage.setItem('title', '従業員情報変更完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/companyEmployeeSearch']); // SPC0070 従業員情報照会画面へ
  }
}
