import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { environment } from './../../../environments/environment.dev';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
  },
  display: {
    dateInput: 'YYYY年MM月DD日',// YYYY/MM/DD
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-registerStart',
  templateUrl: './registerStart.component.html',
  styleUrls: ['./registerStart.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class UserRegisterStartComponent implements OnInit {

  constructor(
    private auth: UserAuthService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    dateAdapter: DateAdapter<NativeDateAdapter>
  ) {
    dateAdapter.setLocale('ja');
  }

  registerForm: FormGroup;
  title: string = '給与前払いサービス－会員登録基本情報入力';
  loading: boolean = false; // ローディング状態

  ngOnInit() {
    this.registerForm = this.fb.group({
      'companyCode': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
      'employeeNumber': ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
      'birthDate': ['', [Validators.required, Validators.minLength(8)]],
      'accountNumber': ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7)]]
    });
  }
  
  onSubmitRegister(value: any) {
    const apiUrl = environment.userUrl.signupServiceApiUrl;
    if(this.registerForm.valid){
      this.loading = true;
      const companyCode = value.companyCode, 
            employeeNumber = value.employeeNumber, 
            birthDate = new Date(value.birthDate), 
            accountNumber = value.accountNumber;
      // header
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // body
      const body = JSON.stringify({
        commonHeader:{
          pageId: 'SPU0020',
          eventId: 'user_signup_start',
          uuId: null,
          appVersion: null,
          sessionId: null
        },
        parameters:{
          companyCode: companyCode, 
          employeeNumber: employeeNumber, 
          birthDate: moment(birthDate).format('YYYYMMDD'), 
          accountNumber: accountNumber
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}user_signup_start`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // header
            const commonHeaderData = response['commonHeader'];
            // parameters
            const parametersData = response['parameters'];
            sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            sessionStorage.setItem('userParameters', JSON.stringify(parametersData));
            if(commonHeaderData['messageCode'] === '00000'){
              this.loading = false;
              this.router.navigate(['/userRegisterAgreement']);
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
    }
  }

  // キャンセルボタンを押す
  onClickCancel(){
    this.router.navigate(['/userLogin']); // ログイン画面へ
  }
  
  // 所属企業コードのエラーメッセージ
  getCompanyCodeErrorMessage() {
    return this.registerForm.get('companyCode').hasError('required') ? '所属企業コードを入力してください' :
           this.registerForm.get('companyCode').hasError('pattern') ? '所属企業コードを半角英数字で入力してください' :
           '';
  };

  // 従業員番号のエラーメッセージ
  getEmployeeNumberErrorMessage() {
    return this.registerForm.get('employeeNumber').hasError('required') ? '従業員番号を入力してください' :
           this.registerForm.get('employeeNumber').hasError('pattern') ? '従業員番号を半角英数字で入力してください' :
           '';
  };

  // 生年月日のエラーメッセージ
  getBirthDateErrorMessage() {
    return this.registerForm.get('birthDate').hasError('required') ? '生年月日を選択してください' :
           this.registerForm.get('birthDate').hasError('minlength') ? '正しい生年月日を入力してください' :
           '';
  };

  // 口座番号のエラーメッセージ
  getAccountNumberErrorMessage() {
    return this.registerForm.get('accountNumber').hasError('required') ? '口座番号を入力してください' :
           this.registerForm.get('accountNumber').hasError('pattern') ? '口座番号を半角英数字で入力してください' :
           this.registerForm.get('accountNumber').hasError('minlength') ? '口座番号を７桁で入力してください' :
           '';
  };
}