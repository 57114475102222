import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transferErrorSetInfo',
  templateUrl: './transferErrorSetInfo.component.html',
  styleUrls: ['./transferErrorSetInfo.component.css']
})
export class AdminTransferErrorSetInfoComponent implements OnInit {

  titleHeader:String = '振込エラー設定が完了しました。';

  // コンストラクタ
  constructor(
    private router: Router,
  ) { }

  // 画面初期化処理 
  ngOnInit() {
    sessionStorage.setItem('title', '振込エラー設定完了'); // タイトル
  }

  // 閉じるボタンを押す
  onCloseClick(){
    this.router.navigate(['/adminTransferErrorSet']); // 振込エラー設定画面
  }

}
