import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-registerUserAgreementAfterLogin',
  templateUrl: './registerUserAgreementAfterLogin.component.html',
  styleUrls: ['./registerUserAgreementAfterLogin.component.css']
})

export class UserRegisterUserAgreementAfterLoginComponent implements OnInit {

  title: string = '給与前払いサービス－個人情報利用同意';
  companyCode: string; // 所属企業コード
  employeeNumber: string; // 従業員番号
  userName: string;// 従業員名
  privacyUrlAfterLogin; // 個人情報利用同意
  checkboxDisabled: boolean = false; // チェックボックス選択
  
  // コンストラクタ
  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private auth: UserAuthService,
  ) {}

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', this.title); // タイトル
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    const userParameters = JSON.parse(sessionStorage.getItem('userParameters'));
    const piiContract = userParameters['company']['piiContract'];
    // 所属企業コード
    this.companyCode = userParameters.company['companyCode'];
    // 従業員番号
    this.employeeNumber = userParameters['employeeNumber'];
    // 従業員名
    this.userName = userSession['userName'];
    if (piiContract){
      // 個人情報利用同意
      this.privacyUrlAfterLogin = this.domSanitizer.bypassSecurityTrustResourceUrl(piiContract);
    }
    // 認証トークン取得
    this.auth.getToken('SPU0170', userSession['uuId'], userSession['sessionId'], 'affiliation_company_commit'); // トーケンの取得
  }

  // 「確定」ボタン押下時処理
  onConfirm(){
    sessionStorage.removeItem('userCompanyParameters');
    const apiUrl = environment.userUrl.userCompanyUrl;
    // セッションを取得する
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    // ヘッダ設定
    const headers = new HttpHeaders({
      'Authorization':'Bearer ' + JSON.parse(sessionStorage.getItem('tokenParameters'))['authToken']['access_token'],
      'Content-Type': 'application/json'
    });
    // ボディー設定
    const body = JSON.stringify({
      commonHeader:{
        pageId: 'SPU0170',
        eventId: 'affiliation_company_commit',
        uuId: userSession['uuId'],
        appVersion: null,
        sessionId: userSession['sessionId']
      },
      parameters:{
        companyCode: this.companyCode, 
        employeeNumber: this.employeeNumber
      }
    });
    // 送信(個人情報利用同意(所属企業追加)APIを呼出す)
    this.http.post(
      `${apiUrl}affiliation_company_commit`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          if(commonHeaderData['messageCode'] === '00000'){ // 正常の場合
          sessionStorage.setItem('userSession',JSON.stringify(commonHeaderData));
            this.router.navigate(['/userCompanyAddInfo']); // 所属企業追加完了画面へ遷移
          } else {
            this.auth.resDialog(commonHeaderData);
          }
      },
      error => {
        this.auth.errDialog(error);
      }
    );
  }
  
  // 戻るボタンを押す
  onBackClick(){
    sessionStorage.setItem('userCompanyAddBackFLG', '1');
    this.router.navigate(['/userCompanyAdd']); // 所属企業追加画面へ
  }
}