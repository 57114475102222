import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyAuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment.dev';

@Component({
  selector: 'app-companyLogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class CompanyLoginComponent implements OnInit {

  hide: boolean = true;
  loginForm:  FormGroup; // 画面フォーム
  loading: boolean; // ローディング状態
  title = '企業管理者向けログイン画面';
  isGitFlg = false; // GIT

  constructor(
    private http: HttpClient,
    private auth: CompanyAuthService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.auth.isGitClientIP(
      (res) => {
        this.isGitFlg = res['isGitFlg'] === '1';
      }
    );
    this.initForm(); // 画面初期化
  }

  // 画面初期化
  initForm() {
    this.loginForm = this.fb.group({
      'companyCode': ['', [Validators.required]], // 利用企業コード
      'managerId': ['', [Validators.required]], // 利用企業管理者ID
      'password': ['', [Validators.required]]
    });
    // 認証トークン取得
    this.auth.getToken('SPC0010', null, null, null, 'company_login');
    this.loading = false;
  }

  // 登録ボタンを押す
  onSubmitLogin(value: any) {
    // トークンパラメータ
    const tokenParameters = JSON.parse(sessionStorage.getItem('tokenParameters')); // tokenParameters
    const authToken = tokenParameters['authToken']['access_token'];
    // フォーム設定
    this.loginForm = this.fb.group({
      'companyCode': [this.loginForm.get('companyCode').value, [Validators.required]], // 利用企業コード
      'managerId': [this.loginForm.get('managerId').value, [Validators.required]], // 利用企業管理者ID
      'password': [this.loginForm.get('password').value, [Validators.required]]
    });
    if (this.loginForm.valid) {
      // 画面項目取得
      const companyCode = value.companyCode,
        managerId = value.managerId,
        password = value.password;
      // ログイン認証処理
      this.signIn(companyCode, managerId, password, authToken);
    }
  }

  // ログイン認証処理 
  signIn(companyCode, managerId, password, authToken) {
    this.loading = true;
    const apiUrl = environment.companyUrl.companyAuthUrl;
    // header
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json'
    });
    // body
    const body = JSON.stringify({
      commonHeader: {
        pageId: 'SPC0010',
        eventId: 'company_login',
        companyCode: null,
        managerId: null,
        sessionId: null
      },
      parameters: {
        companyCode: companyCode,
        managerId: managerId,
        password: password
      }
    });
    this.http.post(
      `${apiUrl}company_login`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          // commonHeader
          const commonHeaderData = response['commonHeader'];
          sessionStorage.setItem('compSession', JSON.stringify(commonHeaderData));
          this.loading = false;
          // 正常パスワードログインする場合
          if (commonHeaderData['messageCode'] === '01000') {
            sessionStorage.setItem('companyLoggedIn', 'true');
            this.router.navigate(['/companyMain']); // 
            // 仮パスワードログインする場合
          } else if (commonHeaderData['messageCode'] === '01110') {
            sessionStorage.setItem('companyLoggedIn', 'true');
            this.auth.resDialog(commonHeaderData);
            this.router.navigate(['/companyPasswordUpdate']); // パスワード変更へ
          } else {
            sessionStorage.setItem('companyLoggedIn', 'false');
            this.auth.resDialog(commonHeaderData);
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
        }
      );
  }

  // 企業コードのエラーメッセージ
  getCompanyCodeErrorMessage() {
    return this.loginForm.get('companyCode').hasError('required') ? '企業コードを入力してください' :
           this.loginForm.get('companyCode').hasError('pattern') ? '企業コードを半角英数字で入力してください' :
           '';
  };

  // 企業管理者IDのエラーメッセージ
  getManagerIdErrorMessage() {
    return this.loginForm.get('managerId').hasError('required') ? '企業管理者IDを入力してください' :
           this.loginForm.get('managerId').hasError('pattern') ? '企業管理者IDを半角英数字で入力してください' :
           '';
  };

  // パスワードのエラーメッセージ
  getPasswordErrorMessage() {
    return this.loginForm.get('password').hasError('required') ? 'パスワードを入力してください' :
           '';
  };
}