import { Component, OnInit } from '@angular/core';
import { AdminAuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.dev';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-prepaymentUserDetail',
  templateUrl: './prepaymentUserDetail.component.html',
  styleUrls: ['./prepaymentUserDetail.component.css']
})
export class AdminPrepaymentUserDetailComponent implements OnInit {

  dataSource = []; // テーブル
  displayedColumns: string[] = [
    'receiptNumber',
    'applicationTimeJST',
    'applicationAmount',
    'transferFeeBurdenType',
    'transferFee',
    'prepayFeeBurdenType',
    'prepayFee',
    'status'
  ]; // テーブルのコラム
  affiliatedCompany: string; // 所属企業
  companyCode: string; // 企業コード
  userName: string; // 氏名
  prepayLimit: number; // 前払申請可能額
  usageCheckPeriod: string; // 利用状況確認期間
  loading: boolean = false; // ローディング状態

  constructor(
    private auth: AdminAuthService,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('title', "前払利用会員明細"); // タイトル
    // セッション取得
    const adminParameters = JSON.parse(sessionStorage.getItem("adminParameters"));
    this.companyCode = adminParameters['companyCode']; // 企業コード
    this.affiliatedCompany = this.companyCode + ' ' + adminParameters['companyName']; // 所属企業
    this.userName = adminParameters['userName']; // 姓名
    this.prepayLimit = adminParameters['prepayLimit']; // 前払申請可能額
    this.usageCheckPeriod = adminParameters['selectTimeStart'] + '～本日'; // 利用状況確認期間
    let prepayDetailList = adminParameters['prepayDetailList']; // 利用状況リスト
    for (let i of prepayDetailList) {
      i.applicationTimeJST = moment(i['applicationTime']).format('YYYY年MM月DD日');
      i.applicationTimeJSTHm = moment(i['applicationTime']).format('HH時mm分');
      i.transferFeeBurdenType = this.auth.toFeeBurdenTypeStr(i['transferFeeBurdenType'] + '');
      i.prepayFeeBurdenType = this.auth.toFeeBurdenTypeStr(i['prepayFeeBurdenType'] + '');
      i.status = this.auth.statusStr(i['status']);
    }
    this.dataSource = prepayDetailList; // テーブル
    this.onInit();
  }

  // 前払利用状況明細情報初期化処理
  onInit() {
    const apiUrl = environment.adminUrl.userInfo2ServiceApiUrl;
    // 共通ヘッダ
    const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
    // ヘッダ
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // ボディー設定
    const body = JSON.stringify({
      // 共通ヘッダ設定
      commonHeader: {
        pageId: 'SPM0230', // 画面ID
        eventId: 'prepayment_user_detail_init', // イベントID
        uuId: adminSession['uuId'], // 共通ID
        sessionId: adminSession['sessionId'] // セッションID 
      }
    });
    // 送信
    this.http.post(
      `${apiUrl}prepayment_user_detail_init`,
      body,
      {
        headers: headers
      })
      .subscribe(
        response => {
          this.loading = false;
          // レストラン情報：共通ヘッダ
          const commonHeaderData = response['commonHeader'];
          // レストラン情報：メッセージ
          if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
            // セッション設定
            // ヘッダー部 セッション対象
            sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
            this.loading = false;
          } else {
            this.auth.resDialog(commonHeaderData);
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.auth.errDialog(error);
          this.loading = false;
        }
      );
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminPrepaymentUserList']) // 前払利用会員一覧画面へ遷移
  }
}
