import { AdminAuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment.dev';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeprecatedDecimalPipe } from '@angular/common';

@Component({
  selector: 'app-transferFeePatternModify',
  templateUrl: './transferFeePatternModify.component.html',
  styleUrls: ['./transferFeePatternModify.component.css']
})
export class AdminTransferFeePatternModifyComponent implements OnInit {
  transferFeePatternModifyFrom: FormGroup; // 画面フォーム
  bankName: string; // 金融機関名
  companyCode: string; // 企業コード
  companyName: string; // 企業名
  company: string;
  isUpdateFlag: boolean // 情報変更フラグ
  bankCode: string; // 金融機関コード
  intimeSameBranchUnderStr: string; // 同支店時間外手数料(3万円未満)表示項目
  offhoursSameBranchUnderStr: string; // 同支店時間外手数料(3万円未満)表示項目
  intimeAnotherBranchUnderStr: string; // 同行他支店時間内手数料(3万円未満)表示項目
  offhoursAnotherBranchUnderStr: string; // 同行他支店時間外手数料(3万円未満)表示項目
  intimeAnotherBankUnderStr: string; // 他行時間内手数料(3万円未満)表示項目
  offhoursAnotherBankUnderStr: string; // 他行時間外手数料(3万円未満)表示項目
  intimeSameBranchOverStr: string; // 同支店時間内手数料(3万円以上)表示項目
  offhoursSameBranchOverStr: string; // 同支店時間外手数料(3万円以上)表示項目
  intimeAnotherBranchOverStr: string; // 同行他支店時間内手数料(3万円以上)表示項目
  offhoursAnotherBranchOverStr: string; // 同行他支店時間外手数料(3万円以上)表示項目
  intimeAnotherBankOverStr: string; // 他行時間内手数料(3万円以上)表示項目
  offhoursAnotherBankOverStr: string; // 他行時間外手数料(3万円以上)表示項目
  loading: boolean; // ローディング状態

  // コンストラクタ
  constructor(
    private auth: AdminAuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) { }

  // 画面初期化処理
  ngOnInit() {
    sessionStorage.setItem('title', '振込手数料マスタ変更');
    this.loading = true;
    const adminParameters = JSON.parse(sessionStorage.getItem('adminParameters'));
    this.bankCode = adminParameters['bankCode'];
    this.bankName = adminParameters['bankCode'] + ' ' + adminParameters['bankName']; // 金融機関
    this.companyCode = adminParameters['companyCode']; // 企業コード
    this.companyName = adminParameters['companyName']; // 企業名
    this.company = this.companyCode + ' ' + this.companyName;
    this.transferFeePatternModifyFrom = this.fb.group({
      'intimeSameBranchUnder': [adminParameters['intimeSameBranchUnder'], [Validators.pattern('^[0-9]+$')]], // 同支店時間内手数料(3万円未満)
      'offhoursSameBranchUnder': [adminParameters['offhoursSameBranchUnder'], [Validators.pattern('^[0-9]+$')]], // 同支店時間外手数料(3万円未満)
      'intimeAnotherBranchUnder': [adminParameters['intimeAnotherBranchUnder'], [Validators.pattern('^[0-9]+$')]], // 同行他支店時間内手数料(3万円未満)
      'offhoursAnotherBranchUnder': [adminParameters['offhoursAnotherBranchUnder'], [Validators.pattern('^[0-9]+$')]], // 同行他支店時間外手数料(3万円未満)
      'intimeAnotherBankUnder': [adminParameters['intimeAnotherBankUnder'], [Validators.pattern('^[0-9]+$')]], // 他行時間内手数料(3万円未満)
      'offhoursAnotherBankUnder': [adminParameters['offhoursAnotherBankUnder'], [Validators.pattern('^[0-9]+$')]], // 他行時間外手数料(3万円未満)
      'intimeSameBranchOver': [adminParameters['intimeSameBranchOver'], [Validators.pattern('^[0-9]+$')]], // 同支店時間内手数料(3万円以上)
      'offhoursSameBranchOver': [adminParameters['offhoursSameBranchOver'], [Validators.pattern('^[0-9]+$')]], // 同支店時間外手数料(3万円以上)
      'intimeAnotherBranchOver': [adminParameters['intimeAnotherBranchOver'], [Validators.pattern('^[0-9]+$')]], // 同行他支店時間内手数料(3万円以上)
      'offhoursAnotherBranchOver': [adminParameters['offhoursAnotherBranchOver'], [Validators.pattern('^[0-9]+$')]], // 同行他支店時間外手数料(3万円以上)
      'intimeAnotherBankOver': [adminParameters['intimeAnotherBankOver'], [Validators.pattern('^[0-9]+$')]], // 他行時間内手数料(3万円以上)
      'offhoursAnotherBankOver': [adminParameters['offhoursAnotherBankOver'], [Validators.pattern('^[0-9]+$')]] // 他行時間外手数料(3万円以上)
    });
    this.intimeSameBranchUnderStr = this.transform(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').value); // 同支店時間内手数料(3万円未満)
    this.offhoursSameBranchUnderStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').value); // 同支店時間外手数料(3万円未満)
    this.intimeAnotherBranchUnderStr = this.transform(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').value); // 同行他支店時間内手数料(3万円未満)
    this.offhoursAnotherBranchUnderStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').value); // 同行他支店時間外手数料(3万円未満)
    this.intimeAnotherBankUnderStr = this.transform(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').value); // 他行時間内手数料(3万円未満)
    this.offhoursAnotherBankUnderStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').value); // 他行時間外手数料(3万円未満)
    this.intimeSameBranchOverStr = this.transform(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').value); // 同支店時間内手数料(3万円以上)
    this.offhoursSameBranchOverStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').value); // 同支店時間外手数料(3万円以上)
    this.intimeAnotherBranchOverStr = this.transform(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').value); // 同行他支店時間内手数料(3万円以上)
    this.offhoursAnotherBranchOverStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').value); // 同行他支店時間外手数料(3万円以上)
    this.intimeAnotherBankOverStr = this.transform(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').value); // 他行時間内手数料(3万円以上)
    this.offhoursAnotherBankOverStr = this.transform(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').value); // 他行時間外手数料(3万円以上)
    this.loading = false;
  }

  // 確認ボタンを押す
  onConfirm() {
    if (this.loading === true) {
      return;
    }
    if (this.transferFeePatternModifyFrom.valid) {
      this.loading = true;
      // セッション取得
      const adminSession = JSON.parse(sessionStorage.getItem('adminSession'));
      // APIURL
      const apiUrl = environment.adminUrl.transInfoServiceApiUrl;
      // 送信前 ヘッダー部設定
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      // 送信前 ボディー設定
      const body = JSON.stringify({
        // 共通ヘッダー
        commonHeader: {
          pageId: 'SPM0170',  // 画面ID
          eventId: 'transferfee_modify_confirm', // イベントID
          uuId: adminSession['uuId'], // 共通ID
          sessionId: adminSession['sessionId'] // セッションID 
        },
        parameters: {
          bankCode: this.bankCode, //金融機関コード
          bankName: this.bankName, // 金融機関
          companyCode: this.companyCode, // 企業コード 
          intimeSameBranchUnder: new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').value), // 同支店時間内手数料(3万円未満)
          offhoursSameBranchUnder: new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').value), // 同支店時間外手数料(3万円未満)
          intimeAnotherBranchUnder: new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').value), // 同行他支店時間内手数料(3万円未満)
          offhoursAnotherBranchUnder: new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').value), // 同行他支店時間外手数料(3万円未満)
          intimeAnotherBankUnder: new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').value), // 他行時間内手数料(3万円未満)
          offhoursAnotherBankUnder: new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').value), // 他行時間外手数料(3万円未満)
          intimeSameBranchOver: new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').value), // 同支店時間内手数料(3万円以上)
          offhoursSameBranchOver: new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').value), // 同支店時間外手数料(3万円以上)
          intimeAnotherBranchOver: new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').value), // 同行他支店時間内手数料(3万円以上)
          offhoursAnotherBranchOver: new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').value), // 同行他支店時間外手数料(3万円以上)
          intimeAnotherBankOver: new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').value), // 他行時間内手数料(3万円以上)
          offhoursAnotherBankOver: new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').value), // 他行時間外手数料(3万円以上)
          updateAddFlag: 1, // 情報変更フラグ
        }
      });
      // 送信
      this.http.post(
        `${apiUrl}transferfee_modify_confirm`,
        body,
        {
          headers: headers
        })
        .subscribe(
          response => {
            // レスポンス情報：共通ヘッダ
            const commonHeaderData = response['commonHeader'];
            // レスポンス情報：パラメータ
            const parametersData = response['parameters'];
            // レスポンス情報：メッセージ
            if (commonHeaderData['messageCode'] === '02000') { // 正常の場合
              // セッション設定
              sessionStorage.setItem('adminSession', JSON.stringify(commonHeaderData));
              sessionStorage.setItem('adminParameters', JSON.stringify(parametersData));
              this.loading = false;
              // 画面遷移
              this.router.navigate(['/adminTransferFeePatternModifyConfirm']);
            } else {
              this.loading = false;
              this.auth.resDialog(commonHeaderData);
            }
          },
          error => {
            this.loading = false;
            this.auth.errDialog(error);
          }
        );
    }
  }

  // 金額型項目フォマード処理Function(XXXXXXXXX → XXX,XXX,XXX)
  transform(value: any, digits?: string): string {
    if(this.transferFeePatternModifyFrom.valid){
      return new DeprecatedDecimalPipe('ja').transform(value, digits);
    }
    return value;
  }

  // 同支店時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toIntimeSameBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').valid){
      this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').value;
      this.intimeSameBranchUnderStr = this.transform(str);
    }
  }

  // 同支店時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offIntimeSameBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').valid){
      this.intimeSameBranchUnderStr = new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').value).toString();
    }
  }

  // 同支店時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursSameBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').valid){
      this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').value;
      this.offhoursSameBranchUnderStr = this.transform(str);
    }
  }

  // 同支店時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursSameBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').valid){
      this.offhoursSameBranchUnderStr = new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').value).toString();
    }
  }

  // 同行他支店時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toIntimeAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').valid){
      this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').value;
      this.intimeAnotherBranchUnderStr = this.transform(str);
    }
  }

  // 同行他支店時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction)
  offIntimeAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').valid){
      this.intimeAnotherBranchUnderStr = new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').value).toString();
    }
  }

  // 同行他支店時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').valid){
      this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').value;
      this.offhoursAnotherBranchUnderStr = this.transform(str);
    }
  }

  // 同行他支店時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBranchUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').valid){
      this.offhoursAnotherBranchUnderStr = new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').value).toString();
    }
  }

  // 他行時間内手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toIntimeAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').valid){
      this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').value;
      this.intimeAnotherBankUnderStr = this.transform(str);
    }
  }

  // 他行時間内手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').valid){
      this.intimeAnotherBankUnderStr = new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').value).toString();
    }
  }

  // 他行時間外手数料(3万円未満)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').valid){
      this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').value;
      this.offhoursAnotherBankUnderStr = this.transform(str);
    }
  }

  // 他行時間外手数料(3万円未満)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBankUnderNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').valid){
      this.offhoursAnotherBankUnderStr = new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').value).toString();
    }
  }

  // 同支店時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toIntimeSameBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').valid){
      this.transferFeePatternModifyFrom.get('intimeSameBranchOver').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeSameBranchOver').value;
      this.intimeSameBranchOverStr = this.transform(str);
    }
  }

  // 同支店時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeSameBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').valid){
      this.intimeSameBranchOverStr = new Number(this.transferFeePatternModifyFrom.get('intimeSameBranchOver').value).toString();
    }
  }

  // 同支店時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursSameBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').valid){
      this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').value;
      this.offhoursSameBranchOverStr = this.transform(str);
    }
  }

  // 同支店時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursSameBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').valid){
      this.offhoursSameBranchOverStr = new Number(this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').value).toString();
    }
  }

  // 同行他支店時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toIntimeAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').valid){
      this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').value;
      this.intimeAnotherBranchOverStr = this.transform(str);
    }
  }

  // 同行他支店時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').valid){
      this.intimeAnotherBranchOverStr = new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').value).toString();
    }
  }

  // 同行他支店時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').valid){
      this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').value;
      this.offhoursAnotherBranchOverStr = this.transform(str);
    }
  }

  // 同行他支店時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBranchOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').valid){
      this.offhoursAnotherBranchOverStr = new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').value).toString();
    }
  }

  // 他行時間内手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toIntimeAnotherBankOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').valid){
      this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').setValue(new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').value;
      this.intimeAnotherBankOverStr = this.transform(str);
    }
  }

  // 他行時間内手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offIntimeAnotherBankOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').valid){
      this.intimeAnotherBankOverStr = new Number(this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').value).toString();
    }
  }

  // 他行時間外手数料(3万円以上)編集を行うFunction(XXX,XXX,XXX)
  toOffhoursAnotherBankOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').valid){
      this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').setValue(new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').value) + '');
      let str = this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').value.toString().trim();
      this.offhoursAnotherBankOverStr = this.transform(str);
    }
  }

  // 他行時間外手数料(3万円以上)はフォーカスオン処理（編集を解除するFunction）
  offOffhoursAnotherBankOverNumberFmt() {
    if(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').valid){
      this.offhoursAnotherBankOverStr = new Number(this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').value).toString();
    }
  }

  // 戻るボタンを押す
  onBackClick() {
    this.router.navigate(['/adminTransferFeePatternSearch']); // 振込手数料マスタ照会画面
  }

  // 同支店時間内手数料(3万円未満)のエラーメッセージ
  getintimeSameBranchUnderMessage() {
    return this.transferFeePatternModifyFrom.get('intimeSameBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursSameBranchUnderMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursSameBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間内手数料(3万円未満)のエラーメッセージ
  getintimeAnotherBranchUnderMessage() {
    return this.transferFeePatternModifyFrom.get('intimeAnotherBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursAnotherBranchUnderMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursAnotherBranchUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間内手数料(3万円未満)のエラーメッセージ
  getintimeAnotherBankUnderMessage() {
    return this.transferFeePatternModifyFrom.get('intimeAnotherBankUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間外手数料(3万円未満)のエラーメッセージ
  getoffhoursAnotherBankUnderMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursAnotherBankUnder').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間内手数料(3万円以上)のエラーメッセージ
  getintimeSameBranchOverMessage() {
    return this.transferFeePatternModifyFrom.get('intimeSameBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同支店時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursSameBranchOverMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursSameBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間内手数料(3万円以上)のエラーメッセージ
  getintimeAnotherBranchOverMessage() {
    return this.transferFeePatternModifyFrom.get('intimeAnotherBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 同行他支店時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursAnotherBranchOverMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursAnotherBranchOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間内手数料(3万円以上)のエラーメッセージ
  getintimeAnotherBankOverMessage() {
    return this.transferFeePatternModifyFrom.get('intimeAnotherBankOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }

  // 他行時間外手数料(3万円以上)のエラーメッセージ
  getoffhoursAnotherBankOverMessage() {
    return this.transferFeePatternModifyFrom.get('offhoursAnotherBankOver').hasError('pattern') ? '当該項目を半角数字で入力してください' :
           '';
  }
}
